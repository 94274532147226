import {
  SET_PLAN_DATA,
  SET_CUSTOMIZED_PLAN_DATA,
  SET_DISPLAY_ADDING_PLAN_FORM,
} from '../../Types/LearningPlans';

export const setLearningPlanData = (payload) => ({
  type: SET_PLAN_DATA,
  payload,
});

export const setCustomizedPlanData = (payload) => ({
  type: SET_CUSTOMIZED_PLAN_DATA,
  payload,
});

export const setDisplayAddingPlanForm = (payload) => ({
  type: SET_DISPLAY_ADDING_PLAN_FORM,
  payload,
});
