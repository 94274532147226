/**
 * @function Charts
 *
 */

 import {
  lineChartParameters,
  barChartParameters,
  DoughnutChartParameters,
} from '../../../MockData/Home/QuickStats';

export const lineChartOptions = {
  responsive: true,
  scales: {
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 17,
        boxWidth: 17,
        padding: 11,
        font: {
          size: 8,
        },
      },
    },
  },
};

export const ManagerDashboardlineChartOptions = {
  responsive: true,
  scales: {
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
    x: {
      display: true,
      ticks: {
        display: false
      },
      grid: {
        borderDash: [8, 4],
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        boxHeight: 17,
        boxWidth: 17,
        padding: 11,
        font: {
          size: 8,
        },
      },
    },
  },
};

export const lineChartData = {
  labels: lineChartParameters.axislabels,
  datasets: [
    {
      label: 'Nudges',
      data: lineChartParameters.data1,
      lineTension: 0.3,
      backgroundColor: '#913DFB',
      borderColor: '#913DFB',
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
      pointBorderColor: '#2a2c30',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#2a2c30',
      pointHoverBorderColor: '#fff',
      pointHoverBorderWidth: 5,
      pointRadius: 0,
      pointHitRadius: 5,
    },
    {
      label: 'Nudges',
      data: lineChartParameters.data2,
      lineTension: 0.3,
      backgroundColor: '#f84c1e',
      borderColor: '#f84c1e',
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
      pointBorderColor: '#f84c1e',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#f84c1e',
      pointHoverBorderColor: '#fff',
      pointHoverBorderWidth: 5,
      pointRadius: 0,
      pointHitRadius: 5,
    },
  ],
};

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        max: 60,
        min: 20,
        stepSize: 20,
        color: '#757F88',
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        color: '#A3AED0',
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Session Logs',
      position: 'bottom',
      color: '#A3AED0',
      padding: {
        top: 14,
      },
    },
  },
};

export const barChartData = {
  labels: [
    'week 1',
    'week 2',
    'week 3',
    'week 4',
    'week 5',
    'week 6',
    'week 7',
    'week 8',
  ],
  datasets: [
    {
      data: barChartParameters.data,
      backgroundColor: '#87D4E4',
      barThickness: 35,
      borderRadius: 3,
    },
  ],
};

export const firstDoughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Emotion for Memory',
      position: 'bottom',
      color: '#028277',
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 20,
        boxWidth: 20,
        padding: 24,
        font: {
          size: 12,
        },
      },
    },
  },
};

export const firstDoughnutChartData = {
  labels: ['Strong', 'Weak', 'N/A'],
  datasets: [
    {
      data: DoughnutChartParameters.data,
      backgroundColor: ['#00A89A', '#87D4E4', '#FB8C26'],
      borderColor: ['#00A89A', '#87D4E4', '#FB8C26'],
      borderWidth: 1,
    },
  ],
};

export const secondDoughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Assessment',
      position: 'bottom',
      color: '#028277',
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 20,
        boxWidth: 20,
        padding: 24,
        font: {
          size: 12,
        },
      },
    },
  },
};

export const secondDoughnutChartData = {
  labels: ['Positive', 'In Progress', 'Negative'],
  datasets: [
    {
      data: DoughnutChartParameters.data,
      backgroundColor: ['#00A89A', '#A45BFF', '#87D4E4'],
      borderColor: ['#00A89A', '#A45BFF', '#87D4E4'],
      borderWidth: 1,
    },
  ],
};

export const MixedChartData = (EmployeLearningPlanGraphData) => {
  return {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    datasets: [
      {
        type: 'line',
        label: EmployeLearningPlanGraphData.LineALabel,
        data: EmployeLearningPlanGraphData.LineA,
        lineTension: 0.3,
        backgroundColor: '#028277',
        borderColor: '#028277',
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderColor: '#2a2c30',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#2a2c30',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        type: 'line',
        label: EmployeLearningPlanGraphData.LineBLabel,
        data: EmployeLearningPlanGraphData.LineB,
        lineTension: 0.3,
        backgroundColor: '#f84c1e',
        borderColor: '#f84c1e',
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderColor: '#2a2c30',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#2a2c30',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        type: 'bar',
        label: EmployeLearningPlanGraphData.BarLabel,
        data: EmployeLearningPlanGraphData.Bar,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(135, 212, 228,0.3)',
      },
    ],
  };
};

export const mixedChartOption = {
  scales: {
    y: {
      display: true,
      grid: {
        display: true,
      },
    },
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: 'rgba(0, 168, 154, 0.18)',
      titleColor: 'rgba(0, 168, 154, 1)',
      titleAlign: 'left',
      displayColors: true,
      boxWidth: 0,
      bodyAlign: 'left',
      titleMarginBottom: 10,
      titleFont: { size: 16, weight: 'bold', lineHeight: 1.9 },
      usePointStyle: true,
      boxHeight: 0,
      footerFont: { size: 16 },
      padding: '15',
      caretSize: 15,
      yAlign: 'bottom',
      callbacks: {
        labelTextColor: function (context) {
          return '#000000';
        },
        labelPointStyle: function (context) {
          return {
            pointStyle: 'star',
            rotation: 0,
          };
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 20,
        boxWidth: 20,
        padding: 24,
        font: {
          size: 12,
        },
      },
    },
  },
};



export const MixedChartDataLearningPlan = {
  labels: ['Plan 1','Plan 2','Plan 3','Plan 4','Plan 5','Plan 6','Plan 7','Plan 8','Plan 9','Plan 10'],
  datasets: [
    {
      type: 'bar',
      label: 'Focused',
      data: [25,95,33,72,97,34,23,22,53,66,37],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: '#87D4E4',
      borderRadius:8,
    },
  ],
};

export const mixedChartOptionLearningPlan = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    
    y: {
      min:0,
      max:100,
      ticks: {
        max: 100,
        min: 25,
        stepSize: 25,
        color: "#4318FF",
      },
    },
    x: {
      ticks: {
        color: "#A3AED0",
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};



export const MixedChartDataAverageLearning = {
  labels: [1,2,3,4,5,6],
  datasets: [
    
    // {
    //   type: 'line',
    //   label: 'Hours Studied',
    //   data: [25,31,76,29,88,31],
    //   lineTension: 0.3,
    //   backgroundColor: '#028277',
    //   borderColor: '#028277',
    //   borderCapStyle: 'round',
    //   borderJoinStyle: 'round',
    //   pointBorderColor: '#2a2c30',
    //   pointBackgroundColor: '#fff',
    //   pointBorderWidth: 1,
    //   pointHoverRadius: 5,
    //   pointHoverBackgroundColor: '#2a2c30',
    //   pointHoverBorderColor: '#fff',
    //   pointHoverBorderWidth: 5,
    //   pointRadius: 0,
    //   pointHitRadius: 5,
    // },
    {
      type: 'line',
      label: 'Weekly Avg Hours',
      data: [5,35,50,90,50,70],
      
      lineTension: 0.3,
      backgroundColor: '#FFA372',
      borderColor: '#FFA372',
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
      pointBorderColor: '#2a2c30',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#2a2c30',
      pointHoverBorderColor: '#fff',
      pointHoverBorderWidth: 5,
      pointRadius: 0,
      pointHitRadius: 5,
    },
    {
      type: 'bar',
      label: 'Capabilities Studied',
      data: [49,58,73,73,106,138],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: '#00A89A',
      borderRadius: 3,
    },
    {
      type: 'bar',
      label: 'Hours Studied',
      data: [66,89,83,97,84,83],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: '#87D4E4',
      borderRadius: 3,
    },
  ],
};

export const mixedChartOptionAverageLearning = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      min:0,
      max:100,
      
      display:true,
      ticks: {
        max: 100,
        min: 25,
        stepSize: 25,
        color: "#4318FF",
      },
      ticks: {
        beginAtZero: true,
        display: false,
        stepSize : 35
      },
      grid: {
        
      drawBorder: false ,
        z:2,
        maxLines: 30,
        lineWidth: 2,
        color: "#F8F8F8",
        beginAtZero: true,
        display: true,
      },
      
    },
    x: {
      drawBorder: false ,
      display:false,
      ticks: {
        beginAtZero: true,
      },
      grid: {
        drawBorder: false ,
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxHeight: 12,
        boxWidth: 12,
        padding: 20,
        font: {
          size: 12,
        },
      },
    },
  },
};

