/**
 * @function SignUpFooter
 * @param {none}
 * @returns It contains the footer of the application.
 */

import React from 'react';
import footerStyling from './footer.module.scss';
import Text from '../../Shared/Text';
// import { useHistory } from 'react-router-dom';
const SignUpFooter = () => {
  // const history = useHistory();
  return (
    <div className={`${footerStyling.signupFooterContiner}`}>
      <div onClick={() => window.open('/terms-and-conditions', '_blank')}>
        <Text
          classToOverride={`${footerStyling.signupFooter} ${footerStyling.cursorPointer}`}
          textType="t2"
        >
          {' '}
          Terms and Conditions{' '}
        </Text>
      </div>
      <div onClick={() => window.open('/privacy-policy', '_blank')}>
        <Text
          classToOverride={`${footerStyling.signupFooter} ${footerStyling.cursorPointer}`}
          textType="t2"
        >
          Privacy Policy{' '}
        </Text>
      </div>
      <Text classToOverride={`${footerStyling.signupFooter}`} textType="t2">
        {' '}
        © AHURA AI 2021 | All Rights Reserved.{' '}
      </Text>
    </div>
  );
};

export default SignUpFooter;
