import React from 'react';
import CustomTabs from '../../Layout/Tabs';
import { Box } from '@mui/system';
import { LearningPlansTabsOptions } from '../../../helpers/LearningPlans';

const LearningPlansTabs = ({ index, setTabIndex }) => {
  const handleChange = (e, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={4}>
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={LearningPlansTabsOptions}
      />
    </Box>
  );
};

export default LearningPlansTabs;
