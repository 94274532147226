import React, { useEffect, useState } from 'react';
import c from '../../Profile.Module.scss';
import classNames from 'classnames';
import Text from '../../../Shared/Text';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import moment from 'moment';
import { Button, Modal } from '@mui/material';
import { getTargetsData } from '../../../../helpers/Profile/Targets/WishlistCapabilities';
import ScheduleModal from './ScheduleModal';
import SchedulesWithDates from './SchedulesWithDates';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const Schedule = ({ capabilities = [], learningPlans = [] }) => {
  const [value, setValue] = useState(new Date());
  const [TargetsData, setTargetsData] = useState({});
  const [calanderDates, setCalenderDates] = useState([]);
  const [formatedDate, setFormatteddate] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [plansArray, setPlansArray] = useState([]);

  useEffect(() => {
    loadTargetsData();
  }, []);

  useEffect(() => {
    const plans = learningPlans.map((item, index) => [
      item?.description,
      index,
    ]);
    setPlansArray(plans);
  }, [learningPlans]);

  // -----------------Fetching and sorting Schedules Data------------------
  const loadTargetsData = async () => {
    const response = await getTargetsData();
    setTargetsData(response);
    const sorted_dates = Object.keys(response).sort((a, b) => {
      const d1 = new Date(a);
      const d2 = new Date(b);
      if (d1 > d2) {
        return 1;
      }
      if (d2 > d1) {
        return -1;
      }
      return 0;
    });
    setCalenderDates([...sorted_dates]);
  };

  const onDateSelect = async (newValue) => {
    setValue(newValue);
    const date = moment(newValue).format('YYYY-MM-DD');
    setFormatteddate(date);

    const found = calanderDates.find((item) => item === date);
    const isEligible = moment().startOf('day').isSameOrBefore(date);
    if (isEligible && !found) {
      const data_cp = [...calanderDates];
      data_cp.push(date);
      data_cp.sort((a, b) => {
        const d1 = new Date(a);
        const d2 = new Date(b);
        if (d1 > d2) {
          return 1;
        }
        if (d2 > d1) {
          return -1;
        }
        return 0;
      });
      await setCalenderDates([...data_cp]);
    }
    const con = document.getElementById(date);
    if (con) {
      con.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classNames(c.targets, 'p-3')}>
      <div className="d-flex justify-content-between mt-2">
        <div>
          <Text textType="t1" classToOverride={c.fontWeight600}>
            Schedule
          </Text>
          {/* <Text textType="t2" classToOverride={classNames(c.fade, 'fw-normal')}>
            Another line here for description
          </Text> */}
        </div>
        <Button
          className={classNames(c.button)}
          startIcon={<AddCircleIcon />}
          variant="contained"
          onClick={() => {
            setModalOpen(true);
            googleAnalyticsTriggerFn(
              'Button',
              'Add New Schedule',
              'Add new schedule button clicked'
            );
          }}
          sx={{ textTransform: 'capitalize' }}
        >
          Add New Schedule
        </Button>
      </div>
      <div className="row mt-3">
        {/* -----------MUI Datepicker Calender------------ */}
        <div className="col-6">
          <div className={classNames(c.calanderBorder)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                textFieldStyle={{ fontSize: 28 }}
                // openTo="month"
                value={value}
                onChange={onDateSelect}
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </div>
        </div>
        {/* --------------Mapping Upcoming courses------------ */}
        <div className={classNames('col-6')}>
          <Text textType="t1" classToOverride={c.fontWeight600}>
            Upcoming Courses
          </Text>
          <div
            className={classNames(c.targetsWithDateCon, 'p-3')}
            id="targetsWithDateCon"
          >
            {calanderDates &&
              calanderDates.map((item) => (
                <div
                  id={item}
                  className={classNames(
                    'mb-3 py-1 px-2',
                    item === formatedDate
                  )}
                  key={item}
                >
                  <SchedulesWithDates
                    data={TargetsData[item]}
                    date={new Date(item)}
                    addCapability={item === formatedDate}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Add schedule"
        aria-describedby="add schedule"
        // className={classNames(c.modalBox)}
      >
        <div>
          <ScheduleModal
            capabilities={capabilities}
            learningPlans={plansArray}
            date={formatedDate}
            loadTargetsData={loadTargetsData}
            setModalOpen={setModalOpen}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Schedule;
