import React, { useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import classNames from 'classnames';
// import c from '../quiz.module.scss';
// import { Button } from '@mui/material';
// import Text from '../../../Shared/Text';
// import { submitAnswer } from '../../../../helpers/Quiz';

import { useSelector } from 'react-redux';

const QuizRadioButtonForm = ({
  options = [],
  questionData,
  addQuestionsAns,
  submitStatus,
}) => {
  const [answer, setAnswer] = useState('');
  const user_client_id = useSelector((state) => state?.generalInformation?.id);

  const submit = (answer) => {
    const answer_data = options.find((value) => value?.id === answer);
    // //increment total attempted ques and increment total correct answers in QuizPage Component in pages folder
    // incrementTotalAttemptedQues();
    // if (answer_data?.is_correct) {
    //   incrementTotalCorrectAns();
    // }

    const params = {
      assignment_id: questionData?.assignment_id,
      question_id: questionData?.id,
      option_id: answer,
      is_correct: answer_data?.is_correct,
      user_id: user_client_id,
      essay_content: null,
      plain_essay_content: null,
    };

    //call api here
    // submitAnswer(params);

    addQuestionsAns(questionData?.id, params);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={answer}
        onChange={(e) => {
          setAnswer(e.target.value);
          submit(e.target.value);
        }}
        className={classNames('ms-4')}
      >
        {options.map(
          (option) =>
            option?.option_value && (
              <React.Fragment key={option?.id}>
                <FormControlLabel
                  value={option?.id}
                  control={<Radio />}
                  label={option?.option_value}
                />
              </React.Fragment>
            )
        )}
      </RadioGroup>
      {answer === '' && submitStatus === true && (
        <small className="text-danger ms-5">Please choose any option</small>
      )}

      {/* <Button
        className={classNames(c.Button, 'fw-bold mt-2')}
        variant="contained"
        disabled={answer ? false : true}
        onClick={() => {
          submit();
        }}
      >
        Submit Answer
      </Button> */}
    </FormControl>
  );
};
export default QuizRadioButtonForm;
