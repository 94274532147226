/**
 * @function AddingPlanRightPanel
 * Renders the right panel of the plan
 */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import { createPlanModuleOrLesson } from '../../../helpers/LearningPlans/AddingPlanRightPanel';
import { setCustomizedPlanData } from '../../../redux/Actions/LearningPlans';
import { Drawer, Toolbar, Badge, Divider } from '@mui/material';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import { iconData } from '../../../helpers/LearningPlans/AddingPlanRightPanel';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddCustomizedContentForm from './AddCustomizedContent/Form';
import CustomizedContentLayout from './AddCustomizedContent/Layout';
import AddNewModuleForm from './AddNewModule/Form';
import ModuleContentLayout from './AddNewModule/Layout';
import ModuleCard from './AddNewModule/ModuleCard';
import FilterControls from './FilterControls';

const AddingPlanRightPanel = ({
  parentId,
  setModuleData,
  setSelectedModuleId,
  planModuleData,
}) => {
  const [iconsActive, setIconsActive] = useState({
    LibraryAdd: true,
    FilterAlt: false,
  });
  const [activeOptionsCount, setActiveOptionsCount] = useState(0);
  const [showContentLayout, setShowContentLayout] = useState(false);

  const showForm = useSelector((state) => state?.displayAddingPlanForm) || {};

  const dispatch = useDispatch();

  const handleModuleData = async (formData) => {
    dispatch(showLoader());
    const response = await createPlanModuleOrLesson({ ...formData }, parentId);
    if (response) {
      setModuleData();
    }
    dispatch(hideLoader());
  };

  const handleModulePlanClick = (id) => {
    setSelectedModuleId(id);
    const lessonsInfo =
      planModuleData.filter((obj) => obj?.id === id)[0]?.lessons || [];
    dispatch(setCustomizedPlanData([...lessonsInfo]));
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{
        paper: classNames(c.rightPanel),
      }}
    >
      <Toolbar />
      <div className="row g-0">
        {iconData.map((item) => (
          <div
            key={item?.id}
            className={classNames(
              'col-2 text-center',
              c.cursorPointer,
              iconsActive[item?.name] ? c.iconActive : c.iconInactive
            )}
            onClick={() =>
              setIconsActive({
                LibraryAdd: item?.active,
                FilterAlt: !item?.active,
              })
            }
          >
            {item?.active ? (
              <LibraryAddOutlinedIcon className="fs-3 my-3" />
            ) : (
              <Badge
                badgeContent={activeOptionsCount}
                color="primary"
                className="my-3"
              >
                <FilterAltOutlinedIcon className="fs-3" />
              </Badge>
            )}
          </div>
        ))}
      </div>

      <Divider />

      <div>
        {iconsActive.LibraryAdd ? (
          <>
            {showForm?.customizedContentAddition ? (
              <AddCustomizedContentForm />
            ) : (
              <>
                {showContentLayout ? (
                  <CustomizedContentLayout
                    displayLayout={setShowContentLayout}
                  />
                ) : (
                  <>
                    {showForm?.moduleAddition ? (
                      <AddNewModuleForm handleSubmit={handleModuleData} />
                    ) : (
                      <ModuleContentLayout>
                        {planModuleData.length > 0 &&
                          planModuleData.map((item) => (
                            <ModuleCard
                              key={item?.id}
                              handleModulePlanClick={handleModulePlanClick}
                              displayLayout={setShowContentLayout}
                              title={item?.heading}
                              id={item?.id}
                              showEllipsisIcon={true}
                            >
                              {item?.description}
                            </ModuleCard>
                          ))}
                      </ModuleContentLayout>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <FilterControls
            activeOptionsCount={activeOptionsCount}
            setActiveOptionsCount={setActiveOptionsCount}
          />
        )}
      </div>
    </Drawer>
  );
};

export default AddingPlanRightPanel;
