import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall } from '../../../utils/axios/axios';

export const LicenseDistributionDoughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'License Usage',
      position: 'bottom',
      color: '#028277',
      padding: {
        top: 50,
      },
      font: {
        size: '18px',
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      padding: 30,
      labels: {
        boxHeight: 20,
        padding: 50,
        boxWidth: 20,
        font: {
          size: 12,
        },
      },
    },
  },
};

export const LicenseDistributionDoughnutChartData = {
  labels: ['Used Licenses', 'Inactive Licenses', 'Remaining Licenses'],
  datasets: [
    {
      data: [47, 33, 20],
      backgroundColor: ['#00A89A', '#A45BFF', '#87D4E4'],
      borderColor: ['#00A89A', '#A45BFF', '#87D4E4'],
      borderWidth: 1,
    },
  ],
};

// export const doughnutChartPlugin = {
//   id: 'doughnut',
//   afterDraw(ChartJS) {
//     const {
//       ctx,
//       chartArea: { width },
//     } = ChartJS;

//     ChartJS.data.datasets.forEach((dataset, i) => {
//       ChartJS.getDatasetMeta(i).data.forEach((datapoint, index) => {
//         const { x, y } = datapoint.tooltipPosition();
//         const hw = width / 2;
//         const xLine = x >= hw ? x + 35 : x - 35;
//         const data = [
//           store?.getState()?.licenseData?.used_licenses,
//           store?.getState()?.licenseData?.inactive_licenses,
//           store?.getState()?.licenseData?.remaining_licenses,
//         ];

//         const extraLine = x >= hw ? 35 : -35;
//         ctx.beginPath();
//         ctx.moveTo(x, y);
//         ctx.lineTo(xLine, y);
//         ctx.lineTo(xLine + extraLine, y);
//         ctx.strokeStyle = '#C4C4C4';
//         ctx.stroke();
//         ctx.font = '15px Arial';
//         const textXPosition = x >= hw ? 'left' : 'right';
//         const plusFivePx = x >= hw ? 20 : -20;
//         ctx.textAlign = textXPosition;
//         ctx.textBaseline = 'middle';
//         ctx.fillText(
//           (
//             (data[index] * 100) /
//             store?.getState()?.licenseData?.total_licenses
//           ).toFixed(2) + '%',
//           xLine + extraLine + plusFivePx,
//           y
//         );
//         ctx.fillText('Highest level', xLine + extraLine + plusFivePx, y - 20);
//       });
//     });
//   },
// };

export const getLicenseData = async (client_id) => {
  try {
    const paramsData = {
      queryParams: {
        corporate_id: client_id,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(
      API_URLS?.license_distribution,
      paramsData
    );
    return response;
  } catch (error) {
    console.log(`Error in fetching License data ${error}`);
  }
};
