import { EMAIL_REGEX_PATTERN } from '../../config/Regex/regex';
import { postApiCall } from '../../utils/axios/axios';
import { API_URLS } from '../../config/API_URLS/api_urls';
import { CLIENT_ID } from '../../config/Constants';
export const initialValues = {
  email: '',
};

export const validate = (values) => {
  const errors = {};
  if (!values?.email) {
    errors.email = 'Email is required';
  }
  if (
    values?.email &&
    !EMAIL_REGEX_PATTERN.test(values?.email?.toLowerCase())
  ) {
    errors.email = 'Not a valid Email';
  }
  return errors;
};

export const validatePassword = (values) => {
  const errors = {};
  if (!values?.password) {
    errors.password = 'Password is required';
  }
  if (!values?.confirm_password) {
    errors.confirm_password = 'Confirm password is required';
  }
  if (values?.confirm_password && values?.password) {
    if (values?.password !== values?.confirm_password)
      errors.confirm_password = 'Password and confirm password do not match';
  }
  return errors;
};

export const handleSubmit = async (email, setIsLoading, setSuccess) => {
  try {
    const reqBody = {
      client_id: CLIENT_ID, //Use CLIENT_ID from Config
      email: email,
    };
    setSuccess(false);
    setIsLoading(true);
    const res = await postApiCall(API_URLS.forgot_password, reqBody);
    if (res?.error) {
      console.log('Err', res?.error?.message);
    } else {
      setSuccess(true);
    }
    setIsLoading(false);
  } catch (err) {
    console.log('error in login', err);
    setIsLoading(false);
    setSuccess(false);
  }
};

export const resendCode = async (email) => {
  try {
    const reqBody = {
      client_id: CLIENT_ID,
      email: email,
    };
    const res = await postApiCall(API_URLS.forgot_password, reqBody);
    if (res?.error) {
      console.log('Err', res?.error?.message);
    }
  } catch (err) {
    console.log('error in sending code', err);
  }
};

export const activationCodeVerification = async (
  code,
  email,
  setIsLoading,
  setSuccess
) => {
  try {
    const reqBody = {
      code: code,
      client_id: CLIENT_ID,
      email: email,
    };
    setSuccess(false);
    setIsLoading(true);
    const res = await postApiCall(
      API_URLS.activation_code_verification,
      reqBody
    );
    if (res?.error) {
      console.log('Err', res?.error?.message);
    } else {
      setSuccess(true);
    }
    setIsLoading(false);
  } catch (err) {
    console.log('error in otp verification', err);
    setIsLoading(false);
    setSuccess(false);
  }
};

export const updatePassword = async (values, mail, setIsLoading, history) => {
  try {
    const reqBody = {
      password: values?.password,
      confirm_password: values?.confirm_password,
      email: mail,
      client_id: CLIENT_ID,
    };
    setIsLoading(true);
    const res = await postApiCall(API_URLS.create_password, reqBody);
    if (res?.error) {
      console.log('Err', res?.error?.message);
    } else {
      history.push('/sign-in');
    }
    setIsLoading(false);
  } catch (err) {
    console.log('error in updating password', err);
    setIsLoading(false);
  }
};
