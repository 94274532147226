import React from 'react';
import { CircularProgress } from '@mui/material';
import Headings from '../../../Shared/Headings';
import c from '../../Profile.Module.scss';
import classNames from 'classnames';
import Text from '../../../Shared/Text';
const TargetProgressBar = ({ value = 0, topic = '', subTopic }) => {
  return (
    <div className="position-relative h-100 w-100 text-center">
      {/* ----------------------grey circular bar ---------------------- */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={150}
        // className="w-100"
        sx={{ color: '#C4C4C4' }}
        thickness={3}
      />

      {/* ================green circular bar---------------- */}
      <CircularProgress
        variant="determinate"
        value={46}
        size={150}
        // className="h-100 w-100"
        className={classNames(
          'position-absolute top-0 start-0 ms-auto me-auto text-center end-0',
          c.circularProgressTargetBar
        )}
        thickness={3}
        sx={{ rotate: 90 }}
      />

      {/* ---------------Inner text------------------- */}
      <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 position-absolute top-0 start-0">
        <Headings headingType="h3" classToOverride="fw-bold">
          {value}
        </Headings>
        <Text
          textType="t1"
          classToOverride={classNames('fw-bold', c.primaryColor)}
        >
          {topic}
        </Text>
        {subTopic && (
          <Text
            textType="t1"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            {subTopic}
          </Text>
        )}
      </div>
    </div>
  );
};

export default TargetProgressBar;
