import React, { useState, useEffect } from 'react';
import { Autocomplete, Button } from '@mui/material';
import { DatePicker } from '@mui/lab';
import c from '../../../Profile.Module.scss';
import classNames from 'classnames';
import Text from '../../../../Shared/Text';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setNewTarget } from '../../../../../helpers/Profile/Targets/WishlistCapabilities';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { googleAnalyticsTriggerFn } from '../../../../../utils/utility';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

const ScheduleModal = ({
  capabilities,
  loadTargetsData,
  setModalOpen,
  learningPlans,
}) => {
  const [selectedCapabiliity, setSelectedCapability] = useState(null);
  const [selectedLearningPlan, setSelectedLearningPlan] = useState(null);
  const [UrlField, setUrlField] = useState('');
  const [error, setError] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [ScheduleDate, setScheduleDate] = useState(
    moment(new Date()).format('yyyy-MM-DD')
  );
  const [loading, setLoading] = useState(false);
  const [formattedDate, setFormattedDate] = useState(
    moment(new Date()).format('yyyy-MM-DD')
  );

  const tempDate = moment(ScheduleDate).format('YYYY-MM-DD');
  useEffect(() => {
    setFormattedDate(tempDate);
  }, [ScheduleDate]);

  const onSubmit = async () => {
    try {
      if (!(ScheduleDate && selectedCapabiliity && startTime && endTime))
        setError(true);
      else {
        //post api calling
        const targets_data = [
          {
            id: selectedCapabiliity?.id,
            title: selectedCapabiliity?.name,
            start_at: startTime,
            end_at: endTime,
            ScheduleDate: ScheduleDate,
            url: UrlField,
            learningPlan: selectedLearningPlan,
          },
        ];
        const params = {
          targets_data,
          date: formattedDate,
          assigned_by: 'self',
        };
        setLoading(true);
        const response = await setNewTarget(params);
        if (response) {
          await loadTargetsData();
          setLoading(false);
          setModalOpen(false);
        }
      }
      googleAnalyticsTriggerFn(
        'Button',
        'Save',
        'Save new schedule Successfull'
      );
    } catch (err) {
      setLoading(false);
      googleAnalyticsTriggerFn(
        'Button',
        'Save',
        'Save new schedule Unsuccessfull'
      );
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    googleAnalyticsTriggerFn(
      'Button',
      'Cancel',
      'Cancel button clicked on add new schedule modal under Targets tab on profile page'
    );
  };

  return (
    <div className={classNames(c.addScheduleModal)}>
      <div className="row justify-content-center px-5">
        <Text
          textType="t0"
          classToOverride={classNames('fw-bold text-center mt-2')}
        >
          Enter your Schedule
        </Text>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="mt-4">
            <DatePicker
              views={['day']}
              disablePast
              label="Date"
              value={ScheduleDate}
              onChange={(newValue) => {
                setScheduleDate(newValue);
              }}
              formatDate={(date) => moment(date).format('YYYY-MM-DD')}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </div>
          <div className="col-6 mt-2">
            <TimePicker
              label="Start Time"
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
                setError(false);
              }}
              renderInput={(params) => (
                <TextField required size="small" {...params} />
              )}
            />
          </div>
          <div className="col-6 mt-2">
            <TimePicker
              label="End Time"
              value={endTime}
              onChange={(newValue) => {
                setEndTime(newValue);
                setError(false);
              }}
              minTime={startTime}
              renderInput={(params) => (
                <TextField required size="small" {...params} />
              )}
            />
          </div>
          <div className="col-12">
            <Autocomplete
              disablePortal
              id="Selection"
              getOptionLabel={(option) => option?.name}
              options={capabilities}
              sx={{ width: '100%' }}
              size="small"
              renderInput={(params) => (
                <TextField
                  required
                  size="small"
                  {...params}
                  label="Selection"
                />
              )}
              // size="small"
              className="mt-2 w-100"
              onChange={(_, value) => {
                setSelectedCapability(value);
                setError(false);
              }}
            />
            <Autocomplete
              disablePortal
              id="learningPlan"
              getOptionLabel={(option) => option[0]}
              key={(option) => option}
              options={learningPlans}
              sx={{ width: '100%' }}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Learning Plan" />
              )}
              // size="small"
              className="mt-4 w-100"
              onChange={(_, value) => {
                setSelectedLearningPlan(value);
                setError(false);
              }}
            />
            <TextField
              id="URL"
              label="Add Url/link"
              className="mt-2 w-100"
              size="small"
              value={UrlField}
              onChange={(e) => {
                setUrlField(e.target.value);
                setError(false);
              }}
            />
          </div>
          {error && (
            <div className={classNames('mt-2', c.errorIcon)}>
              All Fields are required!
            </div>
          )}
          <div className="d-flex justify-content-end">
            <div>
              <div className="col-3 my-4 me-2 text-center">
                <Button
                  className="w-100 fw-bold"
                  variant="text"
                  onClick={handleCancel}
                  sx={{ textTransform: 'capitalize', color: '#1D1D1D' }}
                >
                  <u>Cancel</u>
                </Button>
              </div>
            </div>
            <div className="col-3 my-4 text-center">
              {!loading && (
                <Button
                  className="w-100"
                  variant="contained"
                  onClick={onSubmit}
                >
                  Save
                </Button>
              )}
              {loading && <CircularProgress />}
            </div>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default ScheduleModal;
