/**
 * @function LearningQuality
 */
import React from 'react';
import { LearningQualities } from '../../../../MockData/ManagersDashboard';
import Parameters from './Parameters';

const LearningQuality = () => {
  return (
    <div className="container-fluid mt-4">
      <div className="d-flex ms-4">
        {LearningQualities.map((Data,index) => {return (
          <div className="col" key={index}>
          <Parameters
            heading={Data.heading}
            percent={Data.percent}
            text={Data.text}
          />
          </div>)
        })}

        
      </div>
    </div>
  );
};

export default LearningQuality;
