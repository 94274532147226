import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall } from '../../../utils/axios/axios';
export const ContentTypeData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Top Learners',
    value: 'top',
  },
  {
    label: 'Bottom Learners',
    value: 'bottom',
  },
];

export const getCorporateHighlightsData = async (corp_id, filter) => {
  try {
    const params = {
      corp_id: corp_id,
      filter: filter,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.corp_highlights, paramsData);
    // console.log("Response", response?.data)
    return response?.data;
  } catch (err) {
    console.log('Error in getCorporateHighlightsData | helpers', err);
  }
};

export const weeklyLineChartOptions = {
  type: 'line',
  label: 'Weekly Avg Hours',

  lineTension: 0.3,
  backgroundColor: '#FFA372',
  borderColor: '#FFA372',
  borderCapStyle: 'round',
  borderJoinStyle: 'round',
  pointBorderColor: '#2a2c30',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: '#2a2c30',
  pointHoverBorderColor: '#fff',
  pointHoverBorderWidth: 5,
  pointRadius: 0,
  pointHitRadius: 5,
};

export const capabilitiesBarChartOptions = {
  type: 'bar',
  label: 'Capabilities Studied',
  borderColor: 'rgb(255, 99, 132)',
  backgroundColor: '#00A89A',
  borderRadius: 3,
};

export const hoursBarChartOptions = {
  type: 'bar',
  label: 'Hours Studied',
  borderColor: 'rgb(255, 99, 132)',
  backgroundColor: '#87D4E4',
  borderRadius: 3,
};

export const HighlightsChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      display: true,
      // ticks: {
      //   max: 100,
      //   min: 25,
      //   stepSize: 25,
      //   color: '#4318FF',
      // },
      // ticks: {
      //   beginAtZero: true,
      //   display: false,
      //   stepSize: 35,
      // },
      grid: {
        // drawBorder: false,
        // z: 2,
        // maxLines: 30,
        // lineWidth: 2,
        color: '#F8F8F8',
        beginAtZero: true,
        display: true,
      },
    },
    x: {
      drawBorder: false,
      display: false,
      ticks: {
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 12,
        boxWidth: 12,
        padding: 20,
        font: {
          size: 12,
        },
      },
    },
  },
};
