import React from 'react';
import classNames from 'classnames';
import c from '../../community/community.Module.scss';
import CustomSelect from '../../../Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Text from '../../../Shared/Text';
import TrendingCourses from './TrendingCourses';
import CommunityTopLearners from './TopLearners/Table';
import { TrendingFilters } from '../../../../helpers/Home/Community';
import {
  getPercentage,
  googleAnalyticsTriggerFn,
} from '../../../../utils/utility';
import { Resources } from '../../../../config/Resources';
const CommunityGridStructure = ({
  CommunityLearnersData,
  TrendingCoursesData,
  trendingFilter,
  setTrendingFilter,
}) => {
  return (
    <div className="mt-4 w-100 p-3">
      <div className={classNames('d-flex')}>
        <div className={classNames('mt-3 me-3', c.CommunityPanals)}>
          <div className={classNames('d-flex p-3 bd-highlight mb-3 ')}>
            <Text
              textType="t1"
              classToOverride={classNames(
                'me-auto p-2 bd-highlight text-dark',
                c.CommonHeadingCommunity
              )}
            >
              Trending
            </Text>
            <div
              className={classNames('p-2 bd-highlight w-50', c.CommunityFilter)}
            >
              <CustomSelect
                label="Topic"
                menuItems={TrendingFilters}
                dropdownIcon={FilterAltIcon}
                value={trendingFilter}
                onChange={(e) => {
                  setTrendingFilter(e?.target?.value);
                  googleAnalyticsTriggerFn(
                    'Filter',
                    'Trending Topic',
                    'TrendingTopic Filter changed'
                  );
                }}
              />
            </div>
          </div>

          {TrendingCoursesData && TrendingCoursesData.length > 0 ? (
            TrendingCoursesData.map((Data, index) => {
              return (
                <div key={index}>
                  <TrendingCourses
                    CourseImage={Resources.images.home.community1}
                    CourseHeading={Data?.description}
                    CourseViews={Data?.cur_views}
                    LastMonthViews={Data?.prev_views}
                    Margin={getPercentage(Data?.prev_views, Data?.cur_views)}
                  />
                </div>
              );
            })
          ) : (
            <Text
              textType="t0"
              classToOverride={classNames(c.primaryColor, 'fw-bold m-5')}
            >
              No Data Available!
            </Text>
          )}
        </div>
        <div className={classNames('mt-3', c.CommunityPanals)}>
          <div className={classNames('p-3 bd-highlight mb-3 ')}>
            <Text
              textType="t1"
              classToOverride={classNames(
                'me-auto p-2 bd-highlight text-dark',
                c.CommonHeadingCommunity
              )}
            >
              Top Learners
            </Text>
          </div>
          <div className={classNames('px-4')}>
            <CommunityTopLearners
              CommunityLearnersData={CommunityLearnersData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityGridStructure;
