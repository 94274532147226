import React from 'react';
import c from '../../../learningPlans.Module.scss';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import classNames from 'classnames';
import ModuleCard from '../../AddNewModule/ModuleCard';
import DragDropFeature from '../DragDropFeature';

const CustomizedContentLayout = ({ displayLayout }) => {
  return (
    <>
      <div className={c.formLayout}>
        <Button
          variant="text"
          size="large"
          startIcon={<ChevronLeftIcon className="fs-2" />}
          className={classNames('p-0 text-capitalize', c.font_24, c.backBtn)}
          onClick={() => displayLayout(false)}
        >
          Back to Plan
        </Button>
      </div>

      <ModuleCard title="Add Customized Content" showAddModuleForm={false} />
      <DragDropFeature />
    </>
  );
};

export default CustomizedContentLayout;
