import React from 'react';
import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import c from '../allocation.module.scss';
import Box from '@mui/material/Box';

const RequestMembersContainer = ({
  requestModalStatus,
  setRequestModalStatus,
  ...props
}) => {
  return (
    <Modal
      open={requestModalStatus}
      onClose={() => setRequestModalStatus(false)}
      className="d-flex align-items-center"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        className={classNames('col-md-3 row mx-auto', c.requestApproveContainer)}
      >
        <div
          className={classNames(
            'col-md-12 rounded-start position-relative px-3',
            c.BoxContainer
          )}
        >
          {props.children}
        </div>
      </Box>
    </Modal>
  );
};

export default RequestMembersContainer;
