import React from 'react';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import classNames from 'classnames';
import c from '../landing.module.scss';

const LandingCover = ({ SetPlayerVideo, ...props }) => {
  return (
    <div className={classNames('row mx-0', c.Container)}>
      <LeftPanel />
      <RightPanel SetPlayerVideo={SetPlayerVideo} />
    </div>
  );
};

export default LandingCover;
