/**
 * @module Course
 * renders /Course
 */

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CourseData from '../../components/Course/CourseData';
// import { PersonalPlaybackList } from '../../components/Course/PersonalPlaybackList';
// import CourseStyling from './course.module.scss';
// import { Player } from '../../components/Shared/Player';
// import { Resources } from '../../config/Resources';
import { fetchCourseDetails } from '../../helpers/Course/CourseDetails';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/Actions/Loader';
import MediaView from '../../components/Course/MediaView';
import Quiz from '../../components/Course/Quiz';

const Course = () => {
  const [view, setView] = useState('course');
  const { id } = useParams();
  const [courseDetails, setCourseDetails] = useState([]);
  const [category, setCategory] = useState('course');
  const userData = useSelector((state) => state.generalInformation);
  const dispatch = useDispatch();
  const [campaignCategory, setCampaignCategory] = useState('');

  useEffect(() => {
    async function fetchData() {
      window.scrollTo(0, 0);
      const res = await getCourseDetails(userData, id);
      setCampaignCategory(res?.campaign_category);
    }
  
    fetchData();
    // eslint-disable-next-line
  }, [userData]);
  

  const getCourseDetails = async (userData, id) => {
    dispatch(showLoader());
    const response = await fetchCourseDetails(userData?.id, id);
    if (response?.data && response?.data.length > 0)
      console.log("Course Details Data", response.data)
      setCourseDetails(response.data[0]);
    dispatch(hideLoader());
    return response?.data[0];
  };

  const getLessonData = (userData, lesson_id) => {
    getCourseDetails(userData, lesson_id);
    setCategory('lesson');
  };

  return (
    <React.Fragment>
      {view === 'course' && (
        <div className={classNames('w-100 bg-white p-4')}>
          {/* <PersonalPlaybackList /> */}
          <div className={classNames('px-4 pt-3')}>
            <CourseData
              courseDetails={courseDetails}
              getCourseDetails={getLessonData}
              category={category}
              campaignCategory={campaignCategory}
            />
          </div>
        </div>
      )}

      {/* {view === 'quiz' && <Quiz />} */}
    </React.Fragment>
  );
};

export default Course;
