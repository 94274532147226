import * as types from '../../Types/Common';

export const SET_TOAST_ERROR = (payload) => ({
  type: types.TOAST_ERROR,
  payload,
});

export const SET_PROFILE_COMPLETION_STATUS = (payload) => ({
  type: types.PROFILE_COMPLETION_STATUS,
  payload
})

export const SET_SELECTED_CATEGORY = (payload) => ({
  type: types.SELECTED_CATEGORY,
  payload,
});