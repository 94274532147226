import * as types from '../../Types/Common';

const SET_TOAST_ERROR = (
  state = { error: false, message: undefined },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case types.TOAST_ERROR:
      return payload;
    default:
      return state;
  }
};

const SET_PROFILE_COMPLETION_STATUS = (
  state = { incompleteTabs: [], percentage: 0 },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case types.PROFILE_COMPLETION_STATUS:
      return {
        incompleteTabs: [...payload.incompleteTabs],
        percentage: payload.percentage,
      };
    default:
      return state;
  }
};

const SET_SELECTED_CATEGORY = (
  state = { name: '' },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case types.SELECTED_CATEGORY:
      return payload;
    default:
      return state;
  }
};

export { SET_TOAST_ERROR, SET_PROFILE_COMPLETION_STATUS, SET_SELECTED_CATEGORY };
