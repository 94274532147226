import React from 'react';
import YoutubeIcon from '@mui/icons-material/YouTube';
import MicIcon from '@mui/icons-material/Mic';
import SchoolIcon from '@mui/icons-material/School';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ArticleIcon from '@mui/icons-material/Article';
import { courseCategoryId } from '../../../config/Constants';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall, putApiCall } from '../../../utils/axios/axios';
import { ProfileTabIds } from '../../../config/Constants';
import { CLIENT_ID } from '../../../config/Constants';

export const preferedLessonFormatData = [
  {
    id: courseCategoryId.audio,
    logo: <MicIcon fontSize='large' className='rounded-circle text-white p-2'/>,
    name: 'Audio',
  },
  {
    id: courseCategoryId.article,
    logo: <ArticleIcon fontSize='large' className='rounded-circle text-white p-2'/>,
    name: 'Article',
  },
  {
    id: courseCategoryId.courses,
    logo: <SchoolIcon fontSize='large' className='rounded-circle text-white p-2'/>,
    name: 'OnlineCourses',
  },
  {
    id: courseCategoryId.podcast,
    logo: <PodcastsIcon fontSize='large' className='rounded-circle text-white p-2'/>,
    name: 'Podcast',
  },
  {
    id: courseCategoryId.video,
    logo: <YoutubeIcon fontSize='large' className='rounded-circle text-white p-2'/>,
    name: 'Video',
  },
];

export const UpdateLearningPreferenceDetails = async (values) => {
  try {
    let reqBody = {
      user_client_id: values.user_client_id,
      tab_id: ProfileTabIds.learningPreferences,
      platform_id: CLIENT_ID,
      content: {
        learningPersonality: values.learningPersonality,
        preferredLearningTime: values.preferredLearningTime,
        preferredLearningPace: values.preferredLearningPace,
        preferredLessonFormats: {
          1: values.preferredLessonFormats.first,
          2: values.preferredLessonFormats.second,
          3: values.preferredLessonFormats.third,
        },
      },
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await putApiCall(API_URLS?.profile_details, reqBody, paramsData);
  } catch (error) {
    console.log(`Error in updating Learning Preference Details ${error}`);
  }
};


export const FetchLearningPreferenceDetails = async (user_client_id) => {
  try {
    const paramsData = {
      queryParams: {
        user_client_id: user_client_id,
        tab_id: ProfileTabIds.learningPreferences,
        platform_id: CLIENT_ID,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URLS?.profile_details, paramsData);
    return response;
  } catch (error) {
    console.log(`Error in fetching Learning Preference Details ${error}`);
  }
};
