import React, { useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import c from '../../../home.Module.scss';
import Checkbox from '@mui/material/Checkbox';
import classNames from 'classnames';

const Table = ({
  learningPlanItem,
  learningPlan,
  category,
  id,
  setLearningPlan,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true && !learningPlan.includes(id))
      setLearningPlan([...learningPlan, id]);
    else {
      // learningPlan.splice(learningPlan.indexOf(id),1);
      setLearningPlan(learningPlan.filter((item) => item !== id));
    }
  };

  return (
    <TableRow className={classNames(c.sessionsTableRow)}>
      <TableCell>
        <Checkbox checked={checked} onChange={handleChange} />
        <span className="ms-4">{learningPlanItem}</span>
      </TableCell>
      <TableCell>{category}</TableCell>
    </TableRow>
  );
};

export default Table;
