import React from 'react';
import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import c from '../../allocation.module.scss';
import Box from '@mui/material/Box';

const AllocationModalContainer = ({
  modalStatus,
  setModalStatus,
  heading,
  text,
  primaryBtnText,
  secondaryBtnText,
  ...props
}) => {
  return (
    <Modal
      open={modalStatus}
      onClose={() => setModalStatus(false)}
      className="d-flex align-items-center"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={classNames('col-md-8 row mx-auto', c.ModalContainer)}>
        <div
          className={classNames(
            'col-md-12 text-white rounded-start position-relative',
            c.BoxContainer
          )}
        >
          {props.children}
        </div>
      </Box>
    </Modal>
  );
};
export default AllocationModalContainer;
