import { Resources } from '../../../config/Resources';

export const CalibrationData = [
  {
    id: 'A121b920-24ed-11ec-8cfb-8ba07211c18f',
    Thumbnail: Resources.images.home.CalibrationData,
    describe: '',
  },
  {
    id: 'A131b920-24ed-11ec-8cfb-8ba07211c18f',
    Thumbnail: Resources.images.home.CalibrationData,
    describe: '',
  },
  {
    id: 'A141b920-24ed-11ec-8cfb-8ba07211c18f',
    Thumbnail: Resources.images.home.CalibrationData,
    describe: '',
  },
  {
    id: 'A151b920-24ed-11ec-8cfb-8ba07211c18f',

    Thumbnail: Resources.images.home.CalibrationData,
    describe: 'Fear',
  },
  {
    id: 'A161b920-24ed-11ec-8cfb-8ba07211c18f',
    Thumbnail: Resources.images.home.CalibrationData,
    describe: '',
  },
];