import classNames from 'classnames';
import React from 'react';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import c from '../../allocation.module.scss';
const ModalHeaderText = ({ heading, text, ...props }) => {
  return (
    <React.Fragment>
      <Headings
        headingType="h3"
        classToOverride={classNames('mt-4 fw-bold', c.primaryColor)}
      >
        {heading || ''}
      </Headings>
      <Text textType="t1" classToOverride={classNames(c.primaryColor)}>
        {text || ''}
      </Text>
    </React.Fragment>
  );
};
export default ModalHeaderText;
