import { SET_GENERAL_INFORMATION } from '../../Types/ProfileDetails';

export const GeneralInformation = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GENERAL_INFORMATION:
      return payload;
    default:
      return state;
  }
};
