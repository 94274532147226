import * as yup from 'yup';
import {
  TEXT_FIELD_REGEX_PATTERN,
  PHONE_NUMBER_REGEX_PATTERN,
} from '../../../config/Regex/regex';
import { validatePassword } from '../../../utils/utility';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import axios from 'axios';
import { getApiCall, putApiCall } from '../../../utils/axios/axios';
import store from '../../../redux/store';
import { setGeneralInformation } from '../../../redux/Actions/ProfileDetails';
import { AMAZON_S3_URL_REGEX } from '../../../config/Regex/regex';
import { dataURLtoFile } from '../../../utils/utility';
import { getProfileDetails } from '..';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';

export const UserDataValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'First Name is invalid')
    .required('First Name is required'),
  lastName: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'Last Name is invalid')
    .required('Last Name is required'),
  race: yup.string().matches(TEXT_FIELD_REGEX_PATTERN, 'Race is invalid'),
  religion: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'Religion is invalid'),
  location: yup.string().required('Location is Required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid Email Address format'),
  phoneNo: yup
    .string()
    .matches(PHONE_NUMBER_REGEX_PATTERN, 'Phone Number is not valid'),
});

export const ClientDataValidationSchema = yup.object().shape({
  accountName: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'Name is invalid')
    .required('Name is required'),
  client_address1: yup
    .string()
    .required('Location is required'),
  client_city: yup
    .string()
    .required('City is required'),
  orgEmail: yup
    .string()
    .required('Email is required'),
  orgPhoneNo: yup
    .string()
    .matches(PHONE_NUMBER_REGEX_PATTERN, 'Phone Number is invalid')
    .required('Phone # is required'),
  zip_code: yup
    .string()
    .required('ZIP Code is required'),
  suite: yup
    .string()
    .required('Suite is required'),
  orgWebsite: yup
    .string()
    .required('Website is required'),

});

export const generateInitialValues = (userData) => {
  let values = {};
  values.profileImageURL = userData?.profile_image_url || '';
  values.firstName = userData?.first_name || '';
  values.lastName = userData?.last_name || '';
  values.gender = userData?.gender || '0';
  values.race = userData?.race || '';
  values.religion = userData?.religion || '';
  values.accountName = userData?.user_name || '';
  values.password = userData?.password || '';
  values.existingPassword = '';
  values.location = userData?.user_address1 || '';
  values.employer = userData?.client_name || '';
  values.department = userData?.department || '';
  values.designation = userData?.designation || '';
  values.email = userData?.email || '';
  // values.website = userData?.website || '';
  values.phoneNo = userData?.phone_cellular?.trim() || '';
  values.orgPhoneNo = userData?.orgPhoneNo || '';

  values.website =
    JSON.parse(userData?.additional_details || '{}')?.website || '';
  return values;
};

export const generateInitialClientValues = (clientData) => {
  let values = {};
  values.profileImageURL = clientData?.image_url || '';
  values.accountName = clientData?.name || '';
  values.client_address1 = clientData?.address1 || '';
  values.client_city = clientData?.city || '';
  values.country = clientData?.country_id || '';
  values.state = clientData?.state_id || '';
  values.orgEmail = clientData?.email || '';
  values.orgPhoneNo = clientData?.phone?.trim() || '';
  values.zip_code = clientData?.zip_code || '';
  values.suite = clientData?.suite || '';
  values.orgWebsite = clientData?.website || '';
  values.password = clientData?.password || '';
  return values;
};

export const DemographicsForm = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'gender', label: 'Gender' },
  { id: 'race', label: 'Ethnicity' },
  { id: 'religion', label: 'Religion' },
];

export const PersonalAccountDetailsForm = [
  { id: 'accountName', label: 'Account Name' },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
  },
  { id: 'location', label: 'Location*' },
  { id: 'employer', label: 'Employer' },
  { id: 'department', label: 'Department' },
  { id: 'designation', label: 'Role' },
  { id: 'email', label: 'Email' },
  { id: 'website', label: 'Website' },
  { id: 'phoneNo', label: 'Phone Number' },
  // { id: 'orgPhoneNo', label: 'Organization Phone Number' },
];

export const ClientAccountDetailsForm = [
  { id: 'accountName', label: 'Organization Name' },
  { id: 'orgEmail', label: 'Organization Email' },
  { id: 'orgPhoneNo', label: 'Organization Phone Number' },
  { id: 'orgWebsite', label: 'Organization Website' },
  { id: 'country', label: 'Country' },
  { id: 'state', label: 'State' },
  { id: 'client_city', label: 'City' },
  { id: 'client_address1', label: 'Address' },
  { id: 'suite', label: 'Suite #' },
  { id: 'zip_code', label: 'Zip Code' },
  // { id: 'employer', label: 'Employer' },
  // { id: 'department', label: 'Department' },
  // { id: 'designation', label: 'Role' },
  // {
  //   id: 'password',
  //   label: 'Password',
  //   type: 'password',
  // },
];

yup.addMethod(yup.string, 'validatePassword', validatePassword);

export const UpdatePasswordValidationSchema = yup.object().shape({
  existingPassword: yup.string().validatePassword('Existing Password'),
  newPassword: yup.string().validatePassword('New Password'),
  confirmPassword: yup
    .string()
    .validatePassword('Confirm Password')
    .oneOf([yup.ref('newPassword'), null], 'Confirm Password does not match'),
});

export const UpdatePasswordForm = [
  { id: 'existingPassword', label: 'Existing Password', type: 'password' },
  { id: 'newPassword', label: 'New Password', type: 'password' },
  { id: 'confirmPassword', label: 'Confirm Password', type: 'password' },
];

export const updateUserPassword = async (id, existing_password, password) => {
  try {
    const reqBody = {
      user_client_id: id,
      existing_password: existing_password,
      password: password,
    };
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await putApiCall(
      API_URLS?.update_password,
      reqBody,
      paramsData
    );

    if (response?.message)
      store.dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response?.message,
        })
      );
    else
      store.dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response?.error?.message,
        })
      );

    return response;
  } catch (error) {
    console.log(`Error in password update ${error}`);
  }
};

export const fetchUserDetails = async () => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS?.auth, paramsData);
    store.dispatch(setGeneralInformation(response));
    const id = store.getState()?.generalInformation?.id;
    await getProfileDetails(id);
  } catch (error) {
    console.log(`Error in fetching User details ${error}`);
  }
};

export const updateUserDetails = async (values) => {
  try {
    let reqBody = {
      profile_image_url: values?.profileImageURL,
      first_name: values?.firstName,
      last_name: values?.lastName,
      gender: values?.gender,
      race: values?.race,
      religion: values?.religion,
      address1: values?.location,
      email: values?.email,
      phone_cellular: values?.phoneNo,
      imageFile: '',
      designation: values?.designation,
      department: values?.department,
      additional_details: JSON.stringify({
        website: values?.website,
      }),
    };

    if (values?.password) {
      reqBody = {
        ...reqBody,
        password: values?.password,
        existing_password: values?.existingPassword,
      };
    }

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await putApiCall(API_URLS?.updateUser, reqBody, paramsData);
    await fetchUserDetails();
  } catch (error) {
    console.log(`Error in updating User details ${error}`);
  }
};

export const updateClientDetails = async (values) => {
  try {
    let reqBody = {
      name: values.accountName,
      phone: values.orgPhoneNo,
      city: values.client_city,
      zip_code: values.zip_code,
      image_url: values.profileImageURL,
      country_id: values.country,
      // email: values.orgEmail, no def
      address1: values.client_address1,
      state_id: values.state,
      suite: values.suite,
      website: values.orgWebsite,
      // banner_image_url: ''
    };

    if (values?.password) {
      reqBody = {
        ...reqBody,
        password: values?.password,
        existing_password: values?.existingPassword,
      };
    }

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await putApiCall(API_URLS?.neighborhood_client, reqBody, paramsData);
    await fetchUserDetails();
  } catch (error) {
    console.log(`Error in updating User details ${error}`);
  }
};

export const generateSignedUrl = async () => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS?.mediaSignedURL, paramsData);
    return response;
  } catch (error) {
    console.log(`Error in generating Signed URL ${error}`);
  }
};

export const uploadFile = async (signedURL, file) => {
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    };
    await axios.put(signedURL, file, options);
    return true;
  } catch (error) {
    console.log(`Error in uploading File ${error}`);
    return false;
  }
};

export const getUserInfo = async (formData) => {
  const { profileImageURL } = formData;
  let userInfo = formData;
  if (profileImageURL) {
    if (!AMAZON_S3_URL_REGEX.test(profileImageURL)) {
      const newFile = dataURLtoFile(profileImageURL);
      const response = await generateSignedUrl();
      const signedImgUrl = response?.imageSignedUrl;
      const result = await uploadFile(signedImgUrl, newFile);
      if (result) {
        const url = signedImgUrl.substr(
          0,
          signedImgUrl.indexOf('?X-Amz-Algorithm=')
        );
        userInfo = { ...userInfo, profileImageURL: url };
      }
    }
  } else {
    userInfo = { ...userInfo, profileImageURL: '' };
  }
  return userInfo;
};

export const getClientInfo = async (clientId) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const data = await getApiCall(
      API_URLS?.neighborhood_client + `/${clientId}`,
      paramsData
    );
    return data;
  } catch (err) {
    console.log('getClientInfo ~ err', err);
  }
};
