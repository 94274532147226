/*global chrome */
import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import { Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { IntrestData } from '../../../helpers/signup/ChooseIntrest';
import Headings from '../../Shared/Headings';
import Interest from '../../signUp/Interest';
import ModalStyling from './modal.module.scss';
import { useDispatch } from 'react-redux';
import { setIsTopicModel } from '../../../redux/Actions/ExtensionHandler';
import { handleStartStop } from '../../../helpers/ExtensionHandler';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';

const SelectTopic = React.memo(({ setisSetTopicModal, value }) => {
  const [topic, setTopic] = useState(value || '');
  const InterestDataList = IntrestData;
  const handleKeywordSelect = (topic) => {
    setTopic(topic);
  };
  const dispatch = useDispatch();
  const HandleSelectClick = (e) => {
    e.preventDefault();
    if (chrome && chrome.runtime) {
      handleStartStop(true, topic);
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Extension Is not Installed or Something Went Wrong',
        })
      );
    }

    dispatch(setIsTopicModel(false));
  };
  return (
    <div className="center-modal-image container">
      <div className={classNames(ModalStyling.ModalHeader, 'd-flex')}>
        <Headings headingType="h3" classToOverride={'fw-bold'}>
          Edit Study Topic
        </Headings>
        <Close
          onClick={() => {
            dispatch(setIsTopicModel(false));
          }}
          className={classNames('ms-auto mt-2')}
        />
      </div>
      <Headings
        headingType="h2"
        classToOverride={classNames(
          ModalStyling.TopicHeader,
          'fw-bold mt-5 mb-5 font- text-center'
        )}
      >
        Type or choose a capability to learn about
      </Headings>
      <Form
        className={classNames(
          ModalStyling['ahura-study-search'],
          'd-flex flex-center mb-sm-5 mb-4 pb-4'
        )}
      >
        <Form.Group
          className={classNames(ModalStyling['Fieldinput'], 'mb-0')}
          controlId="formBasicEmail"
        >
          <Form.Control
            onChange={(e) => setTopic(e.target.value)}
            type="text"
            value={topic}
            placeholder="Search Topics"
          />
        </Form.Group>
        <Button
          onClick={(e) => HandleSelectClick(e)}
          type="submit"
          className={classNames(
            ModalStyling['btn-primary'],
            'font-bold display-7'
          )}
        >
          Select
        </Button>
      </Form>
      <div className="row d-flex">
        {/* {console.log(InterestDataList)} */}
        {InterestDataList &&
          InterestDataList.map((data) => (
            <div className="mt-3 d-flex justify-content-center col-4">
              <Interest
                intrestIcon={data.icon}
                intrestText={data.text}
                intrestId={data.id}
                IsSelected={topic === data.text}
                handleSelected={handleKeywordSelect}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default SelectTopic;
