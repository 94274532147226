import { API_URLS } from '../../../config/API_URLS/api_urls';
import { CLIENT_ID, CAMPAIGN_TYPES_IDS, MODULE_ID } from '../../../config/Constants';
import { getApiCall } from '../../../utils/axios/axios';

export const fetchCourseDetails = async (user_client_id, campaign_id) => {
    try {
        const paramsData = {
          queryParams: {
            user_client_id: user_client_id,
            campaign_id: campaign_id,
            publisher_network_id: CLIENT_ID,
          },
          headers: {},
          isAuthorized: true,
        };
        const response = await getApiCall(API_URLS?.campaign_details, paramsData);
        return response;
    } catch (error) {
        console.log(`Error in fetching Categories ${error}`);
    }
}


export const fetchLessonData = async (parentId) => {
  try {
    const queryParams = {
      module_id: MODULE_ID.CAMPAIGN,
      parent_id: parentId,
      client_id: CLIENT_ID,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      sub_campgain: 'lessons',
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(
      API_URLS.all_parent_campaigns,
      paramsData
    );
    return response?.data;
  } catch (error) {
    console.log('Error in fetching Plan Module or Lesson data', error);
  }
};


export const fetchUserAssignmentDetails = async (userId) => {
  try {
    const queryParams = {
      created_by: userId
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(
      API_URLS.user_assignment_details,
      paramsData
    );
    return response?.data;
  } catch (error) {
    console.log('Error in fetching Plan Module or Lesson data', error);
  }
};