export const mainMenuItems = [
  {
    id: 'viewProfileItem',
    name: 'View Accounts',
    redirectTo: '#',
  },
  {
    id: 'homeItem',
    name: 'Home',
    redirectTo: '/home',
  },
  {
    id: 'profileItem',
    name: 'Profile',
    redirectTo: '/profile',
  },
  {
    id: 'joinOrganization',
    name: 'Join Organization',
    redirectTo: '#',
  },
  {
    id: 'changeExtension',
    name: 'Change Extension',
    redirectTo: '#',
  },
  {
    id: 'extensionItem',
    name: 'Get Extension',
    redirectTo: '#',
  },
  // {
  //   id: 'settingsItem',
  //   name: 'Settings',
  //   redirectTo: '#',
  // },
  // {
  //   id: 'helpItem',
  //   name: 'Help',
  //   redirectTo: '#',
  // },
  {
    id: 'logOutItem',
    name: 'LogOut',
    redirectTo: '/sign-in',
  },
];

export const profileAccountMenuItems = [
  {
    id: 'viewPaymentItem',
    name: 'Payments',
    //   redirectTo: '/payments',
  },
  {
    id: 'discontinueAccess',
    name: 'Discontinue Access',
    //   redirectTo: '',
  },
];

export const corporateAccountMenuItems = [
  {
    id: 'viewCorporatePaymentItem',
    name: 'Payments',
    //   redirectTo: '/payments',
  },
  {
    id: 'makeMainAccount',
    name: 'Make Main Account',
    //   redirectTo: '',
  },
  {
    id: 'leaveAccount',
    name: 'Leave Account',
    //   redirectTo: '',
  },
];
