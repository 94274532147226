import React, { useEffect, useState } from 'react';
import UpdatePassword from './UpdatePassword';
import Text from '../../../Shared/Text';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import { ClientAccountDetailsForm } from '../../../../helpers/Profile/GeneralInfo';
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { fetchCountry, fetchStates } from '../../../../helpers/My Account';

const ClientAccountDetails = ({ isEditable, formik }) => {
  const [requirePwdUpdate, setRequirePwdUpdate] = useState(false);
  const { values, handleChange, setFieldValue, errors } = formik;
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (values?.country) {
      getState();
    }
  }, [values?.country]);

  const getCountries = async () => {
    const data = await fetchCountry();
    setCountryData(data);
  };

  const getState = async () => {
    const res = await fetchStates(values?.country);
    setStateData(res);
  };

  return (
    <>
      {requirePwdUpdate && (
        <UpdatePassword
          setRequirePwdUpdate={setRequirePwdUpdate}
          setFieldValue={formik?.setFieldValue}
        />
      )}
      <Text textType="t1" classToOverride={classNames(c.fontWeight600, 'mt-5')}>
        Corporate Details
      </Text>
      {/* <Text textType="t2" classToOverride={classNames(c.fade)}>
        Another line here for description
      </Text> */}
      <div className="row pe-10">
        {ClientAccountDetailsForm.map((field) =>
          field?.id === 'state' || field?.id === 'country' ? (
            <div className="col-6 mt-4" key={field.id}>
              <FormControl
                variant="standard"
                className={classNames('w-50 mt-1 w-100')}
                fullWidth
              >
                <InputLabel id="select-label">{field?.label}</InputLabel>

                <Select
                  fullWidth
                  id={field?.id}
                  name={field?.id}
                  value={values[field?.id]}
                  displayEmpty
                  onChange={handleChange}
                  inputProps={{
                    readOnly: isEditable,
                    className: classNames(
                      'bg-transparent',
                      c.profileInputField
                    ),
                  }}
                >
                  {[
                    ...(field?.id === 'country' ? countryData : stateData),
                  ]?.map((country) => (
                    <MenuItem key={country?.id} value={country?.id}>
                      {country?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div className="col-6 mt-4" key={field?.id}>
              <TextField
                id={field?.id}
                name={field?.id}
                variant="standard"
                fullWidth
                label={field?.label}
                type={field?.type}
                value={
                  field?.id === 'password'
                    ? 'Password'
                    : formik?.values[field?.id]
                }
                onChange={
                  field?.id === 'employer' ? () => {} : formik?.handleChange
                }
                error={formik?.errors[field?.id] ? true : false}
                helperText={formik?.errors[field?.id]}
                InputProps={
                  field?.id === 'password'
                    ? {
                        readOnly: isEditable,
                        endAdornment: field?.id === 'password' && (
                          <Button
                            variant="text"
                            onClick={() => setRequirePwdUpdate(true)}
                            disabled={isEditable}
                          >
                            <Text
                              textType="t2"
                              classToOverride={classNames(
                                'text-nowrap text-decoration-underline text-capitalize',
                                c.fontWeight600
                              )}
                            >
                              Update Password
                            </Text>
                          </Button>
                        ),
                      }
                    : field?.id === 'orgEmail' ? {
                      readOnly: true
                    } : {
                      readOnly: isEditable
                    }


                }
                inputProps={{
                  className: classNames(c.profileInputField),
                }}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default ClientAccountDetails;
