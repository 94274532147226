/**
 * @function AddCustomizedContentForm
 * Render new lesson addition form
 */

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setDisplayAddingPlanForm } from '../../../../../redux/Actions/LearningPlans';
import { useFormik } from 'formik';
import {
  validateCustomizedContentForm,
  addCustomizedContentFormData,
  contentTypeOptions,
} from '../../../../../helpers/LearningPlans/AddingPlanRightPanel';
import { PlanContext } from '../../../../../helpers/LearningPlans/AddingPlanModules';
import { courseCategoryId } from '../../../../../config/Constants';
import { Button, Autocomplete, TextField } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import classNames from 'classnames';
import c from '../../../learningPlans.Module.scss';
import InputControls from '../../InputControls';
import Headings from '../../../../Shared/Headings';

const AddCustomizedContentForm = () => {
  const dispatch = useDispatch();

  const displayForm = () =>
    dispatch(setDisplayAddingPlanForm({ customizedContentAddition: false }));

  const { values, errors, handleChange, setFieldValue } = useFormik({
    initialValues: {
      title: null,
      url: null,
      description: null,
      category: null,
      type: null,
    },
    validate: validateCustomizedContentForm,
  });

  const { title, url, description, category, type } = values;

  const onLessonCreationOrDeletion = useContext(PlanContext);

  const createLesson = async () => {
    displayForm(false);
    const formParams = {
      name: values?.title,
      description: values?.description,
      type: courseCategoryId[values?.type],
    };
    onLessonCreationOrDeletion(formParams, true);
  };

  return (
    <div className={c.formLayout}>
      <Button
        variant="text"
        size="large"
        startIcon={<ChevronLeftIcon className="fs-2" />}
        className={classNames('p-0 text-capitalize', c.font_24, c.backBtn)}
        onClick={displayForm}
      >
        Back to Content
      </Button>

      {addCustomizedContentFormData.map((item) => (
        <InputControls
          key={item?.id}
          id={item?.id}
          inputLabel={item?.inputLabel}
          textFieldLabel={item?.textFieldLabel}
          values={values}
          handleChange={handleChange}
          errors={errors}
        />
      ))}

      <Headings
        headingType="h6"
        classToOverride={classNames(c.fontWeight500, c.inputGroupLabel)}
      >
        Content Type
      </Headings>

      <Autocomplete
        options={contentTypeOptions}
        className="mt-3 mb-4"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: type ?? classNames(c.inputGroupBorder),
              },
            }}
            InputLabelProps={{
              classes: {
                root: type ?? classNames(c.inputGroupLabel),
              },
            }}
            size="small"
            label="Content Type"
            placeholder="Content Type"
            error={errors?.type ? true : false}
            helperText={errors?.type}
          />
        )}
        onChange={(e) => setFieldValue('type', e?.target?.outerText ?? '')}
      />

      <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
        <Button
          variant="contained"
          className="w-50 mb-1 text-capitalize"
          disabled={!(title && url && description && category && type)}
          onClick={createLesson}
        >
          create this content
        </Button>
        <Button
          variant="outlined"
          color="error"
          className="w-50 mt-3 mb-5 text-capitalize"
          onClick={displayForm}
        >
          delete this content
        </Button>
      </div>
    </div>
  );
};

export default AddCustomizedContentForm;
