export const inCompleteProfileTabsDetail = {
  demographics: {
    id: 'demographicInfo',
    name: 'Demographic info',
    redirectTo: '/profile',
  },
  learningPreferences: {
    id: 'learningPreference',
    name: 'Learning Preference',
    redirectTo: { pathname: '/profile', state: { tabIndexToSwitch: 1 } },
  },
  targets: {
    id: 'targets',
    name: 'Targets',
    redirectTo: { pathname: '/profile', state: { tabIndexToSwitch: 2 } },
  },
  calibrations: {
    id: 'calibrations',
    name: 'Face Calibrations',
    redirectTo: { pathname: '/profile', state: { tabIndexToSwitch: 3 } },
    disabled: true,
  },
  badges: {
    id: 'badges',
    name: 'Badges',
    redirectTo: { pathname: '/profile', state: { tabIndexToSwitch: 4 } },
    disabled: true,
  },
};

export const learnerData = [
  {
    prefixText: '(Monthly) ',
    heading: 'Winning',
    description: 'Refresh your career learning milestones list',
    redirectionUrl: 'https://forms.gle/Xxw3NJTbdb6BzDUf9',
  },
  {
    prefixText: '',
    heading: 'Usage',
    description: 'Top ways to learn faster using Ahura AI',
    redirectionUrl: 'https://ahuraai.com/solutions/top-uses-for-learners/',
  },
  {
    prefixText: '',
    heading: 'Privacy',
    description: 'How Ahura AI ensures a private learning experience',
    redirectionUrl: 'https://ahuraai.com/solutions/privacy-and-data/',
  },
];
