import { getApiCall } from '../../utils/axios/axios';
import { API_URLS } from '../../../src/config/API_URLS/api_urls';
export const getLearningData = async(params)=>{
    try {
        const paramsData = {
          queryParams: {
            ext_id: params,
          },
          headers: {},
          isAuthorized: true,
        };
        const res = await getApiCall(API_URLS.learning, paramsData);
        return res;
      } catch (err) {
        console.log('Error in LearningData | Helpers :', err);
      }
}

