/**
 * @function AddingPlanModules
 * Render after plan details have been submitted
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import {
  getPlanData,
  fetchCourses,
  PlanContext,
} from '../../../helpers/LearningPlans/AddingPlanModules';
import {
  fetchPlanModuleOrLessonData,
  createPlanModuleOrLesson,
  deletePlanLesson,
} from '../../../helpers/LearningPlans/AddingPlanRightPanel';
import { HTML_TAG_PATTERN } from '../../../config/Regex/regex';
import {
  setCustomizedPlanData,
  setLearningPlanData,
} from '../../../redux/Actions/LearningPlans';
import { courseCategoryId } from '../../../config/Constants';
import { DragDropContext } from 'react-beautiful-dnd';
import AddingPlanLeftModule from '../AddingPlanLeftPanel';
import AddingPlanRightPanel from '../AddingPlanRightPanel';

const AddingPlanModules = ({ planId, capabilityId }) => {
  const [planData, setPlanData] = useState({});
  const [planModuleData, setPlanModuleData] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState(null);

  const dispatch = useDispatch();

  const { generalInformation, learningPlanData } = useSelector(
    (state) => state
  );

  useEffect(
    () => getAllPlanDetails(),
    // eslint-disable-next-line
    [generalInformation]
  );

  const getAllPlanDetails = async () => {
    dispatch(showLoader());
    dispatch(setLearningPlanData([]));
    if (generalInformation?.id) {
      if (planId) {
        await getPlanData(planId, generalInformation?.id, setPlanData);
        await setModuleData();
        dispatch(hideLoader());
      }
      if (capabilityId) {
        await fetchCourses(capabilityId);
        await setModuleData();
        dispatch(hideLoader());
      }
    }
  };

  const setModuleData = async () => {
    const response = await fetchPlanModuleOrLessonData(
      planId ?? capabilityId,
      generalInformation?.id
    );
    setPlanModuleData([...response]);
    return response;
  };

  const onLessonCreationOrDeletion = async (params, exCreateOp) => {
    dispatch(showLoader());
    const SuccessRes = exCreateOp
      ? await createPlanModuleOrLesson({ ...params }, selectedModuleId)
      : await deletePlanLesson(params?.lessonId);
    if (SuccessRes) {
      const moduleData = await setModuleData();
      const lessonsInfo =
        moduleData.filter((obj) => obj?.id === selectedModuleId)[0]?.lessons ||
        [];
      dispatch(setCustomizedPlanData([...lessonsInfo]));
    }
    dispatch(hideLoader());
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (destination && source?.droppableId !== destination?.droppableId) {
      if (source?.droppableId === 'dropOne') {
        const droppedItem = learningPlanData.find(
          (item) => item?.id === result?.draggableId
        );
        if (droppedItem) {
          const params = {
            name: droppedItem?.heading,
            description: droppedItem?.description?.replace(
              HTML_TAG_PATTERN,
              ''
            ),
            type: droppedItem?.type_id ?? courseCategoryId?.courses,
          };
          onLessonCreationOrDeletion(params, true);
        }
        dispatch(
          setLearningPlanData(
            learningPlanData.filter((item) => item?.id !== result?.draggableId)
          )
        );
      }

      if (source?.droppableId === 'dropTwo') {
        onLessonCreationOrDeletion({ lessonId: result?.draggableId }, false);
      }
    }
  };

  return (
    <>
      <div className="row">
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result);
          }}
        >
          <div className="col-8">
            <AddingPlanLeftModule planData={planData} />
          </div>
          <div className="col-4">
            <PlanContext.Provider value={onLessonCreationOrDeletion}>
              <AddingPlanRightPanel
                parentId={planId ?? capabilityId}
                setModuleData={setModuleData}
                setSelectedModuleId={setSelectedModuleId}
                planModuleData={planModuleData}
              />
            </PlanContext.Provider>
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default AddingPlanModules;
