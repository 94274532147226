import classNames from 'classnames';
import React from 'react';
import Headings from '../../Shared/Headings';
import c from '../sessionDetails.Module.scss';
import CustomSelect from '../../Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Divider } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import QuickStatsDataAnalytics from '../../Home/QuickStats/QuickStatsDataAnalytics';
import { getPercentage } from '../../../utils/utility';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Focus = ({ focus_data = {}, session_data_agumentation }) => {
  // const Focus = ({ focus_data, session_data = {} }) => {
  // const struggle_data =  struggle_data

  // console.log("Agumentation Data", session_data_agumentation)
  // console.log("Focus Data", focus_data)
  return (
    <div className={classNames(c.focus, 'mt-3 pb-4')}>
      <div className="row px-5 py-3">
        <div className="col-8">
          <Headings headingType="h4" classToOverride="fw-bold">
            Stats
          </Headings>
        </div>
        <div className="col-4">
          <CustomSelect label="Filter" dropdownIcon={FilterAltIcon} />
        </div>
      </div>
      <Divider />
      <div className="px-5">
        {/* <div className="mb-3">
          <Line options={options} data={data} height={50} />
        </div> */}
        <div className="row">
          <div className="col">
            <QuickStatsDataAnalytics
              heading="Duration"
              time={
                focus_data?.duration?.cur
                  ? moment
                      .duration(focus_data?.duration?.cur, 'seconds')
                      ?.format('HH:mm:ss', { trim: false })
                  : '0 seconds'
              }
              // time={getHoursFromMinutes(focus_data?.duration?.cur)}
              // status={
              //   String(
              //     getPercentage(
              //       focus_data?.duration?.prev_avg,
              //       focus_data?.duration?.cur
              //     )
              //   )
              // }
            />
          </div>
          <div className="col">
            <QuickStatsDataAnalytics
              heading="Tab Switches"
              time={focus_data?.tab_switches?.cur}
              // status={
              //   String(
              //     getPercentage(
              //       focus_data?.tab_switches?.prev_avg,
              //       focus_data?.tab_switches?.cur
              //     )
              //   )
              // }
            />
          </div>
          <div className="col">
            <QuickStatsDataAnalytics
              heading="Relevant Tabs"
              time={focus_data?.rel_tabs?.cur}
              // status={String(
              //   getPercentage(
              //     focus_data?.rel_tabs?.prev_avg,
              //     focus_data?.rel_tabs?.cur
              //   )
              // )}
            />
          </div>
          {/* <div className="col">
            <QuickStatsDataAnalytics
              heading="Correction Nudges"
              time={focus_data?.corr_nudges?.cur}
              status={
                String(
                  getPercentage(
                    focus_data?.corr_nudges?.prev_avg,
                    focus_data?.corr_nudges?.cur
                  )
                ) 
              }
              // status={''}
              // comparedText="Coming Soon..."
            />
          </div> */}
          <div className="col">
            <QuickStatsDataAnalytics
              heading="Nudges/30min"
              // time={focus_data?.corr_nudges?.cur}
              time={
                session_data_agumentation?.nudge_yes_count ? Math.round(session_data_agumentation.nudge_yes_count/Math.ceil(session_data_agumentation.duration_secs/(30*60))) : 0 //Nudges per 30 Mins so 30*60
              }
              // time={String(
              //   getPercentage(
              //     focus_data?.corr_nudges?.prev_avg,
              //     focus_data?.corr_nudges?.cur
              //   )
              // )}
            />
          </div>
          <div className="col">
            <QuickStatsDataAnalytics
              heading="Struggle Emotions"
              time={session_data_agumentation?.struggling_emotion_count ? Math.round((session_data_agumentation.struggling_emotion_count/session_data_agumentation.total_emotion_count)*100) + "%": "NA" }
              // time={
              //   session_data_agumentation?.struggling_emotion_count
              //     ? Math.round(
              //         (session_data_agumentation.struggling_emotion_count /
              //           session_data_agumentation.total_emotion_count) *
              //           100
              //       ) + ''
              //     : 'NA' //Percentage
              // }
              // status=
              // comparedText="Coming Soon..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Focus;
