import React from 'react';
import c from '../quiz.module.scss';
import classNames from 'classnames';
import QuizTitle from '../QuizTitle';
import QuizSelect from '../QuizSelect';

const QuizTitleContainer = ({heading, title, ...props }) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center'
      )}
    >
      <QuizTitle
        heading={heading}
        title={title}
      />
      {/* <QuizSelect /> */}
    </div>
  );
};
export default QuizTitleContainer;
