import { API_URLS } from '../../../../config/API_URLS/api_urls';
import {
  getApiCall,
  postApiCall,
  putApiCall,
} from '../../../../utils/axios/axios';
import store from '../../../../redux/store';

export const getTargetsData = async (
  user_client_id = store?.getState()?.generalInformation?.id
) => {
  try {
    const paramsData = {
      queryParams: {
        user_client_id: user_client_id,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(
      API_URLS?.target_capabilities,
      paramsData
    );
    return response?.data;
  } catch (error) {
    console.log(`Error in fetching Target Details ${error}`);
  }
};

export const setNewTarget = async (params = {}) => {
  try {
    const body = {
      user_client_id:
        params?.user_client_id || store?.getState()?.generalInformation?.id,

      targets_data: [...params?.targets_data],

      date: params?.date,

      assigned_by: params?.assigned_by,
    };
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await postApiCall(
      API_URLS?.target_capabilities,
      body,
      paramsData
    );
    return response;
  } catch (err) {
    console.log('Error in setNewTarget ', err);
  }
};

export const deleteTarget = async (params) => {
  const body = {
    user_client_id:
      params?.user_client_id || store?.getState()?.generalInformation?.id,

    targets_data: [...params?.targets_data],

    date: params?.date,
  };

  const header = { queryParams: {}, headers: {}, isAuthorized: true };
  const res = await putApiCall(API_URLS.target_capabilities, body, header);
  return res;
};
