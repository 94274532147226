import React from 'react';
import classNames from 'classnames';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';

const QuizQuestionsTitle = ({ heading, title, ...props }) => {
  return (
    <div>
      <Headings headingType="h6" classToOverride={classNames('fw-bold')}>
        {heading}
      </Headings>
      <Text textType="t3">{title}</Text>
    </div>
  );
};
export default QuizQuestionsTitle;
