/**
 * @function UploadComponent
 * Component of Profile/Calibrations
 * @param title title of the upload component
 * @param text text for DottedUploadInput to be displayed at the middle of the box
 * @param accept type for DottedUploadInput like video,image
 */
import React from 'react';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import DottedUploadInput from '../../../Shared/DottedUploadInput/index';
import Text from '../../../Shared/Text/index';
import { Resources } from '../../../../config/Resources';
export const UploadComponent = ({ title, text, accept }) => {

  return (
    <>
      <div className="col-3 mt-3 d-flex flex-column align-items-center">
        {' '}
        <div>
          {' '}
          <Avatar
            alt="Bryan Talebi"
            src={`${Resources.images.AvtarImage}`}
            sx={{
              height: '142px',
              width: '142px'
            }}
          />
        </div>
        <div className={classNames(c.EditStyle)}>
          {' '}
          <Text textType="t2">{title}</Text>
        </div>
      </div>
      <div className="col-4 ms-3">
        <DottedUploadInput accept={accept} type="file" text={text}/>
      </div>
    </>
  );
};
