import {
  PythonIcon,
  FinanceIcon,
  SocialManagementIcon,
  ClimateChangeIcon,
  DigitalMarketingIcon,
  GreenTechIcon,
  CleanEnergyIcon,
  JavaScriptIcon,
  TherapyIcon,
} from '../../components/Shared/Icons';
import { Resources } from '../../config/Resources';
import * as yup from 'yup';
import { API_URLS } from '../../config/API_URLS/api_urls';
import { postApiCall } from '../../utils/axios/axios';
import { CLIENT_ID } from '../../config/Constants';

export let initialValues = {
  email: '',
};

export const UserDataValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address format')
    .required('Email is required'),
});

export const onSubmit = async (values, history, setFieldError) => {
  try {
    const reqBody = {
      client_id: CLIENT_ID,
      email: values?.email,
    };

    const res = await postApiCall(API_URLS.user_email_check, reqBody);

    if (res?.message) {
      if (res.message.length > 0 && res.message[0].is_user_account_active) {
        setFieldError('email', 'Email Already in Use!');
      } else {
        history.push('/signUp', { emailId: values?.email });
      }
    }

    if (res?.error) {
      console.log('Err', res?.error);
    }
  } catch (err) {
    console.log('error in login', err);
  }
};

export const CapabilityData = [
  {
    icon: <PythonIcon />,
    text: 'Python',
    redirectTo: '',
  },
  {
    icon: <FinanceIcon />,
    text: 'Finance',
    redirectTo: '',
  },
  {
    icon: <SocialManagementIcon />,
    text: 'Social Media Management',
    redirectTo: '',
  },
  {
    icon: <ClimateChangeIcon />,
    text: 'Climate Change Science',
    redirectTo: '',
  },

  {
    icon: <DigitalMarketingIcon />,
    text: 'Digital Marketing',
    redirectTo: '',
  },
  {
    icon: <GreenTechIcon />,
    text: 'Green Tech',
    redirectTo: '',
  },
  {
    icon: <CleanEnergyIcon />,
    text: 'Clean Energy',
    redirectTo: '',
  },
  {
    icon: <JavaScriptIcon />,
    text: 'Java Script',
    redirectTo: '',
  },
  {
    icon: <TherapyIcon />,
    text: 'Therapy',
    redirectTo: '',
  },
];

export const TestimonialData = [
  {
    id: '1',
    img: Resources.images.LandingTestimonial,
    text1: `"My entire career has been focused on creating opportunities for global impact. Ahura AI’s breakthrough technology provides people with the ability to learn in a personalized environment. Like so many challenges in the world, access to education is vitally important, and the reality is there needs to be more companies like Ahura AI addressing these worldwide crises."`,
    text2: 'Monique Giggy',
    text3: `"President, Producer of FilmCapital, Former Vice President of Singularity University"`,
  },
  {
    id: '2',
    img: Resources.images.LandingTestimonial,
    text1: `"Ahura AI is creating a world where people can retrain and restart their careers. This mission is not only beautiful, but a critically important one. Our world needs to be more adaptable and innovation can solve challenging world problems as well. Ahura AI is a real bellwether for technology companies."`,
    text2: 'Cezary Petrasik',
    text3: 'Founder & CEO at Healthdom',
  },
  {
    id: '3',
    img: Resources.images.LandingTestimonial,
    text1: `"Ahura AI is helping people increase their fundamental value in the marketplace and reach their full potential."`,
    text2: 'John Belluomini',
    text3: 'Chairman of the Board at Peak Bank',
  },
];
