/* global chrome */
import axios from 'axios';
import moment from 'moment';
import { API_URLS, CHROME_EXTENSION_API } from '../../config/API_URLS/api_urls';
import { putApiCall } from '../../utils/axios/axios';
import lodash from 'lodash';
import { getUserDetails } from '../AppRoute';
import {
  CHROME_EXTENSION_ID,
  CHROME_EXTENSION_ID_PROD,
} from '../../config/Constants';
import store from '../../redux/store';
import {
  setBarData,
  setIsExtensionActive,
  setSessionData,
  setSessionReport,
  setIsSessionDetailsOpen,
  setIsExtensionBtnEnabled,
} from '../../redux/Actions/ExtensionHandler';
import { SET_TOAST_ERROR } from '../../redux/Actions/Common';
import { processSessionPages } from '../../utils/utility';
import { fetchUserDetails } from '../Profile/GeneralInfo';
export var SessionTopic = '';
export var Extension = {
  mostVisited: '',
  openReports: '',
  sessionDuration: '',
  // isSessionStart: false,
  whichNavItem: '',
  external_id_for_debug: '',
  extSessionId: '',
  reportData: [],
  openSearchModal: false,
  emotionalData: [],
  topDistraction: [],
  fromSession: false,
  exitAlertWindow: false,
  sessionStartTime: '',
};
// const handler = null
export var barData = [];
export var aggregateExpression = [];
export var sessions = [];
var generalInformation = store.getState().generalInformation;
/* get user data stored in chrome extension storage */
export const withConnect = async () => {
  if (window.chrome && window.chrome.runtime) {
    const corpID =
      store.getState().userCorporateDetails &&
      store.getState().userCorporateDetails.length > 0
        ? store.getState().userCorporateDetails[0]?.client_id
        : 'ahura';
    console.log(corpID);
    await window.chrome.runtime.sendMessage(
      CHROME_EXTENSION_ID,
      {
        saveUser: true,
        user_id: store.getState().generalInformation.external_id,
        user_name: store.getState().generalInformation.user_name,
        user_client_id: store.getState().generalInformation.id,
        corporate_id: corpID,
      },
      (response) => {
        extCreateSession();
      }
    );
  }
};
/* start session and send session data to extension */
const extStartSession = async () => {
  /** TODO: Open installation wizard if chrome extension is not install already */
  if (window.chrome && window.chrome.runtime) {
    if (SessionTopic) {
      if (store.getState().generalInformation.external_id) {
        withConnect();
      } else {
        const user = await extCreateUser();
        if (user || store?.getState().generalInformation.external_id) {
          extCreateSession();
        }
      }
    }
  } else {
    store.dispatch(
      SET_TOAST_ERROR({
        error: true,
        message: 'Extension Is not Installed or Something Went Wrong',
      })
    );
    window.open(
      `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID_PROD}`
    );
  }
};
/* create user if not exist */
export const extCreateUser = async (username = null) => {
  return new Promise((resolve, reject) => {
    const corpID =
      store.getState().userCorporateDetails &&
      store.getState().userCorporateDetails.length > 0
        ? store.getState().userCorporateDetails[0]?.client_id
        : 'ahura';
    const extPayload = {
      corporate_id: corpID,
      role_name: store.getState().generalInformation?.role,
      user_name: username ? username : store.getState().generalInformation.user_name,
      user_client_id: store.getState().generalInformation.id,
      user_password: "VH2Cjg'mme=>U[UG", // right now extension server will only accept this password
    };
    /* send user name to extension */
  if (chrome && chrome?.runtime) {
    window.chrome.runtime.sendMessage(
      CHROME_EXTENSION_ID,
      { user: extPayload },
      async (response) => {
        console.log('response:::::', response);
        if (response && response.data) {
          Extension.external_id_for_debug = response.data;
          const reqBody = {
            id: store.getState().generalInformation.id,
            external_id: response.data,
          };
          const header = { queryParams: {}, headers: {}, isAuthorized: true };
          const res = await putApiCall(API_URLS.users, reqBody, header);
          if (res && !res.error) {
            generalInformation.external_id = response.data;
            fetchUserDetails();
            resolve(response.data);
          }
        } else {
          store.dispatch(
            SET_TOAST_ERROR({
              error: true,
              message: 'Error from extension!',
            })
          );
          reject(false);
        }
      }
    );
  }
  });
};
export const extUpdateUI = async () => {
  window.onbeforeunload = null;
  Extension.extSessionId = {};
  store.dispatch(setIsExtensionActive(false));
  await getSessions(true);
  store.dispatch(setIsSessionDetailsOpen(true));
};
export const extStopSession = () => {
  if (chrome && chrome.runtime) {
    chrome.runtime.sendMessage(
      CHROME_EXTENSION_ID,
      { stop_session: true },
      (response) => {
        if (response && response.error) {
          store.dispatch(
            SET_TOAST_ERROR({
              error: true,
              message: 'Unable to stop the session. Something went wrong',
            })
          );
          store.dispatch(setIsExtensionActive(false));
        }
      }
    );
  } else {
  }
};
export const handleStartSession = (event, topic) => {
  event?.preventDefault();
  if (chrome && chrome?.runtime) {
    handleStartStop(true, topic);
    store.dispatch(setIsExtensionBtnEnabled(true));
  } else {
    store.dispatch(
      SET_TOAST_ERROR({
        error: true,
        message: 'Extension is not installed or Something went Wrong',
      })
    );
    window.open(
      `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID}`
    );
    store.dispatch(setIsExtensionBtnEnabled(false));
  }
};
export const handleStartStop = (value, topic) => {
  if (value) {
    SessionTopic = topic && topic;
    Extension.user_name = store.getState().generalInformation.user_name;
    Extension.id = store.getState().generalInformation.id;
    generalInformation.external_id =
      store.getState().generalInformation.external_id;
    store.getState().generalInformation.id && extStartSession();
  } else {
    extStopSession();
    if (store.getState().generalInformation.external_id === null) {
      const token = localStorage.getItem('token');
      if (!token) {
      } else {
        getUserDetails(token);
      }
    }
  }
};
const extCreateSession = async () => {
  const element = document.getElementById('extMainbody');
  if (element.classList.contains('extMainbodyMinimized')) {
    element.classList.remove("extMainbodyMinimized");
    element.style.top = "0";
    element.style.maxWidth = "100vw";
  } else {
    element.style.top = "0";
    element.style.maxWidth = "100vw";
    element.style.overflow = "visible";
  }
  const extPayload = {
    description: SessionTopic,
  };
  store.dispatch(setIsExtensionBtnEnabled(true));
  await window.chrome.runtime.sendMessage(
    CHROME_EXTENSION_ID,
    { session: extPayload },
    (response) => {
      store.dispatch(setIsExtensionBtnEnabled(false));
      if (response === undefined) {
        window.open(
          `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID_PROD}`
        );
      }
      if (response && response.data) {
        const sessionStartTime = moment(response.data.start_time).format(
          'dddd, DD MMM YYYY'
        );
        store.dispatch(setIsExtensionActive(true));
        Extension.extSessionId = response.data;
        Extension.sessionStartTime = sessionStartTime;
      } else if (response && response.error) {
        let message =
          'msg' in response
            ? response.msg
            : 'Something went wrong with Extension.';
        store.dispatch(
          SET_TOAST_ERROR({
            error: true,
            message: message,
          })
        );
        Extension.extSessionId = '';
        Extension.sessionStartTime = '';
      } else {
        Extension.extSessionId = '';
        Extension.sessionStartTime = '';
      }
    }
  );
};
export const getSessions = async (openReports = false) => {
  if (
    store.getState().generalInformation.external_id ||
    Extension.external_id_for_debug
  ) {
    const res = await axios
      .get(
        `${CHROME_EXTENSION_API}${
          store.getState().generalInformation.external_id ||
          Extension.external_id_for_debug
        }`,
        {}
      )
      .catch((error) => {
        store.dispatch(
          SET_TOAST_ERROR({
            error: true,
            message: 'Something went Wrong',
          })
        );
      });
    if (res && res.data) {
      reportData(res.data, openReports);
      const totalExpression = [];
      res.data.forEach((item) => {
        if (item && item.call_expression) {
          const expGrp = lodash.countBy(
            item.call_expression,
            'expression_name'
          );
          if (!Object.keys(expGrp).length === 0) {
            const aggExp = Object.keys(expGrp).reduce((a, b) =>
              expGrp[a] > expGrp[b] ? a : b
            );
            totalExpression.push({ expression: lodash.capitalize(aggExp) });
          }
        }
      });
      if (!Object.keys(totalExpression).length === 0) {
        const tE = lodash.countBy(totalExpression, 'expression');
        if (!Object.length) {
          const tF = Object.keys(tE).reduce((a, b) => (tE[a] > tE[b] ? a : b));
          aggregateExpression = tF;
        }
      }
      store.dispatch(setSessionData(res.data));
      getBarData(res.data);
    }
  }
};
const getBarData = (sessions = []) => {
  const sessionData = [];
  sessions &&
    sessions.map((session) => {
      const status = {
        good: 0,
        neutral: 0,
        bad: 0,
        session_id: session?.session_id,
      };
      const totalPages = session.visited_pages.length;
      if (session?.visited_pages?.length) {
        const { relevanceCount } = processSessionPages(session.visited_pages);
        status.good = Math.round(
          (relevanceCount.relevant_count / totalPages) * 100
        );
        status.neutral = Math.round(
          (relevanceCount.transitional_count / totalPages) * 100
        );
        status.bad = Math.round(
          (relevanceCount.distraction_count / totalPages) * 100
        );
      }
      sessionData.push(status);
      return 0;
    });
  if (sessionData.length > 0) {
    Extension.whichNavItem = 'progress';
    const e = { target: { id: 'progress' } };
    addActiveClass(e);
  }
  store.dispatch(setBarData(sessionData));
};
const addActiveClass = (e) => {
  const classes = document.querySelectorAll('.profile-nav-item');
  classes.forEach((classNo) => {
    classNo.classList.remove('profile-nav-active');
  });
  if (e.target.id) {
    document.getElementById(`${e.target.id}`) &&
      document
        .getElementById(`${e.target.id}`)
        .classList.add('profile-nav-active');
    Extension.whichNavItem = e.target.id;
  }
};
const reportData = (data, openReports) => {
  if (data.length === 0) return;
  const allData = [...data];
  data = data[0];
  let good = 0;
  let neutral = 0;
  let bad = 0;
  let allVisitedPages = [];
  allData.forEach((d) => {
    allVisitedPages = allVisitedPages.concat(d.visited_pages);
  });
  if (allVisitedPages && allVisitedPages.length) {
    const totalPages = allVisitedPages.length;
    const isRelevant = lodash.countBy(allVisitedPages, 'is_relevant_algo');
    const isTransitional = lodash.countBy(
      allVisitedPages,
      'is_transitional_algo'
    );
    good = isRelevant[1]
      ? Math.round(
          ((isTransitional[1]
            ? isRelevant[1] - isTransitional[1] < 0
              ? isTransitional[1] - isRelevant[1]
              : isRelevant[1] - isTransitional[1]
            : isRelevant[1]) *
            100) /
            totalPages
        )
      : 0;
    neutral = isTransitional[1]
      ? Math.round((isTransitional[1] * 100) / totalPages)
      : 0;
    const isBad = 100 - (good + neutral);
    bad = isBad ? Math.round(isBad) : 0;
  }
  const reportData = {
    good: good,
    neutral: neutral,
    bad: bad,
  };
  const emotionPerct = getEmotionData(data);
  const topDistractions = getDistractionPages(data);
  if (emotionPerct) {
    Extension.emotionalData = emotionPerct;
  }
  if (topDistractions) {
    Extension.topDistraction = topDistractions;
  }
  const mostVisited = getReleventURLS(data);
  Extension.reportData = reportData;
  Extension.sessionDuration = data.duration;
  Extension.openReports = openReports;
  Extension.pageVisits = data.visited_pages.length || 0;
  Extension.mostVisited = mostVisited;
  Extension.Distraction = setDistractionData(topDistractions);
  if (Extension.Distraction) {
    store.dispatch(
      setSessionReport({
        emotionData: Extension.emotionalData,
        topDistraction: Extension.topDistraction,
        reportData: reportData,
        sessionDuration: data.duration,
        openReports: openReports,
        mostVisited: mostVisited,
        pageVisits: data.visited_pages.length || 0,
        Distraction: setDistractionData(topDistractions),
      })
    );
  }
};
const setDistractionData = (topDistraction) => {
  const TempData = [];
  TempData.push(
    topDistraction &&
      topDistraction.map((distraction) => {
        const distraction_duration = changeToSeconds(distraction.duration);
        const session_duration = changeToSeconds(Extension.sessionDuration);
        const distraction_percentage =
          (distraction_duration / session_duration) * 100;
        return distraction_percentage.toPrecision(3);
      })
  );
  return TempData;
};
const getEmotionData = (data) => {
  const emotionalData = data.call_expression;
  if (emotionalData.length) {
    const emotionMap = {};
    const highestEmotion = { name: '', count: -Infinity };
    // const emotionData = {};
    emotionalData.forEach((emotion) => {
      if (emotionMap.hasOwnProperty(emotion.expression_name)) {
        emotionMap[emotion.expression_name] += 1;
      } else {
        emotionMap[emotion.expression_name] = 1;
      }
      if (emotionMap[emotion.expression_name] > highestEmotion.count) {
        highestEmotion.name = emotion.expression_name
          ? emotion.expression_name.charAt(0).toUpperCase() +
            emotion.expression_name.substr(1).toLowerCase()
          : '';
        highestEmotion.count = emotionMap[emotion.expression_name];
      }
    });
    // Object.keys(emotionMap).forEach(callbackfn)
    const totalSeconds =
      moment(data.duration, 'H:mm:ss').seconds() +
      moment(data.duration, 'H:mm:ss').minutes() * 60 +
      moment(data.duration, 'H:mm:ss').hours() * 3600;
    const totalDurations = Math.floor(totalSeconds / 10);
    return {
      name: highestEmotion.name,
      pert: Math.round((highestEmotion.count / totalDurations) * 100),
    };
  }
  return null;
};
export const changeToSeconds = (time) => {
  const totalSeconds =
    moment(time, 'H:mm:ss').seconds() +
    moment(time, 'H:mm:ss').minutes() * 60 +
    moment(time, 'H:mm:ss').hours() * 3600;
  return totalSeconds;
};
const compareVisitedPages = (b, a) => {
  const a_sec = a.duration
    ? moment(a.duration, 'H:mm:ss').seconds() +
      moment(a.duration, 'H:mm:ss').minutes() * 60 +
      moment(a.duration, 'H:mm:ss').hours() * 3600
    : 0;
  const b_sec = b.duration
    ? moment(b.duration, 'H:mm:ss').seconds() +
      moment(b.duration, 'H:mm:ss').minutes() * 60 +
      moment(b.duration, 'H:mm:ss').hours() * 3600
    : 0;
  if (a_sec < b_sec) {
    return -1;
  }
  if (a_sec > b_sec) {
    return 1;
  }
  return 0;
};
const getDistractionPages = (data) => {
  const visitedPages = data.visited_pages;
  if (visitedPages && visitedPages.length) {
    return visitedPages.sort(compareVisitedPages).slice(0, 2);
  }
  return null;
};
const getReleventURLS = (data) => {
  const map = {
    good: [],
    neutral: [],
    bad: [],
  };
  const visitedPages = data.visited_pages;
  visitedPages.forEach((page) => {
    if (page.is_transitional_algo) {
      map.neutral.push(page);
    } else if (page.is_relevant_algo) {
      map.good.push(page);
    } else {
      map.bad.push(page);
    }
  });
  map.good = map.good.sort(compareVisitedPages).slice(0, 3);
  map.bad = map.bad.sort(compareVisitedPages).slice(0, 3);
  map.neutral = map.neutral.sort(compareVisitedPages).slice(0, 3);
  return map;
};
