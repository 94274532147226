/**
 * @function LeftReportStatsPanal It is used to Display the mixed chart data with dynamic Data
 */

import classNames from 'classnames';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import CustomSelect from '../../../../Layout/Select';
import {
  MixedChartData,
  mixedChartOption,
} from '../../../../../helpers/Home/QuickStats';
import reportStyling from '../../managerQuickstats.module.scss';
import InfoCard from '../../../../../helpers/ManagerDashboard/InfoCard';
import { OverviewMixedGraphData } from '../../../../../MockData/ManagersDashboard';
import Text from '../../../../Shared/Text';

const LeftReportStatsPanal = () => {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
  );

  const ChartData = MixedChartData(OverviewMixedGraphData);
  return (
    <>
      <div className={classNames('d-flex w-100')}>
        <CustomSelect label="Filter By" className="w-25 ms-3" />
        <InfoCard
          classToOveride={classNames(
            'position-absolute',
            reportStyling.infoCard
          )}
        >
          <h5 className={classNames('mt-2', reportStyling.CardHeading)}>
            Lorem Ipsum
          </h5>
          <Text textType="t1">
            This is what's not working. Here's some actionable feedback:
          </Text>
          <Text textType="t1">
            Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
            ipsumlorem ipsum
          </Text>
        </InfoCard>
      </div>
      <div
        className={classNames(
          'py-5 p-5',
          reportStyling.QuickStatsLeftChartContainer
        )}
      >
        <Chart data={ChartData} options={mixedChartOption} />
      </div>
    </>
  );
};

export default LeftReportStatsPanal;
