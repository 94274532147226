export const lineChartParameters = {
  axislabels: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  data1: [
    1.4, 1.8, 2.2, 2.7, 2.9, 2.9, 2.7, 2.5, 2.4, 2.4, 2.5, 2.7, 3, 3.4, 4, 4.9,
    5.3, 5.4, 5.2, 4.6, 4, 3.7, 3.7, 3.8, 4, 4.1, 3.9, 3.3, 3, 2.9, 2.9,
  ],
  data2: [
    1.3, 1.6, 2, 2.2, 2.1, 2.2, 2.7, 3.5, 4, 4.3, 4.4, 4.4, 4.3, 4, 3.3, 2.7,
    2.5, 2.5, 2.6, 2.7, 2.8, 2.8, 2.6, 2.3, 2, 2, 2.3, 2.7, 2.9, 2.8, 2.8,
  ],
};

export const barChartParameters = {
  data: [55, 50, 45, 40, 43, 40, 35, 55],
};

export const DoughnutChartParameters = {
  data: [50, 30, 10],
};

export const dataAnalyticsItemsList1 = [
  {
    heading: "Hours Studied",
    time: "7 hr 32 min",
    status: "40.15%",
  },
  {
    heading: "Number of sessions",
    time: "7",
    status: "15.00%",
  },
  {
    heading: "Number of capabilities",
    time: "7",
    status: "75.00%",
  },
  {
    heading: "Sessions in Backlog",
    time: "6",
    status: "-33.00%",
  },
  {
    heading: "Sessions Past Deadline",
    time: "2",
    status: "-50.00%",
  },
];

export const dataAnalyticsItemsList2 = [
  {
    heading: "Happiness",
    time: "45%",
    status: "15.00%",
  },
  {
    heading: "Lessons Converted",
    time: "4",
    status: "25.00%",
  },
  {
    heading: "Nudges Provided",
    time: "27",
    status: "-42.00%",
  },
  {
    heading: "URL Distraction",
    time: "12",
    status: "-40.15%",
  },
  {
    heading: "URL Distraction (Co.)",
    time: "6%",
    status: "-33.00%",
  },
];
