import { getApiCall } from '../../../utils/axios/axios';
import store from '../../../redux/store';
import { API_URLS } from '../../../config/API_URLS/api_urls';
export const menuItems = [
  { label: 'All time', value: 'all' },
  { label: 'This month', value: 'this' },
  { label: 'Last month', value: 'prev' },
];

export const BarOptions = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'center',
      labels:{
        padding:6,
      }
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio:1.7,
  scales: {
    x: {
      stacked: false,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: false,
      //   grid: {
      //     display: false,
      //   },
    },
  },
};

const labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];

export const CapBarData = (weekly_data = [], capTarget) => {
  const labelsArr = [];
  const userCap = [];
  const companyAvg = [];
  weekly_data.forEach((item) => {
    labelsArr.push(`week ${item?.week}`);
    userCap.push(Number(item?.value));
    companyAvg.push(Number(item?.avg));
  });
  console.log('Cap Weekly', weekly_data)
  console.log('Cap Target', capTarget)
  return {
    labels: [...labelsArr],
    datasets: [
      {
        label: 'Capabilities',
        data: [...userCap],
        backgroundColor: 'rgba(135, 212, 228)',
      },
      {
        label: 'Target',
        data: [...new Array(labelsArr.length).fill(capTarget)],
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
      {
        label: 'Company Average',
        data: [...companyAvg],
        backgroundColor: 'rgba(0,0,0,0.5)',
      },
    ],
  };
};

export const HourBarData = (weekly_data = [], targetHours) => {
  const labelsArr = [];
  const userCap = [];
  const companyAvg = [];
  typeof weekly_data == "object" && weekly_data.forEach((item) => {
    labelsArr.push(`week ${item?.week}`);
    userCap.push(Number(item?.value));
    companyAvg.push(Number(item?.avg));
  });
  console.log("Hour Data", weekly_data)
  console.log("Target Data", targetHours)

  return {
    labels: [...labelsArr],
    datasets: [
      {
        label: 'Minutes',
        data: [...userCap],
        backgroundColor: 'rgba(251, 127, 61)',
      },
      {
        label: 'Target',
        data: [...new Array(labelsArr.length).fill(targetHours)],
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
      {
        label: 'Company Average',
        data: [...companyAvg],
        backgroundColor: 'rgba(0,0,0,0.5)',
      },
    ],
  };
};

export const LineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

export const LineData = {
  labels,
  datasets: [
    {
      label: 'Focus',
      data: [1, 30, 25, 50, 30],
      borderColor: '#C2A0ED',
      borderWidth: 6,
      // backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

export const getHighlightsData = async (filter, id, ext_id) => {
  const queryParams = {
    user_client_id: id || store?.getState()?.generalInformation?.id,
    ext_id: ext_id || store?.getState()?.generalInformation?.external_id,
    filter: filter,
    corp_id: 'ahura', //this is fixed for now, needs to be changed when python team will integrate dynamic corp_id
  };

  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await getApiCall(API_URLS.highlights, paramsData);
  return response;
};
