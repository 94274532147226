import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import c from '../modal.Module.scss';
import { Resources } from '../../../../config/Resources';
import Text from '../../../Shared/Text';
import Headings from '../../../Shared/Headings';
import { Button, CircularProgress } from '@mui/material';
import BackAndContinue from '../Box/BackAndContinue';
import {
  resendCorporateOTP,
  submitCorporateOTP,
} from '../../../../helpers/My Account';
import { useDispatch } from 'react-redux';
import { SET_TOAST_ERROR } from '../../../../redux/Actions/Common';
import { getAccountsData } from '../../../../helpers/Home/CreateAccount/DropdownMenu/ProfileMenu';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const OtpVerification = ({ handleContinue, setOpen, corporateUserData }) => {
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const handleSubmitOTP = async () => {
    const response = await submitCorporateOTP(
      corporateUserData?.email,
      corporateUserData?.parent_id,
      corporateUserData?.phone,
      otp
    );
    if (response?.message) {
      getAccountsData();
      handleContinue();
      googleAnalyticsTriggerFn(
        'Button',
        'Create New Corporate Account',
        'New Corporate Account created Successfully'
      );
    } else {
      console.log(response?.error);
      googleAnalyticsTriggerFn(
        'Button',
        'Create New Corporate Account',
        'New Corporate Account Unsuccessfull'
      );
    }
  };

  const handleResendOtp = async () => {
    const response = await resendCorporateOTP(
      corporateUserData?.email,
      corporateUserData?.id
    );
    if (response?.message) {
      setOtp('');
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response?.message,
        })
      );
    } else {
      console.log(response?.error);
    }
  };
  return (
    <React.Fragment>
      <div className={classNames('mt-2 text-center')}>
        <img
          src={Resources?.images?.AhuraHeaderLogo2}
          className={classNames(c.Logo)}
        />
      </div>
      <div className={classNames('p-4')}>
        <div>
          <Headings
            headingType="h3"
            classToOverride={classNames('text-center fw-bold')}
          >
            Enter verification code
          </Headings>
        </div>

        <div className="my-4 d-flex justify-content-around">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            separator={<span>-</span>}
            isInputNum={true}
            className={c.otpBox}
            focusStyle={c.focusStyle}
          />
        </div>

        <div className={classNames('text-center my-4')}>
          <Text textType="t1">Your 4 digit code was sent to</Text>
          <Text textType="t1" classToOverride={classNames(c.primaryColor)}>
            {corporateUserData?.email || ''}
          </Text>
        </div>

        <div className={classNames('text-center my-4')}>
          <Text textType="t1">
            By verifying your code you consent to receive notifications from
            Ahura.
          </Text>
        </div>

        <div className={classNames('text-center my-4')}>
          <Text textType="t1">I did not receive a code</Text>
          <Button onClick={() => handleResendOtp()}>
            <Text
              textType="t0"
              classToOverride={classNames(
                c.primaryColor,
                'fw-bold',
                c.cursorPointer
              )}
            >
              RESEND
            </Text>
          </Button>
        </div>

        {/* <div className={classNames('mt-5')}>
            <center>
                <Button
                    variant="contained"
                    className={classNames('w-25', c.ForgotBtn)}
                    // onClick={() => handleSubmit()}
                >
                    {isLoading ? <CircularProgress color="inherit" /> : 'Verify'}
                </Button>
            </center>
        </div> */}
      </div>
      <BackAndContinue handleContinue={handleSubmitOTP} setOpen={setOpen} />
    </React.Fragment>
  );
};
export default OtpVerification;
