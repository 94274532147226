/**
 * @function AddNewModuleForm
 * Render new module addition form
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { setDisplayAddingPlanForm } from '../../../../../redux/Actions/LearningPlans';
import { useFormik } from 'formik';
import {
  validateModuleForm,
  addNewModuleFormData,
} from '../../../../../helpers/LearningPlans/AddingPlanRightPanel';
import c from '../../../learningPlans.Module.scss';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import classNames from 'classnames';
import InputControls from '../../InputControls';

const AddNewModuleForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const displayForm = () =>
    dispatch(setDisplayAddingPlanForm({ moduleAddition: false }));

  const { values, errors, handleChange } = useFormik({
    initialValues: {
      name: null,
      description: null,
    },
    validate: validateModuleForm,
  });

  return (
    <div className={c.formLayout}>
      <Button
        variant="text"
        size="large"
        startIcon={<ChevronLeftIcon className="fs-2" />}
        className={classNames('p-0 text-capitalize', c.font_24, c.backBtn)}
        onClick={displayForm}
      >
        Back to Plan
      </Button>

      {addNewModuleFormData.map((item) => (
        <InputControls
          key={item?.id}
          id={item?.id}
          inputLabel={item?.inputLabel}
          textFieldLabel={item?.textFieldLabel}
          values={values}
          handleChange={handleChange}
          errors={errors}
        />
      ))}

      <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
        <Button
          variant="contained"
          className="w-50 mb-1 text-capitalize"
          disabled={!(values?.name && values?.description)}
          onClick={() => {
            handleSubmit(values);
            displayForm();
          }}
        >
          create this module
        </Button>

        <Button
          variant="outlined"
          color="error"
          className="w-50 mt-3 text-capitalize"
          onClick={displayForm}
        >
          delete this module
        </Button>
      </div>
    </div>
  );
};

export default AddNewModuleForm;
