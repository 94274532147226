import * as yup from 'yup';
import { TEXT_FIELD_REGEX_PATTERN } from '../../config/Regex/regex';
import { postApiCall, putApiCall } from '../../utils/axios/axios';
import { CLIENT_ID } from '../../config/Constants';
import { API_URLS } from '../../config/API_URLS/api_urls';

export const InviteTabsOptions = [
  { label: 'Invite By Email', value: 0 },
  { label: 'Batch Invite', value: 1, isDisabled: true },
];

export const InviteDataValidationSchema = yup.array().of(yup.object().shape({
  first_name: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'First Name is invalid')
    .when('fieldsStatus', {
      is: true,
      then: yup.string().required('First Name is required'),
    }),
  last_name: yup
    .string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'Last Name is invalid')
    .when('fieldsStatus', {
      is: true,
      then: yup.string().required('Last Name is required'),
    }),
  email: yup
    .string()
    .email('Invalid Email Address format')
    .when('fieldsStatus', {
      is: true,
      then: yup.string().required('Email is required'),
    }),
}));

export const postInvite = async (client_id, email_data, groupId='', corpId='', groupName='') => {
  try {
    let groupInviteData = (corpId && groupId) ? {
        groupId: groupId,
        corpId: corpId,
        groupName: groupName } : {}

    const reqBody = {
      client_id: client_id,
      platform_id: CLIENT_ID, //Use CLIENT_ID from Config
      email_data: email_data,
      ...groupInviteData
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };

    const res = await postApiCall(API_URLS.invite, reqBody, paramsData);
    if (res?.error) {
      console.log('Err', res?.error?.message);
    } 
  } catch (err) {
    console.log('error in login', err);
  }
};


export const approveAllRequests = async (params) => {
  try {
    const reqBody = {
      ...params,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await putApiCall(
      API_URLS.bulkApproveMemberRequests,
      reqBody,
      header
    );
    return res;
  } catch (err) {
    console.log('Error in Approve all requests | Helpers :', err);
  }
};