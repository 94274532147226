import Text from '../../Shared/Text';
import { Button } from '@mui/material';
import c from '../allocation.module.scss';
import classNames from 'classnames';
import { approveAllRequests } from '../../../helpers/Allocation';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';
import { hideLoader, showLoader } from '../../../redux/Actions/Loader';
import { leaveAccount } from '../../../helpers/My Account';
import { getAcceptedNotificationRequest } from '../../../helpers/Home/Notifications';

const RequestMembersModal = ({
  selectedMembers,
  setRequestModalStatus,
  getAllocationTableData,
  memberStatus,
  groupStatus,
  user_id,
  groupId,
  name,
  setSelectedIds
}) => {

  const { platform_id, external_id } = useSelector(
    (state) => state?.generalInformation
  );

  const dispatch = useDispatch();

  const approveRequests = async () => {
    dispatch(showLoader());
    const reqBody = {
      neighbourhood_ids: selectedMembers,
      status: 'APPROVED',
    };
    const data = await approveAllRequests(reqBody);
    setSelectedIds([]);
    if (Array.isArray(data) && data?.length) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Approved Successfully!',
        })
      );
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something Went Wrong',
        })
      );
    }
    dispatch(hideLoader());
    setRequestModalStatus(false);
    getAllocationTableData();
    
  };

  const leaveAccountFunc = async () => {
    dispatch(showLoader());
    const response = await leaveAccount(
      user_id,
      groupId,
      platform_id,
      external_id
    );
    if (response?.result === 'removed Successfully') {
      getAllocationTableData();
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Removed Successfully!',
        })
      );
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response.result,
        })
      );
    }
    dispatch(hideLoader());
    setRequestModalStatus(false);
  };

  const handleAcceptGroup = async () => {
    dispatch(showLoader());
    const response = await getAcceptedNotificationRequest(
      groupId,
      platform_id,
      user_id,
      'APPROVED',
      true
    );
    if (response?.message === 'Updated') {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Approved Successfully!',
        })
      );
      dispatch(hideLoader());
      setRequestModalStatus(false);
      getAllocationTableData();
    }
  };

  const modalHeading = () => {
    if (groupStatus === 'PENDING') {
      return "Do you want to approve all the members ?"
    } else {
      if (groupStatus === 'APPROVED') {
        return `Are you sure you want to remove ${name} from group?`
      } else {
        return `Do you want to add ${name} in group?`
      }
    }
  }

  return (
    <div className={c.leaveMailContainer}>
      <Text textType="t18" classToOverride="text-center">
        {modalHeading()}
        {
          <div
            className={classNames(
              'd-flex justify-content-end',
              c.btnDivContainer
            )}
          >
            <Button
              size="medium"
              onClick={() => {
                setRequestModalStatus(false);
              }}
              variant="outlined"
              className={`${c.cancelButton}`}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                if (groupStatus === 'PENDING') {
                  approveRequests();
                } else {
                  if (memberStatus) {
                    leaveAccountFunc();
                  } 
                  else {
                    handleAcceptGroup();
                  }
                }
              }}
              className={classNames(c.continueBtn)}
            >
              Confirm
            </Button>
          </div>
        }
      </Text>
    </div>
  );
};

export default RequestMembersModal;
