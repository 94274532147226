/**
 * @function EditAndSaveBtn
 * @param {function} handleSubmit Function for submit
 */

import React from 'react';
import { Button } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const EditAndSaveBtn = ({
  editable,
  setEditable,
  handleSubmit,
  disabled,
  page,
}) => {
  return (
    <div className="d-flex justify-content-end my-4">
      {!editable ? (
        <Button
          variant="contained"
          startIcon={<BorderColorIcon />}
          size="large"
          onClick={() => {
            setEditable(true);
            googleAnalyticsTriggerFn(
              'Button',
              'Edit',
              `Edit button clicked on ${page} page on profile page`
            );
            console.log(`Edit button clicked on ${page} Tab on profile page`);
          }}
        >
          Edit
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={<CheckCircleOutlineIcon />}
          size="large"
          onClick={() => {
            setEditable(false);
            handleSubmit();
            googleAnalyticsTriggerFn(
              'Button',
              'Save',
              `Save button clicked on ${page} Tab on profile page`
            );
          }}
          disabled={disabled}
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default EditAndSaveBtn;
