import CustomTable from '../../Shared/Table/index';
import {
  TableData,
  columns,
} from '../../../helpers/ManagerDashboard/LearningPlan/index';
import LearningPlanTable from './LearningPlanTable';
import classNames from 'classnames';
import React from 'react';
import Headings from '../../Shared/Headings';
import ManagerDashboardStyling from '../../ManagerDashBoard/managerDashboard.module.scss';
import CustomSelect from '../../Layout/Select';
import FilterAlt from '@mui/icons-material/FilterAlt';

const LearningPlan = () => {
  return (
    <div
      className={classNames(
        'bg-white mt-3 pb-5 w-100',
        ManagerDashboardStyling.QuickStatsContainer
      )}
    >
      <div
        className={classNames(
          'p-4 ',
          ManagerDashboardStyling.QuickStatsSection
        )}
      >
        <Headings headingType="h5" classToOverride="fw-bold ms-3 p-1">
          Learning Plan Overview
        </Headings>
      </div>
      <div className={classNames('p-5',ManagerDashboardStyling.learningPlanTable)}>
        <div className="col-4 float-end mb-4 ">
          <CustomSelect label="Filter" dropdownIcon={FilterAlt} />
        </div>
        <CustomTable columns={columns} showPagination={false} >
          <LearningPlanTable TableData={TableData} />
        </CustomTable>
      </div>
    </div>
  );
};

export default LearningPlan;
