/**
 * @function CustomTabs
 * renders tabs
 * @param value The opened tab
 * @param onChange function to handle change of new tab
 * @param options array of objects of the tabs that you want to render
 * structure : - [{label:"abc",value:0},{label:"def",value:1}]
 */

import React from 'react';
import { Tabs, Tab } from '@mui/material';
import LayoutStyling from '../layout.Module.scss'
import classNames from 'classnames';

const CustomTabs = ({ value, onChange, options = [],customOptionStyling="" }) => {
  return (
    <Tabs value={value} onChange={onChange} aria-label="Profile Tabs"  >
      {options &&
        options.length > 0 &&
        options.map((option) => (
          <Tab
          className={classNames(LayoutStyling.textTransform,customOptionStyling)}
            label={option.label}
            value={option.value}
            key={`${option.label}${option.value}`}
            disabled={option.isDisabled || false}
          />
        ))}
    </Tabs>
  );
};

export default CustomTabs;
