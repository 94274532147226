import {
  MIME_TYPE_REGEX,
  UPPERCASE_PATTERN,
  LOWERCASE_PATTERN,
  NUMBER_PATTERN,
  SPECIAL_CHAR_PATTERN,
} from '../../config/Regex/regex';

import store from '../../redux/store';
import { ProfileTabIds } from '../../config/Constants';
import { SET_PROFILE_COMPLETION_STATUS } from '../../redux/Actions/Common';
import { min } from 'lodash';
// import moment from 'moment';
import ReactGA from 'react-ga4';

export const encodeFileToDataURL = (file, setResult) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => setResult(reader.result);
};

export const dataURLtoFile = (dataUrl) => {
  const imgArr = dataUrl?.split(',');
  const mimeType = imgArr[0]?.match(MIME_TYPE_REGEX)[1];
  const decodedStr = Buffer?.from(imgArr[1], 'base64')?.toString('binary');
  let size = decodedStr?.length;
  let u8arr = new Uint8Array(size);
  while (size--) {
    u8arr[size] = decodedStr?.charCodeAt(size);
  }
  return new File([u8arr], 'profileImage', { type: mimeType });
};

export function validatePassword(fieldName) {
  return this.min(8, `${fieldName} must contain minimum 8 characters`)
    .matches(UPPERCASE_PATTERN, `${fieldName} must contain a uppercase letter`)
    .matches(LOWERCASE_PATTERN, `${fieldName} must contain a lowercase letter`)
    .matches(NUMBER_PATTERN, `${fieldName} must contain a number`)
    .matches(
      SPECIAL_CHAR_PATTERN,
      `${fieldName} must contain a special character`
    );
}

export const validateToken = (token) => {
  const decodedPayload = Buffer?.from(token?.split('.')[1], 'base64')?.toString(
    'binary'
  );
  const { exp } = JSON.parse(decodedPayload);
  const isValid = exp * 1000 < Date.now();
  return !isValid;
};


// Function to parse data by tab_id and create new objects with relevant keys
const createTabProfileObjects = (data, tabMappings) => {
  const result = {};

  // Loop through the tabMappings and fetch the corresponding data for each tab_id
  Object.keys(tabMappings).forEach((tabKey) => {
    const tabId = tabMappings[tabKey]; 
    const tabData = data.find((item) => item.tab_id === tabId); 
    if (tabData) {
      result[tabKey] = tabData.content; 
    }
  });

  return result; // Return the new object
};

export const calculateProfilePercentage = (profile_details) => {
  const generalInformation = store.getState().generalInformation;
  let profile_score = 0;
  let tabsCompleted = [];
  let missingFields = [];
  const tabs = Object.keys(ProfileTabIds);

  if (profile_details && profile_details.length > 0) {
    tabsCompleted = [...profile_details.map((item) => item?.tab_id)];
    const learningPreferenceAndTarget = createTabProfileObjects(
      profile_details,
      ProfileTabIds
    );

    // Define fields to check for scores in the 'learningPreferences' tab
    const learningFields = [
      'learningPersonality',
      'preferredLearningTime',
      'preferredLearningPace',
      'preferredLessonFormats',
    ];

    // Iterate through learning fields and add score of 2 if present
    learningFields.forEach((field) => {
      if (learningPreferenceAndTarget.learningPreferences[field]) {
        profile_score += 2; // Increment score by 2 for each found field
      } else {
        missingFields.push(field);
      }
    });

    // Define fields to check for scores in the 'targets' tab
    const targetFields = [
      'targetHoursAWeek',
      'targetCapabilities',
      'targetSchedule',
    ];

    targetFields.forEach((field) => {
      if (learningPreferenceAndTarget.targets[field]) {
        profile_score += 3; // Increment score by 2 for each found field
      } else {
        missingFields.push({
          Field: field,
          MissingScore: 3,
        });
      }
    });
  }

  let tabsNotCompleted = [
    ...tabs.filter((item) => !tabsCompleted.includes(ProfileTabIds[item])),
  ];

  //image profile_score
  if (generalInformation?.profile_image_url) {
    profile_score += 5;
  }

  //demographics profile_score
  const demographicsFields = [
    'first_name',
    'last_name',
    'gender',
    'religion',
    'race',
  ];
  let demographicsFieldsCount = 0;
  demographicsFields.forEach((field) => {
    if (generalInformation[field]) {
      profile_score += 1;
    } else {
      missingFields.push({
        Field: field,
        MissingScore: 1,
      });
    }
  });

  const accountDetails = ['phone_cellular', 'user_address1', 'website'];
  accountDetails.forEach((field) => {
    if (generalInformation[field]) {
      profile_score += 2;
    } else {
      missingFields.push({
        Field: field,
        MissingScore: 2,
      });
    }
  });

  if (demographicsFields.length - demographicsFieldsCount > 0) {
    tabsNotCompleted.push('demographics');
  } else {
    tabsCompleted.push('demographics');
  }

  let percentage = ((profile_score / 33) * 100).toPrecision(3);

  store.dispatch(
    SET_PROFILE_COMPLETION_STATUS({
      incompleteTabs: [...tabsNotCompleted],
      percentage: percentage,
    })
  );
};

export const getTimeInHMS = (duration) => {
  const [hours, minutes, seconds] = duration?.split(':');

  return `${hours}h ${parseInt(minutes)}min ${seconds}s`;
};

// export const areDateEqual = (formattedDate,unFormattedDate) => {
//     const date = moment()
// }

// export const urlShorten = (distraction) => {
//   const initial = distraction.slice(1, -1);
//   const finalurl_after_removing_dot = initial.slice( initial.indexOf(".") + 1, initial.lastIndexOf("/") );
//   return finalurl_after_removing_dot;
// }

export const urlShorten = (distraction) => {
  // e.g 1 - https://chrome.google.com/webstore/
  // e.g 2 - https://www.figma.com/file/
  if (distraction) {
    const url_after_removing_protocol = distraction?.slice(
      distraction?.indexOf('/') + 2
    );
    const url_after_removing_protocol_and_path =
      url_after_removing_protocol?.slice(
        0,
        url_after_removing_protocol?.indexOf('/')
      );
    // works for e.g 1 but for e.g 2 remove "www."
    if (url_after_removing_protocol_and_path?.indexOf('www') > -1) {
      return url_after_removing_protocol_and_path?.slice(4);
    }
    return url_after_removing_protocol_and_path;
  }
};

export const getPercentage = (prev, curr) => {
  if (Number(prev) === 0) {
    return (curr - prev) * 100;
  }
  const per = ((curr - prev) / prev) * 100;
  return per.toPrecision(3);
};

export const getMinutesAndHrsString = (mins = 0) =>
  `${Math.floor(mins / 60)} hrs ${Math.round(mins % 60)} mins`;

export const getMinAndHrString = (mins = 0) => {
  return `${Math.floor(mins / 60)} hr ${Math.round(mins % 60)} min`;
};

export const extractTextFromHTML = (htmlString) => {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(htmlString, 'text/html');
  return parsedDocument.body.textContent || '';
};

// export const getDurationInString = (mins) => {
//   const hours = Math.floor(min / 60);
//   const min =  mins % 60;
//   const sec =
//   return (``)
// }

export const processSessionPages = (pages = []) => {
  const distractedPages = pages?.filter((page) => {
    return !page?.is_relevant_algo && !page?.is_transitional_algo;
  });
  if (pages && pages.length) {
    const relevanceCount = pages.reduce(
      (acc, page) => {
        if (page?.is_relevant_algo) {
          return { ...acc, relevant_count: acc.relevant_count + 1 };
        } else if (page?.is_transitional_algo) {
          return { ...acc, transitional_count: acc.transitional_count + 1 };
        } else return { ...acc, distraction_count: acc.distraction_count + 1 };
      },
      {
        relevant_count: 0,
        transitional_count: 0,
        distraction_count: 0,
      }
    );
    return { distractedPages, relevanceCount };
  }
  return {
    distractedPages,
    relevanceCount: {
      relevant_count: 0,
      transitional_count: 0,
      distraction_count: 0,
    },
  };
};

export const googleAnalyticsTriggerFn = (category, action, label) => {
  ReactGA.event({ category: category, action: action, label: label });
};

export const decimalToTime = (decimalHours) => {
  // Ensure the input is a valid number
  if (isNaN(decimalHours) || decimalHours < 0) {
    return 'Invalid input';
  }
  console.log('Parameter', decimalHours);
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(decimalHours);
  const minutesDecimal = (decimalHours - hours) * 60;
  const minutes = Math.floor(minutesDecimal);
  // const seconds = Math.floor((minutesDecimal - minutes) * 60);
  // const result = hours + " hours, " + minutes + " minutes, " + seconds + " seconds";

  if (hours === 0) {
    return minutes + ' minutes \n';
  }
  return hours + ' hours, ' + minutes + ' minutes \n';
};

// export const calculateTimeDifference = (date_assigned) => {
//   const now = new Date(); // Current date and time
//   const assignedDate = new Date(date_assigned); // Convert date_assigned to a Date object

//   const timeDifference = now - assignedDate; // Difference in milliseconds

//   // Calculate the difference in days, hours, minutes, and seconds
//   const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
//   const hourDifference = Math.floor(timeDifference / (1000 * 60 * 60));
//   const minuteDifference = Math.floor(timeDifference / (1000 * 60));
//   const secondDifference = Math.floor(timeDifference / 1000);

//   // Return the appropriate time difference with a suffix
//   if (dayDifference > 0) {
//     return `${dayDifference} day${dayDifference > 1 ? 's' : ''}`;
//   } else if (hourDifference > 0) {
//     return `${hourDifference} hour${hourDifference > 1 ? 's' : ''}`;
//   } else if (minuteDifference > 0) {
//     return `${minuteDifference} minute${minuteDifference > 1 ? 's' : ''}`;
//   } else {
//     return `${secondDifference} second${secondDifference > 1 ? 's' : ''}`;
//   }
// };

export const calculateTimeDifference = (date_assigned) => {
  const now = new Date(); // Current date and time
  const assignedDate = new Date(date_assigned); // Convert date_assigned to a Date object

  const timeDifference = now - assignedDate; // Difference in milliseconds

  // Calculate the difference in days
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Return the appropriate time difference with "day" or "days"
  return `${dayDifference} day${dayDifference !== 1 ? 's' : ''}`;
};


 /**
 * Remaps specific nudge labels to more user-friendly terms.
 * 
 * @function remapLabels
 * @param {string} label - The original snake_case label from the database.
 * @returns {string} - The remapped label if found in the labelMapping object, or the original label if not.
 * 
 * @description
 * This function was created to address the issue raised in Jira ticket "AH-511," where certain nudge labels 
 * (such as "off_screen" and "web_surfing") needed to be renamed to more user-friendly alternatives ("walked_away" 
 * and "wrong_websites"). While an alternative solution was to modify these values directly in the database, 
 * we opted for a safer approach by remapping them at the presentation layer. This minimizes the risk of introducing 
 * bugs across other modules that may depend on the original names.
 * 
 * Important Note:
 * - This solution ensures that the presentation layer is consistent, but it must be well-documented to ensure that 
 * future developers working on nudge-related features are aware of this remapping logic.
 * 
 * @example
 * // Remapping "off_screen" to "walked_away":
 * const remappedLabel = remapLabels('off_screen'); 
 * console.log(remappedLabel); // Output: 'walked_away'
 * 
 * @example
 * // Returning the original label if no remapping exists:
 * const originalLabel = remapLabels('looking_away');
 * console.log(originalLabel); // Output: 'looking_away'
 * 
 * @jira_ticket AH-511
 * https://skimai.atlassian.net/jira/software/projects/AH/boards/3?selectedIssue=AH-511
 * "Edit nudge text (on dashboards and session details pages). 'Off screen' = 'Walked Away', 'Web Surfing' = 'Wrong Websites'"
 */

/**
 * Discussion:
 * 
 * 1. Change directly from the DB:
 *    - Changing the labels directly in the database was considered. However, this could potentially introduce 
 *      inconsistencies in other modules that depend on the original names. To ensure consistency, we would need 
 *      to track every instance where these labels are used, which could be risky and difficult to maintain.
 * 
 * 2. Remap at the presentation level (current approach):
 *    - The current approach ensures safety by keeping the database values intact and remapping the specific labels 
 *      only where they are presented to users. Although this requires some additional knowledge for anyone working 
 *      on nudge-related features, proper documentation (such as this) mitigates that risk.
 */

export const remapLabels = (label) => {
  // Map of specific nudge labels that require renaming at the presentation level
  const labelMapping = {
    off_screen: "walked_away",  // Remap "off_screen" to "walked_away"
    web_surfing: "wrong_websites"  // Remap "web_surfing" to "wrong_websites"
  };
  
  return labelMapping[label] || label; // Return remapped label if exists, otherwise return original
};