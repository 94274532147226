/**
 * @function RightPanelPage7
 * @param {number} goToNextPage
 * @returns It will show the seventh page of register page.
 */

import React, { useEffect, useState } from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import classNames from 'classnames';
import SignupStyling from '../../signUp.Module.scss';

// import Headings from "../../Shared/Headings";
import { initialValues, licenseCodeValidate } from '../../../../helpers/signup';
import { useFormik } from 'formik';
import { GetLicenseClients, getAllGroups } from '../../../../helpers/signup/LicenseCode';
import LicensecodeEntryPanal from './LicenseCodeEntryPanal';
import Headings from '../../../Shared/Headings';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';
import { useDispatch } from 'react-redux';
import { SET_TOAST_ERROR } from '../../../../redux/Actions/Common';
const RightPanelPage7 = ({ goToNextPage, userId }) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: licenseCodeValidate,
    goToNextPage,
  });
  const [LicenseClient, setLicenseClient] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [corpDetails, setCorpDetails] = useState({error: false});
  const [groupDetails, setGroupDetails] = useState({error: false});
  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const groupId = (urlParams?.size > 0 && urlParams.get('group_id')) || '' ;
  const corpId = (urlParams?.size > 0 && urlParams.get('corp_id')) || '';

  const dispatch = useDispatch();

  const isCorpExist = () => {
      const newfilteredCorp = LicenseClient?.filter(item => item?.client_id === corpId);
      if (newfilteredCorp?.length > 0) {
        setCorpDetails(prevState => ({
          ...prevState,
            id: corpId,
            name: newfilteredCorp[0]?.client_name,
            error: false
        }));
      } else {
        setCorpDetails(prevState => ({
          ...prevState,
            id: '',
            name: '',
            error: "Organization doesn't exist"
        }));
        setLoading(false);
        dispatch(
          SET_TOAST_ERROR({
            error: true,
            message: "Organization doesn't exist.",
          })
        );
      }
    }

    const isGroupExist = () => {
      const newfilteredGroup = groupList?.filter(item => item?.id === groupId);

      if (newfilteredGroup?.length > 0) {
        setGroupDetails(prevState => ({
          ...prevState,
            id: groupId,
            name: newfilteredGroup[0]?.name,
            error: false
        }));
      } else {
        setGroupDetails(prevState => ({
          ...prevState,
            id: '',
            name: '',
            error: "Group doesn't exist"
        }));
        dispatch(
          SET_TOAST_ERROR({
            error: true,
            message: "Group doesn't exist",
          })
        );
        setLoading(false);
      }
    }

  useEffect(() => {
    if (LicenseClient.length === 0) {
      GetLicenseClients(setLicenseClient);
    }
  }, [LicenseClient]);

  useEffect(() => {
    if (LicenseClient?.length > 0) {
      if (corpId && groupId) {
        isCorpExist();
      } else {
        setLoading(false);
      }
    }
  }, [LicenseClient]);

  useEffect(() => {
    if (groupList?.length === 0 && corpId && groupId && corpDetails?.id) {
      getAllGroups(setGroupList, userId, corpId);
    }
  }, [groupList, corpDetails]);

  useEffect(() => {
    if (corpId && groupId && groupList?.length > 0) {
      isGroupExist();
    }
  }, [groupList]);

  return (
    <RightPanelLayout
      heading="Licenses"
      desc={`To purchase licenses, please reach out to us at :`}
      goToNextPage={goToNextPage}
      showContinueBtn={false}
      showSkipBtn={true}
      showBackBtn={false}
      submitForm={true}
      formik={formik}
      corpDetails={corpDetails}
      groupDetails={groupDetails}
      groupId={groupId}
      corpId={corpId}
    >
      <a
        href="mailto:sales@ahuraai.com"
        onClick={() => {
          googleAnalyticsTriggerFn(
            'Hyperlink',
            'sales@ahuraai.com',
            'clicked on sales@ahuraai.com om signUp'
          );
        }}
        className={classNames(SignupStyling.mailLink)}
      >
        sales@ahuraai.com
      </a>
      <div>
        <Headings
          classToOverride={`fw-bold ${SignupStyling.purchaseDividertext} text-center my-4`}
          headingType="h4"
        >
          OR
        </Headings>
        <LicensecodeEntryPanal
          LicenseClient={LicenseClient}
          goToNextPage={goToNextPage}
          corpDetails={corpDetails}
          groupDetails={groupDetails}
          groupId={groupId}
          corpId={corpId}
        />
      </div>
    </RightPanelLayout>
  );
};
export default RightPanelPage7;
