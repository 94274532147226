import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ListItems from '../ListItem';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SerivcesPermission } from '../../../../config/Permission';
import { Permission } from '../../../../config/Permission';
import category from './Category.module.scss';

const Category = ({ handleCategoryView, selectedIndex, ...props }) => {
  const [permission, setPermission] = useState({});

  useEffect(() => {
    setPermission(SerivcesPermission(props.from));
  }, [permission]);

  return (
    <>
      {props.data &&
        props.data?.map(
          (items) =>
            Permission(
              items?.menu_id,
              permission.menu?.visible?.isVisible,
              permission.menu?.visible?.exclude
            ) && (
              <React.Fragment key={items?.menu_id}>
                <div className={classNames(category.networkWrapper)}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className={classNames(category.menuItem)}>
                      {items.menu || ''}
                    </div>
                  </div>
                  <ListItems
                    permission={permission}
                    handleCategoryView={handleCategoryView}
                    selectedIndex={selectedIndex}
                    menu={items}
                  />
                </div>
              </React.Fragment>
            )
        )}
    </>
  );
};

export default Category;
