import React from 'react';
import AvatarWithInfo from '../../Layout/AvatarWithInfo';
import c from '../employeeDetails.Module.scss';
import classNames from 'classnames';
import Headings from '../../Shared/Headings';
// import Text from '../../Shared/Text';
const ViewingProfile = ({ profile_image_url = '', user_name = '' }) => {
  return (
    <div
      className={classNames(
        'w-100 rounded-3 d-flex align-items-center justify-content-between',
        c.viewProfile
      )}
    >
      <AvatarWithInfo
        alt="profile picture"
        src={profile_image_url}
        width="50px"
        height="50px"
        head={
          <Headings
            headingType="h3"
            text={`Viewing ${user_name}`}
            classToOverride="fw-bolder"
          />
        }
        // subHead={<Text textType="t2">{designation}</Text>}
        classForSubHead="fw-lighter"
      />
    </div>
  );
};

export default ViewingProfile;
