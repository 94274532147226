import { API_URLS } from '../../config/API_URLS/api_urls';
import { getApiCall, postApiCall } from '../../utils/axios/axios';
// import { left } from '@mui/lab/node_modules/@popperjs/core';

export const getQuizByLesson = async (lesson_id) => {
  try {
    const params = {
      campaign_id: lesson_id,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.get_quiz_by_lesson, paramsData);
    return response?.data;
  } catch (err) {
    console.log('Error in getQuizByLesson | Helpers : ', err);
  }
};

export const getAssignmentQuestions = async (assignment_id) => {
  try {
    const params = {
      assignment_id: assignment_id,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.assignment_question, paramsData);
    return response?.data;
  } catch (err) {
    console.log('Error in getAssignmentQuestions | Helpers : ', err);
  }
};

export const submitAnswer = async (params) => {
  try {
    const reqBody = {
      assignment_id: params.assignment_id, //quiz_id,
      question_id: params.question_id,
      option_id: params.option_id,
      is_correct: params.is_correct,
      user_id: params.user_id,
      essay_content: params?.essay_content,
      plain_essay_content: null,
    };
    const queryParams = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await postApiCall(
      API_URLS.user_assignment,
      reqBody,
      queryParams
    );
    return res;
  } catch (err) {
    console.log('Error in submitAnswer | helpers :', err);
  }
};

export const submitQuiz = async (params) => {
  try {
    const reqBody = {
      assignment_id: params?.assignment_id,
      user_id: params?.user_id,
      campaign_id: params?.lesson_id,
      total_attempted_questions: params?.total_attempted_questions,
      total_correct_answers: params?.total_correct_answers,
      score: params?.score,
      status: 3,
    };
    const queryParams = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await postApiCall(
      API_URLS.user_assignment_details,
      reqBody,
      queryParams
    );
    return res;
  } catch (err) {
    console.log('Error in submitQuiz | Helpers :', err);
  }
};

export const submitAssignment = async (params) => {
  try {
    const reqBody = {
      assignment_id: params?.assignment_id,
      user_id: params?.user_client_id,
      campaign_id: params?.lesson_id,
      answers: params?.answers,
    };
    const queryParams = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await postApiCall(API_URLS.submit_quiz, reqBody, queryParams);
    return res?.data;
  } catch (err) {
    console.log('Error on submitAssignment | helpers :', err);
  }
};

export const fetchAssignmentReport = async (params) => {
  try {
    const paramsData = {
      queryParams: {
        assignment_id: params?.assignment_id,
        user_client_id: params?.user_client_id,
      },
      headers: {},
      isAuthorized: true,
    };
    const res = await getApiCall(API_URLS.user_assignment_report, paramsData);
    return res;
  } catch (err) {
    console.log('Error in fetchAssignmentReport | Helpers :', err);
  }
};
//-----------------------Doughnut Chart-------------------------
export const YourQuizScoreOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

// const value = 75.5;

// export const YourQuizScoreData = {
//   labels: ['Score'],
//   datasets: [
//     {
//       data: [100 - value, value],
//       backgroundColor: ['#CFD8DC', '#00A89A'],
//       BorderColor: ['#CFD8DC', '#00A89A'],
//       borderWidth: 1,
//       cutout: '80%',
//     },
//   ],
// };

//--------------------Bar Chart-------------------
export const ClassAverageScoreData = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  datasets: [
    {
      label: 'Students Scores',
      type: 'bar',
      data: [100, 70, 80, 60, 30, 80, 40, 90, 90],
      borderColor: '#87D4E4',
      backgroundColor: 'rgba(135, 212, 228, 0.53)',
      borderRadius: 3,
    },
  ],
};

export const ClassAverageScoreOptions = {
  scales: {
    x: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#A3AED0',
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        padding: 20,
        color: '#9595A1',
        boxWidth: 15,
        boxHeight: 15,
        fontSize: '8',
      },
    },
    title: {
      display: true,
      text: 'Avg. score out of 100%',
      color: '#A3AED0',
      align: 'start',
      padding: 20,
    },
    subtitle: {
      display: true,
      text: 'Scores Earned',
      color: '#A3AED0',
      // padding: 10,
      position: 'bottom',
    },
  },
};
