export const columns = [
    { id: 'learningPlan', label: 'Learning Plan' },
    { id: 'learningPlanNames', label: 'Learning Plan Names' },
    {
      id: 'capabilities',
      label: 'Capabilities',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'numberofContents',
      label: 'Number of Contents',
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: 'numberofUses',
      label: 'Number of Uses',
      // format: (value) => value.toFixed(2),
    },
    {
      id: 'avgRateCompleted',
      label: 'Avg. Rate Completed',
    },
  ];
  export const TableData = [
    {
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },
    {
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },{
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },{
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },{
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },{
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },{
      learninfPlan: 'LP-034',
      learningPlanName: 'Marketing Team Training - Phase 1',
      capabilities: 'Digital Marketing',
      numberofContents: '14',
      numberofUses: '652',
      avgRateCompleted : "87%"
    },
    ];