import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  Button,
  Modal,
  TextField,
  Box,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  CircularProgress,
  Autocomplete,
  ListItemText,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAllCorpEmployeesInfo } from '../../../helpers/ManagerDashboard/TableData';
import { getAllCategories } from '../../../helpers/Content';
import {
  CLIENT_ID,
  CAMPAIGN_TYPES_IDS,
  MODULE_ID,
} from '../../../config/Constants';
import { postApiCall, putApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';

// Helper function to check if the query letters appear sequentially in the capability name
const doesCapabilityMatchQuery = (capabilityName, query) => {
  let queryIndex = 0;

  for (let i = 0; i < capabilityName.length; i++) {
    if (capabilityName[i].toLowerCase() === query[queryIndex].toLowerCase()) {
      queryIndex++;
    }
    if (queryIndex === query.length) {
      return true;
    }
  }

  return false;
};

const CapabilityModals = ({
  openAssignModal = false,
  setOpenAssignModal,
  rows,
  setRows,
  organizationAssignedCapabilitiesList,
  setOrganizationAssignedCapabilitiesList,
  isCards = false,
  loading = false,
  isEditMode = false,
  setIsEditMode,
  rowToEdit,
}) => {
  const corp_id = useSelector((state) => state?.generalInformation?.client_id);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedCapability, setSelectedCapability] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [deadline, setDeadline] = useState(null);
  const [personSearchQuery, setPersonSearchQuery] = useState('');
  const [personList, setPersonList] = useState([]);
  const [capabilityList, setCapabilityList] = useState([]);
  const [capabilitySearchQuery, setCapabilitySearchQuery] = useState('');
  const [isCreateCapabilityModalOpen, setCreateCapabilityModalOpen] =
    useState(false);
  const [newCapabilityName, setNewCapabilityName] = useState('');
  const [completeCapabilityCreation, setCompleteCapabilityCreation] =
    useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [achievementCriteria, setAchievementCriteria] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: newCapabilityName,
    description: '',
    image_url: '',
  });

  // Step 3: Update `formValues.name` with `newCapabilityName` once it's generated
  useEffect(() => {
    if (!formValues.name && newCapabilityName) {
      setFormValues((prevValues) => ({
        ...prevValues,
        name: newCapabilityName,
      }));
    }
  }, [formValues.name, newCapabilityName]);

  useEffect(() => {
    const OnMount = async () => {
      try {
        if (corp_id) {
          const person_res = await getAllCorpEmployeesInfo(corp_id, CLIENT_ID);
          if (person_res) {
            setPersonList(person_res);
          }

          const res = await getAllCategories();
          if (res) {
            setCourseData(res.data);
          } else {
            console.warn('No response from getAllCategories');
          }
        } else {
          console.warn('Unable to fetch Corp ID ');
        }
        if (isEditMode && rowToEdit && Object.keys(rowToEdit).length > 0) {
          setDeadline(rowToEdit.deadline);
          setAchievementCriteria(rowToEdit.capability_context);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    OnMount();
  }, [corp_id, isEditMode, rowToEdit]);

  useEffect(() => {
    if (!buttonLoading) {
      const formattedCapabilities = courseData.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      setCapabilityList(formattedCapabilities);
    }
  }, [courseData, buttonLoading, setCapabilityList]);

  const handleCreateCapabilityFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'name') {
      setNewCapabilityName(e.target.value);
      setCapabilitySearchQuery(e.target.value);
    }
  };

  const onSubmitCreateCapability = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Create the request body with the formValues
    const reqBody = {
      name: newCapabilityName, // Assuming 'name' is required
      description: formValues.description || '', // Optional, default to an empty string if not provided
      image_url: formValues.image_url || '', // Optional, default to an empty string if not provided
      crm_module_id: MODULE_ID.CAMPAIGN,
      campaign_type_id: CAMPAIGN_TYPES_IDS.courses,
      owner_id: corp_id,
      client_id: CLIENT_ID,
      created_by: CLIENT_ID,
      updated_by: CLIENT_ID,
    };

    // //console.log('Request Body:', reqBody);

    try {
      // Prepare the parameters for the API call
      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };

      // Make the API call to create the capability
      const capability_response = await postApiCall(
        API_URLS.create_capability, // Assuming this is the correct endpoint for creating a capability
        reqBody,
        paramsData
      );

      // Handle the response, e.g., updating state or triggering other actions
      console.log('Capability API Response:', capability_response);

      // If needed, you can process the response here

      // Format the new capability object from the response
      const newCapability = {
        id: capability_response.data.id, // Assuming 'id' is the key for the capability ID
        name: capability_response.data.name, // Assuming 'name' is the key for the capability name
      };

      // Append the new capability to the existing CapabilityList
      setCapabilityList((prevList) => [...prevList, newCapability]);
      // Assuming you want to reset the form values after a successful submission
      setFormValues({ name: '', description: '', image_url: '' });
      setNewCapabilityName('');
      // Close the modal or perform other actions as required
      setCompleteCapabilityCreation(false);
    } catch (err) {
      // Handle any errors during the API call
      console.error('Error creating capability:', err);
      setCompleteCapabilityCreation(false);
      throw new Error(err?.data?.error?.message);
    }
  };

  const handlePersonSelect = (isSelected, assignee) => {
    if (isSelected) {
      setSelectedAssignees([...selectedAssignees, assignee]);
    } else {
      setSelectedAssignees(selectedAssignees.filter((p) => p !== assignee));
    }
  };

  const handleCreateNewCapability = (capabilityName) => {
    // Logic to create a new capability
    //console.log(`Creating new capability: ${capabilityName}`);

    // Optionally, you might want to add the new capability to your list or make an API call

    // Close the modal after creation
    setCreateCapabilityModalOpen(false);

    // Optionally, you might want to refresh the capabilities list to include the new one

    setCompleteCapabilityCreation(true);
  };

  const transformData = (assignment, response_id) => {
    const {
      assignee,
      capability,
      date_assigned,
      deadline,
      status,
      capability_context,
    } = assignment;

    console.log('ASSS', assignment);

    // Transform the data
    const transformedData = {
      id: response_id,
      assignee: {
        id: assignee.id,
        full_name: assignee.full_name,
      },
      capability: {
        id: capability.id,
        name: capability.name,
      },
      date_assigned: date_assigned.toISOString().split('T')[0],
      deadline: deadline.toISOString().split('T')[0],
      active_time: null,
      is_organization_assigned: true,
      capability_context: capability_context,
      status: status,
    };

    console.log('Transformed Data VV', transformedData);

    return transformedData;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setButtonLoading(true);

    // Define today for date comparison
    const today = new Date().toISOString().split('T')[0];

    // Check if deadline is provided and valid
    if (!deadline) {
      console.error('Deadline cannot be empty.');
      setButtonLoading(false);
      return; // Stop the process if the deadline is empty
    }

    // Check if deadline is a valid Date object
    if (!(deadline instanceof Date) || isNaN(deadline.getTime())) {
      console.error('Invalid deadline.');
      setButtonLoading(false);
      return; // Stop the process if the deadline is invalid
    }

    // Check if deadline is in the past
    if (deadline.toISOString().split('T')[0] < today) {
      console.error('Deadline cannot be in the past.');
      setButtonLoading(false);
      return; // Stop the process if the deadline is in the past
    }

    // Create newRows based on the selectedAssignees
    const newRows = selectedAssignees.map((assignee) => ({
      assignee: [assignee],
      capability: selectedCapability,
      date_assigned: new Date(),
      deadline: deadline,
      status: 'Pending',
      capability_context: achievementCriteria,
    }));

    // Create the request body with a list of objects
    const reqBody = newRows.map((row) => ({
      assignee: row.assignee[0].user_client_id, // Use the user ID from the person object
      capability: row.capability.id, // The capability name or ID
      date_assigned: row.date_assigned
        ? row.date_assigned.toISOString().split('T')[0]
        : null, // Convert date to YYYY-MM-DD format, handle nulls
      deadline_date: row.deadline
        ? row.deadline.toISOString().split('T')[0]
        : null, // Convert deadline to YYYY-MM-DD format, handle nulls
      is_organization_assigned: true, // Assuming this is always true
      capability_context: row.capability_context, // Assuming 'capability_context' is the context description
      assigned_by: corp_id, // ID of the corp assigning the capability
      status: row.status.toLowerCase(), // Convert status to lowercase as per convention
    }));

    try {
      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
      const response = await postApiCall(
        API_URLS.bulkCreateAssignedCapability,
        reqBody,
        paramsData
      );

      if (isCards) {
        // Transform the data based on the response
        const transformedDataList = newRows
          .map((row) => {
            const matchingResponse = response.find(
              (res) => res.assignee === row.assignee[0].user_client_id
            );
            if (matchingResponse) {
              return transformData({
                ...row,
                id: matchingResponse.id, // Use the ID from the response
              });
            }
            return null; // Or handle cases where there's no match
          })
          .filter((data) => data !== null); // Filter out any nulls

        const mergedCapabilitiesList = [
          ...organizationAssignedCapabilitiesList,
          ...transformedDataList,
        ];

        setOrganizationAssignedCapabilitiesList(mergedCapabilitiesList);
      } else {
        // Handle the response, e.g., updating state or triggering other actions
        const updatedRows = newRows.map((row, index) => ({
          ...row,
          id: response[index].id, // Adding the ID from the API response to the row
        }));

        // Updating the local state with the new rows that now include the IDs
        setRows([...rows, ...updatedRows]);
      }
      setButtonLoading(false);
      setOpenAssignModal(false);
      setSelectedAssignees([]);
      setSelectedCapability('');
      setDeadline(null);
    } catch (err) {
      console.error('Error posting assignment data:', err);
      setButtonLoading(false);
    }
  };

  const handleCapabilityUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setButtonLoading(true);
    const today = new Date(); // Get the current date and time

    // Convert deadline to Date object if it is a string
    const deadlineDate =
      typeof deadline === 'string' ? new Date(deadline) : deadline;

    // Validation: Ensure that the deadline is not in the past and is a valid Date object
    if (
      deadlineDate &&
      !(deadlineDate instanceof Date && !isNaN(deadlineDate.getTime()))
    ) {
      console.error('Invalid deadline format.');
      return; // Stop the update process if deadline is not a valid Date object
    }

    if (
      deadlineDate &&
      deadlineDate < today &&
      deadline !== rowToEdit.deadline
    ) {
      console.error('Deadline cannot be in the past.');
      return; // Stop the update process if the deadline is in the past except it is from old assignment date was not changed.
    }

    // Create the updated row based on the selected assignees (assuming one assignee for editing)
    const updatedRow = {
      id: rowToEdit.id, // Ensure the ID of the row being updated is included
      assignee: {
        id: rowToEdit.assignee.id, // Use the ID of the assignee being edited
        full_name: rowToEdit.assignee.full_name, // Keep the full name of the assignee
      },
      capability: {
        id: selectedCapability.id, // Use the ID of the selected capability
        name: selectedCapability.name, // Use the name of the selected capability
      },
      date_assigned: today.toISOString().split('T')[0], // Set date_assigned to the current date in YYYY-MM-DD format
      deadline: deadlineDate ? deadlineDate.toISOString().split('T')[0] : null, // Convert deadline to YYYY-MM-DD format, handle nulls
      is_organization_assigned: true, // Assuming this is always true
      capability_context: achievementCriteria, // Context description
      assigned_by: corp_id, // ID of the corp assigning the capability
      status: 'Pending', // Set the status to 'Pending'
    };

    // Create the request body
    const reqBody = {
      assignee: updatedRow.assignee.id, // Use the user ID from the assignee object
      capability: updatedRow.capability.id, // The capability ID
      date_assigned: updatedRow.date_assigned, // Date in YYYY-MM-DD format
      deadline_date: updatedRow.deadline, // Deadline in YYYY-MM-DD format
      is_organization_assigned: updatedRow.is_organization_assigned, // Assuming this is always true
      capability_context: updatedRow.capability_context, // Context description
      assigned_by: updatedRow.assigned_by, // ID of the corp assigning the capability
      status: updatedRow.status.toLowerCase(), // Convert status to lowercase as per convention
    };

    const url = `${API_URLS.updateAssignedCapability}/${rowToEdit.id}`;

    try {
      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
      const response = await putApiCall(url, reqBody, paramsData);

      console.log('Response', response);

      // Find the index of the row being updated
      const index = rows.findIndex((row) => row.id === rowToEdit.id);

      if (index !== -1) {
        // Create a new array with the updated row at the correct index
        const updatedRows = [
          ...rows.slice(0, index), // Keep rows before the updated row
          updatedRow, // Replace the row at the index
          ...rows.slice(index + 1), // Keep rows after the updated row
        ];

        // Updating the local state with the updated rows
        setRows(updatedRows);
      }
      setButtonLoading(false);
      // Close the modal and reset state after successful update
      setOpenAssignModal(false);
      setIsEditMode(false);
      setSelectedAssignees([]);
      setSelectedCapability('');
      setDeadline(null);
    } catch (err) {
      // Handle any errors
      console.error('Error updating assignment data:', err);
      throw new Error(err?.data?.error?.message);
    }
  };

  // Filter the person list based on search query
  const filteredPersonList = personSearchQuery
    ? personList.filter((assignee) => {
        const queryLower = personSearchQuery.toLowerCase();
        const name = assignee.full_name ? assignee.full_name.toLowerCase() : '';
        const email = assignee.email ? assignee.email.toLowerCase() : '';
        const user_client_id = assignee.user_client_id
          ? assignee.user_client_id.toLowerCase()
          : '';
        return (
          name.includes(queryLower) ||
          email.includes(queryLower) ||
          user_client_id.includes(queryLower)
        );
      })
    : [];

  // Memoize the filtered list to avoid recalculating on every render
  const filteredCapabilityList = useMemo(() => {
    return capabilitySearchQuery
      ? capabilityList.filter((capability) =>
          capability.name
            .toLowerCase()
            .includes(capabilitySearchQuery.toLowerCase())
        )
      : [];
  }, [capabilitySearchQuery, capabilityList]);

  const handleCapabilitySearch = useCallback(
    (query) => {
      if (!query.trim()) {
        // If the query is empty, close the modal and return early.
        setCreateCapabilityModalOpen(false);
        return;
      }

      // Check if a capability is already selected
      const isCapabilitySelected = !!selectedCapability;

      // Check if any capabilities match the query using sequential letter matching
      const doesCapabilityExist = filteredCapabilityList.some((capability) =>
        doesCapabilityMatchQuery(capability.name, query)
      );

      // Determine whether to reveal the modal
      const shouldRevealModal = !doesCapabilityExist && !isCapabilitySelected;

      if (shouldRevealModal) {
        setNewCapabilityName(query);
        setCreateCapabilityModalOpen(true);
      } else {
        // If there's an exact match, or a capability is selected, close the modal
        setCreateCapabilityModalOpen(false);

        // Optionally reopen the modal after a delay if there's no exact match and nothing is selected
        const isExactMatch = filteredCapabilityList.some(
          (capability) => capability.name.toLowerCase() === query.toLowerCase()
        );

        if (!isExactMatch && !isCapabilitySelected) {
          setTimeout(() => {
            setNewCapabilityName(query);
            setCreateCapabilityModalOpen(true);
          }, 2000); // Reduced delay to 2 seconds
        }
      }
    },
    [filteredCapabilityList, selectedCapability]
  );

  const debouncedHandleCapabilitySearch = useCallback(
    (query) => {
      // Clear the previous timeout if it exists
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      // Set a new timeout
      const timeoutId = setTimeout(() => handleCapabilitySearch(query), 1500);
      setDebounceTimeout(timeoutId);
    },
    [debounceTimeout, handleCapabilitySearch]
  );

  useEffect(() => {
    if (isEditMode && rowToEdit?.capability) {
      console.log('rowToEdit ::>>> ', rowToEdit.capability);
      console.log('rowToEdit selectedCapability::>>> ', selectedCapability);
      setSelectedCapability(rowToEdit.capability);
    }
  }, [isEditMode, rowToEdit?.capability]);

  const handleSearchChange = (e) => {
    setCapabilitySearchQuery(e.target.value);
    debouncedHandleCapabilitySearch(e.target.value);
  };

  // Handle capability selection
  const handleCapabilitySelect = (capability) => {
    if (isEditMode) {
      if (selectedCapability?.id === capability.id) {
        // If the same capability is selected again, uncheck it (set to null)
        setSelectedCapability(null);
      } else {
        // Otherwise, select the new capability
        setSelectedCapability(capability);
      }
    } else {
      // Normal mode: the same logic applies
      if (selectedCapability?.id === capability.id) {
        // Unselect if it's the same capability
        setSelectedCapability(null);
      } else {
        // Select a new capability
        setSelectedCapability(capability);
      }
    }
  };

  const createCapabilityStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const assignModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      {/* Notify to Create New Capability Modal */}
      <Modal
        open={isCreateCapabilityModalOpen}
        onClose={() => setCreateCapabilityModalOpen(false)}
        aria-labelledby="create-new-capability-modal"
        aria-describedby="create-new-capability-modal-description"
      >
        <Box sx={assignModalStyle}>
          <Typography variant="h6" component="h2">
            Create New Capability
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            The capability "{newCapabilityName}" does not exist. Would you like
            to create it?
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCreateNewCapability(newCapabilityName)}
            >
              Create Capability
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setCreateCapabilityModalOpen(false)}
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Complete Capability Creation Modal */}
      <Modal
        open={completeCapabilityCreation}
        onClose={() => setCompleteCapabilityCreation(false)}
      >
        <Box sx={createCapabilityStyle}>
          <Typography variant="h6" component="h2" marginBottom={2}>
            Create Capability
          </Typography>
          <form onSubmit={onSubmitCreateCapability}>
            <TextField
              label="Name"
              name="name"
              // Make Editable
              value={formValues.name}
              onChange={handleCreateCapabilityFormChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleCreateCapabilityFormChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            {/* <TextField
              label="Image URL"
              name="image_url"
              value={formValues.image_url}
              onChange={handleCreateCapabilityFormChange}
              fullWidth
              margin="normal"
            /> */}
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setCompleteCapabilityCreation(false)}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* Assign New Capability Modal */}
      <Modal
        open={openAssignModal || isEditMode}
        onClose={() => {
          setOpenAssignModal(false);
          setIsEditMode(false);
        }}
        aria-labelledby="assign-new-capability-modal"
        aria-describedby="assign-new-capability-modal-description"
      >
        <form onSubmit={isEditMode ? handleCapabilityUpdate : handleFormSubmit}>
          <Box sx={assignModalStyle}>
            <Typography variant="h6" component="h2">
              {isEditMode
                ? 'Edit Capability Assignment'
                : 'Assign New Capability'}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Search Person"
                  variant="outlined"
                  fullWidth
                  disabled={isEditMode}
                  value={
                    isEditMode
                      ? rowToEdit.assignee.full_name
                      : personSearchQuery
                  }
                  onChange={(e) => setPersonSearchQuery(e.target.value)}
                />
                <div>
                  {selectedAssignees.map((assignee, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={true}
                          onChange={(e) =>
                            handlePersonSelect(e.target.checked, assignee)
                          }
                        />
                      }
                      label={assignee.full_name}
                    />
                  ))}
                  {filteredPersonList
                    .filter((assignee) => !selectedAssignees.includes(assignee))
                    .map((assignee, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={selectedAssignees.includes(assignee)}
                            onChange={(e) =>
                              handlePersonSelect(e.target.checked, assignee)
                            }
                          />
                        }
                        label={assignee.full_name}
                      />
                    ))}
                </div>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  freeSolo
                  disabled={isEditMode}
                  options={filteredPersonList}
                  getOptionLabel={(option) => option?.full_name || ''}
                  value={isEditMode ? [rowToEdit.assignee] : selectedAssignees}
                  onChange={(event, newValue) => {
                    setSelectedAssignees(newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      <ListItemText primary={option.full_name} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Person"
                      variant="outlined"
                      fullWidth
                      disabled={isEditMode}
                      placeholder="Search and select a person"
                      onChange={(e) => setPersonSearchQuery(e.target.value)} // Updating the search query as the user types
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Search Capability"
                  variant="outlined"
                  fullWidth
                  value={
                    capabilitySearchQuery ||
                    (isEditMode ? rowToEdit.capability?.name : '')
                  } // Show capability name from edit row if search query is empty
                  onChange={handleSearchChange}
                />
                <div>
                  {filteredCapabilityList.map((capability, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            isEditMode
                              ? selectedCapability?.id === capability.id // Check against the selectedCapability in edit mode
                              : selectedCapability?.id === capability.id // Same for normal mode
                          }
                          onChange={() => handleCapabilitySelect(capability)}
                        />
                      }
                      label={capability.name}
                    />
                  ))}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ marginTop: '15px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Select Deadline"
                      value={deadline}
                      onChange={(newDate) => setDeadline(newDate)}
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Achievement Criteria/Target Outcome"
                  variant="outlined"
                  fullWidth
                  value={achievementCriteria}
                  onChange={(e) => setAchievementCriteria(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                  placeholder="e.g., 4 hours, 70% on quiz, intermediate level"
                  margin="normal"
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={11} sm={6}>
                <Box>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ marginTop: '20px' }}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : isEditMode ? (
                      'Update'
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default CapabilityModals;
