import React from "react";
import classNames from "classnames";
// import c from './table.module.scss';
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

const CustomTable = ({ ...props }) => {
  return (
    <div className={classNames(props?.classToOverride)}>
      <TableContainer component={Paper} className="!overflow-x-auto">
        <Table aria-label="simple table">{props.children}</Table>
      </TableContainer>
    </div>
  );
};

const CustomTable1 = ({ ...props }) => {
  return (
    <div className="!mt-4">
      <TableContainer className="!overflow-x-auto">
        <Table aria-label="simple table">{props.children}</Table>
      </TableContainer>
    </div>
  );
};

export const CustomTable2 = (props) => {
  return (
    <TableContainer className="px-3 overflow-y-auto h-86">
      <Table size="small">{props.children}</Table>
    </TableContainer>
  );
};

export { CustomTable, CustomTable1 };
