import classNames from 'classnames';
import React, { useState } from 'react';
import Text from '../../../Shared/Text';
import c from '../quiz.module.scss';
import CustomEditor from '../../../Shared/Editor';
// import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
// import { submitAnswer } from '../../../../helpers/Quiz';
import { useDebouncedCallback } from 'use-debounce';

const OpenEndedQuestion = ({
  question = '',
  options,
  questionNo = 1,
  questionData = {},
  addQuestionsAns,
  submitStatus,
}) => {
  const [answer, setAnswer] = useState('');
  const [raw, setRaw] = useState('');
  const user_client_id = useSelector((state) => state?.generalInformation?.id);

  const submit = () => {
    //increment total attempted ques and increment total correct answers in QuizPage Component in pages folder
    // incrementTotalAttemptedQues();
    // if (questionData?.question_options_attributes[0]?.is_correct)
    //   incrementTotalCorrectAns();

    const params = {
      assignment_id: questionData?.assignment_id,
      question_id: questionData?.id,
      option_id: questionData?.question_options_attributes[0]?.id,
      is_correct: questionData?.question_options_attributes[0]?.is_correct,
      user_id: user_client_id,
      essay_content: answer,
      plain_essay_content: null,
    };

    //call api here
    // submitAnswer(params);

    addQuestionsAns(questionData?.id, params);
  };

  const debounced = useDebouncedCallback(
    // function
    () => {
      raw !== "" && submit();
    },
    // delay in ms
    500
  );
  return (
    <div className="mt-5">
      <Text textType={'t0'} classToOverride={classNames(c.fontWeight600)}>
        Q {questionNo}: {question}
      </Text>
      <div className="mt-2">
        <CustomEditor
          onChange={(value) => {
            setAnswer(value);
            debounced();
          }}
          setRaw={setRaw}
        />
      </div>
      {raw === '' && submitStatus === true && (
        <small className="text-danger ms-5">Field is required.</small>
      )}
      {/* <Button
        variant="contained"
        className="mt-3"
        disabled={raw ? false : true}
        onClick={() => submit()}
      >
        Submit Answer
      </Button> */}
    </div>
  );
};

export default OpenEndedQuestion;
