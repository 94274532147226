import {SET_SESSIONS_DATA} from '../../Types/AllSessionData';

// Initial state for sessions data
const initialState = {
  sessionsData: [],
};

// Reducer function
export const AllSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSIONS_DATA:
      return {
        ...state,
        sessionsData: action.payload,
      };
    default:
      return state;
  }
};
