import React,  { useEffect } from 'react';
import Headings from '../../Shared/Headings';
import classNames from 'classnames';
import CourseContent from './CourseContent';
import LearningProgress from './LearningProgress';
import { Helmet } from 'react-helmet';
// import FullAndLiveSwitch from './FullAndLiveSwitch';
import styles from './courseData.module.scss'
import {shareButtonHostURL} from '../../../config/Constants'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  TwitterShareButton
} from "react-share";

const CourseData = ({
  courseDetails,
  getCourseDetails,
  category,
  campaignCategory,
  ...props
}) => {
  const metaTitle = "Ahura AI: " + courseDetails?.heading;
  const shareUrl = shareButtonHostURL + '/course/' + courseDetails?.id
  const customShareMessage = `Check out this awesome course! ${courseDetails?.heading}`;
  const iconSize = 48;
  useEffect(() => {
    // Set OG tags dynamically
    document.title = metaTitle;
    document.querySelector('meta[property="og:description"]')?.setAttribute('content', "I recommend this course on Ahura AI. Find expertly curated content for exciting new jobs of tomorrow");
    document.querySelector('meta[property="og:image"]')?.setAttribute('content', courseDetails?.image_url);
  }, []);
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content="Expertly curated content on Ahura AI's platform for exciting new jobs of tomorrow" />
      </Helmet>
      <div className={classNames('d-flex justify-content-between')}>
        <Headings headingType="h2" classToOverride="fw-bold">
          {courseDetails?.campaign_type_name}
        </Headings>
        <div className="share-overlay">
          <div className="share-buttons">
            <TwitterShareButton url={shareUrl} className={`mx-2 4 ${styles['social-button']}`}>
              <XIcon size={iconSize} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} className={`mx-2 4 ${styles['social-button']}`}>
                <LinkedinIcon size={iconSize} round />
            </LinkedinShareButton>
            <FacebookShareButton url={shareUrl} className={`mx-2 4 ${styles['social-button']}`}>
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl} separator=" - " className={`mx-2 4 ${styles['social-button']}`} title={customShareMessage}>
              <WhatsappIcon size={iconSize} round />
            </WhatsappShareButton>
          </div>
        </div>
        {/* <FullAndLiveSwitch /> */}
      </div>

      <div className={classNames('w-100 mt-5 d-flex')}>
        <CourseContent
          courseDetails={courseDetails}
          category={category}
          campaignCategory={campaignCategory}
        />
        <LearningProgress getCourseDetails={getCourseDetails} />
      </div>
    </>
  );
};

export default CourseData;
