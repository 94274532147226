import React, { useEffect, useMemo, useState } from 'react';
import Text from '../../../../Shared/Text';
import c from '../../../Profile.Module.scss';
import classNames from 'classnames';
import { Divider } from '@mui/material';
import moment, { monthsShort } from 'moment';
import _ from 'lodash';
import { weekdays } from 'moment';
import ScheduleContainer from './ScheduleContainer';

const colors = ['#00A811', '#006CA8', '#A80051', '#FB7F3D'];
const SchedulesWithDates = ({
  date,
  data = [],
  addCapability = false,
}) => {
  const months = useMemo(() => monthsShort(), []);
  const weekday = useMemo(() => weekdays(), []);
  const [addSchedule, setAddSchedule] = useState(false);
  useEffect(() => {
    setAddSchedule(addCapability);
  }, [addCapability]);
  return (
    <>
      <div className="d-flex">
        <Text textType="t1" classToOverride={classNames(c.fontWeight600)}>
          {months[date?.getMonth()]} {date?.getDate()},
        </Text>
        <Text textType="t1" classToOverride={classNames('ms-1')}>
          {weekday[date?.getDay()]}
        </Text>
      </div>

      <Divider />

      {/* ---------------data mapping------------ */}
      <ScheduleContainer
      date={date}
      data={data}/>
    </>
  );
};

export default SchedulesWithDates;
