/**
 * @function PasswordValidate
 * @param none
 * this components displays the validation checks for password  
 */
import { Close, Done } from '@mui/icons-material';
import classNames from 'classnames';
import React from 'react';
import { passwordValidations } from '../../../../../helpers/signup';
const PasswordValidate = () => {
  return (
    <div className={classNames('d-flex flex-column')}>
      Password must contain atleast:
      <div>
        {passwordValidations.upperCase ? <Done /> : <Close />} One capital
        letter{' '}
      </div>
      <div>
        {passwordValidations.lowerCase ? <Done /> : <Close />} One lowercase
        letter{' '}
      </div>
      <div>
        {passwordValidations.numerical ? <Done /> : <Close />} One number
      </div>
      <div>
        {passwordValidations.special ? <Done /> : <Close />} One special
        character (!@#$%^&*)
      </div>
      <div>
        {passwordValidations.length ? <Done /> : <Close />} 8 character length
      </div>
    </div>
  );
};

export default PasswordValidate;
