import React, { useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import c from '../../home.Module.scss';
import AvatarWithInfo from '../../../Layout/AvatarWithInfo';
import Text from '../../../Shared/Text';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { handleStartSession } from '../../../../helpers/Home/Sessions';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const SessionsTable = ({
  date,
  topic,
  duration,
  sessionId,
  nudges_per_x_min,
  struggling_emotions,
  handleViewSession,
  data,
  isManager = false,
}) => {
  const [actionValue, setActionValue] = useState('View Session');

  return (
    <TableRow className={c.sessionsTableRow}>
      <TableCell>{date}</TableCell>
      <TableCell width="400">
        <AvatarWithInfo
          src={topic}
          alt={topic}
          width="30px"
          height="30px"
          head={<Text textType="t1">{topic}</Text>}
        />
      </TableCell>
      <TableCell width="200">{duration}</TableCell>
      <TableCell width="200">{nudges_per_x_min}</TableCell>
      <TableCell width="250">{struggling_emotions}</TableCell>
      {!isManager && (
        <TableCell width="250" className="pe-5">
          <Select
            fullWidth
            className={classNames(
              'text-white rounded',
              c.font_14,
              c.sessionsActionsMenu
            )}
            variant="standard"
            value={actionValue}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{
              className: classNames('bg-transparent p-2'),
              classes: {
                icon: classNames('text-white me-1'),
              },
            }}
            disableUnderline
            onChange={(event) => setActionValue(event?.target?.value)}
          >
            <MenuItem
              id={sessionId}
              value="View Session"
              onClick={() => {
                handleViewSession(data);
                googleAnalyticsTriggerFn(
                  'Button',
                  'View Session',
                  'View Session Action Button clicked in Sessions '
                );
              }}
            >
              View Session
            </MenuItem>
          </Select>
        </TableCell>
      )}
    </TableRow>
  );
};

export default SessionsTable;
