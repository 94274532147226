import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import c from '../forgotPassword.module.scss';
import Headings from '../../Shared/Headings';
import { TextField, Button, CircularProgress } from '@mui/material';
import { Resources } from '../../../config/Resources';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  initialValues,
  handleSubmit,
  validate,
} from '../../../helpers/ForgotPassword';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const Forgot_Password = ({ updatePage, setMail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) updatePage();
  }, [success]);
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      setMail(values?.email);
      console.log(values?.email);
      handleSubmit(values?.email, setIsLoading, setSuccess);
      googleAnalyticsTriggerFn(
        'Button',
        'Send',
        'Send button clicked on forgot password page'
      );
    },
  });

  return (
    <React.Fragment>
      <div className={classNames('text-center text-white')}>
        <Link
          to="/sign-in"
          className={classNames('text-decoration-none text-white')}
        >
          <div className={classNames('float-start', c.cursorPointer)}>
            <ArrowBackIcon />
          </div>
        </Link>
        <Link to="/" className={classNames('text-decoration-none text-white')}>
          <img
            src={Resources.images.AhuraHeaderLogo2}
            className={classNames(c.Logo)}
          />
        </Link>
      </div>

      <div className={classNames('p-4')}>
        <div>
          <Headings
            headingType="h3"
            classToOverride={classNames('text-center fw-bold text-white')}
          >
            Enter your email and we will send you a new password!
          </Headings>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-5">
            <TextField
              className={classNames(c.TextBox, 'w-100')}
              label="Email"
              type="email"
              id="email"
              onChange={formik?.handleChange}
              value={formik?.values?.email}
              helperText={formik?.errors?.email}
              onBlur={formik?.handleBlur}
              error={formik?.errors?.email ? true : false}
              name="email"
            />
          </div>
          <div className={classNames('mt-5')}>
            <Button
              variant="contained"
              className={classNames('w-100', c.ForgotBtn)}
              type="submit"
            >
              {isLoading ? <CircularProgress color="inherit" /> : 'Send'}
            </Button>
          </div>
        </form>
        <div className={classNames('mt-5')}>
          <Link
            to="/signUp"
            onClick={() => {
              googleAnalyticsTriggerFn(
                'Hyperlink',
                'Create a New Account',
                'Create a New Account lijnk clicked on forgot password page'
              );
            }}
            className={classNames('text-decoration-none text-white')}
          >
            <Headings
              headingType="h7"
              classToOverride={classNames(
                'text-center fw-bold text-white',
                c.cursorPointer,
                c.primaryColor
              )}
            >
              Create a New Account
            </Headings>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Forgot_Password;
