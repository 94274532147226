import { API_URLS } from '../../../config/API_URLS/api_urls';
import { postApiCall, putApiCall } from '../../../utils/axios/axios';


export const handleKeyUp = (e, id) => {
  const current = document.getElementById(id);
  if (id !== 'otp4' && e.keyCode !== 8) {
    current.nextSibling.focus();
  } else if (e.keyCode === 8 && id !== 'otp1') {
    current.previousSibling.focus();
  }
};

export const OtpResend = async (email) => {
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      email: email,
    };

    const res = await putApiCall(API_URLS.resend_confirmation, reqBody);
    return res;
  } catch (err) {
    console.log('error in login', err);
  }
};

export const SubmitOtp = async (Otp, email, proceed, setIsLoading) => {
  
  var code = '';

  Object.values(Otp).map((OtpNumer) => (code = `${code}${OtpNumer}`));
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      code: code,
      email: email,
      is_token_require: true,
    };
    setIsLoading(true);
    const res = await postApiCall(
      API_URLS.activation_code_verification,
      reqBody
    );
    // res?.message && alert(res.message);
    // res?.error && alert(res.error.message)
    setIsLoading(false);
    if(res?.token){
      localStorage.setItem("token", res.token);
      proceed();
    }

    return res;
  } catch (err) {
    console.log(err);
  }
};
