import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import c from './forgotPassword.module.scss';
import ForgotPassword from '../../components/forgotPassword';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const ForgotPasswordPage = ({ ...props }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  return (
    <div className={classNames(c.MainContainer)}>
      <div className={classNames('container')}>
        <div className={classNames('col-lg-6 mx-auto', c.BoxContainer)}>
          <div className={classNames('p-5')}>
            <ForgotPassword />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
