import React, { useState } from 'react';
import AllocationModalContainer from './AllocationModalContainer';
import EmployeeRecords from './EmployeeRecords';
import EmailSuccess from './EmailInvite/EmailSuccess';
import InviteByEmail from './EmailInvite/InviteByEmail';
import CSVFileUpload from './CSVFileUpload';
import EmailInvite from './EmailInvite';

const AllocationModal = ({ setModalStatus, modalStatus, ...props }) => {
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  return (
    <AllocationModalContainer
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
    >
      {type === '' && (
        <EmployeeRecords
          setModalStatus={setModalStatus}
          setType={setType}
          setStatus={setStatus}
        />
      )}
      {type === 'CSV' && (
        <CSVFileUpload status="progress" setModalStatus={setModalStatus} />
      )}
      {type === 'EmailInvite' && (
        <EmailInvite
          status={status}
          setStatus={setStatus}
          setModalStatus={setModalStatus}
        />
      )}
    </AllocationModalContainer>
  );
};
export default AllocationModal;
