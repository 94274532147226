/**
 * @function Routes
 *  Routes
 * AppRoute component returns a layout with sidebar and navigation bar
 * @param {string} name
 * @returns contains all the routes of an applications.
 */

import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from '../../pages/home';
import SignUpPage from '../../pages/signUp';
import Theme from '../Theme';
import MyAccount from '../../components/MyAccount/index';
import ProfilePage from '../../pages/profile';
import { ManagerDashboard } from '../../pages/ManagerDashboard/index';
import LearningPlansPage from '../../pages/learningPlans';
import Course from '../../pages/course';
import AppRoute from '../../components/AppRoute';
import SignInPage from '../../pages/signIn';
import Networks from '../../admin/pages/networks';
// import Campaigns from '../../admin/pages/campaigns';
import CustomSnackBar from '../../components/Shared/SnackBar';
import SessionDetailsPage from '../../pages/SessionDetails';
import EmployeeDetailsPage from '../../pages/employeeDetails';
import AllocationPage from '../../pages/allocation';
import ForgotPasswordPage from '../../pages/forgotPassword';
import jwtDecode from 'jwt-decode';
import { ROLE_IDS } from '../Constants';
import LandingPage from '../../pages/landing';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import TermsAndConditions from '../../pages/TermsAndConditions';
import ContentPage from '../../pages/content';
import CapabilitiesPage from '../../pages/capabilities';
import QuizPage from '../../pages/quiz';
import ManageGroupsPage from '../../pages/manageGroups';


const Routes = () => {
  const token = localStorage.getItem('token');

  const isAdminOrOwner = () => {
    const decode_token = jwtDecode(token);
    if (
      decode_token &&
      (decode_token.role_id === ROLE_IDS.CLIENT_ADMIN ||
        decode_token.role_id === ROLE_IDS.CLIENT_OWNER ||
        decode_token.role_id === ROLE_IDS.PARENT_ADMIN ||
        decode_token.role_id === ROLE_IDS.PARENT_OWNER)
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
    <Theme>
      <Router>
        <Switch>
          <Route exact path="/">
            {token ? (
              isAdminOrOwner() ? (
                <Redirect to="/manager-dashboard" />
              ) : (
                <Redirect to="/home" />
              )
            ) : (
              <LandingPage />
            )}
          </Route>
          <Route exact path="/signUp" component={SignUpPage} />
          <Route exact path="/sign-in" component={SignInPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <AppRoute exact path="/home" component={Home} />
          <AppRoute exact path="/myaccount" component={MyAccount} />
          <AppRoute exact path="/profile" component={ProfilePage} />
          <AppRoute
            exact
            path="/manager-dashboard"
            component={ManagerDashboard}
          />
          <AppRoute
            exact
            path="/learning-plans"
            component={LearningPlansPage}
          />
          <AppRoute
            isAdmin={true}
            exact
            path="/networks"
            component={Networks}
          />
          {/* <Route exact path="/campaigns" component={Campaigns} /> */}
          <AppRoute exact path="/course/:id" component={Course} />
          <AppRoute exact path="/sessions/:id" component={SessionDetailsPage} />
          <AppRoute
            exact
            path="/employee-details/:id"
            component={EmployeeDetailsPage}
          />
          <AppRoute exact path="/allocation" component={AllocationPage} />
          {/* <AppRoute exact path="/manage-groups" component={ManageGroupsPage} /> */}
          <AppRoute
            exact
            path="/capabilities/:capability_id"
            component={CapabilitiesPage}
          />

          <AppRoute exact path="/content" component={ContentPage} />
          <AppRoute exact path="/lesson-quiz/:lesson_id" component={QuizPage} />

          <Route exact path="/privacy-policy" component={PrivacyPolicy} />

          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="*">
            404 Not Found
          </Route>
        </Switch>
      </Router>
      <CustomSnackBar />
    </Theme>
    </>
  );
};

export default Routes;
