import React, { useState } from 'react';
import { learning_plan_columns } from '../../../../../helpers/Home/CapabilitiesToStudy/index';
import { learningPlanData } from '../../../../../MockData/Home/CapabilitiesToStudy/index';
import TableRow from '../TableRow';
import TablePagination from '@mui/material/TablePagination';
import CustomTable from '../../../../Shared/Table';
import Text from '../../../../Shared/Text';
import classNames from 'classnames';
import c from '../../../home.Module.scss';

export const Table = ({learningPlan,setLearningPlan}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (
    <>
      {' '}
      <div
        className={classNames('row g-0', c.tableContainer)}
        style={{ width: '100%' }}
      >
        <CustomTable
          columns={learning_plan_columns}
          showSortIcon={false}
          maxHeight={260}
          minWidth={false}
          showPagination={false}
        >
          {learningPlanData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <TableRow
                key={item.id}
                learningPlanItem={item.learningPlan}
                category={item.category}
                id={item.id}
                setLearningPlan={setLearningPlan}
                learningPlan={learningPlan}
              />
            ))}
        </CustomTable>
      </div>
      <div className="d-flex justify-content-between align-items-baseline m-3">
        <Text textType="t1" classToOverride={classNames('fw-normal', c.fade)}>
          LEARNING PLANS : 479/706
          {/* style={{ bottom: '30px' }} */}
        </Text>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={learningPlanData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          className={c.tablePagination}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};
