import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import Text from '../../../../Shared/Text';
import c from '../../../Notification/notification.Module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { leaveAccount } from '../../../../../helpers/My Account';
import { hideLoader, showLoader } from '../../../../../redux/Actions/Loader';
import { SET_TOAST_ERROR } from '../../../../../redux/Actions/Common';
import { getAcceptedNotificationRequest } from '../../../../../helpers/Home/Notifications';

function LeaveRequestStatus({
  setRequestModalStatus,
  additionalDetailsData,
  handleClick,
}) {
  const { platform_id, external_id, user_id } = useSelector(
    (state) => state?.generalInformation
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const leaveAccountFunc = async () => {
    dispatch(showLoader());
    const response = await leaveAccount(
      additionalDetailsData?.refRecordId,
      additionalDetailsData?.group_id,
      platform_id,
      external_id
    );
    if (response.result === 'removed Successfully') {
      await queryClient.invalidateQueries('getMyGroupQueryData');
      await queryClient.invalidateQueries('getAllGroupQueryData');
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response.result,
        })
      );
    }
    handleClick(additionalDetailsData?.id);
    dispatch(hideLoader());
    setRequestModalStatus(false);
  };

  const acceptNotificationRequest = async () => {
    dispatch(showLoader());
    const response = await getAcceptedNotificationRequest(
      additionalDetailsData?.group_id,
      platform_id,
      additionalDetailsData?.refRecordId,
      'APPROVED',
      true
    );
    handleClick(additionalDetailsData?.id);
    if (response.message === 'Updated') {
      dispatch(hideLoader());
      setRequestModalStatus(false);
    }
  };

  return (
    <div className={c.leaveMailContainer}>
      <Text textType="t18" classToOverride="text-center">
        {additionalDetailsData?.notification_message_plain}
        {!additionalDetailsData?.is_read && (
          <div
            className={classNames(
              'd-flex justify-content-end',
              c.btnDivContainer
            )}
          >
            <Button
              size="medium"
              onClick={leaveAccountFunc}
              variant="outlined"
              className={`${c.cancelButton}`}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={acceptNotificationRequest}
              className={classNames(c.continueBtn)}
            >
              Accept
            </Button>
          </div>
        )}
      </Text>
    </div>
  );
}

export default LeaveRequestStatus;
