import React, { useState } from 'react';
import { validImageFormats } from '../../../../config/Constants';
import { encodeFileToDataURL } from '../../../../utils/utility';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import ProfilePicture from '../../../Shared/ProfilePicture';
import { Button } from '@mui/material';
import c from '../../Profile.Module.scss';
import ResizeImage from './ResizeImage';

const ProfileImageUpload = ({ isEditable, formik }) => {
  const { profileImageURL } = formik?.values;
  const { setFieldValue } = formik;

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [originalImage, setOriginalImage] = useState('');
  const [cropStep, setCropStep] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');

  const handleFileChange = (imagePreview) => {
    const originalFile = imagePreview?.target?.files[0];

    if (validImageFormats.includes(originalFile?.type)) {
      setShowErrorMsg(false);
      encodeFileToDataURL(originalFile, setOriginalImage);
      setCropStep(true);
    } else {
      setShowErrorMsg(true);
    }
    imagePreview.target.value = '';
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Alert
        severity="error"
        className={classNames('my-4', showErrorMsg ? 'visible' : 'invisible')}
        onClose={() => setShowErrorMsg(false)}
      >
        Select a Valid Image
      </Alert>
      <ProfilePicture
        imgAlt="Profile Image"
        imgSrc={profileImageURL || croppedImage}
        classToOverride={isEditable ? c.imageDimensionsReadonly : c.imageDimensions}
        onLoad={(event) => {
          !profileImageURL &&
            setFieldValue('profileImageURL', event?.target?.src);
        }}
      />
      {!profileImageURL ? (
        <Button
          variant="text"
          component="label"
          size="large"
          className={classNames('text-capitalize', c.fontWeight600)}
          disabled={isEditable}
        >
          Edit Image
          <input
            accept="image/png,image/gif,image/jpeg"
            type="file"
            className="d-none"
            onChange={handleFileChange}
          />
        </Button>
      ) : (
        <Button
          variant="text"
          size="large"
          className={classNames('text-capitalize', c.fontWeight600)}
          onClick={() => {
            setFieldValue('profileImageURL', '');
            setCroppedImage('');
          }}
          disabled={isEditable}
        >
          Remove Image
        </Button>
      )}
      {cropStep && (
        <ResizeImage
          originalImage={originalImage}
          setCropStep={setCropStep}
          setCroppedImage={setCroppedImage}
        />
      )}
    </div>
  );
};

export default ProfileImageUpload;
