import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import LearningBehaviorCss from '../LearningBehavior.module.scss';

export default function CircularProgressWithLabel({ c, circleColor, value, ...props }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={100} thickness={6} size={68} sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
      }} />
      <CircularProgress variant="determinate" value={value} thickness={6} size={68} style={{ color: circleColor }} sx={{
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
        position: "absolute",
      }} />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" className={classNames(LearningBehaviorCss.progressText)} color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
