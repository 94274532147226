import store from '../../../redux/store';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall,postApiCall } from '../../../utils/axios/axios';

export const getTopAndLowScore = async (limit, corp_id, capability_id, category_name) => {
    const queryParams = {
      corp_id: corp_id,
      capability_id: capability_id,
      limit: limit,
      capability_name :category_name
    };
  
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.top_and_low_score_users, paramsData);
    return response;
  };


  export const getCapabilityStats= async(corpId,category_name, capability_id)=>{
    const queryParams = {
        corp_id: corpId,
        capability_name: category_name,
        capability_id :capability_id
      };
    
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const response = await getApiCall(API_URLS.capability_stats, paramsData);
      return response;
  }

  export const getAllEmpCapbilities= async ( corpId, category_name, capability_id )=>{
    const queryParams = {
        name: category_name,
        corp_id: corpId,
        capability_id:capability_id
      };    
    
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const response = await getApiCall(API_URLS.allEmployeeCapability, paramsData);
      return response;
  }

  export const getWeekSessionData = async(corp_id, capability)=>{
    const queryParams = {
      corp_id: corp_id,
      capability:capability
    };    
  
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.getWeekSession, paramsData);
    return response;
  }

  export const getExpertiseDataGraph= async(capabilityData, corp_id, external_id=null)=>{ 
    const reqBody={
      capabilityData :capabilityData,
      corp_id:corp_id,
      external_id:external_id
    }

    const paramsData = {queryParams:{}, headers:{}, isAuthorized:true};
    const res = await postApiCall(API_URLS.getExpertiseJourneyData, reqBody,paramsData);
    return res
  }