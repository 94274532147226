import React, { useState } from 'react';
import classNames from 'classnames';
import c from '../forgotPassword.module.scss';
import Headings from '../../Shared/Headings';
import Text from '../../Shared/Text';
import { Button, CircularProgress } from '@mui/material';
import { Resources } from '../../../config/Resources';
import {
  activationCodeVerification,
  resendCode,
} from '../../../helpers/ForgotPassword';
import OtpInput from 'react-otp-input';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const ForgotPasswordOTP = ({ mail, updatePage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otp, setOtp] = useState('');

  const handleOtp = (otp) => {
    setOtp(otp);
  };
  if (success) updatePage();

  const handleClick = () => {
    resendCode(mail);
    googleAnalyticsTriggerFn(
      'Button',
      'Resend',
      'Resend button clicked on forgot password page'
    );
  };

  const handleSubmit = () => {
    if (otp.length === 4 && mail)
      activationCodeVerification(otp, mail, setIsLoading, setSuccess);

    googleAnalyticsTriggerFn(
      'Button',
      'Verify',
      'Verify button clicked on forgot password page'
    );
  };

  return (
    <React.Fragment>
      <div className={classNames('text-center text-white')}>
        <img
          src={Resources.images.AhuraHeaderLogo2}
          className={classNames(c.Logo)}
        />
      </div>

      <div className={classNames('p-4')}>
        <div>
          <Headings
            headingType="h3"
            classToOverride={classNames('text-center fw-bold text-white')}
          >
            Enter verification code
          </Headings>
        </div>

        <div className="my-4 d-flex justify-content-around">
          <OtpInput
            value={otp}
            onChange={handleOtp}
            numInputs={4}
            separator={<span>-</span>}
            isInputNum={true}
            className={c.otpBox}
            focusStyle={c.focusStyle}
          />
        </div>

        <div className={classNames('text-center text-white my-4')}>
          <Text textType="t1">Your 4 digit code was sent to</Text>
          <Text textType="t1" classToOverride={classNames(c.primaryColor)}>
            {mail && mail}
          </Text>
        </div>

        <div className={classNames('text-center text-white my-4')}>
          <Text textType="t1">
            By verifying your code you consent to receive notifications from
            Ahura.
          </Text>
        </div>

        <div className={classNames('text-center text-white my-4')}>
          <Text textType="t1">I did not receive a code</Text>
          <Button onClick={() => handleClick()}>
            <Text
              textType="t0"
              classToOverride={classNames(
                c.primaryColor,
                'fw-bold',
                c.cursorPointer
              )}
            >
              RESEND
            </Text>
          </Button>
        </div>

        <div className={classNames('mt-5')}>
          <Button
            variant="contained"
            className={classNames('w-100', c.ForgotBtn)}
            onClick={() => handleSubmit()}
          >
            {isLoading ? <CircularProgress color="inherit" /> : 'Verify'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordOTP;
