export const preliminaryInformation = {
  learningContent: [
    'Basic concepts of the GSAP library [Gsap object, Tween & common methods for it creating, Timeline tool, Position parameters, Basic animation control',
    'GSAP core plugins [AttrPlugin, CSSPlugin, ModifiersPlugin, EndArrayPlugin, SnapPlugin]',
    'This course contains 24 unique animation projects which illustrate the features of the properties and methods included in the core of the GSAP library',
    'Animation methods and properties of the GSAP core [purpose, syntax, application features, examples of using]',
  ],
  requirements: [
    'This course is not for beginners. Basic knowledge of CSS, SVG and JavaScript is required',
    'Any computer and OS will work — Windows, macOS or Linux',
    'Internet connection',
    'Web browser - Google Chrome (all animations have been tested in this browser)',
    'A text editor that you already have is suitable as a code editor',
    'There are lessons where the preparation of images in a graphics editor is required for animations (images are already prepared and attached to the corresponding lessons)',
    'Archiver for unpacking ".rar" archive with animation sources',
    'There is no need for any paid software - the core of the GSAP library is completely free',
  ],
};
