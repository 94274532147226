import React, { useEffect, useState } from 'react';
import LearningBehaviorCss from './LearningBehavior.module.scss';
import classNames from 'classnames';
import LearningBehaviorHeader from './LearningBehaviorHeader';
import LearningBehaviorLeftPanel from './LearningBehaviorLeftPanel';
import LearningBehaviorRightPanle from './LearningBehaviorRightPanel';
// import { LearningBehaviourMockData } from '../../../helpers/Home/LearningBehavior/GraphData';
// import { getLearningBehaviourdata } from '../../../helpers/Home/LearningBehavior/GraphData';
// import { useSelector } from 'react-redux';
// import { getLearningData } from '../../../helpers/Learning';
const LearningBehavior = ({
  user_ext_id,
  emotion_data,
  browsing_data,
  visited_pages,
  setEmotionData,
  setBrowsingData,
}) => {
  // const user_ext_id = useSelector(
  //   (state) => state?.generalInformation?.external_id
  // );
  // const [emotionData, setEmotionData] = useState([]);
  // const [browsingData, setBrowsingData] = useState({});
  // const userCorpDetail = useSelector((state)=>  state?.userCorporateDetails)


  // useEffect(async () => {
  //   if (user_ext_id) {
  //     const data = await getLearningData(user_ext_id);
  //     // console.log('data:::::', data);
  //     setEmotionData(data?.expression_data);
  //     setBrowsingData(data);
  //   } else {
  //   }
  // }, [user_ext_id, userCorpDetail]);

  // useEffect(() => {
  //   if (!user_ext_id) {
  //     if (emotion_data?.length) setEmotionData([...emotion_data]);
  //     if (browsing_data)
  //       setBrowsingData({ ...browsing_data, visited_pages: visited_pages });
  //   }
  // }, [browsing_data, emotion_data, visited_pages]);
  return (
    <React.Fragment>
      <div
        className={classNames(LearningBehaviorCss.learning_behavior_container)}
      >cs
        <div className={classNames(LearningBehaviorCss.headerContainer)}>
          <LearningBehaviorHeader />
        </div>
        <div className="row m-0">
          <LearningBehaviorLeftPanel emotionData={emotion_data} />
          <LearningBehaviorRightPanle browsingData={browsing_data} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default LearningBehavior;
