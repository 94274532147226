import Headings from '../../../Shared/Headings';
import classNames from 'classnames';
import c from '../../home.Module.scss';

import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';

const NoCapabilitiesAssignedUI = () => {
    return(
        <div className={classNames("mx-5 my-4 d-flex flex-column align-items-center justify-content-center ",c.noCapabilities)}>
              
        <FolderOffOutlinedIcon />        
     
        <Headings 
        headingType="h6" 
        classToOverride={classNames(c.fontWeight600)}
        >
          No Target Capabilities assigned
        </Headings>
        
    </div>
    )
}
export default NoCapabilitiesAssignedUI;