/**
 * @function QuickStats
 * QuickStatsDataAnalytics module of home page
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import style from '../home.Module.scss';
import Headings from '../../Shared/Headings';
import { Divider } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CustomSelect from '../../Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import {
  barChartOptions,
  barChartData,
  lineChartData,
  lineChartOptions,
  firstDoughnutChartData,
  firstDoughnutChartOptions,
  secondDoughnutChartData,
  secondDoughnutChartOptions,
} from '../../../helpers/Home/QuickStats';
import {
  Chart as JSChart,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import QuickStatsDataAnalytics from './QuickStatsDataAnalytics';
import {
  dataAnalyticsItemsList1,
  dataAnalyticsItemsList2,
} from '../../../MockData/Home/QuickStats';

const QuickStats = () => {
  JSChart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const [filterOption, setFilterOption] = useState('');

  return (
    <div className={classNames(style.quickStats, 'h-100 w-100')}>
      <Headings
        headingType="h3"
        classToOverride={classNames(
          style.mainHeading,
          'text-nowrap',
          style.fontWeight600
        )}
      >
        Quick Stats
      </Headings>

      <Divider />

      <div className="row g-0">
        <div className="col-6">
          <Headings
            headingType="h5"
            classToOverride={classNames(
              style.subHeading1,
              'text-nowrap',
              style.fontWeight600
            )}
          >
            Learning Volume
            <InfoRoundedIcon className={style.quickStatsInfoIcon} />
          </Headings>
        </div>

        <div className="col-6">
          <CustomSelect
            label="Filter"
            menuItems={[
              { label: 'All time' },
              { label: 'Current month' },
              { label: 'Last month' },
            ]}
            onChange={(e) => setFilterOption(e?.target?.value)}
            value={filterOption}
            className={style.quickStatsFormControl}
            dropdownIcon={FilterAltIcon}
          />
        </div>
      </div>

      <div className="row g-0 justify-content-evenly align-items-center">
        <div className={classNames('col-6', style.quickStatsLineGraph)}>
          <Line data={lineChartData} options={lineChartOptions} />
        </div>

        <div className={classNames('col-6', style.quickStatsBarGraph)}>
          <Bar data={barChartData} options={barChartOptions} />
        </div>
      </div>

      <div
        className={classNames(
          'row g-0 align-items-end',
          style.quickStatsLearningDetails
        )}
      >
        {dataAnalyticsItemsList1.map((item, index) => (
          <QuickStatsDataAnalytics
            key={index}
            heading={item['heading']}
            time={item['time']}
            status={item['status']}
          />
        ))}
      </div>

      <Divider />

      <Headings
        headingType="h5"
        classToOverride={classNames(
          style.subHeading2,
          'text-nowrap',
          style.fontWeight600
        )}
      >
        Enjoyment
        <InfoRoundedIcon className={style.quickStatsInfoIcon} />
      </Headings>

      <div className={classNames('row g-0', style.quickStatsEnjoymentDetails)}>
        {dataAnalyticsItemsList2.map((item, index) => (
          <QuickStatsDataAnalytics
            key={index}
            heading={item['heading']}
            time={item['time']}
            status={item['status']}
          />
        ))}
      </div>

      <Divider />

      <Headings
        headingType="h5"
        classToOverride={classNames(
          style.subHeading3,
          style.fontWeight600,
          'text-nowrap'
        )}
      >
        Memory
        <InfoRoundedIcon className={style.quickStatsInfoIcon} />
      </Headings>

      <div
        className={classNames(
          'row g-0 justify-content-evenly',
          style.quickStatsDoughnutGraph
        )}
      >
        <div className={classNames('col-6 mt-2', style.graphDimension)}>
          <Doughnut
            data={firstDoughnutChartData}
            options={firstDoughnutChartOptions}
          />
        </div>

        <div className={classNames('col-6 mt-2', style.graphDimension)}>
          <Doughnut
            data={secondDoughnutChartData}
            options={secondDoughnutChartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default QuickStats;
