import React from 'react';
import { Chart } from 'react-chartjs-2';
import Button from '@mui/material/Button';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import CustomSelect from '../../Layout/Select';
import Headings from '../../Shared/Headings';

import {
  MixedChartData,
  mixedChartOption,
} from '../../../helpers/Home/QuickStats';
import AddIcon from '@mui/icons-material/Add';
import QuickStatsStyling from '../../../helpers/ManagerDashboard/QuickStats/QuickStatsStyling.module.scss';
import classNames from 'classnames';
import { EmployeLearningPlanGraphData } from '../../../MockData/ManagersDashboard';
import { ContentDropdown } from './dropdownData/index'
const PlanPerformance = () => {
  const [contentType, setContentType] = React.useState('')
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
  );


  const ChartData = MixedChartData(EmployeLearningPlanGraphData)

  return (
    <div className="bg-white mt-3 pb-5">
      <div className={classNames(QuickStatsStyling.ActionableInsights)}>
        <div className="d-flex justify-content-between">
          <Headings headingType="h4" classToOverride="row g-0 fw-bold ">
            Learning Plan Performance
          </Headings>

          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            className={classNames(QuickStatsStyling.buttonAlignment)}
          >
            Add New Plan
          </Button>
        </div>
        <br />
        <div className="d-flex justify-content-between ">
          <CustomSelect label="Plan 1" className="w-25 pb-3" />

          <CustomSelect
            label="Date Range"
            className={classNames(QuickStatsStyling.buttonAlignment, 'w-25')}
          />
        </div>
      </div>
      <br />
      <div
        className={classNames(QuickStatsStyling.ActionableInsightsContainer)}
      >
        <div>
          <div className="row justify-content-end">
            <div className="col-10">
              <div className="d-flex justify-content-end">
                <div className="col-3">
                  <CustomSelect
                    label="Content Type"
                    menuItems={ContentDropdown}
                    className="w-100"
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                  />
                </div>
                <CustomSelect label="Concept 1" className="w-25 ms-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="m-3">
          <Chart data={ChartData} options={mixedChartOption} />
        </div>
      </div>
    </div>
  );
};

export default PlanPerformance;
