
import classNames from 'classnames';
import React from 'react';
import Headings from '../../../../Shared/Headings';
import courseDataStyling from '../../courseData.module.scss';

const CourseContantData = ({ CourseTitle, CourseData }) => {
  return (
    <div>
      <Headings
        headingType="h30"
        classToOverride={classNames(
          'fw-bold',
          courseDataStyling.contantHeading
        )}
      >
        {CourseTitle}
      </Headings>
      <div className={classNames(courseDataStyling.CourseDescription)} dangerouslySetInnerHTML={{__html: CourseData}}/>
      {/* <Headings headingType="h6" classToOverride={classNames("text-justify mt-5")}>{CourseData}</Headings> */}
    </div>
  );
};

export default CourseContantData;
