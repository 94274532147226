import React from 'react';
import classNames from 'classnames';
import c from '../../learningPlans.Module.scss';
import Headings from '../../../Shared/Headings';
import { FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import Text from '../../../Shared/Text';
import {
  contentTypeOptions,
  providers,
} from '../../../../helpers/LearningPlans/AddingPlanRightPanel';

const FilterControls = ({ activeOptionsCount, setActiveOptionsCount }) => {
  const handleChange = (e) => {
    setActiveOptionsCount(
      e?.target?.checked ? activeOptionsCount + 1 : activeOptionsCount - 1
    );
  };

  return (
    <>
      <div className={c.filterControlHeading}>
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center pb-4 fw-bold',
            c.primaryColor
          )}
        >
          <Headings headingType="h5">Filter By</Headings>

          <u className={c.cursorPointer}>
            <Text textType="t1">Reset Filters</Text>
          </u>
        </div>

        <FormGroup>
          <Text
            textType="t1"
            classToOverride={classNames('pt-3 pb-3 fw-bold', c.primaryColor)}
          >
            Providers
          </Text>
          {providers.map((item, index) => (
            <FormControlLabel
              className="fw-normal"
              key={`providerCheckbox${index}`}
              control={
                <Checkbox
                  size="small"
                  className="rounded"
                  onChange={handleChange}
                />
              }
              label={<Text textType="t1" text={item} />}
            />
          ))}
          <Text
            textType="t1"
            classToOverride={classNames('pt-4 pb-3 fw-bold', c.primaryColor)}
          >
            Content
          </Text>
          {contentTypeOptions.map((item, index) => (
            <FormControlLabel
              className={classNames('text-capitalize fw-normal')}
              key={`contentCheckbox${index}`}
              control={
                <Checkbox
                  size="small"
                  className="rounded"
                  onChange={handleChange}
                />
              }
              label={<Text textType="t1" text={item} />}
            />
          ))}
        </FormGroup>
        <div className="py-5">
          <Button
            className="text-capitalize"
            variant="contained"
            disabled={!activeOptionsCount}
          >
            Apply
          </Button>

          <Button className="text-capitalize ms-3" variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterControls;
