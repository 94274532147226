import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import c from './quiz.module.scss';
import QuizTitleContainer from './QuizTitleContainer';
import QuizQuestionsTitleContainer from './QuizQuestionsTitleContainer';
import QuizQuestion from './QuizQuestion';
// import { mockQuestions } from '../../../MockData/Course';
import { Button } from '@mui/material';
import OpenEndedQuestion from './OpenEndedQuestion';
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { submitAssignment } from '../../../helpers/Quiz';
import QuizModal from './QuizModal';
import QuizReport from './QuizReport';
import { useHistory } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
const Quiz = ({
  loader = false,
  quizQues = [],
  quizData = {},
  assignment_id = '',
  lesson_id = '',
  course_id = '',
}) => {
  // const [QuizQuestions, setQuizQuestions] = useState([]);
  // useEffect(() => {
  //   setQuizQuestions([...quizQues]);
  // }, [quizQues]);
  // const [total_attempted_questions, setTotalAttemptedQues] = useState(0);
  // const [total_correct_answers, setCorrectAnswers] = useState(0);
  const user_client_id = useSelector((state) => state?.generalInformation?.id);
  const [view, setView] = useState('quiz');
  const [openModal, setOpenModal] = useState(false);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [submitStatus, setSubmitStatus] = useState(false);
  const history = useHistory();

  const addQuestionsAns = (question_id, answer) => {
    const old_answers = { ...answers };
    //adding new answer
    old_answers[question_id] = answer;
    setAnswers({ ...old_answers });
  };
  const onAssignmentSubmit = async () => {
    if (Object.keys(answers).length !== quizQues.length) {
      setSubmitStatus(true);
      return;
    }

    const answerArr = Object.values(answers);
    const params = {
      assignment_id: assignment_id,
      user_client_id: user_client_id,
      lesson_id: lesson_id,
      answers: [...answerArr],
    };
    console.log('params', params);
    const res = await submitAssignment(params);
    if (res) {
      console.log('score::::', res);
      setScore(res?.score);
    }
    setOpenModal(true);
  };
  return (
    <React.Fragment>
      {view === 'quiz' && (
        <div className={classNames('bg-white p-4')}>
          {!loader ? (
            <QuizTitleContainer heading={quizData?.assignment_name} title="" />
          ) : (
            <Typography variant="h2">
              <Skeleton />
            </Typography>
          )}

          <div className={classNames(c.QuizMainContainer, 'mt-5 p-4')}>
            <QuizQuestionsTitleContainer
              heading="Mixed Quiz"
              title="Answer the questions below and click the submit button"
            />
            {quizQues &&
              quizQues.map((ques, index) =>
                ques?.question_type !== 3 ? (
                  <React.Fragment key={index}>
                    <QuizQuestion
                      question={ques?.question_title}
                      options={ques?.question_options_attributes}
                      questionNo={index + 1}
                      questionData={ques}
                      addQuestionsAns={addQuestionsAns}
                      submitStatus={submitStatus}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <OpenEndedQuestion
                      question={ques?.question_title}
                      options={ques?.question_options_attributes}
                      questionNo={index + 1}
                      questionData={ques}
                      addQuestionsAns={addQuestionsAns}
                      submitStatus={submitStatus}
                    />
                  </React.Fragment>
                )
              )}
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="outlined"
                className="text-capitalize fw-bold"
                size="large"
                onClick={() => {
                  history.push(`/course/${course_id}`);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Cancel',
                    'Cancel button clicked on quiz page'
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="text-capitalize fw-bold ms-2"
                size="large"
                onClick={() => {
                  console.log('answers::::', answers);
                  onAssignmentSubmit();
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Complete Quiz',
                    'Complete Quiz button clicked on quiz page'
                  );
                }}
              >
                Complete Quiz
              </Button>
            </div>
          </div>
        </div>
      )}
      {view === 'report' && (
        <QuizReport
          user_client_id={user_client_id}
          assignment_id={assignment_id}
          courseId={course_id}
        />
      )}
      {openModal && (
        <QuizModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          setView={setView}
          course_id={course_id}
          score={Number(score)}
        />
      )}
    </React.Fragment>
  );
};

export default Quiz;
