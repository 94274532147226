import React from 'react';
import classNames from 'classnames';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import Text from "../../../Shared/Text";

const ContentUsageRow = ({link, percentage, time, ...props}) => {
    return(
        <React.Fragment>
            <div className={classNames("col-md-12 mt-2 mb-1")}>
                <div className={classNames("row")}>
                    <div className={classNames("col-md-4 text-start")}>
                        <Text textType="t2" classToOverride={LearningBehaviorCss.visitedSessionText} text={link}/>
                    </div>
                    <div className={classNames("col-md-4 text-center")}>
                        <Text textType="t2" classToOverride={LearningBehaviorCss.visitedSessionText} text={percentage}/>
                    </div>
                    <div className={classNames("col-md-4 text-end")}>
                        <Text textType="t2" classToOverride={LearningBehaviorCss.visitedSessionText} text={time}/>
                    </div>
                </div>
            </div>            
        </React.Fragment>
    )
}
export default ContentUsageRow;