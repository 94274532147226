export const LearningQualities = [
  {
    heading: 'Learner NPS',
    percent: '27',
    text: 'Compared to last month',
  },
  {
    heading: 'Happiness',
    percent: '45%',
    text: 'Compared to last month',
  },
  {
    heading: 'Confusion',
    percent: '15%',
    text: 'Compared to last month',
  },
  {
    heading: 'Face/Assessment',
    percent: '#',
    text: 'Compared to last month',
  },
  {
    heading: 'URL Distraction (Co.)',
    percent: '6%',
    text: 'Compared to last month',
  },
];

export const InterventionsData = [
  {
    heading: 'Nudges Provided',
    percent: '27',
    text: 'Compared to last month',
  },
  {
    heading: 'Learning Tips',
    percent: '12',
    text: 'Compared to last month',
  },
  {
    heading: 'Number in Backlog',
    percent: '6',
    text: 'Compared to last month',
    Remarks: '3 assigned / 3 self-starred',
  },
  {
    heading: 'Backlog Past Deadline',
    percent: '3',
    text: 'Compared to last month',
  },
  {
    heading: 'Lessions Converted',
    percent: '4',
    text: 'Compared to last month',
  },
  {
    heading: 'Curated Learning Plans',
    percent: '3',
    text: 'Compared to last month',
  },
];

export const OverviewMixedGraphData = {
  BarLabel: 'Lorem Ipsum',
  LineALabel: 'Focused',
  LineBLabel: 'Distracted',
  Bar: [21, 31, 36, 29, 48, 31, 28, 28, 30, 40, 13],
  LineA: [23, 32, 35, 27, 52, 31, 28, 20, 21, 30, 31],
  LineB: [24, 35, 33, 22, 57, 34, 23, 32, 23, 36, 37],
};
export const EmployeLearningPlanGraphData = {
  BarLabel: 'Content Type',
  LineALabel: 'Focus Level',
  LineBLabel: 'Distraction Level',
  Bar: [21, 31, 36, 29, 48, 31, 28, 28, 30, 40, 13],
  LineA: [23, 32, 35, 27, 52, 31, 28, 20, 21, 30, 31],
  LineB: [24, 35, 33, 22, 57, 34, 23, 32, 23, 36, 37],
};


export const averageLearningItemsList = [
  {
    heading: "Hours Studied",
    time: "7 hr 32 min",
    status: "20.15%",
    text: 'Compared to last week',
  },
  {
    heading: "Weekly Avg. Hours",
    time: "6 hr 15 min",
    status: "15.00%",
    text: 'Compared to last week',
  },
  {
    heading: "Capabilities Studied",
    time: "12",
    status: "25.00%",
    text: 'Compared to last week',
  },
];

export const LicenseDistributionMenuItems = [
  {
    id: "1",
    value: "go_to_licenses",
    text: "Go to Licenses"
  },
  {
    id: "2",
    value: "other_actions_1",
    text: "Other Actions"
  },
  {
    id: "3",
    value: "other_actions_2",
    text: "Other Actions"
  }
]