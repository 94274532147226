import React from 'react';
import classNames from 'classnames';
import c from '../landing.module.scss';
import { Carousel } from 'react-bootstrap';
import { TestimonialData } from '../../../helpers/Landing';
import Headings from '../../Shared/Headings';
import Text from '../../Shared/Text';

const Testimonial = ({ ...props }) => {
  return (
    <div className={classNames('col-lg-12 col-md-12', c.TestimonialContainer)}>
      <div className={classNames('mt-3 mb-5')}>
        <Headings
          headingType="h1"
          classToOverride={classNames('text-white fw-bold text-center')}
        >
          What People are Saying
        </Headings>
      </div>

      <Carousel className={c.TestimonialCarouselMain}>
        {TestimonialData &&
          TestimonialData.map((item) => {
            return (
              <Carousel.Item key={item?.id}>
                <div
                  className={classNames(
                    c.TestimonialImg,
                    'd-flex flex-lg-nowrap align-items-center mb-5'
                  )}
                >
                  <img src={item?.img} alt="Testimonial" />
                  <div
                    className={classNames(
                      c.TestimonialText,
                      'd-flex flex-wrap text-white p-5'
                    )}
                  >
                    <Headings headingType="h6">{item?.text1}</Headings>
                    <div className={classNames('mt-auto')}>
                      <Headings
                        headingType="h6"
                        classToOverride={classNames('fw-bold')}
                      >
                        {item?.text2}
                      </Headings>
                      <Text textType="t0">{item?.text3}</Text>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </div>
  );
};

export default Testimonial;
