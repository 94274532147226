import {
  getApiCall,
  postApiCall,
  deleteApiCall,
  putApiCall
} from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';

export const columns = [
  { id: 'time_finished', label: 'Date' },
  { id: 'description', label: 'Topic' },
  { id: 'duration', label: 'Duration' },
  { id: 'nudges_per_x_min', label: 'Nudges/30min' },
  { id: 'struggling_emotions', label: 'Struggle Emotions' },
  { id: 'actions', label: 'Actions' },
];

export const manager_columns = [
  { id: 'time_finished', label: 'Date' },
  { id: 'description', label: 'Topic' },
  { id: 'duration', label: 'Duration' },
];

export const menuItems = [
  { label: 'Start Ahura AI', value: 'start' },
  { label: 'View Session', value: 'view' },
];

export const TableData = [
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
];

// Function to get all organization-assigned capabilities
export const getOrganizationAssignedCapabilities = async (assigned_by) => {
  const queryParams = {
    assigned_by,
  };

  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await getApiCall(
    API_URLS.organizationAssignedCapabilities,
    paramsData
  );
  return response;
};

// Function to get all assigned capabilities
export const getAllAssignedCapabilities = async () => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await getApiCall(
    API_URLS.allAssignedCapabilites,
    paramsData
  );
  return response;
};

// Function to get user-assigned capabilities
export const getUserAssignedCapability = async (userId) => {
  const queryParams = {
    user_id: userId,
  };

  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await getApiCall(
    API_URLS.userAssignedCapability,
    paramsData
  );
  return response;
};

// Function to create a new assigned capability
export const createAssignedCapability = async (capabilityData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.createAssignedCapability,
    capabilityData,
    paramsData
  );
  return response;
};

// Function to bulk create assigned capabilities
export const bulkCreateAssignedCapability = async (capabilitiesData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.bulkCreateAssignedCapability,
    capabilitiesData,
    paramsData
  );
  return response;
};

// Function to delete an assigned capability
export const deleteAssignedCapability = async (capabilityId) => {
  const queryParams = {};
  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await deleteApiCall(
    API_URLS.deleteAssignedCapability + '/' + capabilityId,
    paramsData
  );
  return response;
};
// Function to bulk delete assigned capabilities
export const bulkDeleteAssignedCapabilities = async (capabilityIds) => {
  const paramsData = { 
    queryParams: {}, 
    headers: { 'Content-Type': 'application/json' }, 
    isAuthorized: true 
  };

  // Include capabilityIds in the body of the request
  const response = await deleteApiCall(
    API_URLS.bulkDeleteAssignedCapability,
    capabilityIds, 
    paramsData
  );
  
  return response;
};

// Function to update an assigned capability
export const updateAssignedCapability = async (capabilityId, capabilityData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  
  // Send the PUT request with the capability data
  const response = await putApiCall(
    API_URLS.updateAssignedCapability + capabilityId,
    capabilityData,
    paramsData
  );
  
  return response;
};