import moment from 'moment';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall, putApiCall } from '../../../utils/axios/axios';

// for getting the all notifications
export const getNotification = async (id) => {
  try {
    const paramsData = {
      queryParams: {
        limit: 10,
      },
      isAuthorized: true,
    };
    const data = await getApiCall(
      `${API_URLS.userClients}${id}${API_URLS.notifications}`,
      paramsData
    );
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

// for updating a notification read-unread status
export const updateNotifications = async (value) => {
  try {
    const reqBody = {
      id: value,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await putApiCall(
      API_URLS?.updateNotifications,
      reqBody,
      header
    );
    return response;
  } catch (error) {
    console.log(`Error in Athlete Account Setup ${error.message}`);
  }
};

// for accepting a notification request
export const getAcceptedNotificationRequest = async (
  client_id,
  platform_id,
  user_id,
  group_status,
  is_active
) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const reqBody = {
      client_id,
      platform_id,
      user_id,
      group_status,
      is_active,
    };
    const response = await putApiCall(
      API_URLS.acceptNotificationRequest,
      reqBody,
      paramsData
    );
    return response;
  } catch (error) {
    console.log(`Error in accepting request ${error.message}`);
  }
};

// for showing the notification time
export const getLastUpdate = (date) => {
  if (date) {
    const a = moment(date);
    let Days = '';
    const Difference = moment.duration(a.diff(moment()));
    if (Math.abs(Difference.years()) > 0) {
      Days = `${Math.abs(Difference.years())} years ago`;
    } else if (Math.abs(Difference.months()) > 0) {
      Days = `${Math.abs(Difference.months())} months ago`;
    } else if (Math.abs(Difference.days()) > 1) {
      Days = `${Math.abs(Difference.days())} days ago`;
    } else if (Math.abs(Difference.hours()) > 24) {
      Days = `yesterday`;
    } else if (
      Math.abs(Difference.hours()) < 24 &&
      Math.abs(Difference.hours()) > 0
    ) {
      Days = `${Math.abs(Difference.hours())} hours ago`;
    } else if (Math.abs(Difference.minutes()) > 0) {
      Days = `${Math.abs(Difference.minutes())} minutes ago`;
    } else {
      Days = 'Just now';
    }
    return Days;
  } else {
    return '';
  }
};
