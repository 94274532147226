import Button from '@mui/material/Button';
import classNames from 'classnames';
import btnCls from './buttons.module.scss';

const Button1 = ({ classToOverride, value, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      className={classNames('text-capitalize', btnCls.Button1, classToOverride)}
      {...props}
    >
      {value}
    </Button>
  );
};

const Button2 = ({ value, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      className={classNames('text-capitalize', btnCls.Button2)}
      {...props}
    >
      {value}
    </Button>
  );
};

const Button3 = ({ value, classToOverride, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      className="text-capitalize"
    >
      {value}
    </Button>
  );
};

export { Button1, Button2, Button3 };
