import React from 'react';
// import Button from '@mui/material/Button';
import c from '../quiz.module.scss';
import classNames from 'classnames';
import Headings from '../../../Shared/Headings';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import {
  YourQuizScoreOptions,
  YourQuizScoreData,
} from '../../../../helpers/Quiz/index';
// import Text from '../../../Shared/Text';

const YourQuizScore = ({ fromQuizModal = false, score }) => {
    const YourQuizScoreData = {
    labels: ['Score'],
    datasets: [
      {
        data: [100 - score, score],
        backgroundColor: ['#CFD8DC', '#00A89A'],
        BorderColor: ['#CFD8DC', '#00A89A'],
        borderWidth: 1,
        cutout: '80%',
      },
    ],
  };
  return (
    <>
      <div
        className={classNames(
          fromQuizModal ? c.scoreModal : c.ScoreContainer,
          'p-2 me-1'
        )}
      >
        <div className="d-flex bd-highlight justify-content-between mt-3 mb-3">
          <div className="bd-highlight">
            <Headings headingType="h6" classToOverride="fw-bold p-2">
              Your Quiz Score
            </Headings>
          </div>
          {/* <div className="bd-highlight p-2">
            <MoreHorizIcon />
          </div> */}
        </div>
        <div className="d-flex justify-content-center mt-5 position-relative">
          <div className="position-absolute d-flex h-100 w-100 justify-content-center align-items-center">
            <Headings
              headingType="h30"
              classToOverride={classNames(c.primaryColor, 'fw-bold p-2')}
            >
              {score?.toFixed(1)}%{'\n'}
            </Headings>
          </div>
          <div className={classNames(c.chartContainer)}>
            <Doughnut
              data={YourQuizScoreData}
              options={YourQuizScoreOptions}
              //plugins={[doughnutChartPlugin]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default YourQuizScore;
