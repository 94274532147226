import { getApiCall } from '../../utils/axios/axios';
import { CLIENT_ID } from '../../config/Constants';
import { API_URLS } from '../../config/API_URLS/api_urls';
import { calculateProfilePercentage } from '../../utils/utility';
export const ProfileTabsOptions = [
  { label: 'General Information', value: 0 },
  { label: 'Learning Preferences', value: 1 },
  { label: 'Targets', value: 2 }
];

export const getProfileDetails = async (user_client_id) => {
  try {
    const paramsData = {
      queryParams: {
        user_client_id: user_client_id,
        platform_id: CLIENT_ID,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URLS?.profile_details, paramsData);
    calculateProfilePercentage(response?.data);
    return response;
  } catch (error) {
    console.log(`Error in fetching Learning Preference Details ${error}`);
  }
};
