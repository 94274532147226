import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import Text from '../../Text';
import c from '../../shared.Module.scss';
import classNames from 'classnames';

const SessionDetailsChart = ({
  nudgeTimeseries,
  sessionInfo,
  labelsToShow,
  time,
}) => {
  // Extract nudge names and times from the provided data
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const nudges = nudgeTimeseries && Object?.keys(nudgeTimeseries);

  const newDuration = !(sessionInfo?.time_started && sessionInfo?.time_finished)
    ? 0
    : new Date(sessionInfo?.time_finished).getTime() -
      new Date(sessionInfo?.time_started).getTime();

  const Utils = {
    // Function to generate a random number between min and max
    rand: function (min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    // Chart colors object for easy reference
    CHART_COLORS: {
      primary: 'rgba(255, 129, 22, 1)',
      secondary: 'rgba(0,128,128, 1)',
      // Add more colors as needed
    },
  };

  // Example usage:
  const data = {
    labels: nudges,
    datasets: [
      {
        label: 'Nudge Time',
        data: nudges?.flatMap((nudge) => {
          const nudgeData = nudgeTimeseries[nudge];
          return nudgeData?.map((session) => {
            const endTime = new Date(session.end_time).getTime();
            const startTime = new Date(session.start_time).getTime();
            return {
              x: [
                (new Date(startTime).getTime() -
                  new Date(sessionInfo?.time_started).getTime()) /
                  1000,
                (new Date(endTime).getTime() -
                  new Date(sessionInfo?.time_started).getTime()) /
                  1000,
              ],
              y: nudge,
            };
          });
        }),
        backgroundColor: nudges?.flatMap((nudge, index) => {
          const nudgeData = nudgeTimeseries[nudge];
          return nudgeData?.map((session) => {
            if (index % 2 === 0) return Utils?.CHART_COLORS.primary;
            else return Utils?.CHART_COLORS.secondary;
          });
        }),
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    height: '360',
    plugins: {
      legend: {
        display: false,
      },
      beforeDraw: (chart) => {
        const ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'rgba(43, 150, 150, 1)';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
      tooltip: {
        top: '2%',
        position: 'average',
        legend: {
          display: false,
        },
        callbacks: {
          label: function (context) {
            const startTime = context.dataset.data[context.dataIndex].x[0];
            const endTime = context.dataset.data[context.dataIndex].x[1];

            // Calculate the difference in seconds
            const timeDifferenceInSeconds = endTime - startTime;

            // Extract hours, minutes, and seconds
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
            const seconds = Math.floor(timeDifferenceInSeconds % 60);

            // Format the time
            let formattedTime = '';

            if (hours > 0) {
              formattedTime += `${hours}h:`;
            }

            if (minutes > 0 || (hours === 0 && seconds === 0)) {
              formattedTime += `${minutes}m:`;
            }

            if (seconds > 0) {
              formattedTime += `${seconds}s`;
            }

            return `Nudge Time: ${formattedTime}`;
          },
        },
      },
    },
    scales: {
      x: {
        min: 0,
        max: newDuration / 1000,
        type: 'linear',
        position: 'top',
        beginAtZero: false,
        grid: {
          display: true,
          drawBorder: false,
          color: 'rgba(43, 150, 150, 1)',
          borderDash: [3, 3],
        },
        ticks: {
          display: false,
          stepSize: newDuration / 6000,
        },
      },
      y: {
        stacked: false,
        ticks: {
          display: false,
          beginAtZero: true,
          font: {
            size: 12, // Adjust font size
          },
          color: 'blue',
          backdropColor: 'rgba(255,255,255,0.75)',
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Nudges',
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      bar: {
        order: 2, // Specify the stacking order
      },
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center  p-4 shadow-sm mt-3 ">
        <div>
          <Text textType="t24">
            {new Date(sessionInfo?.time_finished).toLocaleDateString('en-US', {
              weekday: 'long',
              month: 'short',
              day: 'numeric',
            })}
          </Text>
          <Text textType="t18" classToOverride={c.sessionChartTime}>
            {new Date(sessionInfo?.time_started).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
            -
            {new Date(sessionInfo?.time_finished).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </Text>
        </div>
        <div>
          <Text textType="t18">
            {time[0] > 0 && `${time[0]}h`} {time[1] > 0 && `${time[1]}m`}{' '}
            {time[2] > 0 && `${time[2]}s`}{' '}
            {/* {new Date(sessionInfo?.time_started).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
            -
            {new Date(sessionInfo?.time_finished).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })} */}
          </Text>
          <Text
            textType="t18"
            classToOverride={classNames(
              c.sessionChartTime,
              'd-flex justify-content-end'
            )}
          >
            Learning Journey
          </Text>
        </div>
      </div>
      <div
        className="position-relative"
        style={{ height: '360px', width: '100%', overflowY: 'auto' }}
      >
        <Bar
          className={c.barMargin}
          data={data}
          options={options}
          type="horizontalBar"
        />
        <div
          className="position-absolute top-0 mt-2 "
          style={{ left: '0', height: '370px' }}
        >
          {labelsToShow.map((value) => {
            return (
              <div
                key={value.label}
                className={classNames(
                  c.sessionChartLabel,
                  'text-white p-2 mt-2'
                )}
              >
                <div className="d-flex justify-content-between ">
                  <div>{value.count}</div>
                  <div>{value.icon}</div>
                </div>
                <div className="accordion-header text-center pt-1 text-capitalize ">
                  {value.label.includes('_')
                    ? `${value.label.split('_')[0]} ${
                        value.label.split('_')[1]
                      }`
                    : value.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SessionDetailsChart;
