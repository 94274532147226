/**
 * @function RightPanelPage5
 * @param {number} goToNextPage
 * @returns It will show the fifth page of register page.
 */

import React, { useRef } from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import SignupStyling from '../../signUp.Module.scss';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { Resources } from '../../../../config/Resources';
import { installExtensionHandler } from '../../../../helpers/signup/ExtensionHandler';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';
const RightPanelPage5 = (props) => {
  const timerId = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    installExtensionHandler(event, props.goToNextPage, timerId);
    googleAnalyticsTriggerFn(
      'Button',
      'Install Ahura Extention',
      'Install Ahura Extension button clicked on signUp'
    );
  };
  return (
    <>
      <RightPanelLayout
        heading="Get the Ahura Chrome Extention"
        desc="To use Ahura.ai, you must install the Chrome extension to your Chrome browser."
        goToNextPage={props.goToNextPage}
        showContinueBtn={false}
      >
        <div className="text-center mb-5">
          <img
            className={classNames(SignupStyling.centerImage)}
            src={Resources.images.ChromeExtention}
            alt="logo"
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          className={classNames(
            'w-100 py-4 fw-bold mb-2',
            SignupStyling.signUpBtns,
            SignupStyling.backgroundColorBtn
          )}
          onClick={handleClick}
        >
          INSTALL AHURA EXTENSION
        </Button>
      </RightPanelLayout>
    </>
  );
};
export default RightPanelPage5;
