/**
 * @function LicensecodeEntryPanal
 * @param {Array} LicensecodeEntryPanal This is list of Clients containing License Ids
 * @param {Function} goToNextPage
 * This components renders the dropdown list of the license Clients and
 * input panal where user withh enter their license codes
 */

import { KeyboardArrowDown } from '@mui/icons-material';
import {
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SignupStyling from '../../../signUp.Module.scss';
import {
  initialValues,
  licenseCodeValidate,
} from '../../../../../helpers/signup';
import Headings from '../../../../Shared/Headings';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../../../redux/Actions/UserData';
import {
  CLIENT_ID,
  PROMO_ACCOUNT_CLIENT_ID,
} from '../../../../../config/Constants';
import { API_URLS } from '../../../../../config/API_URLS/api_urls';
import { useSelector } from 'react-redux';
import { postApiCall } from '../../../../../utils/axios/axios';
import jwtDecode from 'jwt-decode';
import { googleAnalyticsTriggerFn } from '../../../../../utils/utility';
import Text from '../../../../Shared/Text';
const LicensecodeEntryPanal = ({ LicenseClient, goToNextPage, corpDetails={}, groupDetails={}, groupId='', corpId=''  }) => {
  const { userData } = useSelector((state) => state);

  const handlePost = async (res) => {
    try {
      const token = localStorage.getItem('token');
      const decode_token = jwtDecode(token);
      const reqBody = {
        neighbourhood_id: res.neighbourhood_id,
        client_id: CLIENT_ID, //Use CLIENT_ID from Config
        user_id: userData?.id || decode_token?.id,
      };

      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
      const response = await postApiCall(
        API_URLS?.addUserToNeighborhood,
        reqBody,
        paramsData
      );
    } catch (err) {
      console.log('Error in getAccounts Data ', err);
    }
  };

  const dispatch = useDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const SaveUserData = (resultData) => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length === 0) {
        console.log(resultData);
        resultData && resultData.data && dispatch(setUserData(resultData.data));
        // resultData &&
        //   resultData.data &&
        //   localStorage.setItem('token', resultData.data.UserClients[0]?.token);
        handlePost(resultData);
        goToNextPage();
      }
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: licenseCodeValidate,
    onSubmit: (values) => {
      SaveUserData(values);
      googleAnalyticsTriggerFn(
        'Button',
        'Submit Code',
        'Submit Code button clicked on signUp'
      );
    },
  });

  useEffect(() => {
    if (LicenseClient && LicenseClient?.length) {
      const promoAccount = LicenseClient.find(
        (corporate) => corporate?.client_id === PROMO_ACCOUNT_CLIENT_ID
      );
      if (promoAccount) {
        formik.setFieldValue('license_client', promoAccount);
        formik.setFieldValue('neighbourhood_id', promoAccount?.client_id);
      }
    }
  }, [LicenseClient]);

  return (
    <>
      <Headings
        headingType="h5"
        classToOverride={` ${SignupStyling.pageLayoutDesc} fw-normal`}
      >
        Gain unlimited access to all Premium learning experiences for you and
        your organization.
      </Headings>

      {groupId && corpId && groupDetails?.id && corpDetails?.id && (<>
        <div className="d-flex justify-content-start">
          <Text 
            textType="t18"
            classToOverride={classNames('text-black')}>
              Organisation Name: 
          </Text>&nbsp;
          <Text 
            textType="t18"
            classToOverride={classNames(SignupStyling.groupCorpInfo)}>
              {corpDetails?.name}
          </Text>
        </div>

        <div className="d-flex justify-content-start">
          <Text 
            textType="t18"
            classToOverride={classNames('text-black')}>
              Group Name:
          </Text>&nbsp;
          <Text 
            textType="t18"
            classToOverride={classNames(SignupStyling.groupCorpInfo)}>
              {groupDetails?.name}
          </Text>

        </div>
        </>)
      }

      {(!groupId || !corpId) && <><Select
        IconComponent={KeyboardArrowDown}
        variant="outlined"
        name="license_client"
        id="license_client"
        value={formik?.values?.license_client}
        onChange={formik?.handleChange}
        // onBlur={(e) =>
        //   console.log(
        //     'event',
        //     e.target.value,
        //     'formik?.values?.license_client?',
        //     formik?.values?.license_client
        //   )
        // }
        onBlur={formik?.handleBlur}
        error={
          (formik?.touched.license_client ||
            formik?.touched.license_client?.max_licenses) &&
          formik?.errors?.license_client?.max_licenses
            ? true
            : false
        }
        inputProps={{
          className: `fw-bold ${SignupStyling.signUpFields} `,
        }}
        MenuProps={{
          PaperProps: { className: `${SignupStyling.LicenseClientDrop}` },
        }}
        className={`${SignupStyling.signUpFieldsContainer} w-100 `}
      >
        <MenuItem value="none" disabled>
          Select Neighbourhood
        </MenuItem>
        {LicenseClient?.map((items) => (
          <MenuItem
            key={items.client_id}
            className={`py-3 w-100 ${
              formik?.values?.license_client === items.client_id &&
              classNames(` ${SignupStyling.SelectedItem}`)
            }`}
            value={items}
          >
            {items.client_name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classNames('mb-3 ms-3 text-danger')}>
        {(formik?.touched.license_client ||
          formik?.errors?.license_client?.max_licenses) &&
          formik?.errors?.license_client?.max_licenses}
      </FormHelperText>
      <TextField
        type="text"
        placeholder="Enter Code"
        variant="outlined"
        name="neighbourhood_id"
        id="neighbourhood_id"
        value={formik?.values?.neighbourhood_id}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        error={
          (formik?.touched.sumbited || formik?.touched.neighbourhood_id) &&
          formik?.errors?.neighbourhood_id
            ? true
            : false
        }
        helperText={
          (formik?.touched.sumbited || formik?.touched.neighbourhood_id) &&
          formik?.errors?.neighbourhood_id
        }
        inputProps={{
          className: `${SignupStyling.signUpFields} fw-bold`,
        }}
        className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
      />
      <Button
        variant="contained"
        color="primary"
        className={classNames(
          'w-100 fw-bold py-4 mt-2 mb-2',
          SignupStyling.signUpBtns,
          SignupStyling.buttonBackground,
          SignupStyling.backgroundColorBtn
        )}
        onClick={formik.handleSubmit}
      >
        {IsLoading ? <CircularProgress color="inherit" /> : 'SUBMIT CODE'}
      </Button></>}
    </>
  );
};

export default LicensecodeEntryPanal;
