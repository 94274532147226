import classNames from 'classnames';
import React from 'react';
import PageHeaderLeftSection from './PageHeaderLeftSection';
import pageHeaderStyling from './pageheader.module.scss';
const PageHeader = ({ name, subText, icon, ...props }) => {
  return (
    <div
      className={classNames(
        pageHeaderStyling.pageHeaderContainer,
        'd-flex p-4'
      )}
    >
      <PageHeaderLeftSection name={name} subText={subText} icon={icon} />
    </div>
  );
};

export default PageHeader;
