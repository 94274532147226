import React from 'react';
import Divider from '@mui/material/Divider';

import PreferedLessonFormat from '../PreferedLessonFormat';

const PreferedLessonFormatDropdown = ({
  editable,
  dropdownState,
  handleChangeDropdown,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Divider />
          <div className="row align-items-center mt-2 mb-2">
            <div className="col-1">{'1st'}</div>
            <div className="col-4 ms-3">
              <PreferedLessonFormat
                value1={dropdownState.first}
                value2={dropdownState.second}
                value3={dropdownState.third}
                handleChange={handleChangeDropdown}
                editable={editable}
                name={'first'}
              />
            </div>
          </div>
          <Divider />
          <div className="row align-items-center mt-2 mb-2">
            <div className="col-1">{'2nd'}</div>
            <div className="col-4 ms-3">
              <PreferedLessonFormat
                value1={dropdownState.second}
                value2={dropdownState.first}
                value3={dropdownState.third}
                handleChange={handleChangeDropdown}
                editable={editable}
                name={'second'}
              />
            </div>
          </div>
          <Divider />
          <div className="row align-items-center mt-2 mb-2">
            <div className="col-1">{'3rd'}</div>
            <div className="col-4 ms-3">
              <PreferedLessonFormat
                value1={dropdownState.third}
                value2={dropdownState.second}
                value3={dropdownState.first}
                handleChange={handleChangeDropdown}
                editable={editable}
                name={'third'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferedLessonFormatDropdown;
