import React from 'react';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import classNames from 'classnames';
import CircularProgressWithLabel from '../Progress';
import Text from '../../../Shared/Text';
import { Avatar } from '@mui/material';

const EmotionProgress = ({
  img,
  text,
  circleColor,
  value,
  emotionDataLength=0,
  ...props
}) => {
  return (
    <div
      className={`col-md-3 mt-3 `}
    >
      <div className=" my-2">
        {/* <img src={img} alt="emotion" className={classNames(LearningBehaviorCss.avtarImage)}/> */}
        <Avatar
          alt="emotion"
          src={img}
          className={classNames(LearningBehaviorCss.avtarImage, 'mx-auto')}
        />
      </div>
      <div className="text-center my-3">
        <Text textType="t1" text={text} />
      </div>
      <div className="text-center mt-4 my-4">
        <CircularProgressWithLabel
          value={value}
          c={LearningBehaviorCss}
          circleColor={circleColor}
        />
      </div>
    </div>
  );
};
export default EmotionProgress;
