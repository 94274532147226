import Skeleton from '@mui/material/Skeleton';

export const GetAPISkeleton = () => {
  return (
    <div>
      <div className="row mt-5">
        <div className="col-3">
          {' '}
          <Skeleton variant="rectangular" height={10} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-5">
          <Skeleton variant="rectangular" height={10} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-6">
          <Skeleton variant="rectangular" height={10} width={150} />
          <Skeleton animation="wave" height={50} />
        </div>
        <div className="col-6">
          <Skeleton variant="rectangular" height={10} width={150} />
          <Skeleton animation="wave" height={50} />
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <div className="col-6">
          <Skeleton variant="rectangular" height={10} width={150} />
          <Skeleton animation="wave" height={50} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-6">
          {' '}
          <div className="mb-4">
            <Skeleton variant="rectangular" height={15} width={200} />
          </div>
          <Skeleton animation="wave" height={40} width={350} />
          <Skeleton animation="wave" height={40} width={350} />
          <Skeleton animation="wave" height={40} width={350} />
        </div>
      </div>
    </div>
  );
};
