import { Doughnut } from 'react-chartjs-2';

const SessionGaugeChart = ({ visitedPages }) => {
  console.log('visited', visitedPages);
  let distractionPercentage = 0;
  let focusedPercentage = 0; // dummy percentage
  if (visitedPages && visitedPages.length > 0) {
    for (let i = 0; i < visitedPages?.length; i++) {
      focusedPercentage +=
        visitedPages[i]?.is_relevant_algo &&
        !visitedPages[i]?.is_transitional_algo
          ? 1
          : 0;
      distractionPercentage +=
        !visitedPages[i]?.is_relevant_algo &&
        !visitedPages[i]?.is_transitional_algo
          ? 1
          : 0;
    }
    focusedPercentage = (focusedPercentage / visitedPages?.length) * 100;
    focusedPercentage = focusedPercentage.toFixed(1);
    distractionPercentage =
      (distractionPercentage / visitedPages?.length) * 100;
    distractionPercentage = distractionPercentage.toFixed(1);
  }

  const sourceData = [
    {
      label: 'Focused',
      value: focusedPercentage,
    },
    {
      label: 'Distractions',
      value: distractionPercentage,
    },
  ];

  return (
    <div className="position-relative">
      <Doughnut
        data={{
          datasets: [
            {
              label: 'Count',
              data: sourceData.map((data) => data.value),

              backgroundColor: ['rgba(0, 128, 128, 1)', 'rgba(255,129,22,1)'],
              borderColor: [' rgba(0, 128, 128, 1)', 'rgba(255,129,22,1)'],
              hoverOffset: 4,
              cutout: '80%',
              rotation: -100,
              circumference: 200,
            },
          ],
        }}
        options={{
          hover: {
            mode: null,
          },
          cutout: '60%',
          plugins: {
            title: {
              text: 'Emotions',
            },
            legend: {
              display: true,
              position: 'top',
              paddingBottom: 16,
              align: 'start',
              labels: {
                fontColor: '#555555',
                fontSize: 20,
                boxWidth: 0,
              },
            },
            datalabels: {
              display: true,
              formatter: (label) => {
                return label;
              },
            },
          },
        }}
      />
      {/* Center label */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, 10%)',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            color: '#3A9D9D',
            fontWeight: '400',
          }}
        >
          <span style={{ fontSize: '42px' }}>{focusedPercentage}</span>
          <span style={{ fontSize: '20px' }}>%</span>
        </div>
        <div
          style={{
            color: '#171923',
            textAlign: 'center',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
          }}
        >
          Browsing
        </div>
      </div>
    </div>
  );
};

export default SessionGaugeChart;
