/**
 * @function CustomSelect
 * @param {string} label the label of the select
 * @param {array} menuItems array of menuItems
 * structure of menuItems :-
 * [{label:'ten',value:10},{label:'twenty',value:20}.{label:'thirty',value:30}];
 * @param {string} value the state value(The value you want to change onChange)
 * @param className class for the whole dropdown
 * @param dropdownIcon TheIcon that you want to render.
 * @param {function} onChange function
 * SEND THE ICON VARIABLE NOT THE COMPONENT otherwise it will render an error on the screen
 * example:- dropdownIcon={FilterIcon}  not  dropdownIcon={<FilterIcon />}
 */

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';
import c from '../layout.Module.scss';
const CustomSelect = ({
  label,
  menuItems,
  onChange,
  value,
  className,
  dropdownIcon,
}) => {
  return (
    <FormControl
      fullWidth
      className={classNames(c.defaultSelect, className)}
      size="small"
    >
      <InputLabel className={classNames(c.defaultSelectInput)}>
        {label}
      </InputLabel>
      <Select
        // hideBackdrop
        value={value || ''}
        label={label}
        IconComponent={dropdownIcon || KeyboardArrowDownIcon}
        onChange={onChange || null}
        classes={{
          iconOpen: classNames(c.filterIcon),
        }}
      >
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((menuItem) => {return(
            <MenuItem
              value={menuItem.value}
              name={menuItem.label}
              key={menuItem.label}
            >
              {menuItem.label}
            </MenuItem>
          )})}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
