import { SESSION_RECORD } from "../../Types/SessionRecord";

export const SessionRecordReducer = (state = {}, action) => {
const { type, payload } = action;
  switch (type) {
    case SESSION_RECORD:
      return payload;
    default:
      return state;
  }
};
