import Text from '../../Shared/Text';
import classNames from 'classnames';
import c from '../signIn.Module.scss';
import { useHistory } from 'react-router-dom';

export const SignInFooter = () => {
  const history = useHistory();
  return (
    <div
      className={classNames(
        c.signInFooter,
        'd-flex align-items-center flex-row justify-content-end bg-dark'
      )}
    >
      <div className="d-flex flex-column">
        <Text
          textType="t2"
          classToOverride={classNames('text-white d-block', c.fade)}
        >
          © AHURA AI 2021. All Rights Reserved.
        </Text>
        <Text textType="t2" classToOverride={classNames('text-white', c.fade)}>
          <span
            onClick={() => window.open('/privacy-policy', '_blank')}
            className={c.cursorPointer}
          >
            Privacy Policy
          </span>
          |
          <span
            onClick={() => window.open('/terms-and-conditions', '_blank')}
            className={c.cursorPointer}
          >
            Terms and Conditions
          </span>
        </Text>
      </div>
    </div>
  );
};
