import classNames from 'classnames';
import React from 'react';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import c from '../../home.Module.scss';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { getMinAndHrString } from '../../../../utils/utility';

const HighlightsDetailBox = ({
  className,
  heading,
  highlightedData,
  highlightedPercentage,
  targetData,
  companyAverageData,
}) => {
  return (
    <div className={classNames(className, 'w-100', c.highlightDetailBox)}>
      <Text textType="t2" classToOverride={c.fontWeight600}>
        {heading}
      </Text>
      <Headings
        headingType="h5"
        classToOverride={classNames(
          Number(highlightedPercentage) > 0 ? c.positiveData : c.negativeData,
          'mt-2 fw-bold'
        )}
      >
        { (heading === "# of Hours" ? getMinAndHrString(highlightedData) : highlightedData) || 0} 
        ({Number(highlightedPercentage) > 0 ? '+' : ''}
        {Number(highlightedPercentage) || 0}%)
        {Number(highlightedPercentage) > 0 ? (
          <ArrowDropUpIcon className="" />
        ) : (
          <ArrowDropDownIcon />
        )}
      </Headings>

      {/* Target Data */}

      <div className="row mt-2">
        <div className="col-5">
          <Text
            textType="t2"
            classToOverride="d-flex justify-content-start align-items-center"
          >
            <PersonIcon color="inherit" />
            My Target
          </Text>
        </div>
        <div className={classNames(c.font_12, 'col-3 fw-bold text-center')}>
          {heading === "# of Hours" ? getMinAndHrString(targetData?.data) : (targetData?.data)}
        </div>
        {/* <div
          className={classNames(
            'col-4 fw-bold d-flex justify-content-end',
            targetData?.percentage > 0 ? c.primaryColor : c.negativeData
          )}
        >
          <div>
            ({targetData?.percentage > 0 ? '+' : ''}
            {targetData?.percentage}%)
          </div>
          {Number(targetData?.percentage) > 0 ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </div> */}
      </div>

      {/* Company Average Data */}

      <div className="row mt-2">
        <div className="col-5">
          <Text
            textType="t2"
            classToOverride="d-flex justify-content-start align-items-center"
          >
            <BusinessCenterIcon color="inherit" />
            Company Average
          </Text>
        </div>
        <div className={classNames(c.font_12,'col-3 fw-bold text-center')}>
          {(heading === "# of Hours" ? getMinAndHrString(companyAverageData?.data) : companyAverageData?.data) || 0}
        </div>
        <div
          className={classNames(
            'col-4 fw-bold d-flex justify-content-end', c.font_12,
            companyAverageData?.percentage > 0 ? c.primaryColor : c.negativeData
          )}
        >
          ({companyAverageData?.percentage > 0 ? '+' : ''}
          {Number(companyAverageData?.percentage) || 0}%)
          {Number(companyAverageData?.percentage) > 0 ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default HighlightsDetailBox;
