/**
 * @function LearningPreferences
 * Component of Profile section
 */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import c from '../Profile.Module.scss';
import Text from '../../Shared/Text/index';
import CustomSlider from './CustomSlider';
import EditAndSaveBtn from '../FormEditAndSubmit';
import PreferedLessonFormatDropdown from './PreferredLessonFormatDropdown';
import { Divider } from '@mui/material';
import {
  FetchLearningPreferenceDetails,
  UpdateLearningPreferenceDetails,
} from '../../../helpers/Profile/LearningPreferences';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import { GetAPISkeleton } from './GetAPISkeleton';
import { getProfileDetails as updatePercentage } from '../../../helpers/Profile';
const LearningPreferences = () => {
  const [editable, setEditable] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [sliderState, setSliderState] = useState({
    learningPersonality: 0,
    preferredLearningTime: 0,
    preferredLearningPace: 0,
  });

  const [dropdownState, setDropdownState] = useState({
    first: '',
    second: '',
    third: '',
  });

  const dispatch = useDispatch();

  const id = useSelector((state) => state?.generalInformation?.id);

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line
  }, []);

  const getProfileDetails = async () => {
    try {
      const res = await FetchLearningPreferenceDetails(id);
      setSkeleton(false);
      if (res?.data?.content) {
        setSliderState(() => ({
          learningPersonality: res?.data?.content?.learningPersonality,
          preferredLearningTime: res?.data?.content?.preferredLearningTime,
          preferredLearningPace: res?.data?.content?.preferredLearningPace,
        }));
        setDropdownState(() => ({
          first: res?.data?.content?.preferredLessonFormats['1'],
          second: res?.data?.content?.preferredLessonFormats['2'],
          third: res?.data?.content?.preferredLessonFormats['3'],
        }));
      }
    } catch (err) {
      console.log('Error in getProfileDetails', err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSliderState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeDropdown = (e) => {
    const { name, value } = e.target;
    setDropdownState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    dispatch(showLoader());
    await UpdateLearningPreferenceDetails({
      ...sliderState,
      preferredLessonFormats: dropdownState,
      user_client_id: id,
    });
    await updatePercentage(id);
    dispatch(hideLoader());
  };

  return skeleton ? (
    <GetAPISkeleton />
  ) : (
    <>
      <EditAndSaveBtn
        editable={editable}
        setEditable={setEditable}
        handleSubmit={handleSubmit}
        page="Learning Preferences"
      />
      <div
        className={classNames(
          c.LearningPreferencesContainer,
          'p-3 pb-5 mb-2 border-6'
        )}
      >
        <div className="ps-3 pt-3">
          <Text textType="t1" classToOverride="fw-bold">
            Learning Preferences
          </Text>
          {/* <Text textType="t2">Another line here for description</Text> */}
        </div>
        <div className="m-2 p-2 mb-5">
          <div className="row">
            <div className="col-6">
              <CustomSlider
                value={sliderState.learningPersonality}
                name="learningPersonality"
                sliderTitle="Learning Personality"
                sliderText1="Introvert"
                sliderText2="Extrovert"
                onChange={handleChange}
                isDisabled={!editable}
              />
            </div>
            <div className="col-6">
              <CustomSlider
                value={sliderState.preferredLearningTime}
                name="preferredLearningTime"
                sliderTitle="Preferred Time for Learning"
                sliderText1="Morning"
                sliderText2="Night"
                onChange={handleChange}
                isDisabled={!editable}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <CustomSlider
                value={sliderState.preferredLearningPace}
                name="preferredLearningPace"
                sliderTitle="Preferred Learning Pace"
                sliderText1="Slow"
                sliderText2="Fast"
                onChange={handleChange}
                isDisabled={!editable}
              />
            </div>
          </div>
        </div>

        <div className="ps-3">
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <Text textType="t2">Preferred Lesson Format</Text>
              </div>
              <Divider />
              <div>
                <PreferedLessonFormatDropdown
                  handleChangeDropdown={handleChangeDropdown}
                  setDropdownState={setDropdownState}
                  dropdownState={dropdownState}
                  editable={editable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LearningPreferences;
