import React, { useState } from 'react';
import c from '../learningPlans.Module.scss';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import Headings from '../../Shared/Headings';
import Text from '../../Shared/Text';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { Resources } from '../../../config/Resources';
import Timeline from '../Timeline';
import VisibilityIcon from '@mui/icons-material/Visibility';

const LearningPlanCard = ({
  totalOptedCourses = 0,
  index,
  ranking = 3,
  cardHead,
  handlePlanPreview,
  ...props
}) => {
  const [isCardActive, setIsCardActive] = useState(false);
  return (
    <div
      className={classNames(
        'pe-3',
        c.learningPlanCard,
        c.cursorPointer,
        isCardActive ? c.activeCardVariant : c.inactiveCardVariant
      )}
      onClick={() => setIsCardActive(!isCardActive)}
    >
      <div className="pt-2 ps-3 d-flex justify-content-end">
        {Boolean(totalOptedCourses) && (
          <Headings
            headingType="h5"
            classToOverride={classNames('fw-bold', c.cardCoursesCnt)}
          >
            {totalOptedCourses}
          </Headings>
        )}
        <Text
          textType="t1"
          classToOverride={classNames('fw-normal', c.cardCourses)}
        >
          {totalOptedCourses
            ? totalOptedCourses === 1
              ? 'Course'
              : 'Courses'
            : 'No Courses'}
        </Text>
      </div>
      <div className={classNames('pt-3 ps-4', c.cardContentContainer)}>
        <Headings headingType="h6" classToOverride="fw-bold">
          {`Learning Plan ${index} :`} <br /> {cardHead}
        </Headings>
        <Tooltip title={props?.children} arrow>
          <span className={classNames(c.fontWeight500, c.font_14, c.cardDesc)}>
            {_.truncate(props?.children, { length: 130 })}
          </span>
        </Tooltip>
      </div>
      <div className="row g-0 py-2 align-items-center">
        <div className="col ps-3">
          <img
            className={c.learningPlanIcon}
            src={Resources.images.learningPlanIcon}
            alt="Learning Plan Icon"
          />
          <Text textType="t5">Learning Plans</Text>
        </div>
        <div className="col-6 text-center">
          <Timeline
            items={6}
            finishItems={ranking}
            activeItemClass={c.cardTimelineActiveItem}
            inertItemClass={c.cardTimelineInertItem}
          />
        </div>
        <div className="col-3 text-center">
          <IconButton
            aria-label="play"
            color="inherit"
            disabled={!isCardActive}
            onClick={(e) => {
              e.stopPropagation();
              handlePlanPreview(true);
            }}
          >
            <VisibilityIcon
              className={classNames(c.iconButton, 'rounded-circle')}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default LearningPlanCard;
