export const CorporateDetailsFormFields = [
  {
    id: 'name',
    type: 'text',
    Label: 'Organization Name',
  },
  {
    id: 'email',
    type: 'email',
    placeholder: 'Enter Here',
    Label: 'Organization Email',
  },
  {
    id: 'phone',
    type: 'text',
    Label: 'Organization Phone Number',
  },
  {
    id: 'website',
    type: 'text',
    Label: 'Organization Website',
  },
  {
    type: 'select',
    Label: 'Country',
    id: 'country',
    MenuItems: [
    ],
  },
  {
    type: 'select',
    Label: 'State',
    id: 'state',
    MenuItems: [
    ],
  },
  {
    id: 'add_location',
    type: 'text',
    Label: 'Address',
  },
  {
    id: 'suite',
    type: 'text',
    Label: 'Suite #',
  },
  {
    id: 'city',
    type: 'text',
    Label: 'City',
  },
  {
    id: 'zip_code',
    type: 'text',
    Label: 'Zip Code',
  },
  // {
  //   type: 'select',
  //   Label: 'Role',
  //   id: 'role',
  //   MenuItems: [
  //     {
  //       value: 'teacher',
  //       Label: 'Teacher',
  //     },
  //     {
  //       value: 'manager',
  //       Label: 'Manager',
  //     },
  //   ],
  // },
  {
    id: 'password',
    type: 'Password',
    Label: 'Create Password',
  },

  {
    id: 'confirm_password',
    type: 'Password',
    Label: 'Confirm Password',
  },

];

export const CreateAccountFields = [
  {
    type: 'select',
    Label: 'Account',
    id: 'accountType',
    MenuItems: [
      // {
      //   value: 10,
      //   Label: 'Personal Account',
      // },
      {
        value: 'Coorporate Account',
        Label: 'Coorporate Account',
      },
    ],
  },
  {
    type: 'select',
    Label: 'Capacity',
    id: 'capacity',
    MenuItems: [
      // {
      //   value: 10,
      //   Label: 'Ten',
      // },
      // {
      //   value: 20,
      //   Label: 'Twenty',
      // },
      {
        value: 50,
        Label: '50',
      },
      // {
      //   value: 100,
      //   Label: 'Hundred',
      // },
    ],
  },
];
