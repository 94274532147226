import classNames from 'classnames';
import React from 'react';
import AvatarWithInfo from '../../../../Layout/AvatarWithInfo';
import Text from '../../../../Shared/Text';
import c from '../../../community/community.Module.scss';

const TrendingCourses = ({
  CourseImage,
  CourseHeading,
  CourseViews,
  LastMonthViews,
  Margin,
}) => {
  return (
    <div
      className={classNames(
        c.TrendingListDataContainer,
        'd-flex align-items-center py-1 px-2 mx-2'
      )}
    >
      <div className="col-7  mb-2">
        <AvatarWithInfo
          src={CourseImage}
          alt="Python"
          width="65px"
          height="65px"
          head={
            <Text
              textType="t1"
              text={CourseHeading}
              classToOverride="fw-bold"
            />
          }
          classForHead={classNames(c.TrendingTopics)}
        />
      </div>
      <div className="col-3">
        <div className={classNames('row', c.TextCountViews)}>
          {CourseViews} views
        </div>
        <div className={classNames('row mt-1', c.TextCountViewsDescription)}>
          {LastMonthViews !== null ? `Last month: ${LastMonthViews} views` : ''}
        </div>
      </div>
      <div
        className={classNames(
          'col-2 fw-bold',
          Number(Margin) >= 0 ? c.TextCountPercentage : c.error
        )}
      >
        ({`${Margin >= 0 ? '+' : ''}${Margin}`}%)
      </div>
      {/* <hr className={classNames(c.HorizontalLine)}></hr> */}
    </div>
  );
};

export default TrendingCourses;
