
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import classNames from 'classnames';
import React from 'react';
import CreatorActionsStyling from './CreatorActions.module.scss';
const CreatorActions = () => {
  return (
    <div className={classNames('d-flex align-items-center',CreatorActionsStyling.UserActionContainer)}>
      <PersonAddAlt1OutlinedIcon className={classNames(CreatorActionsStyling.creatorActionsIcon,'me-4')}/>
      <ChatBubbleOutlineOutlinedIcon  className={classNames(CreatorActionsStyling.creatorActionsIcon)}/>
    </div>
  );
};

export default CreatorActions;
