import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Modal,
  Box,
  CircularProgress,
  Checkbox,
  Typography,
  TableSortLabel,
} from '@mui/material';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getAllCategories } from '../../../helpers/Content';
import { useHistory } from 'react-router-dom';
import Text from '../../Shared/Text';
import c from '../home.Module.scss';
import { getExpertiseData } from '../../../helpers/Home/ExpertiseJourney2.0';
import {
  googleAnalyticsTriggerFn,
  calculateTimeDifference,
} from '../../../utils/utility';
import {
  getOrganizationAssignedCapabilities,
  bulkDeleteAssignedCapabilities,
  deleteAssignedCapability,
} from '../../../helpers/Home/AssignedCapabilities';
import CapabilityModals from '../CapabilityModalsManager';
import { postApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
// Sorting method

const sortRows = (rows, orderBy, order) => {
  return rows.slice().sort((a, b) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    if (orderBy === 'assignee') {
      aValue = a.assignee.length ? a.assignee[0].full_name : '';
      bValue = b.assignee.length ? b.assignee[0].full_name : '';
    }

    if (orderBy === 'capability') {
      aValue = a.capability.name;
      bValue = b.capability.name;
    }

    if (orderBy === 'capability_context') {
      aValue = a.capability.name;
      bValue = b.capability.name;
    }

    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
const CapabilitiesTab = (props) => {
  const corp_id = useSelector((state) => state?.generalInformation?.client_id);
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [actualData, setActualData] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const handleSortRequest = (column) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const sortedRows = sortRows(rows, orderBy, order);

  const handleChange = (event) => {
    const item = event.target.value;
    if (item) {
      history.push(`/capabilities/${item.id}?category_name=${item.name}`);
    }
    googleAnalyticsTriggerFn(
      'Select',
      'Select Capability',
      `${item.name} selected`
    );
  };

  useEffect(() => {
    const OnMount = async () => {
      try {
        if (corp_id) {
          console.log('Client ID', corp_id);
          let response = await getExpertiseData(corp_id, 'corp');
          if (response) {
            setCourses(response);
          } else {
            console.warn('No response from getExpertiseData');
          }

          const res = await getAllCategories();
          if (res) {
            setCourseData(res.data);
          } else {
            //console.warn('No response from getAllCategories');
          }
          const assigned_capabilities_data =
            await getOrganizationAssignedCapabilities(corp_id);

          if (assigned_capabilities_data) {
            //console.log('Data', JSON.stringify(assigned_capabilities_data));
            setRows(assigned_capabilities_data);
          }
        } else {
          //console.log('Unable to fetch Corp ID ');
        }
      } catch (error) {
        //console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    OnMount();
  }, [corp_id]);

  useEffect(() => {
    if (!loading) {
      const intersectionData = [];

      courses.forEach((expertiseDataItem) => {
        courseData.forEach((categoryDataItem) => {
          if (
            expertiseDataItem.capability.toLowerCase() ===
            categoryDataItem.name.toLowerCase()
          ) {
            intersectionData.push({
              id: categoryDataItem.id,
              name: categoryDataItem.name,
            });
          }
        });
      });

      // console.log('Capability/ Category List', intersectionData);
      setActualData(intersectionData);
    }
  }, [courses, courseData, loading]);

  const handleAddRow = () => {
    setOpenAssignModal(true);
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleDeleteSelectedRows = () => {
    const newRows = rows.filter((_, index) => !selectedRows.includes(index));
    // Extract the IDs of the rows to be deleted
    const _idsToDelete = selectedRows.map((index) => rows[index].id);
    const idsToDelete = {
      ids: _idsToDelete,
    };
    bulkDeleteAssignedCapabilities(idsToDelete);
    setRows(newRows);
    setSelectedRows([]);
  };

  const handleDeleteSingleRow = () => {
    const newRows = rows.filter((_, index) => index !== currentRowIndex);
    deleteAssignedCapability(rows[currentRowIndex].id);
    setRows(newRows);
  };

  const handleRemind = (currentRowIndex) => {
    // Get the capability ID from the current row index
    const capabilityId = rows[currentRowIndex]?.id;
    console.log("Assigned Capability ::>>", rows[currentRowIndex])
    console.log("Assigned Capability ID ::>>", rows[currentRowIndex].id)
  
    if (!capabilityId) {
      console.error("Capability ID not found");
      return;
    }
  
    // Call the function to send a reminder
    sendReminder(capabilityId);
  };
  
  // Function to send a reminder by capability ID
  const sendReminder = async (capabilityId) => {
    try {
      // Prepare the endpoint URL
      const reminderUrl = `${API_URLS.send_reminder}/${capabilityId}`; // Assuming API_URLS.send_reminder is correct
  
      // Prepare the parameters for the API call
      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  
      // Send the POST request to trigger the reminder
      const reminderResponse = await postApiCall(reminderUrl, {}, paramsData);
  
      // Handle the response, e.g., success message or UI update
      console.log('Reminder API Response:', reminderResponse);
      alert("Reminder sent successfully!");
  
    } catch (err) {
      // Handle any errors
      console.error('Error sending reminder:', err);
      alert("Failed to send reminder. Please try again.");
    }
  };
  
  const handleEdit = (index) => {
    setRowToEdit(rows[index]);
    console.log('index', rows[index]);
    setIsEditMode(true);
  };

  const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      {loading ? (
        <div className="col-12 text-center">
          <br />
          <br />
          <CircularProgress size={200} />
        </div>
      ) : (
        <div className="mt-5 p-5" style={{ backgroundColor: '#ffffff' }}>
          <Box>
            <Text
              textType="t1"
              classToOverride={classNames(
                'me-auto bd-highlight ms-3',
                c.CapabilitiesTab_Heading
              )}
            >
              View Expertise
            </Text>
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginTop: '20px', marginLeft: '15px' }}
            >
              <FormControl sx={{ minWidth: 500, marginRight: '20px' }}>
                <InputLabel id="select-capability">
                  Select Capability
                </InputLabel>
                <Select
                  defaultValue=""
                  labelId="select-capability"
                  fullWidth
                  input={
                    <OutlinedInput
                      id="select-capability"
                      label="Select Capability"
                    />
                  }
                  id="select-capability"
                  onChange={handleChange}
                  inputProps={{
                    className: classNames(
                      'bg-transparent',
                      c.profileInputField
                    ),
                  }}
                >
                  {actualData &&
                    actualData.map(
                      (item, ind) =>
                        item.id && (
                          <MenuItem key={ind} value={item}>
                            {item?.name}
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ marginTop: '60px', marginLeft: '15px' }}
          ></Box>
          <Box>
            <Text
              textType="t1"
              classToOverride={classNames(
                'me-auto bd-highlight ms-3',
                c.CapabilitiesTab_Heading
              )}
            >
              Assigned Capabilities
            </Text>
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginTop: '20px', marginLeft: '15px' }}
            ></Box>
            <Button
              variant="contained"
              onClick={handleAddRow}
              sx={{ marginLeft: '20px' }}
            >
              Assign New Capability
            </Button>
          </Box>

          {/* Table */}
          <br />
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < rows.length
                      }
                      checked={
                        rows.length > 0 && selectedRows.length === rows.length
                      }
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedRows(rows.map((_, index) => index));
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                    />
                  </TableCell>
                  {/* Add TableSortLabel for sorting */}
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'assignee'}
                      direction={orderBy === 'assignee' ? order : 'asc'}
                      onClick={() => handleSortRequest('assignee')}
                    >
                      Person
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'capability'}
                      direction={orderBy === 'capability' ? order : 'asc'}
                      onClick={() => handleSortRequest('capability')}
                    >
                      Capability
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'capability_context'}
                      direction={
                        orderBy === 'capability_context' ? order : 'asc'
                      }
                      onClick={() => handleSortRequest('capability_context')}
                    >
                      Achievement Criteria/Target Outcome
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'date_assigned'}
                      direction={orderBy === 'date_assigned' ? order : 'asc'}
                      onClick={() => handleSortRequest('date_assigned')}
                    >
                      Active Time
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'deadline'}
                      direction={orderBy === 'deadline' ? order : 'asc'}
                      onClick={() => handleSortRequest('deadline')}
                    >
                      Deadline
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={() => handleSortRequest('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row, index) => (
                  <TableRow key={index} hover>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(index)}
                        onChange={() => handleRowSelect(index)}
                      />
                    </TableCell>
                    <TableCell>
                      {row.assignee.length ? (
                        row.assignee.map((assignee, idx) => (
                          <Chip key={idx} label={assignee.full_name} />
                        ))
                      ) : (
                        <Chip label={row.assignee.full_name} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip label={row.capability.name} />
                    </TableCell>
                    <TableCell>{row.capability_context}</TableCell>
                    <TableCell>
                      {calculateTimeDifference(row.date_assigned)}
                    </TableCell>
                    <TableCell>
                      {new Date(row.deadline).toDateString()}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={row.status || 'Pending'}
                        color={
                          row.status === 'Complete' ? 'primary' : 'default'
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Button color="info" onClick={() => handleRemind(index)}>
                        Remind
                      </Button>
                      <Button onClick={() => handleEdit(index)}>Edit</Button>
                      <Button
                        color="error"
                        onClick={() => {
                          setCurrentRowIndex(index);
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            onClick={() => {
              // setCurrentRowIndex(index);
              setOpenDeleteModal(true);
            }}
            style={{ marginTop: '20px', marginLeft: '15px' }}
            disabled={selectedRows.length === 0}
          >
            Delete Selected Rows
          </Button>
        </div>
      )}

      {/* Include the CapabilityModals component */}
      <CapabilityModals
        openAssignModal={openAssignModal}
        setOpenAssignModal={setOpenAssignModal}
        rows={rows}
        setRows={setRows}
        loading={loading}
        setLoading={setLoading}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        rowToEdit={rowToEdit}
      />
      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box sx={deleteModalStyle}>
          <Typography variant="h6" component="h2">
            Are you sure you want to delete?
          </Typography>
          <Box sx={{ marginTop: '20px' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                if (currentRowIndex !== null) {
                  handleDeleteSingleRow();
                } else {
                  handleDeleteSelectedRows();
                }
                setOpenDeleteModal(false);
              }}
            >
              Yes, Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDeleteModal(false);
                setCurrentRowIndex(null);
              }}
              sx={{ marginLeft: '20px' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CapabilitiesTab;
