import React from 'react';
import Box from '@mui/system/Box';
import CustomTabs from '../../../Layout/Tabs';
import {
  TabOptions,
  EmployeeDetailsTabs,
} from '../../../../helpers/Home/CapabilitiesToStudy';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const CapabilitiesToStudyTabs = ({
  index,
  setTabIndex,
  isEmployeeDetailPage = false,
}) => {
  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
    googleAnalyticsTriggerFn(
      'Tabs',
      'Capabilities To Study Tabs',
      'Capabilities to Study tab switched'
    );
  };

  return (
    <Box borderBottom={1} borderColor="divider">
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={isEmployeeDetailPage ? EmployeeDetailsTabs : TabOptions}
      />
    </Box>
  );
};

export default CapabilitiesToStudyTabs;
