import React from 'react';
import c from '../../../Notification/notification.Module.scss';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { getLastUpdate } from '../../../../../helpers/Home/Notifications';
import Text from '../../../../Shared/Text';
import { SET_TOAST_ERROR } from '../../../../../redux/Actions/Common';
import { useDispatch } from 'react-redux';
import { GROUP_APPROVAL_ID } from '../../../../../config/Constants';

const NotificationData = ({
  id,
  setRequestModalStatus,
  notification_message_plain,
  notification_image,
  name,
  created_at,
  is_read,
  handleClick,
  setAdditionalDetailsData,
  additionalDetails,
  refRecordId,
  setIsNotification,
  notificationId
}) => {
  const dispatch = useDispatch()
  const parsedAdditionalDetails =
    additionalDetails &&
    (typeof additionalDetails === 'object'
      ? additionalDetails
      : JSON.parse(additionalDetails));

  return (
    <div
      className={classNames(
        c.notificationData,
        !is_read && c.unreadNotifications
      )}
      onClick={() => {
        setIsNotification(false);
        if (
          notificationId === GROUP_APPROVAL_ID
        ) {
          if (
            additionalDetails &&
            Object.keys(parsedAdditionalDetails).length !== 0
          ) {
            if (is_read) {
              dispatch(
                SET_TOAST_ERROR({
                  error: true,
                  message: 'Request has already been processed.',
                })
              );
            }
            setAdditionalDetailsData({
              ...parsedAdditionalDetails,
              refRecordId,
              is_read,
              notification_message_plain,
              id,
            });
            setRequestModalStatus(true);
          }
        } else {
          handleClick(id);
        }
      }}
    >
      <span className={classNames('gap-2', c.notificationDataSpan)}>
        {notification_image !== '' ? (
          <Avatar
            className={classNames(c.notificationDataAvatar)}
            alt="UserProfile"
            src={notification_image}
          />
        ) : (
          <Avatar
            className={classNames(
              c.notificationDataAvatar,
              c.notificationAvatarInitials
            )}
            alt="UserProfile"
          >
            {name.charAt(0)}
          </Avatar>
        )}
        <div className="ml-2 mr-3">
          <div className={classNames(c.notificationDataName)}>
            <span>{name}</span>
          </div>
          <div className={classNames(c.notificationDataMsg)}>
            <span className="d-flex align-items-center">
              <div>{notification_message_plain}</div>
            </span>
          </div>
        </div>
      </span>
      <div className="d-flex flex-column align-items-start ml-auto gap-2">
        <Text textType="t3">
          {getLastUpdate(created_at)
            .replace(' months', 'mon')
            .replace(' minutes', 'min')
            .replace(' years', 'y')
            .replace(' hours', 'hrs')}
        </Text>
      </div>
    </div>
  );
};

export default NotificationData;
