/**
 * @function RightPanelPage1
 * @param {number} goToNextPage
 * @returns It will show the first page of register page.
 */

import React from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import { Button } from '@mui/material';
import classNames from 'classnames';
import SignupStyling from '../../signUp.Module.scss';
import { Resources } from '../../../../config/Resources';
import Headings from '../../../Shared/Headings';
import { API_URLS } from '../../../../config/API_URLS/api_urls';
const RightPanelPage1 = (props) => {

  return (
    <RightPanelLayout
      heading="Register"
      desc="Use your social media account to register in just a few seconds."
      goToNextPage={props.goToNextPage}
      showContinueBtn={false}
      showBackBtn={false}
    >
      <div>
        <Button
          variant="contained"
          color="secondary"
          href={`${API_URLS.baseURL}${API_URLS.Facebook}`}
          // endIcon={<FacebookIcon />}
          className={classNames('w-100 py-4 mb-2', SignupStyling.signUpBtns)}
        >
          <img src={Resources.images.FacebookTextIcon} alt="logo" />
        </Button>

        <Button
          variant="contained"
          color="secondary"
          href={`${API_URLS.baseURL}${API_URLS.Google}`}
          className={classNames('w-100 py-4 mb-2', SignupStyling.signUpBtns)}
        >
          <img src={Resources.images.GoogleTextIcon} alt="logo" />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          href={`${API_URLS.baseURL}${API_URLS.LinkedIn}`}
          className={classNames('w-100 py-4 mb-2', SignupStyling.signUpBtns)}
        >
          <img src={Resources.images.LinkedinTextIcon} alt="logo" />
        </Button>
        <Headings classToOverride={`fw-bold text-center my-4`} headingType="h4">
          Or
        </Headings>
        {/* < className={`fw-bold h5 text-center my-5`}>Or</div> */}
        <Button
          variant="contained"
          color="primary"
          className={classNames(
            'w-100 py-4 mb-2 fw-bold',
            SignupStyling.signUpBtns,
            SignupStyling.backgroundColorBtn
          )}
          onClick={props.goToNextPage}
        >
          Register Manually
        </Button>
      </div>
    </RightPanelLayout>
  );
};
export default RightPanelPage1;
