import React from 'react';
import c from '../landing.module.scss';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import { Resources } from '../../../config/Resources';
import { useHistory } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const Activate = () => {
  const history = useHistory();
  return (
    <div className={classNames('col-lg-12 col-md-12')}>
      <div
        className={classNames(
          c.Activate,
          'p-3 d-flex align-items-center justify-content-end'
        )}
      >
        <Button
          className={classNames(c.ButtonActivate, 'px-5')}
          variant="contained"
          onClick={() => {
            history.push('/signUp');
            googleAnalyticsTriggerFn(
              'Button',
              'Activate',
              'Activate button clicked on landing page'
            );
          }}
        >
          <img
            src={Resources.images.LandingPageCourseIcon}
            className={classNames(c.LandingPageCourseIcon, 'me-2')}
            alt="cover"
          />
          Activate
        </Button>
      </div>
    </div>
  );
};
export default Activate;
