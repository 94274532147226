import React from 'react';
import Text from '../../../../../Shared/Text';
import c from '../../../../Profile.Module.scss';
import classNames from 'classnames';
import moment, { monthsShort } from 'moment';
import { Star } from '@mui/icons-material';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ScheduleContainer = ({ date, data = [], addCapability = false }) => {
  return (
    <>
      {/* ---------------data mapping------------ */}
      {data &&
        data.map((item, index) => (
          <div
            className={classNames(
              'w-100 mt-2 rounded-2 p-2 d-flex justify-content-between'
            )}
            style={{
              background: '#f2fafa',
              color: '#00A89A',
              borderRadius: '5px',
              minHeight: '108px',
            }}
            key={item.title + index} //needs to be changed
          >
            <div>
              <Text
                textType="t2"
                classToOverride={classNames(c.fontWeight600, 'mb-1 mt-1')}
              >
                <Star style={{ fontSize: 'small' }} />
                &nbsp; {item?.title}
              </Text>
              <Text textType="t3" classToOverride={classNames('mb-1')}>
                {date?.getDate()}
                {moment(new Date()).format('/MM/yyyy')}
                <span> | </span>
                {moment(new Date(item?.start_at)).format('hh:mm A')} -{' '}
                {moment(new Date(item?.end_at)).format('hh:mm A')}
              </Text>
              <Text textType="t3" classToOverride={classNames('mb-1 fw-bold')}>
                {item?.url || 'URL'}
              </Text>
              <Text textType="t3" classToOverride={classNames('mb-1 fw-bold')}>
                {item?.title || 'Capability'}
              </Text>
            </div>
            <div className="me-2">
              <EditIcon sx={{ fontSize: 'medium', marginRight: '8px' }} />
              <MoreHorizIcon />
            </div>
          </div>
        ))}
    </>
  );
};

export default ScheduleContainer;
