import React from 'react';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import classNames from 'classnames';
import c from '../../../learningPlans.Module.scss';
import CardContent from '@mui/material/CardContent';
import { Grid, IconButton } from '@mui/material';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import Text from '../../../../Shared/Text';
import { setDisplayAddingPlanForm } from '../../../../../redux/Actions/LearningPlans';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

const ModuleCard = ({
  handleModulePlanClick,
  displayLayout,
  id,
  title,
  showEllipsisIcon,
  showAddModuleForm,
  ...props
}) => {
  const dispatch = useDispatch();

  const onModuleClick = () => {
    handleModulePlanClick(id);
    displayLayout(true);
  };

  return (
    <Card className={classNames('mt-1', c.moduleCard)}>
      <CardContent
        className={classNames(
          'h-100 p-0 d-flex align-items-center border-start border-5',
          c.border
        )}
      >
        <Grid item xs={1} className="text-center">
          <ViewAgendaOutlinedIcon
            className={classNames(c.font_16, c.viewAgendaIcon)}
          />
        </Grid>

        <Grid item xs={9}>
          <Text
            textType="t0"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            {title}
          </Text>
          {props?.children && (
            <Text textType="t1" classToOverride={c.fontWeight500}>
              {props?.children}
            </Text>
          )}
        </Grid>

        <Grid item xs className="text-center">
          <IconButton
            onClick={() =>
              !showEllipsisIcon
                ? dispatch(
                    setDisplayAddingPlanForm(
                      showAddModuleForm
                        ? { moduleAddition: true }
                        : { customizedContentAddition: true }
                    )
                  )
                : onModuleClick()
            }
          >
            {!showEllipsisIcon ? (
              <AddCircleOutlineIcon className={c.primaryColor} />
            ) : (
              <MoreHorizSharpIcon className={c.primaryColor} />
            )}
          </IconButton>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ModuleCard;
