import React, { useEffect } from 'react';
import classNames from 'classnames';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
// import ContactFormat from '../ContentFormat';
import ContentUsage from '../ContentUsage';
import BrowsingBar from '../BrowsingBar';
// import { useSelector } from 'react-redux';
import { getSessions } from '../../../../helpers/ExtensionHandler';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const LearningBehaviorRightPanle = ({ browsingData }) => {
  // const { sessionReport } = useSelector((state) => state);
  useEffect(() => {
    getSessions(true);
  }, []);

  return (
    <div
      className={classNames(
        LearningBehaviorCss.lBehaviorRightContainer,
        'col-md-6'
      )}
    >
      <div className="px-3 py-2">
        <Headings
          headingType="h6"
          classToOverride={classNames(
            LearningBehaviorCss.textColor,
            LearningBehaviorCss.font_bold_600
          )}
        >
          Browsing
        </Headings>

        <div className="px-4">
          <div className="d-inline-block">
            <Text
              textType="t1"
              classToOverride={'fw-bold'}
              // text={`${
              //   !isNaN(browsingData?.total_duration)
              //     ? Math.floor(browsingData?.total_duration / 60)
              //     : 0
              // } hrs ${
              //   !isNaN(browsingData?.total_duration)
              //     ? browsingData?.total_duration % 60
              //     : 0
              // } mins`}
              text={moment
                .duration(browsingData?.total_duration, 'seconds')
                ?.format('HH:mm:ss', { trim: false })}
            />
            <Text
              textType="t3"
              classToOverride={'text-center'}
              text="Session Time"
            />
          </div>
          <BrowsingBar
            distractionPrct={browsingData?.distraction_prcnt}
            focusPrct={browsingData?.focus_prcnt}
            neutralPrct={browsingData?.neutral_prcnt}
          />
          {/* <ContactFormat /> */}
          <div className="mt-5">
            <ContentUsage pages={browsingData?.visited_pages} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LearningBehaviorRightPanle;
