import { useEffect, useState } from 'react';
import { fetchCountry } from '../../../../../../../helpers/My Account';
import CustomSelect from '../CustomSelect';
const Country = ({ formik, items }) => {
  const [country, setCountry] = useState([]);
  const getCountry = async () => {
    const response = await fetchCountry();
    setCountry(response);
  };

  useEffect(()=>{
      getCountry();
  },[])
  return <CustomSelect formik={formik} items={items} data={country} text="Select Country"/>;
};
export default Country;
