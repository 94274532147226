export const columns = [
  { id: 'planName', label: 'Plan Name', minWidth: 190 },
  { id: 'content', label: '# of content', minWidth: 190 },
  {
    id: 'users',
    label: '# of users',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'capability',
    label: 'Capability',

    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];
