/**
 * @function ManagerDashBoardPage
 * All the components of ManagerDashboard Page are rendered here.
 */
import React, { useEffect, useState } from 'react';
import ManagerDashboardStyling from './managerDashboard.module.scss';
import classNames from 'classnames';
import WelcomePanal from '../../components/ManagerDashBoard/WelcomePanal';
import ActionableInsights from '../../components/Home/ActionableInsights';
import ManagerQuickStats from '../../components/ManagerDashBoard/ManagerQuickStats';
import PlanPerformanceTabs from '../../helpers/ManagerDashboard/LearningPlanPerformance/PlanPerformanceTabs';
import PlanPerformance from '../../components/ManagerDashBoard/PlanPerformance';
import LearningPlan from '../../components/ManagerDashBoard/LearningPlan';
import Employees from '../../components/ManagerDashBoard/Employees';
import Community from '../../components/Home/community/Community/Community';
import { TrendingCoursesData } from '../../helpers/Home/Community';
import { TopLearnersDataManager } from '../../helpers/ManagerDashboard/Community';
import LicenseDistribution from '../../components/ManagerDashBoard/LicenseDistribution';
import AverageLearningVolume from '../../components/ManagerDashBoard/AverageLearningVolume';
import LearningPlanSection from '../../components/ManagerDashBoard/LearningPlanSection';
import ExpertiseJourney2 from '../../components/Home/ExpertiseJourney2.0';
import { useSelector } from 'react-redux';
import CapabilitiesTab from '../../components/Home/CapabilitiesTab';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export function ManagerDashboard() {
  const [tabIndex, setTabIndex] = useState(0);
  const { search: querySearchParams } = useLocation();
  const searchParams = new URLSearchParams(querySearchParams);

  useEffect(() => {
    if (searchParams.get('setIndex') === '2') {
      handleTabChange();
    }
  }, [searchParams.get('setIndex')]);

  const handleTabChange = () => {
    setTabIndex(Number(searchParams.get('setIndex')));
  };

  const user_ext_id = useSelector(
    (state) => state?.generalInformation?.external_id
  );
  const corp_id = useSelector((state) => state?.generalInformation?.client_id);
  return (
    <div
      className={classNames(ManagerDashboardStyling.ManegerDashbordContainer)}
    >
      <div className="row g-0">
        <WelcomePanal />
      </div>

      <div className="row mt-3 g-0">
        <PlanPerformanceTabs index={tabIndex} setTabIndex={setTabIndex} />
      </div>

      {/* Import and render your components here */}
      {tabIndex === 0 && (
        <div className={classNames('row ')}>
          <div className="row mt-4 g-0">
            <div className="col-6">
              <LicenseDistribution />
            </div>
            <div className="col-6">
              <AverageLearningVolume corpId={corp_id} />
            </div>
          </div>
          {/* <div className="row mt-4 g-0">
            <LearningPlanSection />
          </div> */}
          {/* <div className="row mt-4 g-0">
            <ActionableInsights  showSelect={false}/>
          </div> */}
          <div className="row g-0">
            <Community corpID={corp_id} />
          </div>

          <div className="row mt-4 g-0">
            <ExpertiseJourney2
              external_id={corp_id}
              entity={'corp'}
              setTabIndex={setTabIndex}
            />
          </div>
          {/* <div className="row mt-4 g-0">
            <ManagerQuickStats />
          </div> */}
          <div className="row mt-4 g-0">
            <Employees corpId={corp_id} />
          </div>
        </div>
      )}
      {tabIndex === 1 && (
        <>
          <div>
            <PlanPerformance />
            <LearningPlan />
          </div>
        </>
      )}
      {tabIndex === 2 && (
        <>
          <CapabilitiesTab setTabIndex={setTabIndex} />
        </>
      )}
    </div>
  );
}
