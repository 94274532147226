/**
 * @function RightPanelPage6
 * @param {number} goToNextPage
 * @returns It will show the sixth page of register page.
 */

import React, { useEffect, useState } from "react";
import RightPanelLayout from "../../RightPanelLayout";
import Button from "@mui/material/Button";
import { Resources } from "../../../../config/Resources";
import classNames from "classnames";
import signupStyling from "../../signUp.Module.scss";
import { openExtension ,isExtensionInstalled} from "../../../../helpers/signup/ExtensionHandler";

const RightPanelPage6 = (props) => {
  const [IsExtensionSuccessFull, setIsExtensionSuccessFull] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsExtensionSuccessFull(isExtensionInstalled)
    }, 3000);
  }, []);
  return (
    <>
      {IsExtensionSuccessFull ? (
        <RightPanelLayout
          heading="Installation Success!"
          desc="You have successfully installed the Ahura Chrome extension.  "
          goToNextPage={props.goToNextPage}
        >
          <div
            className={classNames(signupStyling.extensionImages, "text-center")}
          >
            <img src={Resources.images.SignupSuccessGraphic} alt="logo" />
          </div>
        </RightPanelLayout>
      ) : (
        <RightPanelLayout
          heading="Install Ahura Extension "
          desc="The Chrome store has opened in a new browser tab.  Install the Ahura extension to continue."
          goToNextPage={props.goToNextPage}
          showContinueBtn={false}
          showSkipBtn={true}

        >
          <div className={`text-center`}>
            <Button onClick={openExtension} variant="outlined" className={classNames(`fw-bold px-5 rounded-pill`, signupStyling.ExtensionColor)}>
              Click here to relaunch Chrome Store
            </Button>
          </div>
          <div
            className={classNames(
              `text-center mt-2`,
              signupStyling.extensionImages
            )}
          >
            <img src={Resources.images.SignupCorporate} alt="logo" />
          </div>
          <Button
            disabled
            variant="contained"
            color="secondary"
            className={classNames(
              "w-100 py-4 fw-bold",
              signupStyling.signUpBtns,
              signupStyling.buttonMargin,
              signupStyling.backgroundBtnDisable
            )}
            onClick={props.goToNextPage}
          >
            <p className="mt-3">WAITING FOR AHURA EXTENSION....</p>
          </Button>
        </RightPanelLayout>
      )}
    </>
  );
};
export default RightPanelPage6;
