import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTargetDetails } from '../../../helpers/Profile/Targets';
import Headings from '../../Shared/Headings';
import c from '../home.Module.scss';
import classNames from 'classnames';
import CustomSelect from '../../Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  menuItems,
  BarOptions,
  CapBarData,
  LineOptions,
  LineData,
  HourBarData,
  getHighlightsData,
} from '../../../helpers/Home/Highlights2.0';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import HighlightsDetailBox from './HiglightsDetailBox';
import { Skeleton } from '@mui/material';
import Text from '../../Shared/Text';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const Highlights2 = ({ userData }) => {
  const dispatch = useDispatch();
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('all');
  // const [targetHours, setTargetHours] = useState(0);
  // const userData = useSelector((state) => state?.generalInformation);
  const [highlightsData, setHighlightsData] = useState({});
  const [isLoading, setIsLoading] = useState();
  const sessionsInformation = useSelector((state) => state?.sessionData) || [];
  const userCorpDetail = useSelector((state) => state?.userCorporateDetails);
  console.log("User Data", userData)

  // const [capabilityBarData, setCapabilityBarData] = useState([]);
  // useEffect(() => {
  //   if (userData?.id) {
  //     fetchTargetHours();
  //   }
  //   // eslint-disable-next-line
  // }, [userData]);
  

  useEffect(() => {
    async function fetchUserData() {
      if (userData?.id && userData?.external_id) {
        setIsLoading(true);
        const res = await getHighlightsData(
          selectedDropdownItem,
          userData?.id,
          userData?.external_id
        );
        console.log("Highlight Data", res?.data)
        setHighlightsData(res?.data);
        setIsLoading(false);
      }
    }
    fetchUserData();
  }, [
    selectedDropdownItem,
    userData?.id,
    userData?.external_id,
    sessionsInformation,
    userCorpDetail,
  ]);

  // const fetchTargetHours = async () => {
  //   const { data } = await fetchTargetDetails(userData?.id);
  //   if (data?.content?.targetHoursAWeek) {
  //     setTargetHours(data?.content?.targetHoursAWeek);
  //   }
  // };

  const getPercentage = (prev, curr) => {
    if (Number(prev) === 0) {
      if (selectedDropdownItem === 'all') return 100;
      return 0;
    }
    const per = ((curr - prev) / prev) * 100;
    return per.toPrecision(3);
  };

  return (
    <div className={classNames(c.highlights)}>
      <Headings headingType="h4" classToOverride={classNames(c.fontWeight600)}>
        Highlights
      </Headings>
      <div className="row justify-content-end mt-2">
        <div className="col-lg-2">
          <CustomSelect
            label="Sort By"
            dropdownIcon={FilterAltIcon}
            value={selectedDropdownItem}
            onChange={(e) => {
              setSelectedDropdownItem(e?.target?.value);
              googleAnalyticsTriggerFn(
                'Filter',
                'Highlights Sort Filter',
                'Highlights Sort Filter changed '
              );
            }}
            menuItems={menuItems}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-4 mt-md-4">
          <div className={classNames(c.chartBox, 'col-12')}>
            {isLoading ? (
              <>
                <Skeleton variant="rectangle" width={'100%'} height={190} />
              </>
            ) : (
              <Bar
                options={BarOptions}
                data={CapBarData(
                  highlightsData?.capability?.weekly_data || [],
                  highlightsData?.capability?.capability_target
                )}
              />
            )}
          </div>
          <div className="col-12 mt-4">
            <HighlightsDetailBox
              className={c.capabilities}
              heading="# of Unique Capabilities"
              highlightedData={highlightsData?.capability?.user_data?.current}
              highlightedPercentage={
                getPercentage(
                  highlightsData?.capability?.user_data?.previous,
                  highlightsData?.capability?.user_data?.current
                ) || 0
              }
              targetData={{
                data: highlightsData?.capability?.capability_target,
              }}
              companyAverageData={{
                data: highlightsData?.capability?.company_avg?.current,
                percentage:
                  getPercentage(
                    highlightsData?.capability?.company_avg?.previous,
                    highlightsData?.capability?.company_avg?.current
                  ) || 0,
              }}
            />
          </div>
        </div>
        <div className="col-lg-4 mt-md-4">
          <div className={classNames(c.chartBox, 'col-12')}>
            {isLoading ? (
              <>
                <Skeleton variant="rectangle" width={'100%'} height={190} />
              </>
            ) : (
              <Bar
                options={BarOptions}
                data={HourBarData(
                  highlightsData?.hours?.weekly_data || [],
                  highlightsData?.hours?.week_hours_target
                )}
              />
            )}
          </div>
          <div className="col-12 mt-4">
            <HighlightsDetailBox
              className={c.hours}
              heading="# of Hours"
              highlightedData={highlightsData?.hours?.user_data?.current}
              highlightedPercentage={
                getPercentage(
                  highlightsData?.hours?.user_data?.previous,
                  highlightsData?.hours?.user_data?.current
                ) || 0
              }
              targetData={{
                data: highlightsData?.hours?.hours_target,
              }}
              companyAverageData={{
                data: highlightsData?.hours?.company_avg?.current,
                percentage:
                  getPercentage(
                    highlightsData?.hours?.company_avg?.previous,
                    highlightsData?.hours?.company_avg?.current
                  ) || 0,
              }}
            />
          </div>
        </div>
        <div className="col-lg-4 mt-md-4">
          <div className={classNames('position-relative w-100')}>
            <div className={classNames(c.ComingSoon)}>
              <Text textType="t16" classToOverride={classNames('text-center')}>
                Coming Soon...
              </Text>
            </div>
            <div className={classNames(c.ComingSoonContainer)}>
              <div className={classNames(c.chartBox, 'col-12')}>
                {isLoading ? (
                  <>
                    <Skeleton variant="rectangle" width={'100%'} height={190} />
                  </>
                ) : (
                  <Line options={LineOptions} data={LineData} />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <HighlightsDetailBox
              className={c.focus}
              heading="Average Focus"
              highlightedData="0"
              highlightedPercentage="0"
              targetData={{ data: 0, percentage: 0 }}
              companyAverageData={{ data: 0, percentage: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights2;
