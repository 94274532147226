import React from 'react';
import classNames from 'classnames';
import c from '../../home.Module.scss';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import { cardIconsInfo } from '../../../../helpers/Home/CapabilitiesToStudy';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';

const OrgainizationAssignedCapabiliityCard = ({
  index,
  cardId,
  cardHead,
  cardSubHeading,
  targetOutcome,
  assigneerName,
  dueDate,
  timeSpent,
  handleClick,
  ...props
}) => {
  const history = useHistory();

  return (
    <div
      className={classNames(
        'py-3 px-4',
        c.capabilityCard,
        c[`cardMode${index}`]
      )}
    >
      <Headings headingType="h4" classToOverride="pb-1 fw-bold">
        {cardHead}
      </Headings>
      <Text
        textType="t2"
        classToOverride={classNames(
          c.fontWeight500,
          c.cardTextProp,
          c.cardDescDimension
        )}
      >
        {/* {cardSubHeading} */}
      </Text>
      <div className="row g-0 pt-2 align-items-end">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <Text textType="t1" classToOverride={c.fontWeight500}>
              <span>Due date : </span>
              <span className={c.cardTextProp}>{dueDate}</span>
            </Text>
            {/* <Text textType="t1" classToOverride={c.fontWeight500}>
              <span>Assigner Name : </span>
              <span className={c.cardTextProp}>{assigneerName}</span>
            </Text> */}
            <Text textType="t1" classToOverride={c.fontWeight500}>
              <span>Target Outcome : </span>
              <span className={c.cardTextProp}>{targetOutcome}</span>
            </Text>
            <Text textType="t1" classToOverride={c.fontWeight500}>
              <span>Active Time : </span>
              <span className={c.cardTextProp}>{timeSpent}</span>
            </Text>
          </div>
          <div className="d-flex justify-content-end">
            {cardIconsInfo.map((item) => (
              <div
                key={item?.id}
                className="d-flex flex-column align-items-center"
              >
                <IconButton
                  color="inherit"
                  onClick={(event) =>
                    item?.id === 'completeIcon'
                      ? handleClick(event?.target?.id)
                      : history.push({
                          pathname: '/content',
                        })
                  }
                >
                  <SvgIcon
                    id={cardId}
                    component={item?.iconName}
                    className={classNames(c.cardIconButton, 'rounded-circle')}
                  />
                </IconButton>
                <Text textType="t3">{item?.downLabel}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgainizationAssignedCapabiliityCard;
