/**
 * @module Loading
 * @param none
 * this Component Displays the Loading screen  that will occure after a process completion
 *
 */

import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Resources } from '../../../config/Resources';
import c from '../signUp.module.scss';
const CompletionLoading = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/home');
    }, 2000);
  });

  return (
    <div className={classNames(c.signupContainer, 'row g-0')}>
      <div className={classNames(c.loading, 'text-center')}>
        <h2 className="fw-bold"> Launching Ahura Learning! </h2>
        <p>Give us just a few seconds to initiate your learning dashboard</p>
        <div className=" mt-3 text-center">
          <img src={Resources.Videos.FinalLoading} alt="loading..." />
        </div>
      </div>
    </div>
  );
};

export default CompletionLoading;
