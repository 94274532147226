import React, { useEffect, useState } from 'react';
import Highlights2 from '../../components/Home/Highlights_2.0';
import ExpertiseJourney2 from '../../components/Home/ExpertiseJourney2.0';
import CapabilitiesToStudy from '../../components/Home/CapabilitiesToStudy';
import Sessions from '../../components/Home/Sessions';
import { useParams } from 'react-router-dom';
import { getUserInfo } from '../../helpers/Common';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../config/Constants';
import ViewingProfile from '../../components/EmployeeDetails/ViewingProfie';
import Nudges from '../../components/Shared/Nudges';
import { getLearningData } from '../../helpers/Learning';

const EmployeeDetailsPage = () => {
  const params = useParams();
  const category_id = useSelector(
    (state) => state?.generalInformation?.category_id
  );
  const [employeeDetail, setEmployeeDetail] = useState({});
  useEffect(() => {
    if (params.id) getEmployeeDetails(params?.id);
  }, [params]);

  const [nudgesData, setNudgesData] = useState();

  const getEmployeeDetails = async (id) => {
    const res = await getUserInfo(id);
    setEmployeeDetail(res);
  };

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (employeeDetail?.external_id) {
      const data = await getLearningData(employeeDetail.external_id);
      setNudgesData(data?.session_nudges);
    }
  }, [employeeDetail]);


  return (
    <div className="">
      <div className="">
        {/* <WelcomeSection /> */}
        <ViewingProfile
          profile_image_url={employeeDetail?.profile_image_url}
          user_name={`${employeeDetail?.first_name}  ${employeeDetail?.last_name}`}
        />
      </div>
      <div className="mt-3">
        <Highlights2 userData={employeeDetail} />
      </div>
      <div className="mt-5">
        {employeeDetail?.external_id && (
          <ExpertiseJourney2
            external_id={employeeDetail?.external_id}
            entity="user"
          />
        )}
      </div>
      <div className="mt-5">
        <CapabilitiesToStudy employeeID={params?.id}  showAddLearningPlanBtn={true} />
      </div>
      <div className="row mt-4 g-0">
        <Nudges nudgesData={nudgesData} />
      </div>
      <div className="mt-5">
        {employeeDetail?.external_id && (
          <Sessions
            extId={employeeDetail?.external_id}
            employee_full_name={`${employeeDetail?.first_name}  ${employeeDetail?.last_name}`}
            // isManager={true}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeDetailsPage;
