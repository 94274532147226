import store from '../../../redux/store';
import { CLIENT_ID } from '../../../config/Constants';
import { getApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';

export const fetchLearningPlans = async () => {
  try {
    const { id } = store.getState()?.generalInformation;
    const paramsData = {
      queryParams: {
        created_by: id,
        corporate_id: CLIENT_ID,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URLS?.learning_plans, paramsData);
    return response;
  } catch (error) {
    console.log(`Error in fetching Learning Plans ${error}`);
  }
};
