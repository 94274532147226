import Headings from '../../Headings';
import classNames from 'classnames';
import c from '../../shared.Module.scss';
import { extractDomain } from '../../../../helpers/Home/Sessions';
import moment from 'moment';
import { useEffect } from 'react';

const TopDistractions = ({ stats }) => {
  // const [sortedUrls, setsortedUrls] = useState([]);
  const hostReducer = () => {
    const hostObj = stats?.visited_pages?.reduce((accumulator, currentItem) => {
      // const url = new URL(currentItem.url); // Parse the URL to get the hostname
      const hostname = extractDomain(currentItem.url);

      if (
        currentItem.is_relevant_algo == '0' &&
        currentItem.is_transitional_algo == '0'
      ) {
        if (!accumulator[hostname]) {
          accumulator[hostname] = { ...currentItem, page_duration: 0 }; //new url entry
        }

        accumulator[hostname].page_duration += currentItem.page_duration; //already existing
      }
      // console.log('accumulator', accumulator, 'index');
      return accumulator;
    }, {});
    const hostArray = Object.values(hostObj);
    // converts obj of ojb's into array of objects
    const sortHostedArray = hostArray.sort(
      (a, b) => b.page_duration - a.page_duration
    );

    return sortHostedArray.slice(0, 3);
  };

  return (
    <>
      <div className={classNames('rounded-3 m-2 p-3', c.firstBox3)}>
        <div>
          <Headings classToOverride={c.textHeading}>Top Distractions</Headings>
        </div>
        <div className="d-flex justify-content-between ">
          <Headings classToOverride={c.contentHeading}>Content URL</Headings>
          <Headings classToOverride={c.contentHeading}>Avg Time</Headings>
        </div>
        {stats?.visited_pages &&
          // ?.reduce((acc, item) => {
          //   if (!item.is_transitional_algo && !item.is_relevant_algo) {
          //     const existingIndex = acc.findIndex(
          //       (el) => extractDomain(el.url) === extractDomain(item.url)
          //     );
          //     console.log(item.unformatedTime, 'index');
          //     if (existingIndex !== -1) {
          //       // URL already exists in the accumulator, add the duration
          //       acc[existingIndex].unformatedTime += item.unformatedTime;
          //       console.log('existing index', existingIndex);
          //     } else {
          //       // URL does not exist in the accumulator, add a new entry
          //       acc.push({ url: item.url, duration: item.unformatedTime });
          //     }
          //   }
          //   console.log('accumulator', acc, 'index');
          //   return acc;
          // }, [])
          hostReducer().map((item, index) => {
            // console.log(item, index, 'line no 50');
            return !item.is_transitional_algo && !item.is_relevant_algo ? ( // is_transitional_algo: 0 and is_relevant_algo: 0
              <div className="d-flex justify-content-between" key={index}>
                <Headings
                  classToOverride={classNames('text-break', c.textContent)}
                >
                  {extractDomain(item.url)}
                </Headings>
                <Headings classToOverride={c.textContent}>
                  {moment
                    .duration(item.page_duration, 'seconds')
                    ?.format('HH:mm:ss', { trim: false })}
                </Headings>
              </div>
            ) : (
              <></>
            );
          })}
      </div>
    </>
  );
};

export default TopDistractions;
