/**
 * @function RightPanelPage8
 * @param {number} goToNextPage
 * @returns It will show the eight page of register page.
 */

import React from "react";
import RightPanelLayout from "../../RightPanelLayout";
import classNames from "classnames";
import SignupStyling from "../../signUp.Module.scss";

import {signUpStepYtVideoembedId} from '../../../../config/Constants'
import YoutubeEmbed from '../AccessGranted/youtubePlayer.js'

const RightPanelPage8 = (props) => {

  return (<RightPanelLayout
    heading="Access Pass Granted!"
    desc={`Watch this 2-minutes video to understand how to learn with an AI learning coach`}
    goToNextPage={props.goToNextPage}
    showContinueBtn={true}
    showSkipBtn = {false}
    showBackBtn={false}
  >
    <div>
      <div className={classNames(SignupStyling.extensionImages, "text-center")}>
        <YoutubeEmbed title="Ahura AI: Study Session Overview" embedId={signUpStepYtVideoembedId} width="600" height="350" />
      </div>
    </div>
  </RightPanelLayout>
  );
};
export default RightPanelPage8;