/**
 * @function MainMenu
 *
 */

import React from 'react';
import { useHistory } from 'react-router';
import c from '../../../home.Module.scss';
import { mainMenuItems } from '../../../../../helpers/Home/CreateAccount';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemText from '@mui/material/ListItemText';
import Headings from '../../../../Shared/Headings';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch } from 'react-redux';
import { onLogOut } from '../../../../../redux/Actions/UserLoggedOut';
import { chromeWebStoreLink } from '../../../../../config/Constants';
import ExtensionIcon from '@mui/icons-material/Extension';
import JoinCorporateNew from '../../../../Shared/JoinCorporateNew';
import ChangeExtension from '../../../../Shared/ChangeExtension';
import { getApiCall } from '../../../../../utils/axios/axios';
import { useSelector } from 'react-redux';
import { API_URLS } from '../../../../../config/API_URLS/api_urls';

const MainMenu = ({ goToMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showJoinCom, setJoinComp] = React.useState(false);
  const [showChangeExtension, setShowChangeExtension] = React.useState(false);
  const [neighbourhoodData, setNeighbourhoodData] = React.useState('');
  const { generalInformation } = useSelector((state) => state);

  // const insert = (arr, index, ...newItems) => [
  //   ...arr.slice(0, index),
  //   ...newItems,
  //   ...arr.slice(index),
  // ];

  const getJoinedNeighborhoodData = async () => {
    try {
      const paramsData = {
        queryParams: {
          user_id: generalInformation.user_id,
          platform_id: generalInformation.platform_id,
        },
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URLS.getJoinedNeighborhood, paramsData);
      setNeighbourhoodData(res);
      return res;
    } catch (err) {}
  };

  // const getMenuData = () => {
  //   if (neighbourhoodData.length === 0) {
  //     return insert(mainMenuItems, 3, {
  //       id: 'joinOrganization',
  //       name: 'Join Organization',
  //       redirectTo: '#',
  //     });
  //   } else {
  //     return mainMenuItems;
  //   }
  // };

  React.useEffect(() => {
    getJoinedNeighborhoodData();
  }, []);

  const onMenuItemClick = (id, link) => {
    if (id === 'viewProfileItem') {
      goToMenu(true);
    }
    if (id === 'extensionItem') {
      window.open(chromeWebStoreLink);
    }
    if (id === 'changeExtension') {
      setShowChangeExtension(true);
    }
    if (id === 'logOutItem') {
      localStorage.clear();
      dispatch(onLogOut());
    } else if (id === 'joinOrganization') {
      if (showJoinCom == false) {
        setJoinComp(true);
      } else {
        return;
      }
    }

    history.push(link);
  };

  return (
    <>
      <MenuList>
        {mainMenuItems.map((item) => {
          if (
            (item.id === 'changeExtension' &&
              process.env.REACT_APP_ENV !== 'staging' && process.env.REACT_APP_ENV !== 'development')
          ) {
            return;
          }
          return (
            <MenuItem
              onClick={() => onMenuItemClick(item.id, item.redirectTo)}
              key={item.id}
            >
              {item.id === 'logOutItem' && (
                <ListItemIcon className={c.menuIcon}>
                  <LogoutIcon />
                </ListItemIcon>
              )}
              {item.id === 'extensionItem' && (
                <ListItemIcon className={c.menuIcon}>
                  <ExtensionIcon />
                </ListItemIcon>
              )}

              <ListItemText
                inset={item.id !== 'logOutItem' && item.id !== 'extensionItem'}
              >
                <Headings headingType="h6" text={item.name} />
              </ListItemText>

              {item.id === 'joinOrganization' && (
                <>
                  <ListItemIcon
                    className={c.menuIcon}
                    disabled={true}
                  ></ListItemIcon>
                </>
              )}

              {item.id === 'viewProfileItem' && (
                <ListItemIcon className={c.menuIcon}>
                  <KeyboardArrowRightIcon className="ms-3" />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        })}
      </MenuList>

      {showJoinCom && (
        <JoinCorporateNew
          setJoinComp={setJoinComp}
          showJoinCom={showJoinCom}
          getJoinedNeighborhoodData={getJoinedNeighborhoodData}
        />
      )}

      {showChangeExtension && (
        <ChangeExtension
          setShowChangeExtension={setShowChangeExtension}
          showChangeExtension={showChangeExtension}
        />
      )}
    </>
  );
};

export default MainMenu;
