/**
 * @function FacialExpressions
 */
import classNames from 'classnames';
import React from 'react';
import c from './FaceExpression.module.scss';
import { Resources } from '../../../../config/Resources';
import { Button } from '@mui/material';
import Text from '../../../Shared/Text';

export const FacialExpressions = ({ FaceData, index }) => {
  return (
    <div
      className={classNames(
        c.faceCalibrationContainer,
        'bd-highlight ms-4 mt-2 mb-3'
      )}
    >
      <div className={classNames(c.CalibrationData,"p-1 pl-0 bd-highlight")}>
        <img
          src={FaceData.Thumbnail}
          alt="FaceMovement"
          className={classNames(c.faceDataWrapper, 'w-100 h-100')}
        />
        <Text classToOverride={classNames(c.calibrationText)} type="text0">{FaceData.describe}</Text>
      </div>
      <Button
        className={classNames(c.RecalibrateButton, 'mt-4')}
        variant="contained"
      >
        Recalibrate
      </Button>

      {/* <div className="p-1 bd-highlight">
            {' '}
            <img
              src={Resources.images.home.FaceMovement}
              alt="FaceMovement"
              className="w-100 h-100"
            />
          </div>
          <div className="p-1 bd-highlight">
            {' '}
            <img
              src={Resources.images.home.FaceMovement}
              alt="FaceMovement"
              className="w-100 h-100"
            />
          </div> */}
    </div>
  );
};
