import {
  EMAIL_REGEX_PATTERN,
  TEXT_FIELD_REGEX_PATTERN,
  PHONE_NUMBER_REGEX_PATTERN,
} from '../../config/Regex/regex';
import * as Yup from 'yup';
import { dataURLtoFile, validatePassword } from '../../utils/utility';
import { CLIENT_ID } from '../../config/Constants';
import { getApiCall, postApiCall, putApiCall } from '../../utils/axios/axios';
import {
  API_URLS,
  getJoinedNeighborhoodURL,
} from '../../config/API_URLS/api_urls';
import axios from 'axios';
// import { response } from 'express';

export const initialValuesforCreateAccountBox = {
  accountType: 'Coorporate Account',
  capacity: 50,
};

export const initialValuesForCorporateDetails = {
  name: '',
  email: '',
  role: 'teacher',
  password: '',
  confirm_password: '',
  phone: '',
  website: '',
  country: 'select',
  state: 'select',
  add_location: '',
  suite: '',
  city: '',
  zip_code: '',
};

Yup.addMethod(Yup.string, 'validatePassword', validatePassword);

export const validateSchemaForCorpDetails = Yup.object().shape({
  name: Yup.string()
    .matches(TEXT_FIELD_REGEX_PATTERN, 'Name is invalid')
    .required('Name is required'),

  email: Yup.string().email('Invalid email').required('Email is required'),

  password: Yup.string()
    .required('Password is required')
    .validatePassword('New Password'),

  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .validatePassword('Confirm Password')
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

  website: Yup.string().required('Website is required'),

  country: Yup.string().test('', 'Please select country', (value) => {
    return !/select/.test(value);
  }),

  state: Yup.string().test('', 'Please select state', (value) => {
    return !/select/.test(value);
  }),

  add_location: Yup.string().required('Add location is required'),

  suite: Yup.string().required('Suite is required'),

  city: Yup.string().required('City is required'),

  zip_code: Yup.string().required('Zip code is required'),
});

export const createCorporateAccount = async (user_id, values) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const reqBody = {
      website: values?.website,
      name: values?.name,
      city: values?.city,
      email: values?.email,
      role: values?.role,
      zip_code: values?.zip_code,
      parent_id: CLIENT_ID,
      country_id: values?.country,
      suite: values?.suite,
      state_id: values?.state,
      address1: values?.add_location,
      password: values?.password,
      phone: values?.phone,
      user_id: user_id,
      source: 'web',
      is_active: false,
      imageFile: {},
    };

    const response = await postApiCall(
      API_URLS?.neighborhood_sign_up,
      reqBody,
      paramsData
    );
    return response;
  } catch (err) {
    console.log('Error in createCorporateAccount | Helpers :', err);
  }
};

export const fetchCountry = async () => {
  try {
    const paramsData = {
      queryParams: {
        limit: 300,
        order: 'name',
      },
      headers: {},
      isAuthorized: true,
    };

    const res = await getApiCall(API_URLS?.get_countries, paramsData);
    return res?.data;
  } catch (err) {
    console.log('Error in fetchCountry | Helpers :', err);
  }
};

export const fetchStates = async (country_id) => {
  try {
    const paramsData = {
      queryParams: {
        country_id: country_id,
        limit: 200,
        order: 'name',
      },
      headers: {},
      isAuthorized: true,
    };
    const res = await getApiCall(API_URLS?.get_state, paramsData);
    return res?.data;
  } catch (error) {
    console.log('Error in fetchStates | Helpers', error);
  }
};

export const resendCorporateOTP = async (email, id) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const reqBody = {
      email: email,
      client_id: id,
    };
    const response = await putApiCall(
      API_URLS?.neighborhood_resend_confirmation,
      reqBody,
      paramsData
    );
    return response;
  } catch (error) {
    console.log('Error in resendCorporateOTP | Helpers', error);
  }
};

export const submitCorporateOTP = async (email, parent_id, phone, code) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const reqBody = {
      email: email,
      client_id: parent_id,
      phone_cellular: phone,
      code: code,
    };
    const response = postApiCall(
      API_URLS?.neighborhood_verify_activation_code,
      reqBody,
      paramsData
    );
    return response;
  } catch (error) {
    console.log('Error in submitCorporateOTP | Helpers', error);
  }
};

export const uploadFile = async (signedURL, file) => {
  console.log(file);
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    };
    await axios.put(signedURL, file, options);
    return true;
  } catch (error) {
    console.log(`Error in uploading File ${error}`);
    return false;
  }
};

export const discontinueAccess = async () => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await putApiCall(
      API_URLS.deactivateMyAccount,
      { is_active: 'false' },
      paramsData
    );
    console.log('Account deactivated successfully');
    return true;
  } catch (err) {
    console.log(err);
    return false;
    // dispatch(
    //   SET_TOAST_ERROR({
    //     err: true,
    //     message: 'Something went wrong while deactivating the account',
    //   })
    // );
  }
};

export const makeMainAccountMenu = async (user_id, client_id, platform_id) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await putApiCall(
      API_URLS.makeMainAccount,
      {
        user_id,
        client_id,
        platform_id,
        main_acc: true,
      },
      paramsData
    );
    // return response.data.message;
    return response.message;
  } catch (err) {
    console.log(err);
    throw err; // Re-throw the error to handle it in the calling code
  }
};

export const leaveAccount = async (
  user_id,
  client_id,
  platform_id,
  external_id
) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await putApiCall(
      API_URLS.removeUserFromNeighborhood,
      {
        client_id,
        platform_id,
        user_id,
        external_id,
      },
      paramsData
    );
    return response;
  } catch (error) {
    console.log('Error in leaveAccount | Helpers', error);
    throw error;
  }
};

export const getMembersData = async (client_id, platform_id) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const reqBody = {
      platform_id,
      client_id,
    };
    const response = postApiCall(API_URLS.membersData, reqBody, paramsData);
    return response;
  } catch (error) {
    console.log('Error in members data', error);
    // throw error;
  }
};

export const uploadCorporateProfilePicture = async (
  croppedImage,
  corporateUserData
) => {
  try {
    const newFile = dataURLtoFile(croppedImage);
    const result = await uploadFile(corporateUserData?.imageSignedURL, newFile);
    if (result) {
      const url = corporateUserData?.imageSignedURL.substr(
        0,
        corporateUserData?.imageSignedURL.indexOf('?X-Amz-Algorithm=')
      );

      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
      const reqBody = {
        id: corporateUserData?.UserClients[0]?.id,
        image_url: url,
        logo_url: '',
        banner_image_url: '',
      };
      const response = await putApiCall(
        API_URLS?.neighborhood_client,
        reqBody,
        paramsData
      );
      return response;
    }
  } catch (error) {
    console.log('Error in submitCorporateOTP | Helpers', error);
  }
};
