/**
 * @function ActivityComponent
 * Component of Profile/Calibrations
 * @param activity activity name
 * @param description description about activity
 * @returns a row with activity,description
 */
import React from 'react';
import classNames from 'classnames';
import Text from '../../../Shared/Text/index';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
export const ActivityComponent = ({ activity, description }) => {
  return (
    <>
      <div className="row mt-2">
        <div className="col-12 ">
          <Divider />
          <div className="row align-items-center pt-2">
            <div className="col-7">
              <div className="row">
                <div className={classNames('col-3 fw-bold')}>
                  {' '}
                  <Text textType="t1">{activity}</Text>
                </div>
                <div className="col-4">
                  {' '}
                  <Text textType="t1">{description}</Text>
                </div>
              </div>
            </div>
            <div className="col-5">
                <div className="d-flex ps-4">
                  {' '}
                  <Button
                    variant="contained"
                    className={classNames('ms-auto')}
                    sx={{ bgcolor: '#00A89A', textTransform: 'none' }}
                  >
                    Start
                  </Button>
                  <Button
                    variant="outlined"
                    className={classNames("ms-2")}
                    sx={{ color: '#00A89A', textTransform: 'none' }}
                  >
                    View Result
                  </Button>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
