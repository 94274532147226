import classNames from 'classnames';
import Divider from '@mui/material/Divider';
// import { MdKeyboardArrowDown } from "react-icons/md";
import Text from '../../../shared/Text';
import leftPanelCls from './leftpanel.module.scss';

const LeftPanel = ({ ...props }) => {
  return (
    <div className={classNames(leftPanelCls.leftPanel)}>
      <div
        className={classNames(
          leftPanelCls.headerWrpper,
          'd-flex align-items-center justify-content-between'
        )}
      >
        <Text className={classNames(leftPanelCls.headerText)}>
          {props?.heading}
        </Text>
      </div>

      <Divider className={classNames(leftPanelCls.customDivider)} />

      {props.children}
    </div>
  );
};

export default LeftPanel;
