import React from 'react';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { videoTutorialsLink } from '../../../config/Constants';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import QuizIcon from '@mui/icons-material/Quiz';
import c from '../header.Module.scss';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const Help = ({ anchorEl, setAnchorEl }) => {
  return (
    <Menu
      id="helpMenu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      classes={{
        paper: classNames(c.helpMenu),
      }}
    >
      <MenuItem
        className="mt-3"
        onClick={() => window.open(videoTutorialsLink)}
      >
        <PlayArrowIcon />
        <span className="ms-2">Video Tutorials</span>
      </MenuItem>
      <MenuItem className="my-3">
        <HeadsetMicIcon />
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf_0fFlnglLEJBkxjAnS_SzaJCQ8flni7Vkg-MLDY34yickPA/viewform"
          className={classNames('ms-2 text-decoration-none', c.primaryColor)}
          target="_blank"
          rel="noreferrer"
        >
          Ahura AI Support Entry
        </a>
      </MenuItem>
      <MenuItem
        className="my-3"
        onClick={() =>
          window.open(
            'https://docs.google.com/document/d/1-HmeK57LyJ0Eb09-TLS6jdPjxyIxxdWSFj0cD_rgwSo/edit'
          )
        }
      >
        <QuestionMarkIcon />
        <span className={classNames('ms-2', c.primaryColor)}>FAQs</span>
      </MenuItem>
      <MenuItem className="my-3">
        <QuizIcon />
        <a
          href="https://drive.google.com/file/d/1ukDZbnm9nQrAscry9MAhL8Bm0xx2XIyA/view?usp=sharing"
          className={classNames('ms-2 text-decoration-none', c.primaryColor)}
          target="_blank"
          rel="noreferrer"
        >Learner challenges</a>
      </MenuItem>
    </Menu>
  );
};

export default Help;
