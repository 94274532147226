import classNames from 'classnames';
import React, { useEffect } from 'react';
import Headings from '../../components/Shared/Headings';
import c from './terms.module.scss';
import SignUpNav from '../../components/signUp/SignUpNav';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const TermsAndConditions = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  return (
    <>
      <SignUpNav />
      <div className={classNames(c.terms, 'bg-white p-5')}>
        <div>
          <Headings
            headingType="h3"
            classToOverride={classNames(c.primaryColor, 'fw-bold')}
          >
            Terms and Conditions
          </Headings>
          <Headings headingType="h6">Last Updated: Jan 2023</Headings>
        </div>

        <div className="mt-5">
          Welcome to Ahura AI! Ahura AI, Inc. (“Ahura AI”) provides our website
          located at www.ahuraai.com (the “Site”) and our education product at
          <a target="_blank" href="https://www.ahura.ai">
            {' '}
            https://ahura.ai{' '}
          </a>{' '}
          (the “Product”). To make these Terms easier to read, the Site, our
          services and product are collectively called the “Services.” Please
          read these Terms of Service (the “Terms”) carefully because they
          govern your use of our Services. In addition, please also read our
          Privacy Policy, which may be found at{' '}
          <a target="_blank" href="https://ahura.ai/privacy-policy">
            https://ahura.ai/privacy-policy{' '}
          </a>
        </div>
        <div className="mt-5">
          YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SERVICE, YOU
          ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY
          THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED WITH THE SERVICE. IF
          YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR
          USE THE SERVICE. If you accept or agree to these Terms on behalf of a
          company or other legal entity, you represent and warrant that you have
          the authority to bind that company or other legal entity to these
          Terms. The terms "you" and "your" as used herein refer to all
          individuals and/or entities accessing this Service for any reason.
        </div>
        <div className="mt-5">
          Ahura AI, Inc. ("Ahura AI") may revise these terms and conditions at
          any time by updating these Terms as they are displayed on this
          Service. We are not required to contact you prior to making any change
          to these Terms; therefore, we encourage you to visit this page
          frequently so you are aware of any changes.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-5')}>
          Use of Ahura AI and the Service Content
        </div>
        <div className="mt-5">
          Ahura AI authorizes you to view and access the Service solely for your
          personal, non-commercial use. You shall not copy or adapt the
          underlying HTML code with respect to any portion of the Service or the
          content appearing therein.
        </div>
        <div className="mt-5">
          The contents of this Service, including all text, images, graphics,
          logos, video, audio, links, button icons, software, and other content
          (collectively, "Service Content"), are protected under both United
          States and international copyright, trademark, patent, trade secret,
          and other intellectual property laws. All Service Content is the sole
          and exclusive property of Ahura AI, its content suppliers or its
          clients. The compilation (meaning the selection, collection,
          arrangement, and assembly) of all content on this Service is the
          exclusive property of Ahura AI and is also protected by applicable
          intellectual property laws. Unauthorized copying or use of the Service
          Content (including its use on any other website) may violate
          intellectual property rights and/or other laws. You may not use,
          reproduce, modify, transmit, display, publish, sell, create derivative
          works, or distribute by any means, method, or process whatsoever, now
          known or hereafter developed, any of the Service Content. You are
          prohibited from using the Service in violation of any applicable
          foreign, domestic, federal, or state laws, rules, or regulations or in
          a manner that that will infringe Ahura AI's or any third party's
          intellectual property rights or otherwise violate the privacy,
          publicity or other personal rights of others. The use of defamatory,
          threatening, hateful, obscene, or abusive language, images, or
          postings on the Ahura AI Sites are strictly prohibited.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Permitted Uses</div>
        <div className="mt-1">
          Ahura AI may be used only for lawful purposes by individuals seeking
          to use the Ahura AI service ("Service"), as described on this Site.
          Ahura AI specifically prohibits any other use of the Service. By using
          the Service you agree not to take any of the following actions: (a)
          using any device, software or routine to interfere or attempt to
          interfere with the proper working of any Ahura AI Service or any
          activity being conducted on the Service; (b) Use or attempt to use any
          engine, software, tool, agent or other device or mechanism (including,
          without limitation, customized browsers, spiders, robots, avatars or
          intelligent agents) to navigate or search the Service other than the
          search engine and software available from Ahura AI on the Service and
          other than generally available third party web browsers such as
          Microsoft Explorer is strictly prohibited; (c) attempt to decipher,
          decompile, disassemble or reverse engineer any of the software
          comprising or in any way making up a part of the Service; (d) taking
          any action which imposes an unreasonable or disproportionately large
          load on the Service's infrastructure, including, without limitation,
          denial of service attacks; and (e) aggregating, copying or duplicating
          in any manner any of the Service Content or information available from
          the Service.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Posting and Conduct Restrictions
        </div>
        <div className="mt-1">
          When you create your own personalized or corporate account, you may be
          able to provide User content ("User Content"). You are solely
          responsible for the User Content that you post, upload, link to, or
          otherwise make available on the Service. You agree that Ahura AI is
          only acting as a passive conduit for your online distribution and
          publication of your User Content. Ahura AI reserves the right to
          remove or alter any User Content from the Service at its sole
          discretion. The following rules pertain to User Content. By
          transmitting and submitting any User Content while using the Service,
          you agree that you: (i) are solely responsible for your account and
          the activity that occurs while signed in to or while using your
          account; (ii) will not post information that is malicious, false or
          inaccurate; (iii) will not submit content that is subject to third
          party proprietary rights, including privacy, publicity, trade secret,
          copyright, or trademark, unless you are the owner of such rights or
          have the appropriate permission from its rightful owner. Ahura AI does
          not, and cannot, pre-screen or monitor all User Content. However, at
          its discretion, Ahura AI may monitor and/or record your interactions
          with the Service.
          <br />
          <br />
          <u>Rights in User Content Granted by You </u>
          <br />
          <br />
          By making any User Content available through Services you hereby grant
          to Ahura AI a non-exclusive, transferable, sublicensable, worldwide,
          royalty-free license to use, copy, modify, create derivative works
          based upon, distribute, publicly display, publicly perform and
          distribute your User Content in connection with operating and
          providing the Services and Content to you and to other users.
          <br /> <br />
          <u> Rights in Content Granted by Ahura AI </u>
          <br />
          <br />
          Subject to your compliance with these Terms, Ahura AI grants you a
          limited, non-exclusive, non-transferable, non-sublicensable license to
          download, view, copy and display the Content solely in connection with
          your permitted use of the Services and solely for your personal and
          non-commercial purposes.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Digital Millennium Copyright Act
        </div>
        <div className="mt-1">
          Ahura AI takes claims of copyright infringement seriously. If you
          believe any materials on the Service infringe your copyright, please
          inform Ahura AI by sending written notice by U.S. Mail to Ahura AI at
          2000 Winter Wind St, Las Vegas, NV 89134, USA or by email to
          <a
            target="_blank"
            href="mailto: hi@Ahuraai.com"
            className={classNames(c.noLinkUnderline)}
          >
            {' '}
            hi@Ahuraai.com.{' '}
          </a>
          In accordance with the Online Copyright Infringement Liability
          Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. §
          512) ("DMCA"), your written notice must include substantially the
          following:
        </div>
        <div className="mt-1">
          <ul>
            <li>
              A reasonably detailed description of the copyrighted work(s) or
              other intellectual property(s) you claim has/have been infringed,
              or if the claim involves multiple words, a representative list of
              such works;
            </li>
            <li>
              Identification of the specific material you believe infringes upon
              our rights and sufficient information to enable Ahura AI to locate
              the specific material. The DMCA only authorizes Ahura AI to remove
              or disable access to specific infringing materials. We are not
              authorized to generally disable access to non-infringing
              materials;
            </li>
            <li>
              The following statements or equivalent:
              <ol>
                <li>
                  "I swear, under penalty of perjury, that the information
                  contained in this notification is accurate and that I am the
                  copyright owner or am authorized to act on behalf of the owner
                  of an exclusive right that is allegedly infringed; and
                </li>
                <li>
                  I have a good faith belief that use of the copyrighted
                  materials described above on the allegedly infringing web
                  pages is not authorized by the copyright owner, its agent, or
                  the law."
                </li>
              </ol>
            </li>
            <li>
              Your company name (if any), your legal name (printed), mailing
              address, and phone number, and, if available, an email address;
              and
            </li>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of the right that is allegedly violated or
              infringed, and the present date.
            </li>
          </ul>
        </div>
        <div className="mt-5">
          If you fail to comply with all of the requirements of § 512(c)(3) of
          the DMCA, your notice may not be effective. Please be aware that if
          you knowingly materially misrepresent that material or activity on the
          is infringing your copyright, you may be held liable for damages
          (including costs and attorneys' fees) under § 512(f) of the DMCA.
        </div>
        <div className="mt-5">
          Upon Ahura AI's receipt of a valid, complete takedown notice, Ahura AI
          shall (i) remove or disable access to the specific allegedly
          infringing material identified in the notice, and (ii) take reasonable
          steps to contact the author(s) or other sources of the material.
        </div>
        <div className="mt-5">
          If Ahura AI removes or disables access to material as a result of a
          takedown notice, the person or entity supplying such material may
          instruct Ahura AI to restore such material by providing a
          counter-notification to Ahura AI. Upon receipt of a
          counter-notification, Ahura AI shall send a copy of the
          counter-notification to the original complaining party and inform such
          party that Ahura AI shall replace or restore access to the material
          within no less than 10 but no more than 14 days unless Ahura AI
          receives official notice from the complaining party that it has filed
          an action seeking a court order.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-5')}>Prohibitions</div>
        <div className="mt-5">You agree not to do any of the following:</div>
        <div className="mt-5">
          <ul>
            <li>
              Post, upload, publish, submit or transmit any Content that: (i)
              infringes, misappropriates or violates a third party’s patent,
              copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy;
              (ii) violates, or encourages any conduct that would violate, any
              applicable law or regulation or would give rise to civil
              liability; (iii) is fraudulent, false, misleading or deceptive;
              (iv) is defamatory, obscene, pornographic, vulgar or offensive;
              (v) promotes discrimination, bigotry, racism, hatred, harassment
              or harm against any individual or group; (vi) is violent or
              threatening or promotes violence or actions that are threatening
              to any person or entity; or (vii) promotes illegal or harmful
              activities or substances;
            </li>
            <li>
              Use, display, mirror or frame the Services, or any individual
              element within the Services, Ahura AI’s name, any Ahura AI
              trademark, logo or other proprietary information, or the layout
              and design of any page or form contained on a page, without Ahura
              AI’s express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Services,
              Ahura AI’s computer systems, or the technical delivery systems of
              Ahura AI’s providers;
            </li>
            <li>
              Attempt to probe, scan, or test the vulnerability of any Ahura AI
              system or network or breach any security or authentication
              measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise
              circumvent any technological measure implemented by Ahura AI or
              any of Ahura AI’s providers or any other third party (including
              another user) to protect the Services;
            </li>
            <li>
              Attempt to access or search the Services or download Collective
              Content from the Services through the use of any engine, software,
              tool, agent, device or mechanism (including spiders, robots,
              crawlers, data mining tools or the like) other than the software
              and/or search agents provided by Ahura AI or other generally
              available third party web browsers;
            </li>
            <li>
              Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters, or other form of
              solicitation;
            </li>
            <li>
              Use any meta tags or other hidden text or metadata utilizing an
              Ahura AI trademark, logo URL, or product name without Ahura AI’s
              express written consent;
            </li>
            <li>
              Use the Services for any commercial purpose or the benefit of any
              third party or in any manner not permitted by these Terms;
            </li>
            <li>
              Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Services to send altered, deceptive or false
              source-identifying information;
            </li>
            <li>
              Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Services;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any
              user, host, or network, including, without limitation, sending a
              virus, overloading, flooding, spamming, or mail-bombing the
              Services;
            </li>
            <li>
              Collect or store any personally identifiable information from the
              Services from other users of the Services without their express
              permission;
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ul>
        </div>
        <div className="mt-5">
          Although we’re not obligated to monitor access to or use of the
          Services or Content or to review or edit any Content, we have the
          right to do so for the purpose of operating the Services, to ensure
          compliance with these Terms, and to comply with applicable law or
          other legal requirements. We reserve the right but are not obligated,
          to remove or disable access to any Content, at any time and without
          notice, including, but not limited to, if we, at our sole discretion,
          consider any Content to be objectionable or in violation of these
          Terms. We have the right to investigate violations of these Terms or
          conduct that affects the Services. We may also consult and cooperate
          with law enforcement authorities to prosecute users who violate the
          law.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Purchases</div>
        <div className="mt-1">
          <u>Fees </u> <br />
          Ahura AI offers certain enhanced features of the Services which you
          can purchase as a monthly, yearly, or lifetime subscription
          (“Subscription”). A description of features associated with
          Subscriptions is available via the Services. When you purchase a
          Subscription or a Product (each, a “Transaction”), we may ask you to
          supply additional information relevant to your Transaction, such as
          your credit card number, the expiration date of your credit card, and
          your address(es) for billing and delivery (such information, “Payment
          Information”). You represent and warrant that you have the legal right
          to use all payment method(s) represented by any such Payment
          Information. The amounts due and payable by you for a Transaction
          through the Services will be presented to you before you place your
          order. If you choose to initiate a Transaction via the Services, you
          authorize us to provide your Payment Information to third party
          service providers so we can complete your Transaction and agree (a) to
          pay the applicable fees and any taxes; (b) that Ahura AI may charge
          your credit card or third party payment processing account, including,
          but not limited to, your account with the app store or distribution
          platform (like the Apple App Store, Google Play or the Amazon
          Appstore) where the App is made available (each, an “App Provider”),
          for verification, pre-authorization and payment purposes; and (c) to
          bear any additional charges that your App Provider, bank or other
          financial service providers may levy on you as well as any taxes or
          fees that may apply to your order. You’ll receive a confirmation email
          after we confirm the payment for your order. Your order is not binding
          on Ahura AI until accepted and confirmed by Ahura AI. All payments
          made are non-refundable and non-transferable except as expressly
          provided in these Terms. <br />
          If you have any concerns or objections regarding charges, you agree to
          raise them with us first and you agree not to cancel or reject any
          credit card or third-party payment processing charges unless you have
          made a reasonable attempt at resolving the matter directly with Ahura
          AI. <br />
          Ahura AI reserves the right to not process or to cancel your order in
          certain circumstances, for example, if your credit card is declined if
          we suspect the request or order is fraudulent, or in other
          circumstances, Ahura AI deems appropriate in its sole discretion.
          Ahura AI also reserves the right, in its sole discretion, to take
          steps to verify your identity in connection with your order. You may
          need to provide additional information to verify your identity before
          completing your Transaction (such information is included within the
          definition of Payment Information). Ahura AI will either not charge
          you or refund the charges for orders that we do not process or cancel.
          <div className="mt-1">
            <br /> <u>Title, Risk of Loss </u>{' '}
          </div>
          <br />
          The risk of loss and title for Products purchased by you pass from
          Ahura AI to you on shipment from Ahura AI’s facility. All shipments
          are governed in accordance to Ahura AI’s standard Shipping Terms.{' '}
          <br />
          Subscriptions Automatically Renew Until You Cancel & How to Cancel
          Your Subscription <br />
          All amounts are payable and charged: (i) for one-off purchase (e.g.
          lifetime Subscription), at the time you place your order; and (ii) For
          monthly or yearly subscriptions, at the beginning of the subscription
          and, because each such subscription renews automatically for an
          additional period equal in length to the expiring subscription term
          until you cancel it, at the time of each renewal until you cancel,
          using the Payment Information you have provided. You must cancel your
          monthly or yearly Subscription before it renews to avoid the billing
          of the fees for the next Subscription period. If you purchase your
          Subscription via the Site, you can cancel the renewal of your
          subscription at any time by contacting us by email at
          <a
            target="_blank"
            href="mailto: hi@Ahuraai.com"
            className={classNames(c.noLinkUnderline)}
          >
            {' '}
            hi@Ahuraai.com.{' '}
          </a>
          If you purchase your Subscription via an App Provider, you can cancel
          the renewal of your subscription at any time with the App Provider.
          You will not receive a refund for the fees you already paid for your
          current subscription period and you will continue to receive the
          Services ordered until the end of your current Subscription period.
          <div className="mt-1">
            <br /> <u>Changes to Price Terms for Subscriptions</u>{' '}
          </div>
          <br />
          Ahura AI reserves the right to change its pricing terms for
          Subscriptions at any time and Ahura AI will notify you in advance of
          such changes becoming effective. Changes to the pricing terms will not
          apply retroactively and will only apply for Subscription renewals
          after such changed pricing terms have been communicated to you. If you
          do not agree with the changes to Ahura AI’s pricing terms then you may
          choose not to renew your Subscription in accordance with the section
          “Subscriptions Automatically Renew Until You Cancel & How to Cancel
          Your Subscription.”
          <div className="mt-1">
            <br /> <u>Future Functionality</u>{' '}
          </div>
          <br />
          You agree that your purchases are not contingent on the delivery of
          any future functionality or features, or dependent on any oral or
          written public comments made by Ahura AI regarding future
          functionality or features.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-5')}>
          User Information
        </div>
        <div className="mt-1">
          When you register for the Service, you will be asked to provide Ahura
          AI with certain information including, without limitation, a valid
          email address. Additionally, if you elect to use the Service, you will
          be required to open an account. You must complete the registration
          process by providing Ahura AI with current, complete, and accurate
          information as prompted by the applicable registration form. You also
          will choose a password and a username. If you register with Ahura AI
          using an enterprise account and later leave that enterprise, you may
          retrieve your account by emailing
          <a
            target="_blank"
            href="mailto: hi@Ahuraai.com"
            className={classNames(c.noLinkUnderline)}
          >
            {' '}
            hi@Ahuraai.com.{' '}
          </a>
          You are entirely responsible for maintaining the confidentiality of
          your password and account. Furthermore, you are entirely responsible
          for any and all activities that occur under your account. You agree to
          notify Ahura AI immediately of any unauthorized use of your account or
          any other breach of security. Ahura AI will not be liable for any loss
          that you may incur as a result of someone else using your password or
          account, either with or without your knowledge. However, you could be
          held liable for losses incurred by Ahura AI or another party due to
          someone else using your account or password. You may not use anyone
          else's account at any time, without the permission of the account
          holder. You are responsible for maintaining the confidentiality of
          your password and log in. Ahura AI reserves the right, in its sole
          discretion, to terminate your access to the Service with or without
          notice.
        </div>
        <div className="mt-5">
          In addition to the terms and conditions that may be set forth in Ahura
          AI's Privacy Policy on this Service, you understand and agree that
          Ahura AI may disclose to third parties, on an anonymous basis, certain
          aggregate information based on the information you provide to Ahura AI
          and your usage of the Service. Ahura AI will not disclose to any third
          party your personally identifiable information without your prior
          consent, except to the extent necessary or appropriate to comply with
          applicable laws, subpoenas, or other legally compelled disclosure.
          Please see Ahura AI's Privacy Policy for further details regarding the
          use of your personally identifiable information.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Children Under the Age of 13
        </div>
        <div className="mt-1">
          The safety and privacy of children are very important to us. We are
          committed to complying with the requirements of all federal and state
          laws, including the Children's Online Privacy Protection Act of 1998
          ("COPPA"). For more information about COPPA, please visit the Federal
          Trade Commission's website at COPPA Website. <br />
          This Service is not intended for children under the age of 13. Ahura
          AI will not knowingly register or otherwise collect personally
          identifiable information from persons who identify themselves as being
          under the age of 13 during the registration process.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Termination and Account Cancellation
        </div>
        <div className="mt-1">
          Ahura AI reserves the right to revoke your access to and use of the
          Service at any time, with or without cause. If you breach any of these
          Terms, Ahura AI will have the right to suspend or disable your
          account, at its sole discretion and without prior notice to you. You
          may cancel your Account at any time by sending an email to us at
          <a
            target="_blank"
            href="mailto: hi@Ahuraai.com"
            className={classNames(c.noLinkUnderline)}
          >
            {' '}
            hi@Ahuraai.com.{' '}
          </a>
          Once you are in your Profile Settings, you select "Inactivate account"
          and select "yes" to confirm that you would like to delete your
          account. If you are connected to an enterprise account, you cannot
          delete your individual account.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Disclaimers</div>
        <div className="mt-1">
          YOUR USE OF THE SERVICE, THE SERVICE CONTENT, AND ANY USE OF THE
          SERVICE OR ITEMS OBTAINED THROUGH THE SITE ARE AT YOUR OWN RISK. THE
          SERVICE, THE SERVICE CONTENT, AND ANY USE OF THE SERVICE OR ITEMS
          OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS
          AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
          IMPLIED. WITHOUT LIMITING THE FOREGOING, Ahura AI EXPLICITLY DISCLAIMS
          ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF
          COURSE OF DEALING OR USAGE OF TRADE. Ahura AI MAKES NO WARRANTY THAT
          THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON
          AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. Ahura AI MAKES NO
          WARRANTY REGARDING THE QUALITY OF ANY SERVICE OR SITE CONTENT OBTAINED
          THROUGH THE SITE OR SERVICES OR THE ACCURACY, TIMELINESS,
          TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY SITE CONTENT OBTAINED
          THROUGH THE SITE OR THE SERVICE.
        </div>
        <div className="mt-5">
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM Ahura
          AI OR THROUGH THE SITE OR SITE CONTENT, WILL CREATE ANY WARRANTY NOT
          EXPRESSLY MADE HEREIN.
        </div>
        <div className="mt-5">
          YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS OF THE SITE AND WITH OTHER PERSONS WITH
          WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE
          OR THE SERVICES. YOU UNDERSTAND THAT Ahura AI DOES NOT SCREEN OR
          INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE SITE, NOR DOES Ahura
          AI MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SITE OR
          THE SERVICE. Ahura AI MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE
          CONDUCT OF USERS OF THE SITE OR THE SERVICE OR THEIR COMPATIBILITY
          WITH ANY CURRENT OR FUTURE USERS OF THE SITE. YOU AGREE TO TAKE
          REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH
          OTHER USERS OF THE SITE AND WITH OTHER PERSONS WITH WHOM YOU
          COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE
          PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Indemnity</div>
        <div className="mt-1">
          You agree to defend, indemnify, and hold Ahura AI, its officers,
          directors, employees, licensors, service providers, and agents,
          harmless from and against any claims, liabilities, damages, losses,
          and expenses, including, without limitation, reasonable legal and
          accounting fees, arising out of or in any way connected with your
          access to or use of the Service or your violation of these Terms
          including, without limitation, any use of the Service Content, the
          Service or your use of any information obtained from the Service.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Limitation of Liability
        </div>
        <div className="mt-1">
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
          LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
          SITE, THE SERVICE AND SITE CONTENT REMAINS WITH YOU. NEITHER Ahura AI
          NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE
          SITE OR THE SERVICE WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
          EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF
          DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
          SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR
          ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS
          ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
          INABILITY TO USE THE SITE, THE SERVICE, SITE CONTENT, OR FROM ANY
          COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE SITE,
          OR THE SERVICE OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT
          AS A RESULT OF YOUR USE OF THE SITE OR THE SERVICES, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
          ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Ahura AI HAS BEEN INFORMED
          OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
          HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT
          WILL Ahura AI' AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION
          WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE, THE
          SERVICE OR SITE CONTENT EXCEED ONE HUNDRED DOLLARS ($100). THE
          LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
          BASIS OF THE BARGAIN BETWEEN Ahura AI AND YOU. SOME JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
          OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Proprietary Rights Notices
        </div>
        <div className="mt-1">
          All trademarks, service marks, logos, trade names and any other
          proprietary designations of Ahura AI used herein are trademarks or
          registered trademarks of Ahura AI. Any other trademarks, service
          marks, logos, trade names and any other proprietary designations are
          the trademarks or registered trademarks of their respective parties.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Controlling Law and Jurisdiction
        </div>
        <div className="mt-1">
          These Terms and any action related thereto will be governed by the
          laws of the State of California without regard to its conflicts of law
          provisions. The exclusive jurisdiction and venue of any action with
          respect to the subject matter of these Terms will be the state and
          federal courts located in California and each of the parties hereto
          waives any objection to jurisdiction and venue in such courts.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Entire Agreement
        </div>
        <div className="mt-1">
          These Terms and the Privacy Policy constitute the entire and exclusive
          understanding and agreement between Ahura AI and you regarding use of
          the Site, the Service and/or the Site Content and these Terms
          supersede and replace any and all prior oral or written understandings
          or agreements between Ahura AI and you regarding the Service.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Assignment</div>
        <div className="mt-1">
          These Terms are personal to you and your use of the Service and may
          not be transferred or assigned to anyone. Ahura AI may assign or
          transfer these Terms, at its sole discretion, without restriction.
          Subject to the foregoing, these Terms will bind and insure to the
          benefit of the parties, their successors and permitted assigns.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>Notices</div>
        <div className="mt-1">
          Any notices or other communications permitted or required hereunder,
          including those regarding modifications to these Terms, will be in
          writing and given by Ahura AI (i) via email (in each case to the
          address that you provide) or (ii) by posting to the Service. For
          notices made by email, the date of receipt will be deemed the date on
          which such notice is transmitted.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>
          Links to Other Websites
        </div>
        <div className="mt-1">
          The Service may contain links to other websites operated by third
          parties. Such links are provided for your convenience only, and if you
          access any other websites linked to the Service you do so at your own
          risk. We are not responsible for the content of any such websites, or
          the products and services sold on them, nor are we responsible or
          liable for any loss or damage that may arise from your use of such
          websites. When you visit a linked website you should read the terms of
          use and privacy policy that govern that particular website. Links to
          other websites do not imply our endorsement of any content,
          advertising, products, services, or other materials on or available
          through such websites.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-1')}>General</div>
        <div className="mt-1">
          The failure of Ahura AI to enforce any right or provision of these
          Terms will not constitute a waiver of future enforcement of that right
          or provision. The waiver of any such right or provision will be
          effective only if in writing and signed by a duly authorized
          representative of Ahura AI. Except as expressly set forth in these
          Terms, the exercise by either party of any of its remedies under these
          Terms will be without prejudice to its other remedies under these
          Terms or otherwise. If for any reason a court of competent
          jurisdiction finds any provision of these Terms invalid or
          unenforceable, that provision will be enforced to the maximum extent
          permissible and the other provisions of these Terms will remain in
          full force and effect.
        </div>
        <div className={classNames(c.tcSubHeading, 'mt-2')}>
          Contacting Ahura AI
        </div>
        <div className="mt-1">
          If you have any questions about these Terms or any questions,
          complaints or claims, please contact us at:
        </div>
        <div className="mt-5 mb-5">
          Email:{' '}
          <a
            target="_blank"
            href="mailto: hi@Ahuraai.com"
            className={classNames(c.noLinkUnderline)}
          >
            {' '}
            hi@Ahuraai.com{' '}
          </a>{' '}
          Address: 2000 Winter Wind St, Las Vegas, NV 89134
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
