import { SHOW_LOADER, HIDE_LOADER } from '../../Types/Loader';

export const LoaderReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_LOADER:
      return true;
    case HIDE_LOADER:
      return false;
    default:
      return state;
  }
};
