/**
 * @function TargetCapabilities
 * Component of Profile/Targets section
 *
 */

import React from 'react';
import Text from '../../../Shared/Text/index';
import Divider from '@mui/material/Divider';
import c from '../../Profile.Module.scss';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { GUID_REGEX } from '../../../../config/Regex/regex';
import Skeleton from '@mui/material/Skeleton';

const TargetCapabilities = ({ strengths, capabilityList }) => {
  return (
    <>
      <Text textType="t2" classToOverride="pb-3 fw-light">
        Top 3 Target Capabilities
      </Text>
      {Boolean(strengths) && capabilityList?.length ? (
        Object.keys(strengths).map((item, index) => (
          <div key={`targetCapability${index}`}>
            <Divider />
            <div className="row pt-4 pb-3 align-items-center">
              <div className="col-1">
                <Text
                  textType="t1"
                  text={`${item}${
                    (item === '1' && 'st') || (item === '2' && 'nd') || 'rd'
                  }`}
                  classToOverride={c.fontWeight600}
                />
              </div>
              <div className="col-1">
                <Box
                  className={classNames(
                    c[`capability${item}`],
                    'rounded-circle'
                  )}
                />
              </div>
              <div className="col">
                <Text textType="t2" classToOverride={c.fontWeight500}>
                  {GUID_REGEX.test(strengths[item])
                    ? capabilityList?.find((obj) => obj?.id === strengths[item])
                        ?.name
                    : strengths[item]}
                </Text>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <Divider />
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={`skeletonLoader${index}`}
              variant="text"
              animation="wave"
              height={70}
            />
          ))}
        </>
      )}
    </>
  );
};

export default TargetCapabilities;
