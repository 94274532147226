import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchTargetDetails,
  fetchUserTargetCapabilites,
} from '../../../helpers/Profile/Targets';
import { fetchCategories } from '../../../helpers/LearningPlans/LearningPlansModalBox';
import Headings from '../../Shared/Headings';
import classNames from 'classnames';
import c from '../home.Module.scss';
import CapabilitiesToStudyTabs from './CapabilitiesToStudyTabs';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CapabilityCard from './CapabilityCard';
import OrgainizationAssignedCapabiliityCard from './OrgainizationAssignedCapabiliityCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Text from '../../Shared/Text';
import Skeleton from '@mui/material/Skeleton';
import NoCapabilitiesAssignedUI from './NoCapabilitiesAssignedUI';
import { Button } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AssignLearningPlan from './AssignLearningPlan';
import {
  googleAnalyticsTriggerFn,
  calculateTimeDifference,
} from '../../../utils/utility';
import CapabilityModals from '../CapabilityModalsManager';

const CapabilitiesToStudy = ({
  employeeID,
  showAddLearningPlanBtn = false,
}) => {
  const [tabIndex, setTabIndex] = useState(showAddLearningPlanBtn ? 1 : 0);
  const [showModal, setShowModal] = useState(false);
  const [capabilitiesList, setCapabilitiesList] = useState([]);
  const [
    organizationAssignedCapabilitiesList,
    setOrganizationAssignedCapabilitiesList,
  ] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const userData = useSelector((state) => state?.generalInformation);
  console.log('employeeID', employeeID);
  console.log(' employeeID userData?.id', userData?.id);
  const learner_id = employeeID ? employeeID : userData?.id;
  console.log(' employeeID Learner', learner_id);

  useEffect(() => {
    if (learner_id) {
      getTargetCapabilities();
      getUserOrganizationAssignedCapabilities();
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    console.log('organizationAssignedCapabilitiesList', organizationAssignedCapabilitiesList);
    // eslint-disable-next-line
  }, [organizationAssignedCapabilitiesList]);

  const handleAddRow = () => {
    setOpenAssignModal(true);
  };
  const getTargetCapabilities = async () => {
    const { data } = await fetchTargetDetails(learner_id);
    if (data?.content?.targetCapabilities) {
      const { targetCapabilities } = data?.content;
      if (targetCapabilities) {
        const capabilityIds = Object.values(targetCapabilities);
        const { data } = await fetchCategories();
        if (data) {
          const capabilitiesInfo = data?.filter((item) =>
            capabilityIds.includes(item?.id)
          );
          setCapabilitiesList(capabilitiesInfo);
        }
      }
    }
    setShowLoader(false);
  };


  const getUserOrganizationAssignedCapabilities = async () => {
    const data = await fetchUserTargetCapabilites(learner_id);

    // Ensure data is an array, or default to an empty array
    const validData = Array.isArray(data) ? data : [];

    console.log('Edu', JSON.stringify(validData));

    if (validData.length > 0) {
      console.log("DATA organizationAssignedCapabilitiesList", validData);
      setOrganizationAssignedCapabilitiesList(validData);
    }

    setShowLoader(false);
};


  const markComplete = (id) => {
    const newList = capabilitiesList?.filter((item) => item?.id !== id);
    setCapabilitiesList(newList);
  };

  const swiper = useRef(null);

  return (
    <>
      {showModal && showAddLearningPlanBtn && (
        <AssignLearningPlan showModal={showModal} setShowModal={setShowModal} />
      )}
      <div className="h-100 w-100 bg-white mt-5 px-5 py-4">
        <div className="row justify-content-between align-items-center">
          <Headings
            headingType="h3"
            classToOverride={classNames(' col-4', c.fontWeight600)}
          >
            Capabilities to Study
          </Headings>
          {showAddLearningPlanBtn && (
            <div className="col-3 text-end">
              <Button
                variant="contained"
                startIcon={<CreateNewFolderIcon />}
                sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  // setShowModal(true);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Assign Learning Plan',
                    'Assign Learning Plan button clicked'
                  );
                  handleAddRow();
                }}
              >
                Assign Learning Plan
              </Button>
            </div>
          )}
        </div>
        <div className="row g-0 ps-5 pe-2">
          <CapabilitiesToStudyTabs
            index={tabIndex}
            setTabIndex={setTabIndex}
            isEmployeeDetailPage={showAddLearningPlanBtn}
          />
        </div>
        {/* By Me */}
        {!showAddLearningPlanBtn &&
          tabIndex === 0 &&
          (capabilitiesList.length > 0 ? (
            <>
              <div className="pt-4 d-flex align-items-center">
                {capabilitiesList?.length > 2 && (
                  <ArrowBackIosNewIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft -= 500)}
                  />
                )}
                <div
                  className={classNames(
                    'd-flex justify-content-between',
                    c.slider,
                    capabilitiesList?.length < 3 && 'ms-5'
                  )}
                  ref={swiper}
                >
                  {capabilitiesList.map((item, index) => (
                    <CapabilityCard
                      key={item?.id}
                      index={index + 1 - 5 * parseInt(index / 5)}
                      cardId={item?.id}
                      cardHead={item?.name}
                      handleClick={markComplete}
                    >
                      {item?.description}
                    </CapabilityCard>
                  ))}
                </div>
                {capabilitiesList?.length > 2 && (
                  <ArrowForwardIosIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft += 500)}
                  />
                )}
              </div>
              <Text
                textType="t0"
                classToOverride={classNames(
                  'pt-4 ps-5',
                  c.fontWeight600,
                  c.capabilitiesCount
                )}
              >
                {`Number of Capabilities : ${capabilitiesList.length}`}
              </Text>
            </>
          ) : (
            !showLoader &&
            !capabilitiesList.length && <NoCapabilitiesAssignedUI />
          ))}
        {showLoader && (
          <div>
            <Skeleton
              variant="rectangular"
              height={273}
              className="mt-4 mx-4 rounded"
            />
          </div>
        )}
        {/* By Company */}
        {tabIndex === 1 &&
          (organizationAssignedCapabilitiesList.length > 0 ? (
            <>
              <div className="pt-4 d-flex align-items-center">
                {organizationAssignedCapabilitiesList?.length > 2 && (
                  <ArrowBackIosNewIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft -= 500)}
                  />
                )}
                <div
                  className={classNames(
                    'd-flex justify-content-between',
                    c.slider,
                    organizationAssignedCapabilitiesList?.length < 3 && 'ms-5'
                  )}
                  ref={swiper}
                >
                  {organizationAssignedCapabilitiesList.map((item, index) => (
                    <OrgainizationAssignedCapabiliityCard
                      key={item?.id}
                      index={index + 1 - 5 * parseInt(index / 5)}
                      cardId={item?.id}
                      cardHead={item?.capability.name}
                      cardSubHeading={item?.capability.description}
                      targetOutcome={item?.capability_context}
                      // assigneerName = {}
                      dueDate={item?.deadline}
                      timeSpent={calculateTimeDifference(item?.date_assigned)}
                      handleClick={markComplete}
                    >
                      {item?.description}
                    </OrgainizationAssignedCapabiliityCard>
                  ))}
                </div>
                {organizationAssignedCapabilitiesList?.length > 2 && (
                  <ArrowForwardIosIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft += 500)}
                  />
                )}
              </div>
              <Text
                textType="t0"
                classToOverride={classNames(
                  'pt-4 ps-5',
                  c.fontWeight600,
                  c.capabilitiesCount
                )}
              >
                {`Number of Capabilities :  ${organizationAssignedCapabilitiesList.length}`}
              </Text>
            </>
          ) : (
            !showLoader &&
            !organizationAssignedCapabilitiesList.length && (
              <NoCapabilitiesAssignedUI />
            )
          ))}
      </div>

      {/* Include the CapabilityModals component */}
      <CapabilityModals
        openAssignModal={openAssignModal}
        setOpenAssignModal={setOpenAssignModal}
        organizationAssignedCapabilitiesList={organizationAssignedCapabilitiesList}
        setOrganizationAssignedCapabilitiesList={setOrganizationAssignedCapabilitiesList}
        isCards= {true}
        loading={loading}
        isEditMode={false}
      />
    </>
  );
};

export default CapabilitiesToStudy;
