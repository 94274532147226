import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LensIcon from '@mui/icons-material/Lens';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { Badge } from 'react-bootstrap';
import { Permission } from '../../../../config/Permission';
import CreateCategory from '../CreateCategory';
import SubListItem from '../SubListItem';
import listItemCls from './listItem.module.scss';

const ListItems = ({
  menu,
  handleCategoryView,

  selectedIndex,
  permission,
}) => {
  const setCategoryData = ({ ...params }) => {
    handleCategoryView({ ...params, parent_id: menu.menu_id }); // menu_id change to id
  };

  return (
    <Box className="w-100">
      <List component="nav" aria-label="main mailbox folders">
        {menu &&
          menu.menu_items.map((category_item) => {
            return (
              Permission(
                category_item?.id,
                permission.menu_items?.visible?.isVisible,
                permission.menu_items?.visible?.exclude
              ) && (
                <React.Fragment key={category_item.id}>
                  <ListItemButton
                    selected={selectedIndex === category_item.id}
                    className={classNames('p-0')}
                  >
                    <ListItemIcon
                      className={classNames(listItemCls.listItemsIcon)}
                    >
                      <LensIcon className={classNames(listItemCls.LensIcon)} />
                    </ListItemIcon>
                    <ListItemText
                      onClick={() => setCategoryData(category_item)}
                    >
                      <div className={classNames(listItemCls.menuItemName)}>
                        {category_item?.menu_name}
                      </div>
                    </ListItemText>

                    {category_item?.count > 0 && (
                      <Badge className={classNames(listItemCls.categoryCount)}>
                        {category_item?.count}
                      </Badge>
                    )}
                  </ListItemButton>
                  <SubListItem
                    selectedIndex={selectedIndex}
                    permission={permission}
                    category_item={category_item}
                  />
                </React.Fragment>
              )
            );
          })}
      </List>
    </Box>
  );
};

export default ListItems;
