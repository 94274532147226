import React, { useEffect } from 'react';
import { CustomTable } from '../../shared/Table';
import CustomTableHead from '../../shared/Table/TableHead';
import { TablePagination, TableBody, TableRow } from '@mui/material';
import {
  TableCellText,
  TableCellDropdown,
  TableCellSwitch,
  TableCell15,

} from '../../shared/Table/TableCell';
import PanelHeader from '../../../components/shared/Header/PanalHeader';
import { tableHeader } from '../../../helpers/networks/citizens';
import { getNetworkUsers, updateIsAdmin } from '../../../services/networks';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { activeOrdeactive } from '../../../services/networks';
import ScreenLoader from '../../shared/ScreenLoader';
import Text from '../../shared/Text';
import { useSelector } from 'react-redux';
import useUserData from '../../../hooks/UserData';
import { useHistory } from 'react-router-dom';
import c from '../table.module.scss'
const SUPER_USER_CLIENT_ID = 'cb19c961-fc50-4613-87d9-40488220a1d9'

const Citizens = () => {
  const [citizensData, setCitizensData] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ name: 'asc' });
  const userData = useUserData();
  const { client_name } = useSelector((state) => state?.generalInformation);
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState({
    totalPages: 0,
    currentPage: 0,
    limit: 25,
  });

  const history = useHistory();

  const handleLinkClick = (id) => () => history.push(`/employee-details/${id}`);

  const opPageChange = (e, page_number) => {
    setPageNumber({
      ...pageNumber,
      currentPage: page_number,
    });
    window.scrollTo(0, 0);
  };

  const setRowsPerPage = (row_page) => {
    setPageNumber({
      ...pageNumber,
      limit: row_page,
    });
    window.scrollTo(0, 0);
  }

  const { mutate, isLoading: loadingActiveDeactive } = useMutation(
    activeOrdeactive,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getNetworkUser');
      },
    }
  );

  const handleActionChange = (value, id, item) => {
    if (value) {
      const data = {
        id: item.user_id,
        status: value === 'Active' ? true : false,
      };
      mutate(data);
    }
  };

  const { mutate: updateAdminFn, isLoading: isSetting } = useMutation(
    updateIsAdmin,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getNetworkUser');
        queryClient.refetchQueries('getNetworkSummary');
      },
    }
  );

  const updateAdmin = (data) => {
    updateAdminFn({
      id: data.id,
      is_admin: !data?.is_admin,
      loggedInUserClientID: userData.id,
      user_id: data.user_id,
      client_name: client_name,
    });
  };

  const sortHandler = (sortCellName) => {
    setSort({
      ...sort,
      [sortCellName]: sort[sortCellName] === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    if (!initialLoading) {
      const timeOut = setTimeout(() => {
        setStatus(search);
        setPageNumber({
          ...pageNumber,
          currentPage: 0,
        });
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [search]);

  // ${sort.name.toString()}
  const {data,isFetching: loadingNetworkUser, refetch } = useQuery(
    ['getNetworkUser', sort, pageNumber.currentPage, status],
    () =>
      getNetworkUsers({
        type: 'users',
        page_number: pageNumber.currentPage + 1,
        limit: pageNumber.limit,
        sort_by: `[["user_name","${sort.name}"]]`,
        search: search || '',
      }),
    {
      refetchOnMount: true,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.registrants && data?.registrants.length !== 0) {
          setCitizensData(data?.registrants);
          setInitialLoading(false);
          setPageNumber({
            ...pageNumber,
            totalPages: data?.total_pages || 0,
          });
        }
      },
      onError: () => {
        setInitialLoading(false);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [pageNumber.limit])

  return (
    <div className="w-100">
      <PanelHeader
        heading="Citizens"
        isSearch={true}
        isSelect={false}
        setSearch={setSearch}
      />
      {loadingNetworkUser || loadingActiveDeactive ? (
        <ScreenLoader />
      ) : citizensData?.length !== 0 ? (
        <>
          <CustomTable classToOverride="w-100 px-4 my-2">
            <CustomTableHead
              headerData={tableHeader}
              sortHandler={sortHandler}
              sort={sort}
            />
            <TableBody>
              {citizensData
                // .filter(({ user_name }) =>
                //   user_name?.toLowerCase().trim().includes(search)
                // )
                // .slice(0, 100)
                .map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCellText text={user.user_name} />
                    <TableCellText text={user?.client_id != SUPER_USER_CLIENT_ID ? user.client_name : '-'} />
                    <TableCellText text={user.email} />

                    <TableCell15
                      textOverride={'mb-0'}
                      style="cursor-pointer"
                      handleClick={handleLinkClick(
                        user.id
                      )}
                      text="View"
                    />

                    <TableCellSwitch
                      checked={user?.is_admin}
                      onClick={() => updateAdmin(user)}
                    />
                    <TableCellDropdown
                      btnName={
                        user.is_user_account_active ? 'Active' : 'Inactive'
                      }
                      options={[
                        { id: 'active', value: 'Active' },
                        { id: 'inactive', value: 'Inactive' },
                      ]}
                      item={user}
                      setValue={handleActionChange}
                    />
                  </TableRow>
                ))}
            </TableBody>
          </CustomTable>
          <div className="d-flex align-items-center justify-content-center py-5">
            <TablePagination
              className={c.tablePagination}
              component="div"
              count={data?.pagination.totalRecords}
              page={pageNumber?.currentPage}
              rowsPerPageOptions={[10, 25, 100]}
              rowsPerPage={pageNumber?.limit}
              onPageChange={(e, page) => {
                opPageChange(e, page);
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e?.target?.value));
              }}
            />
          </div>
        </>
      ) : (
        <Text className="text-center mt-5 fs-4 font-weight-bold">
          No Record Found
        </Text>
      )}
    </div>
  );
};

export default Citizens;
