import React from 'react';
import Text from '../../../Shared/Text';
import Divider from '@mui/material/Divider';
import TargetProgressBar from '../TargetProgressBar';
// import {
//   Chart as ChartJS,
//   LinearScale,
//   CategoryScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Legend,
//   Tooltip,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

// import {
//   ChartOptionProfileTargets,
//   ChartDataProfileTargets,
// } from '../../../../helpers/Profile/Targets';
// import c from '../../Profile.Module.scss';
// import { Skeleton } from '@mui/material';

const TargetLearningHours = ({ data }) => {
  // ChartJS.register(
  //   LinearScale,
  //   CategoryScale,
  //   BarElement,
  //   PointElement,
  //   LineElement,
  //   Legend,
  //   Tooltip
  // );

  return (
    <>
      <div className="pb-3">
        <Text textType="t2" classToOverride="fw-light">
          Target Number of Learning Hours
        </Text>
      </div>
      <Divider />
      {/* <div className="mt-5 d-flex justify-content-center">
        <div className="w-75">
          <Bar
            data={ChartDataProfileTargets}
            options={ChartOptionProfileTargets}
          />
        </div>
      </div> */}
      {/* <div className="mt-2 d-flex justify-content-evenly">
        {typeof targetWeekHrs === 'number' ? (
          <Text textType="t1" classToOverride={c.fontWeight600}>
            <span>{targetWeekHrs}</span>
            <span className={c.primaryColor}> hours a week</span>
          </Text>
        ) : (
          <Skeleton animation="wave" height={21} width={109} />
        )}
        {typeof targetMonthHrs === 'number' ? (
          <Text textType="t1" classToOverride={c.fontWeight600}>
            <span>{targetMonthHrs}</span>
            <span className={c.primaryColor}> hours a month</span>
          </Text>
        ) : (
          <Skeleton animation="wave" height={21} width={116} />
        )}
      </div> */}

      {/* new bars */}

      <div className="row h-100 justify-content-between align-items-center">
        <div className="col-lg-4 justify-content-center g-0">
          <TargetProgressBar
            value={data?.targetHoursAWeek || 0}
            topic="hours a week"
          />
        </div>
        <div className="col-lg-4 justify-content-center g-0">
          <TargetProgressBar
            value={data?.targetHoursAMonth || 0}
            topic="hours a month"
          />
        </div>
        <div className="col-lg-4 justify-content-center g-0">
          <TargetProgressBar
            value={data?.targetNumber || 0}
            topic="Capabilities"
            subTopic="per Week"
          />
        </div>
      </div>
    </>
  );
};

export default TargetLearningHours;
