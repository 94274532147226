import { SET_USERDATA } from "../../Types/UserData";

export const UserData = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_USERDATA:
        return payload;
      default:
        return state;
    }
  };