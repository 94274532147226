import React from 'react';
import classNames from 'classnames';
import c from '../../landing.module.scss';
import { Resources } from '../../../../config/Resources';

const RightPanel = ({SetPlayerVideo, ...props}) => {
    return(
        <div className={classNames("col-md-8 col-lg-8 px-0 position-relative")}>
            <img src={Resources.images.LandingPage} alt="cover" className={classNames(c.LandingPageCover)}/>
            <div className={classNames(c.videoPlayAction)}  onClick={()=> {SetPlayerVideo(true, "https://ahura.sharemeister.com/static/media/Ahura%20Promo.4432ffa0.mp4")}}>
                <span></span>
            </div>`
        </div>
    )
}

export default RightPanel;