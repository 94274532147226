/**
 * @function MockData
 * Mock data for Actionable Insights component
 */


const ContentDropdown = [{
    label: "Blog",
    Value: "Blog",

},
{
    label: "Video",
    Value: "Video",

},
{
    label: "Course",
    Value: "Course",

},

]





export { ContentDropdown };