import React from 'react';
import Text from '../../../Shared/Text';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import MenuItem from '@mui/material/MenuItem';
import { DemographicsForm } from '../../../../helpers/Profile/GeneralInfo';
import { TextField } from '@mui/material';
import { genderList, ethnicityDropdown} from '../../../../config/Constants';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Demographics = ({ isEditable, formik }) => {
  const { values, handleChange, setFieldValue, errors } = formik;
  return (
    <>
      <Text textType="t1" classToOverride={classNames(c.fontWeight600, 'mt-5')}>
        Demographics
      </Text>
      {/* <Text textType="t2" classToOverride={classNames(c.fade)}>
        Another line here for description
      </Text> */}
      <div className="row pe-5">
        {DemographicsForm.map((field) =>
          field.id === 'gender' || field.id === 'race' ? (
            <div className="col-6 mt-4" key={field.id}>
              <FormControl variant="standard" className="w-100">
                <InputLabel id="select-label">{field?.label}</InputLabel>
                <Select
                  id={field?.id}
                  name={field?.id}
                  value={values[field?.id]}
                  fullWidth
                  displayEmpty
                  onChange={handleChange}
                  endAdornment={
                    values[field?.id] &&
                    !isEditable && (
                      <IconButton
                        className="me-4"
                        onClick={() => setFieldValue(field.id, '')}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )
                  }
                  inputProps={{
                    readOnly: isEditable,
                    className: isEditable ? classNames('bg-transparent',c.profileInputFieldReadOnly) : classNames('bg-transparent',c.profileInputField),
                  }}
                >
                  {field.id === 'gender' && genderList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                   { field.id === 'race'  && ethnicityDropdown.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div className="col-6 mt-4" key={field?.id}>
              <TextField
                id={field?.id}
                name={field?.id}
                variant="standard"
                fullWidth
                label={field?.label}
                value={values[field?.id]}
                onChange={handleChange}
                error={errors[field?.id] ? true : false}
                helperText={errors[field?.id]}
                InputProps={{
                  readOnly: isEditable,
                }}
                inputProps={{
                  className: classNames(c.profileInputField),
                }}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Demographics;
