import React from 'react';
import c from '../sessionDetails.Module.scss';
import classNames from 'classnames';
import Headings from '../../Shared/Headings';
import Text from '../../Shared/Text';
import { Resources } from '../../../config/Resources';
// import { getMinutesAndSeconds } from '../../../helpers/SessionDetails/VisitedURLs';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

momentDurationFormatSetup(moment);

const LearningJourney = ({ session_details = {}, topic: propTopic }) => {
   const location = useLocation();
   const { employee_full_name } = location.state || {};
   const learner_full_name = employee_full_name 
   const session_topic = useSelector(state => state.sessionRecord.description);
     const topic = session_topic !== undefined ? session_topic : propTopic
  return (
    <>
      <div
        className={classNames(
          'd-flex justify-content-between align-items-center mb-4 g-0 py-4 px-4',
          c.sessionsCard
        )}
      >
        <div className="">
          <Headings
            headingType="h3"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            Learning Journey
          </Headings>
          <Text
            textType="t0"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            Learner :{' '} {learner_full_name}
          </Text>
          <Text
            textType="t0"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            Topic :{' '}            {topic}
          </Text>

          <Text
            textType="t0"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            On Ahura:{' '}
            {`${session_details.ahura_learning_plan || ''} (${
              session_details?.ahura_duration
                ? moment
                    .duration(session_details?.ahura_duration, 'seconds')
                    ?.format('HH [hours] mm [minutes] ss [seconds]')
                : '0 minutes'
            })`}
          </Text>
          <Text
            textType="t0"
            classToOverride={classNames('fw-bold', c.primaryColor)}
          >
            On the Web:{' '}
            {`${
              session_details?.web_tabs ? session_details?.web_tabs : 0
            } tabs (${
              session_details?.web_duration
                ? moment
                    .duration(session_details?.web_duration, 'seconds')
                    ?.format('HH [hours] mm [minutes] ss [seconds]')
                : '0 minutes'
            })`}
          </Text>
        </div>

        {session_details?.stop_reason === 'Auto Stop' && (
          <Text classToOverride={classNames(c.autoStoppedCls, 'p-2')}>
            Session auto stopped to prevent running while user not studying
          </Text>
        )}

        <img
          className={classNames(c.logo)}
          src={Resources.images.AhuraHeaderImage}
          alt="ahuraLogo"
        />
      </div>
    </>
  );
};

export default LearningJourney;
