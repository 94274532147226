/**
 * @function ProfilePage
 * All the components of profile page are rendered here.
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SelectTopic from '../../components/Home/SelectTopic';
import c from './profile.module.scss';
import WelcomeSection from '../../components/Shared/WelcomeSection';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Text from '../../components/Shared/Text';
import LinearProgress from '@mui/material/LinearProgress';
import Headings from '../../components/Shared/Headings';
import ProfileTabs from '../../components/Profile/ProfileTabs';
import GeneralInformation from '../../components/Profile/GeneralInformation';
import LearningPreferences from '../../components/Profile/LearningPreferences';
import Targets from '../../components/Profile/Targets';
import Calibrations from '../../components/Profile/Calibrations';
import Badges from '../../components/Profile/Badges';
import { googleAnalyticsTriggerFn } from '../../utils/utility';

const ProfilePage = (props) => {
  const tabsIncompleteCount =
    useSelector(
      (state) => state?.profileCompletionStatus?.incompleteTabs?.length
    ) || 0;
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (Boolean(props?.state)) {
      setTabIndex(props?.state?.tabIndexToSwitch);
    }
  }, [props?.state]);

  const history = useHistory();

  const { isTopicModal, profileCompletionStatus } = useSelector(
    (state) => state
  );

  return (
    <>
      {isTopicModal ? (
        <SelectTopic />
      ) : (
        <div className={c.profilePage}>
          <WelcomeSection />
          <div className={classNames('bg-white rounded', c.profileDetails)}>
            <div className="row align-items-center">
              <div className="col-7">
                <Button
                  variant="text"
                  startIcon={<ChevronLeftIcon />}
                  size="large"
                  onClick={() => {
                    history.push('/home');
                    googleAnalyticsTriggerFn(
                      'Button',
                      'Back',
                      'Back button clicked on profile page'
                    );
                  }}
                >
                  Back
                </Button>
              </div>
              <div className="col-5">
                <div className="mb-1 d-flex">
                  <Text
                    textType="t3"
                    classToOverride={classNames(
                      c.profileStatus,
                      c.fontWeight600
                    )}
                  >
                    {/* In Progress: Step {5 - tabsIncompleteCount} of 5 */}
                    In Progress: Step {3 - tabsIncompleteCount} of 3
                  </Text>
                  <Text
                    textType="t3"
                    classToOverride={classNames('ms-auto', c.profileStatus)}
                  >
                    {profileCompletionStatus.percentage}% Complete
                  </Text>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={profileCompletionStatus.percentage}
                  className={c.profileProgress}
                />
              </div>
            </div>
            <Headings
              headingType="h3"
              classToOverride={classNames('mt-3 fw-bold', c.primaryColor)}
            >
              Profile Details
            </Headings>
            <div className="mt-3">
              <ProfileTabs index={tabIndex} setTabIndex={setTabIndex} />
            </div>
            <div className="row">
              {/* Import and render your components here */}
              {tabIndex === 0 && <GeneralInformation />}
              {tabIndex === 1 && <LearningPreferences />}
              {tabIndex === 2 && <Targets />}
              {tabIndex === 3 && <Calibrations />}
              {tabIndex === 4 && <Badges />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
