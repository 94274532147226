/**
 * @function ProfileTabs
 * the tabs of profile details section are rendered here
 */

import React from 'react';
import { Box } from '@mui/system';
import CustomTabs from '../../Layout/Tabs';
import { ProfileTabsOptions } from '../../../helpers/Profile';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
const ProfileTabs = ({ index, setTabIndex }) => {
  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      googleAnalyticsTriggerFn(
        'Tab',
        'General Information',
        'switched to General Information tab on profile page'
      );
    } else if (newValue === 1) {
      googleAnalyticsTriggerFn(
        'Tab',
        'Learning Preferences',
        'switched to Learning Preferences tab on profile page'
      );
    } else if (newValue === 2) {
      googleAnalyticsTriggerFn(
        'Tab',
        'Targets',
        'switched to Targets tab on profile page'
      );
    } else if (newValue === 3) {
      googleAnalyticsTriggerFn(
        'Tab',
        'Calibratons',
        'switched to Calibratons tab on profile page'
      );
    } else {
      googleAnalyticsTriggerFn(
        'Tab',
        'Badges',
        'switched to Badges tab on profile page'
      );
    }
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={ProfileTabsOptions}
      />
    </Box>
  );
};

export default ProfileTabs;
