/**
 * @function MyAccount
 * @param {none}
 * @returns It will contains the My Account page and also contains the 3 modals.
 */
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MyAccountStyling from './MyAccount.Module.scss';
import classNames from 'classnames';
import CustomSelect from '../Layout/Select';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import ProfileCard from '../Shared/Cards';
import Text from '../Shared/Text';
import Box from './Modals/Box';
import { Modal } from '@mui/material';
import Headings from '../Shared/Headings';
import AccountTabs from './AccountTabs';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
const MyAccount = () => {
  const accounts = useSelector((state) => state?.accounts) || {};
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white p-4">
      <Modal open={open}>
        <Box setOpen={() => setOpen(false)} />
      </Modal>
      <div className="row justify-content-between align-items-center">
        <div className="col-4">
          <Headings
            classToOverride={classNames(
              MyAccountStyling.primaryColor,
              'fw-bold'
            )}
            headingType="h3"
          >
            My Account{' '}
          </Headings>
        </div>
        <div className="col-2">
          <CustomSelect label="This Week" dropdownIcon={KeyboardArrowDown} />
        </div>
      </div>

      <div className="mt-5">
        <AccountTabs setTabIndex={setTabIndex} index={tabIndex} />
      </div>
      {/* <div>
        <p className={classNames(MyAccountStyling.accounts)}>Accounts</p>
        <LinearProgress
          variant="determinate"
          value={6.5}
          className={classNames(MyAccountStyling.linearProgress)}
        />
      </div> */}
      <div className="row mt-3">
        <div className="col-3 mt-2">
          <div
            className={classNames(
              MyAccountStyling.card1,
              'd-flex justify-content-center align-items-center h-100 w-100 flex-column'
            )}
          >
            {/* <div onClick={() => handleOpen()} className="">
                hello
              </div> */}
            <IconButton
              onClick={() => {
                setOpen(true);
                googleAnalyticsTriggerFn(
                  'Button',
                  'Add Account',
                  'Add Account button clicked on myaccount page'
                );
              }}
              color="primary"
            >
              <AddIcon />
            </IconButton>
            <Text
              textType={'t0'}
              classToOverride={classNames(MyAccountStyling.primaryColor)}
            >
              Add Account
            </Text>
          </div>
        </div>

        {/* -----------------Personal Profile------------- */}
        {accounts &&
          accounts?.Users &&
          accounts?.Users?.length > 0 &&
          accounts?.Users?.map((item) => {
            console.log('store', item);
            return (
              <ProfileCard
                name={item?.user_name}
                email={item?.email}
                para={''}
                image={item?.profile_image_url}
                info={'Personal Profile'}
                corporateId={''}
                type="personal"
              />
            );
          })}

        {/* ------------Corporate Profile--------------- */}
        {accounts &&
          accounts?.Neighbourhoods &&
          accounts?.Neighbourhoods?.length > 0 &&
          accounts?.Neighbourhoods?.map((item) => {
            console.log(item, 'items');
            return (
              <ProfileCard
                name={item?.community_name}
                email={item?.email}
                para={''}
                image={item?.profile_image_url}
                info={'Corporate Account'}
                corporateId={item?.client_id}
                type="corporate"
                mainAcc={item.main_acc}
              />
            );
          })}
        {/* ------------Owner Profile--------------- */}
        {accounts &&
          accounts?.Clients &&
          accounts?.Clients?.length > 0 &&
          accounts?.Clients?.map((item) => (
            <ProfileCard
              name={item?.user_name}
              email={item?.email}
              para={''}
              image={item?.profile_image_url}
              info={'Owner Account'}
              corporateId={item?.client_id}
              type="owner"
            />
          ))}

        {/* -------------Admin Profile-------------- */}
        {accounts &&
          accounts?.Admins &&
          accounts?.Admins?.length > 0 &&
          accounts?.Admins?.map((item) => (
            <ProfileCard
              name={item?.user_name}
              email={item?.email}
              para={''}
              image={item?.profile_image_url}
              info={'Admin Profile'}
              corporateId={item?.client_id}
              type="admin"
            />
          ))}

        {/* ------------------Parent Profile------------------ */}

        {accounts &&
          accounts?.Parents &&
          accounts?.Parents?.length > 0 &&
          accounts?.Parents?.map((item) => (
            <ProfileCard
              name={item?.user_name}
              email={item?.email}
              para={''}
              image={item?.profile_image_url}
              info={'Parent Profile'}
              corporateId={item?.client_id}
              type="parent"
            />
          ))}
      </div>
    </div>
  );
};

export default MyAccount;
