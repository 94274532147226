import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall } from '../../../utils/axios/axios';
import store from '../../../redux/store';
import { setCoprateClients } from '../../../redux/Actions/corpotateClients';
export const LicenseClients = [];

export const GetLicenseClients = async (LicenseSetter) => {
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      type: 'clients',
    };
    const res = await getApiCall(API_URLS.Get_Clients_Summary, {
      queryParams: reqBody,
      headers: {},
      isAuthorized: false,
    });
    const clients = {};
    res.clients.forEach((client) => {
      clients[client.client_id] = client;
    });
    res && store.dispatch(setCoprateClients(clients))
    LicenseSetter && LicenseSetter(res.clients);
    return res;
  } catch (err) {
    console.log('error in login', err);
  }
};

export const getAllGroups = async (setAllGroups, userId='', corpId='') => {
  try {
    const reqBody = {
      corp_id: corpId,
      user_id: userId,
    };
    const res = await getApiCall(API_URLS.allGroups, {
      queryParams: reqBody,
      headers: {},
      isAuthorized: true,
    });
    setAllGroups && setAllGroups(res?.allGroups);
    return res;
  } catch (err) {
    console.log('error in login', err);
  }
}
