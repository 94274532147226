import AllRegistrants from './allRegistrants';
import Citizens from './citizens';
import Admins from './admins';
import Neighbourhoods from './neighbourhoods';

const Network = ({
  selectedData,
  networkType,
  selectedSubData,
  setDrawerTypeData,
  ...props
}) => {
  switch (networkType) {
    case 'View':
      return <></>;
    case 'ALL_REGISTRANTS':
      return <AllRegistrants />;
    case 'CITIZENS':
      return <Citizens />;
    case 'ADMINS':
      return <Admins />;
    case 'NEIGHBOURHOODS':
      return <Neighbourhoods />;
    default:
      return <></>;
  }
};
export default Network;
