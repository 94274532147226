import { USER_CORPORATE_DATA } from '../../Types/UserCorporateDetails';

export const UserCorporateDetails = (state = [], actions) => {
  const { type, payload } = actions;
  switch (type) {
    case USER_CORPORATE_DATA:
      return payload;
    default:
      return state;
  }
};
