import React from 'react';
import classNames from 'classnames';
import { Paper, Stack, styled } from '@mui/material';
import ModuleStyling from '../../../../MyAccount/Modals/modal.Module.scss';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

const RoundedCircle = ({ numberOfItems, indexOfColoredItem }) => {
  const n = numberOfItems;
  const index = indexOfColoredItem;

  return (
    <Stack direction="row" spacing={1}>
      {[...Array(index)].map((index) => (
        <Item key={index} className="rounded-circle"></Item>
      ))}

      {[...Array(1)].map((index) => (
        <Item
          key={index}
          className={classNames(
            ModuleStyling.backgroundColor,
            'rounded-circle'
          )}
        ></Item>
      ))}

      {[...Array(n - index - 1)].map((index) => (
        <Item key={index} className="rounded-circle"></Item>
      ))}
    </Stack>
  );
};

export default RoundedCircle;
