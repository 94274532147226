import classNames from 'classnames';
import React from 'react';
import Headings from '../../../../../Shared/Headings';

const CreatorInfo = ({CreatorName, Role, ...props}) => {
  return (
    <div className={classNames('ms-4')}>
      <Headings headingType="h3" classToOverride="fw-bold">
        {CreatorName || ""}
      </Headings>
      <Headings headingType="h6">{Role || ""}</Headings>
    </div>
  );
};

export default CreatorInfo;
