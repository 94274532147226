import React, { useEffect, useState } from 'react';
import SignUpNav from '../../components/signUp/SignUpNav';
import c from './signUp.module.scss';
import classNames from 'classnames';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from '@mui/material';
import LeftPanelIndex from '../../components/signUp/LeftPanelIndex';
import RightPanelIndex from '../../components/signUp/RightPanelIndex';
import SignUpFooter from '../../components/signUp/Footer';
import CompletionLoading from './CompletionLoading';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SignUpPage = ({ ...props }) => {
  const [pageNo, setPageNo] = useState(2);
  const [userId, setUserId] = useState('');
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  const goToNextPage = () => {
    setPageNo(pageNo + 1);
  };

  useEffect(() => {
    if (
      props?.location?.state?.emailId !== undefined &&
      props?.location?.state?.page_number !== undefined
    ) {
      setPageNo(props?.location?.state?.page_number);
    }
  }, []);

  return (
    <div className={classNames(c.signUpPage)}>
      <SignUpNav pageNo={pageNo} />
      {pageNo === 9 ? (
        // <div className={classNames(c.signupContainer, "row g-0")}>
        <CompletionLoading />
      ) : (
        // </div>
        <div className={classNames(c.signupContainer, 'row g-0')}>
          <div className={classNames('col-md-6', c.leftPanel)}>
            <LeftPanelIndex goToNextPage={goToNextPage} pageNo={pageNo} />
          </div>
          <div
            className={classNames(
              'col-md-6 py-4 position-relative',
              c.rightPanel
            )}
          >
            {/* go back button */}
            {pageNo !== 2 && pageNo !== 4 && pageNo !== 11 && (
              <IconButton
                aria-label="back-btn"
                className="p-0 mb-5"
                onClick={() => {
                  if (pageNo > 1) setPageNo(pageNo - 1);
                }}
              >
                <ArrowBackIosNewIcon
                  className={`rounded-3 ${c.ArrowBackCustomStyle}`}
                />
              </IconButton>
            )}

            {/* right panel pages */}
            {/*goToNextPage is used to increment the pages and by default page is set to 1 */}
            <RightPanelIndex
              pageNo={pageNo}
              userId={userId}
              setUserId={setUserId}
              emailId={props?.location?.state?.emailId}
              goToNextPage={goToNextPage}
            />
            {(pageNo === 1 || pageNo === 2) && (
              <div className={classNames(c.SignupFooter)}>
                <SignUpFooter />
              </div>
            )}
            {/* <div className={classNames('text-center', c.leftPanelIconDiv)}>
              {Array.from(Array(11), (i, e) => {
                return (
                  <FiberManualRecordIcon
                    key={e}
                    className={`${
                      pageNo === e + 1 ? c.activeIcon : c.inActiveIcon
                    }`}
                  />
                );
              })}
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default SignUpPage;
