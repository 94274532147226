import { Box, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import loaderCss from './screenloader.module.scss';

const ScreenLoader = ({ classToOverride }) => {
  return (
    <Box
      className={classNames(
        loaderCss.Loader,
        'd-flex align-items-center justify-content-center w-100',
        classToOverride
      )}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default ScreenLoader;
