import React from 'react';
import classNames from 'classnames';
import c from '../../allocation.module.scss';
import Text from '../../../Shared/Text';
import { LinearProgress } from '@mui/material';
import { SuccessIcon } from '../../../Shared/Icons';
import ModalHeaderText from '../ModalHeaderText';
import { Button } from '@mui/material';

const CSVSuccess = ({ ...props }) => {
  return (
    <React.Fragment>
      <ModalHeaderText heading="Success!" text="CSV upload is complete" />
      <div className={classNames('text-center mt-5')}>
        <SuccessIcon />
        <Text
          textType="t0"
          classToOverride={classNames(c.primaryColor, 'mt-2')}
        >
          Success
        </Text>
        <center>
          <div className="col-md-6 mt-4">
            <LinearProgress variant="determinate" value={100} />
            <div
              className={classNames(
                c.primaryColor,
                'd-flex justify-content-between mt-1'
              )}
            >
              <Text textType="t0">0%</Text>
              <Text textType="t0">100%</Text>
            </div>
          </div>
        </center>
      </div>
      <div className={classNames(c.ButtonContainer)}>
        <Button variant="outlined">Cancel</Button>
        <Button variant="contained" className="ms-2 text-capitalize fw-bold">
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};

export default CSVSuccess;
