import React, { useState, useRef } from 'react';
import ModalBox from '../../../../Shared/ModalBox';
import Headings from '../../../../Shared/Headings';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Button } from '@mui/material';

const ResizeImage = ({ originalImage, setCropStep, setCroppedImage, groupHeading = false }) => {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const cropper = useRef(null);

  const getCroppedImage = () => {
    setCroppedImage(cropper?.current?.getImage()?.toDataURL());
    setCropStep(false);
  };

  return (
    <ModalBox openModal={true} classToOverride="w-50 text-center">
      <Headings headingType="h3" classToOverride="pt-4 pb-5 fw-bold">
        {groupHeading ? "Add Image" : "Edit Thumbnail"}
      </Headings>
      <div className="d-flex justify-content-evenly align-items-center">
        <AvatarEditor
          image={originalImage}
          height={180}
          width={180}
          border={30}
          borderRadius={100}
          scale={scale}
          rotate={rotate}
          ref={cropper}
        />
        <div className="w-25">
          <Headings headingType="h5" classToOverride="py-2">
            {`Zoom: ${Math.floor((scale - 1) * 10)}%`}
          </Headings>
          <Slider
            aria-label="image-scale-slider"
            min={1}
            max={11}
            step={0.1}
            onChange={(_, newValue) => setScale(newValue)}
          />
          <Headings headingType="h5" classToOverride="pt-3 pb-2">
            {`Rotate: ${rotate}°`}
          </Headings>
          <Slider
            aria-label="image-rotate-slider"
            min={0}
            max={360}
            step={1}
            onChange={(_, newValue) => setRotate(newValue)}
          />
        </div>
      </div>
      <div className="pt-5 pb-4">
        <Button
          className="text-capitalize me-4"
          variant="contained"
          onClick={getCroppedImage}
        >
          Crop
        </Button>
        <Button
          className="text-capitalize"
          variant="outlined"
          onClick={() => setCropStep(false)}
        >
          Cancel
        </Button>
      </div>
    </ModalBox>
  );
};

export default ResizeImage;
