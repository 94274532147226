import { getApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { decimalToTime } from '../../../utils/utility';
export const quadrants = (x_max = 3.5, y_max = 50) => ({
  id: 'quadrants',
  beforeDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { left, top, right, bottom },
      scales: { x, y },
    } = chart;
    const midX = x.getPixelForValue(x_max);
    const midY = y.getPixelForValue(y_max);
    ctx.save();
    ctx.fillStyle = options.topLeft;
    ctx.fillRect(left, top, midX - left, midY - top);
    ctx.fillStyle = options.topRight;
    ctx.fillRect(midX, top, right - midX, midY - top);
    ctx.fillStyle = options.bottomRight;
    ctx.fillRect(midX, midY, right - midX, bottom - midY);
    ctx.fillStyle = options.bottomLeft;
    ctx.fillRect(left, midY, midX - left, bottom - midY);
    ctx.restore();
  },
});

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      // backgroundColor: 'rgba(255,255,255)',
      //   titleColor: 'rgba(0, 168, 154, 1)',
      titleAlign: 'left',
      displayColors: true,
      boxWidth: 0,
      bodyAlign: 'left',
      //   titleMarginBottom: 10,
      // titleFont: { size: 20, weight: 'bold', lineHeight: 1.9 },
      bodyFont: { size: 16, weight: 'bold' },
      usePointStyle: true,
      boxHeight: 0,
      footerFont: { size: 40 },
      padding: '15',
      caretSize: 0,
      caretPadding: 10,
      //   borderColor: 'rgba(163, 174, 208, 1)',
      //   borderWidth: 1,
      cornerRadius: 0,
      yAlign: 'bottom',
      callbacks: {
        labelTextColor: function (context) {
          return '#FFFFFF';
        },
        // beforeLabel: function (context) {
        //   return 'Hi ' + context.parsed.y;
        // },
        beforeLabel: function (context) {
          if (context.parsed.y !== null && context.parsed.x !== null) {
            return `Name: ${context.raw.topic}`;
          }
          return '';
        },
        // ${context?.raw?.userOrCorp === "corp"?`Total Users: ${context?.raw?.total_user}, `:""}
        // Total Sessions: ${context?.raw?.session}, 
        label: function (context) {
          return `Study Time: ${decimalToTime(parseFloat(context.parsed.x)).toString()}`;
        },
        afterLabel : function (context) {
          return `Average Score: ${context?.raw.average_score}% \n---------------------------------------------------------------\n`
        },
        labelPointStyle: function (context) {
          return {
            pointStyle: 'star',
            rotation: 0,
          };
        },
        // labelColor: function (context) {
        //   const item = context?.parsed;
        //   console.log('ite,', item);
        //   return {
        //     borderColor: 'rgb(255, 255, 255)',
        //     backgroundColor: 'rgb(0,0,0)',
        //     borderWidth: 2,
        //     borderDash: [2, 2],
        //     borderRadius: 2,
        //   };
        //   if (item?.x <= 3 && item?.y <= 50) {
        //     return {
        //       //   borderColor: 'rgb(255, 255, 255)',
        //       backgroundColor: 'rgb(0,255,0)',
        //       //   borderWidth: 2,
        //       //   borderDash: [2, 2],
        //       //   borderRadius: 2,
        //     };
        //   } else if (item.x > 3 && item.y <= 50) {
        //     return {
        //       borderColor: 'rgb(0, 0, 255)',
        //       backgroundColor: 'rgb(255, 0, 0)',
        //       borderWidth: 2,
        //       borderDash: [2, 2],
        //       borderRadius: 2,
        //     };
        //   } else if (item.x > 3 && item.y > 50) {
        //     return {
        //       borderColor: 'rgb(0, 0, 255)',
        //       backgroundColor: 'red',
        //       borderWidth: 2,
        //       borderDash: [2, 2],
        //       borderRadius: 2,
        //     };
        //   } else {
        //     return {
        //       borderColor: 'rgb(0, 0, 255)',
        //       backgroundColor: 'red',
        //       borderWidth: 2,
        //       borderDash: [2, 2],
        //       borderRadius: 2,
        //     };
        //   }
        // },
      },
    },
    title: {
      text: 'Assessment (in %)',
      display: true,
      position: 'left',
    },

    quadrants: {
      topLeft: 'rgba(0, 192, 181,0.2)',
      topRight: 'rgba(194, 160, 237,0.2)',
      bottomRight: 'rgba(149, 149, 161,0.2)',
      bottomLeft: 'rgba(251, 127, 61,0.2)',
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 10,
        min: 0,
        max: 100,
      },
    },
  },
};

export const labels = [1, 2, 3, 4, 5, 6];

export const getExpertiseData = async (ext_id, entity) => {
  try {
    const params = {
      ext_id,
      entity,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.expertise_journey, paramsData);
    return response?.data || [];
  } catch (err) {
    console.log('Error in getExpertise Data::', err);
  }
};
