import React from 'react';
import classNames from 'classnames';
import c from '../../allocation.module.scss';
import Text from '../../../Shared/Text';
import { Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { Link } from 'react-router-dom';
import ModalHeaderText from '../ModalHeaderText';

const EmployeeRecords = ({ setType, setStatus, setModalStatus, ...props }) => {
  return (
    <React.Fragment>
      <ModalHeaderText
        heading="Member Records"
        text="How would you like to intgrate your member records?"
      />
      <div
        className={classNames(
          'd-flex flex-column align-items-center justify-content-center mt-5 h-50'
        )}
      >
        <div
          className={classNames(
            c.InviteByEmail,
            'col-md-6 mt-2 py-4 text-center'
          )}
          onClick={() => {
            setType('EmailInvite');
            setStatus('InviteByEmail');
          }}
        >
          <MailOutlineOutlinedIcon
            className={classNames(c.primaryColor, c.EmailIcon)}
          />
          <Text textType="t0" classToOverride={classNames(c.primaryColor)}>
            Enter Email To Invite
          </Text>
        </div>
      </div>

      <div className={classNames(c.ButtonContainer)}>
        <Button variant="outlined" onClick={() => setModalStatus(false)}>
          Cancel
        </Button>
        {/* <Button variant="contained" className="ms-2 text-capitalize fw-bold">
          Continue
        </Button> */}
      </div>
    </React.Fragment>
  );
};
export default EmployeeRecords;
