export const API_URLS = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  getNetworkSummary: '/v2/networkSummary',
  getNetworkUsers: '/v1/networkUsers',
  updateIsAdmin: '/v1/updateIsAdmin',
  activate_or_deactivate: '/v1/users/activate_or_deactivate',
  accounts: '/v1/accounts',
  productCategories: '/v1/product_categories',
  productTypes: '/v1/displayProductTypes',
  addProductTypes: '/v1/product_types',
  getImageUrl: '/v1/media/signed_url',
  orders: '/v1/orders',
  addCategories: '/v1/categories',
  getShopsSummary: '/v2/shopsSummary',
  pendingRequests: '/v1/pending_requests',
  products: '/v1/products',
  addSubProduct: '/v1/products/bulkCreate',
  approveDocuments: '/v1/athlete_request_approval_action',
  getDocument: '/v1/documents',
  getproducts: 'v1/categories_products',
  getProductByTypes: '/v1/getProductByTypes',
  getSports: '/v1/sports',
  addSchool: '/v1/schools',
  getEstates: '/v1/estates',
  getEstateInfo: '/v1/estate_info',
  livefeed: '/v1/campaigns/livefeed',
  campaigns: '/v1/campaigns/',
  getNeighborhoods: '/v1/networkUsers',
  getNeighborhoodMemers: '/v1/users/getNeighborhoodAllocation',
  setNeighborhoodadmin: '/v1/users/setNeighborhoodadmin',
  removeUserFromNeighborhood: 'v1/users/removeUserFromNeighborhood',
};
