import React from 'react';
import c from '../../../learningPlans.Module.scss';
import Headings from '../../../../Shared/Headings';
import classNames from 'classnames';
import Text from '../../../../Shared/Text';
import ModuleCard from '../ModuleCard';

const ModuleContentLayout = (props) => {
  return (
    <>
      <div className={c.moduleContentHeading}>
        <Headings
          headingType="h5"
          classToOverride={classNames('fw-bold', c.primaryColor)}
        >
          Build Your Own Plan
        </Headings>

        <Text textType="t0" classToOverride="fw-normal">
          Add the content you like in their own module
        </Text>
      </div>

      <ModuleCard title="ADD NEW MODULE" showAddModuleForm={true} />

      {props?.children}
    </>
  );
};

export default ModuleContentLayout;
