import { useState } from 'react';
import { CustomTable } from '../../../shared/Table';
import CustomTableHead from '../../../shared/Table/TableHead';
import { TableBody, TableRow } from '@mui/material';
import {
  TableCellSwitch,
  TableCellText,
  TableCellButton,
  TableCell15,
} from '../../../shared/Table/TableCell';
import PanelHeader from '../../../../components/shared/Header/PanalHeader';
import { neighborhoodMembersTableHeader } from '../../../../helpers/networks/neighbourhoods';
import { useMutation, useQuery } from 'react-query';
import {
  getNeighborhoodMemebers,
  removeFromNeighborhood,
  setNeighborhoodadmin,
} from '../../../../services/networks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScreenLoader from '../../../shared/ScreenLoader';
import { useHistory } from 'react-router-dom';

const NeighborhoodMembers = ({
  corporateId,
  setShowMemebers = () => {},
  showBackButton = true,
  showPanelHeader = true,
  setSearch,
  searchString = '',
}) => {
  const history = useHistory();

  const [sort, setSort] = useState({ name: 'asc' });
  const [sortBy, setSortBy] = useState('full_name');

  const { data, isFetching, refetch } = useQuery(
    ['neighborhoodMemebers', corporateId],
    () => getNeighborhoodMemebers(corporateId),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: setAdmin, isLoading: isSetting } = useMutation(
    ({ isAdmin, userId, clientId, email}) =>
      setNeighborhoodadmin(isAdmin, userId, clientId, email),
    {
      onSuccess: refetch,
    }
  );

  const { mutate: removeUser, isLoading: isRemoving } = useMutation(
    ({ userId, clientId }) => removeFromNeighborhood(userId, clientId),
    {
      onSuccess: refetch,
    }
  );

  const sortHandler = (sortCellName) => {
    setSort({
      ...sort,
      [sortCellName]: sort[sortCellName] === 'asc' ? 'desc' : 'asc',
    });
    setSortBy(sort[sortCellName] === 'asc' ? '-full_name' : 'full_name');
  };

  const compare = (sortBy) => {
    var sortOrder = 1;
    if (sortBy[0] === '-') {
      sortOrder = -1;
      sortBy = sortBy.substr(1);
    }
    return function (a, b) {
      var result = a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
      return result * sortOrder;
    };
  };

  const handleLinkClick = (id) => () => history.push(`/employee-details/${id}`);

  const getLastestTopics = (topics) => {
    if (!topics) return;
    topics.length = 3;
    let someTopic = topics?.filter(function (element) {
      return element !== undefined;
    });
    return someTopic?.join(',');
  };

  if (isFetching || isSetting || isRemoving) return <ScreenLoader />;

  return (
    <div className="w-100">
      {showPanelHeader && (
        <PanelHeader
          heading="Neighborhoods"
          isSearch={true}
          isSelect={false}
          setSearch={setSearch}
        />
      )}
      {showBackButton && (
        <div
          className="d-flex px-4 pb-2"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowMemebers(false)}
        >
          <ArrowBackIcon />
          <div className="pl-2">Back</div>
        </div>
      )}
      <CustomTable classToOverride="w-100 px-4 mt-2">
        <CustomTableHead
          headerData={neighborhoodMembersTableHeader}
          sortHandler={sortHandler}
          sort={sort}
        />
        <TableBody>
          {data
            .filter(({ full_name, topics, user_info }) =>
              full_name?.toLowerCase().trim().includes(searchString) ||
              user_info.email?.toLowerCase().trim().includes(searchString) ||
              topics.some(topic => topic.toLowerCase().trim().includes(searchString))
            )
            .sort(compare(sortBy))
            .map((m, i) => (
              <TableRow hover key={i}>
                <TableCellText text={m?.full_name} />
                <TableCellText text={m?.user_info?.email || ''} />
                <TableCellText text={m?.sessions} />
                <TableCellText text={getLastestTopics(m?.topics)} />
                <TableCellSwitch
                  checked={m.is_admin}
                  onClick={() =>
                    setAdmin({
                      isAdmin: !m.is_admin,
                      userId: m.user_id,
                      clientId: m.client_id,
                      email: m.user_info.email
                    })
                  }
                />
                <TableCell15
                  textOverride={'mb-0'}
                  style="cursor-pointer"
                  handleClick={handleLinkClick(m?.user_info?.user_client_id)}
                  text="View"
                />
                <TableCellButton
                  btnName="Remove"
                  onClick={() =>
                    removeUser({
                      userId: m.user_id,
                      clientId: m.client_id,
                    })
                  }
                />
              </TableRow>
            ))}
        </TableBody>
      </CustomTable>
    </div>
  );
};
export default NeighborhoodMembers;
