/**
 * @function HomePage
 * renders /home
 */
/*global chrome */
import React, { useEffect, useState } from 'react';
import c from './home.module.scss';
import classNames from 'classnames';
import CapabilitiesToStudy from '../../components/Home/CapabilitiesToStudy';
import Community from '../../components/Home/community/Community/Community';
import WelcomeSection from '../../components/Shared/WelcomeSection';
import Stats from '../../components/Home/Stats';
// import HighLights from '../../components/Home/Highlights';
import QuickStats from '../../components/Home/QuickStats';
// import AssignedCapabilities from '../../components/Home/AssignedCapabilities';
import Sessions from '../../components/Home/Sessions';
// import ExpertiseJourney from '../../components/Home/ExpertiseJourney';
import ActionableInsights from '../../components/Home/ActionableInsights';
import LearningPersona from '../../components/Home/LearningPersona';
import LearningBehavior from '../../components/Home/LearningBehavior';
import {
  TopLearnersData,
  TrendingCoursesData,
} from '../../helpers/Home/Community';
import { CHROME_EXTENSION_ID } from '../../config/Constants';
import Highlights2 from '../../components/Home/Highlights_2.0';
import SelectTopic from '../../components/Home/SelectTopic';
import { useSelector } from 'react-redux';
import ExpertiseJourney2 from '../../components/Home/ExpertiseJourney2.0';
import Nudges from '../../components/Shared/Nudges';
import { getLearningData } from '../../helpers/Learning';
// import { Helmet } from 'react-helmet-async';


const Home = () => {
  // const sessionData = useSelector(state => state.sessionData)
  const isTopicModal = useSelector((state) => state.isTopicModal);
  const user_ext_id = useSelector(
    (state) => state?.generalInformation?.external_id
  );
  const userData = useSelector((state) => state?.generalInformation);
  const [emotionData, setEmotionData] = useState([]);
  const [browsingData, setBrowsingData] = useState({});
  const [nudgesData, setNudgesData] = useState({});
  const [laoding, setLoading] = useState(false);
  const userCorpDetail = useSelector((state) => state?.userCorporateDetails);

  useEffect(async () => {
    if (user_ext_id) {
      setLoading(true)
      const data = await getLearningData(user_ext_id);
      setLoading(false)
      // console.log('data:::::', data);
      setEmotionData(data?.expression_data);
      setBrowsingData(data);
      setNudgesData(data?.session_nudges);
    }
  }, [user_ext_id, userCorpDetail]);
  // useEffect(() => {
  //   if (chrome.runtime) {
  //     const tmp = chrome.runtime.connect(CHROME_EXTENSION_ID);
  //     const port = tmp;
  //     // setPort(tmp);
  //     if (port) {
  //       port.onMessage.addListener((msg) => {
  //         if (msg.update_ui) {
  //           extUpdateUI();
  //         }
  //       });
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   if (chrome.runtime) {
  //     const tmp = chrome.runtime.connect(CHROME_EXTENSION_ID);
  //     const port = tmp;
  //     if (port) {
  //       port.onMessage.addListener((msg) => {
  //         if (msg.update_ui) {
  //           extUpdateUI();
  //         }
  //       });
  //     }
  //   }
  // }, []);
  return (
    <>
      {/* <Helmet prioritizeSeoTags>
        <meta property="og:title" content={`"Ahura AI: Meet your Future`} />
        <meta
          name="description"
          content="Equipping workforce to thrive in the age of AI"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:description"
          content="Equipping workforce to thrive in the age of AI"
        />
        <meta
          property="og:image"
          content="https://ahuraai.com/wp-content/uploads/2022/07/african-american-girl-1024x576.png"
        />
      </Helmet> */}
      {isTopicModal ? (
        <SelectTopic />
      ) : (
        <div className={classNames(c.homepage)}>
          <div className="row g-0">
            <WelcomeSection />
          </div>
          <div className="row g-0">
            <Stats />
          </div>
          <div className="row g-0 mt-4">
            <Highlights2 userData={userData} />
          </div>
          {/* <div className="row mt-5 g-0">
            <HighLights />
          </div> */}
          <CapabilitiesToStudy />
          <Community />
          {/* <div className="row mt-4 g-0">
            <ActionableInsights />
          </div> */}
          <div className="row mt-4 g-0">
            <ExpertiseJourney2 external_id={user_ext_id} entity="user" />
          </div>
          {/* <div className="row mt-4 g-0">
            <QuickStats />
          </div> */}
          {/* <div className={`row mt-4 g-0`}>
            <LearningPersona />
          </div> */}
          {/* <div className="row mt-4 g-0">
            <AssignedCapabilities />
          </div> */}
          <div className={`row mt-4 g-0`}>
            <LearningBehavior
              user_ext_id={user_ext_id}
              emotion_data={emotionData}
              browsing_data={browsingData}
              setEmotionData={setEmotionData}
              setBrowsingData={setBrowsingData}
            />
          </div>
          <div className="row mt-4 g-0">
            <Nudges isShowloader={laoding} nudgesData={nudgesData} />
          </div>
          <div className="row mt-4 g-0">
            <Sessions extId={user_ext_id} />
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
