import store from '../../../redux/store';
import {
  CAMPAIGN_TYPES_IDS,
  CLIENT_ID,
  MODULE_ID,
} from '../../../config/Constants';
import {
  postApiCall,
  getApiCall,
  deleteApiCall,
} from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';

export const iconData = [
  { id: 'libraryAdd', name: 'LibraryAdd', active: true },
  { id: 'filterAlt', name: 'FilterAlt', active: false },
];

export const addNewModuleFormData = [
  {
    id: 'name',
    inputLabel: 'Module Name',
    textFieldLabel: 'Enter Section Name',
  },
  {
    id: 'description',
    inputLabel: 'Description',
    textFieldLabel: 'Enter Description',
  },
];

export const validateModuleForm = (values) => {
  const { name, description } = values;
  let errors = {};
  if (name === '') {
    errors.name = 'Module Name is required';
  }
  if (description === '') {
    errors.description = 'Description is required';
  }
  return errors;
};

export const addCustomizedContentFormData = [
  {
    id: 'title',
    inputLabel: 'Customized Content Title',
    textFieldLabel: 'Enter Here',
  },
  {
    id: 'url',
    inputLabel: 'Content URL',
    textFieldLabel: 'Enter Description',
  },
  {
    id: 'description',
    inputLabel: 'Description',
    textFieldLabel: 'Enter Description',
  },
  {
    id: 'category',
    inputLabel: 'Category',
    textFieldLabel: 'Enter Description',
  },
];

export const validateCustomizedContentForm = (values) => {
  const { title, url, description, category, type } = values;
  let errors = {};
  if (title === '') {
    errors.title = 'Content Title is required';
  }
  if (url === '') {
    errors.url = 'Content URL is required';
  }
  if (description === '') {
    errors.description = 'Description is required';
  }
  if (category === '') {
    errors.category = 'Category is required';
  }
  if (type === '') {
    errors.type = 'Content Type is required';
  }
  return errors;
};

export const contentTypeOptions = [
  'audio',
  'article',
  'podcast',
  'video',
  'courses',
];

export const providers = ['Coursera', 'YouTube', 'Udemy', 'Google'];

//functions

//function to create a Module for a Plan or Lesson for a Module
export const createPlanModuleOrLesson = async (
  moduleOrLessonParams,
  parentId
) => {
  try {
    const { first_name, last_name, email, user_id, id } =
      store.getState()?.generalInformation;

    const { name, description, type } = moduleOrLessonParams;

    const body = {
      heading: name ?? '',
      description: description ?? '',
      image_url: '',
      category_id: null,
      type_id: type ?? null,
      parent_id: parentId,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      author_name: first_name + last_name,
      author_email: email,
      author_id: user_id,
      created_by: user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: user_id,
      user_client_id: id,
      client_id: CLIENT_ID,
      is_public: false,
    };
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await postApiCall(API_URLS?.campaigns, body, paramsData);
    return response;
  } catch (error) {
    console.log('Error in Module Creation', error);
  }
};

export const fetchPlanModuleOrLessonData = async (parentId, user_client_id) => {
  try {
    const queryParams = {
      module_id: MODULE_ID.CAMPAIGN,
      parent_id: parentId,
      client_id: CLIENT_ID,
      user_client_id: user_client_id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      sub_campgain: 'lessons',
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(
      API_URLS.all_parent_campaigns,
      paramsData
    );
    return response?.data;
  } catch (error) {
    console.log('Error in fetching Plan Module or Lesson data', error);
  }
};

export const deletePlanLesson = async (lessonId) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await deleteApiCall(
      `${API_URLS.campaigns}/${lessonId}`,
      paramsData
    );
    return response;
  } catch (error) {
    console.log('Error in deleting Plan lesson', error);
  }
};
