import React, { useState } from 'react';
import classNames from 'classnames';
import c from '../forgotPassword.module.scss';
import Headings from '../../Shared/Headings';
import { TextField, Button, CircularProgress } from '@mui/material';
import { Resources } from '../../../config/Resources';
import { useFormik } from 'formik';
import { updatePassword, validatePassword } from '../../../helpers/ForgotPassword';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const UpdatePassword = ({ mail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const initialValues = {
    confirm_password: '',
    password: '',
  };
  const formik = useFormik({
    initialValues,
    validate:validatePassword,
    onSubmit: (values) => {
      updatePassword(values, mail, setIsLoading, history);
    },
  });

  return (
    <React.Fragment>
      <div className={classNames('text-center text-white')}>
        <img
          src={Resources.images.AhuraHeaderLogo2}
          className={classNames(c.Logo)}
        />
      </div>

      <div className={classNames('p-4')}>
        <div>
          <Headings
            headingType="h3"
            classToOverride={classNames('text-center fw-bold text-white')}
          >
            Update your Password!
          </Headings>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <TextField
              className={classNames(c.TextBox, 'w-100')}
              label="Password"
              type="password"
              id="password"
              onChange={formik?.handleChange}
              value={formik?.values?.password}
              helperText={formik?.errors?.password}
              onBlur={formik?.handleBlur}
              error={formik?.errors?.password ? true : false}
              name="password"
            />
          </div>

          <div className="my-4">
            <TextField
              className={classNames(c.TextBox, 'w-100')}
              label="Confirm Password"
              type="password"
              id="confirm_password"
              onChange={formik?.handleChange}
              value={formik?.values?.confirm_password}
              helperText={formik?.errors?.confirm_password}
              onBlur={formik?.handleBlur}
              error={formik?.errors?.confirm_password ? true : false}
              name="confirm_password"
            />
          </div>

          <div className={classNames('mt-5')}>
            <Button
              variant="contained"
              className={classNames('w-100', c.ForgotBtn)}
              type="submit"
            >
              {isLoading ? <CircularProgress color="inherit" /> : 'Update'}
            </Button>
          </div>
        </form>

        <div className={classNames('mt-5')}>
        <Link
            to="/signUp"
            className={classNames('text-decoration-none text-white')}
          >
            <Headings
              headingType="h7"
              classToOverride={classNames(
                'text-center fw-bold text-white',
                c.cursorPointer,
                c.primaryColor
              )}
            >
              Create a New Account
            </Headings>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdatePassword;
