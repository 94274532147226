/**All the left panel pages are listed here
 * @param {number} pageNo the page number
 * @returns the page
 */

import React from 'react';
import LeftPanelLayout from '../LeftPanelLayout';
import SignupStyling from '../signUp.Module.scss';
import { Button } from '@mui/material';
import classNames from 'classnames';
const LeftPanelIndex = ({ pageNo, goToNextPage }) => {
  return (
    <>
      {(pageNo === 1 || pageNo === 2 || pageNo === 3) && (
        <LeftPanelLayout
          pageNo={pageNo}
          head="AHURA AI"
          desc="Get upskilled and ready for your future job"
        />
      )}
      {pageNo === 4 && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="WORKERS"
          head="Enhanced Personalized Learning"
          desc="3-5x faster learning with reduced distraction and more fun and enjoyment."
        />
      )}

      {(pageNo === 5 || pageNo === 6) && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="WORKERS"
          head="Increase Earning Potential"
          desc="Indentify and focus on specific skills needed for promotion and tangible impace."
        />
      )}
      {pageNo === 7 && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="Employers"
          head="Employees at their Best"
          desc="Support learning culture, increase learning productivity, identify new relevant skills and capabilities."
        />
      )}
      {pageNo === 8 && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="Employers"
          head="Maximize Production "
          desc="Fine tune and sharpen competitive edge to address an increasingly aggressive market landscape."
        />
      )}
      {(pageNo === 9 || pageNo === 10) && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="Employers"
          head="Employees at their Best"
          desc="Support learning culture, increase learning productivity, identify new relevant skills and capabilities."
        />
      )}
      {pageNo === 11 && (
        <LeftPanelLayout
          pageNo={pageNo}
          smallHead="Lorem Ipsum"
          head="Face Calibrations"
          desc="Lorem Ipsum Lorem IpsumLorem IpsumLorem Ipsum"
        />
      )}
      
    </>
  );
};
export default LeftPanelIndex;
