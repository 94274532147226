import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import c from './landing.module.scss';
import { PlayerAuto } from '../../components/Shared/Player';
import CloseIcon from '@mui/icons-material/Close';
import LandingCover from '../../components/landing/LandingCover';
import Capability from '../../components/landing/Capability';
import Testimonial from '../../components/landing/Testimonials';
import VideoSection from '../../components/landing/VideoSection';
import Activate from '../../components/landing/Activate';
import SignUpNav from '../../components/signUp/SignUpNav';
import { SignInFooter } from '../../components/signIn/Footer';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = ({ ...props }) => {
  const [playerVideo, setPlayerVideo] = useState(null);
  const [playerStatus, setPlayerStatus] = useState(false);

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  const SetPlayerVideo = (status, url) => {
    setPlayerStatus(status);
    setPlayerVideo(url);
  };

  return (
    <React.Fragment>
      <Helmet>
      <meta property="og:description" content="Expertly curated content for exciting new jobs of tomorrow" />
        <meta property="og:image" content="https://ahuraai.com/wp-content/uploads/2022/07/african-american-girl-1024x576.png"/>
      </Helmet>
      <SignUpNav pageNo={1} />
      <div
        id="landingMainContainer"
        className={classNames('col-md-12 col-lg-12', c.MainContainer)}
      >
        <LandingCover SetPlayerVideo={SetPlayerVideo} />
        <Capability />
        <Testimonial />
        <VideoSection SetPlayerVideo={SetPlayerVideo} />
        <Activate />
        {playerStatus && (
          <div className={classNames(c.LandingPlayer)}>
            <PlayerAuto
              url={playerVideo}
              width="100vw"
              height="100vh"
              playing={true}
              loop={false}
              onEnded={() => {
                SetPlayerVideo(false, null);
              }}
            />
            <div
              className={c.ClosePlayer}
              onClick={() => SetPlayerVideo(false, null)}
            >
              <CloseIcon />
            </div>
          </div>
        )}
        <SignInFooter />
      </div>
    </React.Fragment>
  );
};
export default LandingPage;
