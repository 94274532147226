import { CORPORATE_CLIENTS } from "../../Types/corpotateClients";

export const CorporateClients = (state = [], actions) => {
  const { type, payload } = actions;
  switch (type) {
    case CORPORATE_CLIENTS:
      return payload;
    default:
      return state;
  }
};
