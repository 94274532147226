import React from 'react';
import classNames from 'classnames';
import c from '../../landing.module.scss';
import Headings from '../../../Shared/Headings';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Text from '../../../Shared/Text';
import { Resources } from '../../../../config/Resources';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  initialValues,
  UserDataValidationSchema,
  onSubmit,
} from '../../../../helpers/Landing';
import { Link } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const LeftPanel = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserDataValidationSchema,
    onSubmit: (values) => onSubmit(values, history, formik.setFieldError),
  });
  return (
    <div className={classNames('col-md-4 col-lg-4 px-0')}>
      <div className={classNames('p-5')}>
        <Headings
          headingType="h1"
          classToOverride={classNames('text-white fw-bold my-2')}
        >
          AHURA AI
        </Headings>
        <Headings
          headingType="h3"
          classToOverride={classNames('text-white my-2')}
        >
          RETRAINING WORKERS FOR THE JOBS OF THE FUTURE 5x FASTER.
        </Headings>
        <TextField
          className={classNames(c.TextBox, 'w-100 mt-4')}
          label="Email"
          type="text"
          id="email"
          name="email"
          value={formik?.values?.email}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            (formik?.touched?.email || formik?.touched.sumbited) &&
            formik?.errors?.email
              ? true
              : false
          }
          helperText={
            (formik?.touched?.email || formik?.touched.sumbited) &&
            formik?.errors?.email
          }
        />
        <Button
          className={classNames(c.ButtonInvite, 'mt-4')}
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
            googleAnalyticsTriggerFn(
              'Button',
              'Activate',
              'Activate button clicked on landing page'
            );
          }}
        >
          <img
            src={Resources.images.LandingPageCourseIcon}
            className={classNames(c.LandingPageCourseIcon, 'me-2')}
            alt="cover"
          />
          <Headings headingType="h5" classToOverride={classNames('text-white')}>
            Activate
          </Headings>
        </Button>
        <Text textType="t1" classToOverride={classNames('text-white mt-4')}>
          By sharing your email and selecting “Activate”, you agree to download
          the Ahura AI extension and the{' '}
          <b>
            <Link
              to="/terms-and-conditions"
              className={classNames('text-decoration-none text-white')}
              target="_blank"
            >
              Terms of Service
            </Link>
          </b>{' '}
          and{' '}
          <b>
            <Link
              to="/privacy-policy"
              className={classNames('text-decoration-none text-white')}
              target="_blank"
            >
              Privacy Policy
            </Link>
          </b>
          .
        </Text>
      </div>
    </div>
  );
};

export default LeftPanel;
