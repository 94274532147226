/**
 * @function Chart
 * Chart for Actionable Insights
 */
import { ActionableChartData } from '../MockData/index';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
        drawBorder: true,
      },
    },
    x: {
      beginAtZero: true,
      grid: {
        display: false,
        drawBorder: true,
      },
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: 'rgba(0, 168, 154, 0.18)',
      titleColor: 'rgba(0, 168, 154, 1)',
      titleAlign: 'left',
      displayColors: true,
      boxWidth: 0,
      bodyAlign: 'left',
      titleMarginBottom: 10,
      titleFont: { size: 16, weight: 'bold', lineHeight: 1.9 },
      usePointStyle: true,
      boxHeight: 0,
      footerFont: { size: 16 },
      padding: '15',
      caretSize: 15,
      yAlign: 'bottom',
      callbacks: {
        labelTextColor: function (context) {
          return '#000000';
        },
        labelPointStyle: function (context) {
          return {
            pointStyle: 'star',
            rotation: 0,
          };
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 20,
        boxWidth: 20,
        padding: 30,
        font: {
          size: 19,
        },
      },
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

export const data = {
  labels: ActionableChartData.labels,
  datasets: [
    {
      label: 'Nudges',
      data: ActionableChartData.dataNudges,
      borderColor: '#00A89A',
      backgroundColor: '#00A89A',
      lineTension: 1,
    },
    {
      label: 'Distracted',
      data: ActionableChartData.dataDistracted,
      borderColor: '#FB7F3D',
      backgroundColor: '#FB7F3D',
      lineTension: 1,
    },
  ],
};
