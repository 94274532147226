import { combineReducers } from 'redux';
import {
  LearningPlanData,
  CustomizedLearningPlanData,
  DisplayAddingPlanForm,
} from './LearningPlans';
import { UserData } from './UserData';
import { GeneralInformation } from './ProfileDetails';
import { LoaderReducer } from './Loader';
import * as common from './Common';
import { SessionRecordReducer } from './SessionRecord';
import { AllSessionReducer } from './AllSessionData';
import {
  BarData,
  IsExtensionActive,
  IsTopicModal,
  SessionData,
  SessionReport,
  isSessionDetailsOpen,
  IsExtensionBtnEnabled
} from './ExtensionHandler';
import { UserCorporateDetails } from './UserCorporateDetails';
import { CorporateClients } from './corpotateClients';
import { USER_LOGGED_OUT } from '../Types/UserLoggedOut';
import { AccountsData } from './Accounts';

const appReducer = combineReducers({
  learningPlanData: LearningPlanData,
  customizedLearningPlanData: CustomizedLearningPlanData,
  displayAddingPlanForm: DisplayAddingPlanForm,
  userData: UserData,
  generalInformation: GeneralInformation,
  loaderState: LoaderReducer,
  IsExtensionActive: IsExtensionActive,
  IsExtensionBtnEnabled:IsExtensionBtnEnabled,
  toast_error: common.SET_TOAST_ERROR,
  barData: BarData,
  sessionData: SessionData,
  isTopicModal: IsTopicModal,
  userCorporateDetails: UserCorporateDetails,
  corporateClients: CorporateClients,
  sessionReport: SessionReport,
  profileCompletionStatus: common.SET_PROFILE_COMPLETION_STATUS,
  sessionRecord: SessionRecordReducer,
  allSessionData: AllSessionReducer,
  isSessionDetailsOpen: isSessionDetailsOpen,
  accounts: AccountsData,
  selectedCategory: common.SET_SELECTED_CATEGORY,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === USER_LOGGED_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
