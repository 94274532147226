/**
 * @function QuickStatsDataAnalytics
 */

import React from 'react';
import style from '../../home.Module.scss';
import classNames from 'classnames';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import { Tooltip, IconButton } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const QuickStatsDataAnalytics = ({ heading, time, status, comparedText }) => {
  let statusInFloat = parseFloat(status);
  return (
    <div className="col-md-4 mt-4 gx-3">
      <div className="d-flex align-items-center">
        <Text
          textType="t1"
          classToOverride={classNames(
            style.text1,
            'text-nowrap',
            style.fontWeight600,
            style.primaryColor
          )}
        >
          {heading}
          <Tooltip title={heading}>
            <IconButton className="px-0">
              <InfoRoundedIcon className={style.quickStatsInfoIcon} />
            </IconButton>
          </Tooltip>
        </Text>
      </div>
      <div className="d-flex align-items-center">
        <Headings
          headingType="h4"
          text={time}
          classToOverride="fw-bold text-nowrap"
        >
          {time}
        </Headings>
        {status &&
          (statusInFloat < 0 ?? false ? (
            <ArrowDropDownIcon className={style.text4} />
          ) : (
            <ArrowDropUpIcon className={style.text2} />
          ))}
        {/* {!time && <div className="ms-3"></div>} */}
        <Text
          textType="t2"
          classToOverride={classNames(
            'fw-normal',
            statusInFloat < 0 ? style.text4 : style.text2
          )}
        >
          {status ? status?.replace('-', '') : ''}
        </Text>
      </div>
      {/* <Text
        textType="t2"
        classToOverride={classNames('fw-normal', style.text3)}
      >
        {comparedText ? comparedText : 'Compared to last month'}
      </Text> */} 
    </div>
  );
};

export default QuickStatsDataAnalytics;
