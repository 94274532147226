/**
 * @function RightPanelIndex
 * @param {number} pageNo
 * @param {number} goToNextPage
 * @returns It contains all the pages at the right panel.
 */

import React from 'react';
import RightPanelPage1 from '../RightPanelPages/RegisterWelcome';
import RightPanelPage2 from '../RightPanelPages/RegisterInputs';
import RightPanelPage3 from '../RightPanelPages/PasswordSet';
// import RightPanelPage4 from "../RightPanelPages/EducationLevel";
import RightPanelPage5 from '../RightPanelPages/ExtensionDownload';
import RightPanelPage6 from '../RightPanelPages/ExtensionRedirectSuccess';
import RightPanelPage7 from '../RightPanelPages/LicensePurchase';
import RightPanelPage8 from '../RightPanelPages/AccessGranted';
import RightPanelPage9 from '../RightPanelPages/Jumpstart'
import Otp from '../RightPanelPages/Otp';
// import RightPanelPage11 from "../RightPanelPages/FaceCalibration";
const RightPanelIndex = ({ pageNo, goToNextPage, emailId, userId, setUserId  }) => {
  return (
    <>
    {pageNo === 1 && <RightPanelPage1 goToNextPage={goToNextPage} />}
      {pageNo === 2 && <RightPanelPage2 emailId={emailId} goToNextPage={goToNextPage} />}
      {pageNo === 3 && <RightPanelPage3 goToNextPage={goToNextPage} setUserId={setUserId} />}
      {/* {pageNo === 4 && <RightPanelPage4 goToNextPage={goToNextPage} />} */}
      {/*pageNo === 4 && <Otp goToNextPage={goToNextPage} emailId={emailId} />*/}
      {pageNo === 4 && <RightPanelPage5 goToNextPage={goToNextPage} />}
      {pageNo === 5 && <RightPanelPage6 goToNextPage={goToNextPage} />}
      {pageNo === 6 && <RightPanelPage7 goToNextPage={goToNextPage} userId={userId} />}
      {pageNo === 7 && <RightPanelPage8 goToNextPage={goToNextPage} />}
      {pageNo === 8 && <RightPanelPage9 goToNextPage={goToNextPage} />}
    </>
  );
};

export default RightPanelIndex;
