import React, { useEffect, useState } from 'react';
import c from '../sessionDetails.Module.scss';
import CustomSelect from '../../Layout/Select';
import classNames from 'classnames';
import Text from '../../Shared/Text';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable from '../../Shared/Table';
import {
  columns,
  getMinutesAndSeconds,
} from '../../../helpers/SessionDetails/VisitedURLs';
import VisitedURLsTableRow from './Table';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const VisitedURLs = ({ visited_pages = [] }) => {
  const [visitedPages, setVisitedPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  // const sessionRecord = useSelector((state) => state.sessionRecord);
  // console.log(sessionRecord);

  useEffect(() => {
    setVisitedPages(visited_pages);
  }, [visited_pages]);
  useEffect(() => {
    const pageArr = visitedPages?.slice(
      rowsPerPage * pageNo,
      rowsPerPage * (pageNo + 1)
    );
    setPages([...pageArr]);
  }, [rowsPerPage, pageNo, visitedPages]);

  return (
    <div className={classNames(c.visitedURLs, 'px-5 py-3')}>
      <div className="row g-0 justify-content-between">
        <div className="col-2">
          <Text textType="t1" classToOverride={classNames(c.primaryColor)}>
            Visited URLs
          </Text>
          {/* <Text textType="t2">Another line here for description</Text> */}
        </div>
        <div className="col-4 row ">
          {/* <div className="col-5">
            <CustomSelect label="Actions" />
          </div> */}

          <CustomSelect label="Filter " dropdownIcon={FilterAltIcon} />
        </div>
      </div>
      <div className="mt-5">
        <CustomTable
          columns={columns}
          showPagination={true}
          showSortIcon={false}
          minWidth={false}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageNo={pageNo}
          setTablePageNo={setPageNo}
        >
          {pages?.map((item) => {
            return (
              <React.Fragment key={String(Math.random())}>
                <VisitedURLsTableRow
                  key={String(Math.random())}
                  title={item?.title}
                  url={item?.url}
                  topic={item?.capability}
                  timeStamp={item?.visited_time_server || ''}
                  duration={
                    item?.page_duration
                      ? moment
                          .duration(item?.page_duration, 'seconds')
                          ?.format('HH [hrs] mm [mins] ss [secs]')
                      : ''
                  }
                  item={item}
                />
              </React.Fragment>
            );
          })}
        </CustomTable>
        <Text textType="t2">Pages Visited : {visitedPages?.length}</Text>
      </div>
    </div>
  );
};

export default VisitedURLs;
