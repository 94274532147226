/**
 * @function LearningPlan
 * Component of Manager Dashboard Overview section
 */

import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import style from '../managerDashboard.module.scss';
import Headings from '../../Shared/Headings';
import LearningPlanChart from './Chart/index';
import LearningPlanTabs from './LearningPlanTabs/index';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const LearningPlanSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className={classNames(style.LearningPlanContainer,'p-1')}>
      <div className="d-flex justify-content-between">
        <div className="p-3 bd-highlight">
          {' '}
          <Headings headingType="h3" classToOverride={'fw-bold'}>
            Learning Plan
          </Headings>
        </div>
        <div className="p-4 bd-highlight">
          {' '}
          <Button
            variant="contained"
            sx={{ textTransform: 'none' }}
            startIcon={<AddIcon />}
          >
            Add New Plan
          </Button>
        </div>
      </div>
      <div className="col-12">
        <LearningPlanTabs index={tabIndex} setTabIndex={setTabIndex} />
        {tabIndex === 0 && <LearningPlanChart />}
        {tabIndex === 1 && 'Learning Plans'}
      </div>
    </div>
  );
};

export default LearningPlanSection;
