import React from 'react';
import classNames from 'classnames';
import c from './rightpanel.module.scss';

export const RightPanel = ({ ...props }) => {
  return (
    <div
      className={classNames(
        'w-75  overflow-y-scroll bg-white',
        c.overflowYscroll,
        c.rightPanel
      )}
    >
      {props.children}
    </div>
  );
};
