import axios from 'axios';
import { API_URLS } from '../../config/API_URLS/api_urls';
import store from '../../redux/store';
import { SET_TOAST_ERROR } from '../../redux/Actions/Common';
const axiosInstance = axios.create({
  baseURL: API_URLS.baseURL,
});

axiosInstance.interceptors.request.use((config) => {
  //   store.dispatch(actions.showLoader());
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error?.response?.data?.error?.code === 1004) {
      localStorage.clear();
      window.location.href = '/sign-in';
    }

    // store.dispatch(actions.hideLoader());

    let showErrMsg = 'Something went wrong';

    // Check if there's an error object with a response
    if (error?.response) {
      // First, check if a detailed error message is provided in the response data
      if (error.response.data?.error?.message) {
        showErrMsg = error.response.data.error.message;
      } else if (error.response.data?.error) {
        showErrMsg = error.response.data.error;
      } else if (error.response.statusText) {
        showErrMsg = error.response.statusText;
      } else {
        // Handle specific cases based on the status code
        switch (error.response.status) {
          case 404:
            showErrMsg = 'Resource not found.';
            break;
          case 400:
            showErrMsg = 'Bad request.';
            break;
          case 401:
            showErrMsg = 'Unauthorized access.';
            break;
          case 403:
            showErrMsg = 'Forbidden.';
            break;
          case 500:
            showErrMsg = 'Server error. Please try again later.';
            break;
          default:
            // Fallback message if no specific error details are available
            showErrMsg = 'An error occurred. Please try again.';
            break;
        }
      }
    } else if (error?.message) {
      // Handle cases where there's a general error message (e.g., network issues)
      showErrMsg = error.message;
    } else if (error?.request) {
      // Handle cases where the request was made but no response was received
      showErrMsg =
        'No response received from the server. Please check your network connection.';
    }

    // Log or use the showErrMsg variable
    console.log(showErrMsg);

    console.log('error?', error);
    console.log('error?.response', error?.response);
    console.log('error?.response?.data', error?.response?.data);
    console.log('error?.response?.data?.error', error?.response?.data?.error);
    console.log(
      'error?.response?.data?.error?.message',
      error?.response?.data?.error?.message
    );
    store.dispatch(
      SET_TOAST_ERROR({
        error: true,
        message: showErrMsg,
      })
    );
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
