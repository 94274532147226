import React, { useEffect } from 'react';
import c from './signIn.module.scss';
import classNames from 'classnames';
import SignIn from '../../components/signIn';
import { SignInFooter } from '../../components/signIn/Footer';
import SignUpNav from '../../components/signUp/SignUpNav';
import { Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const SignInPage = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  if (localStorage.getItem('token')) {
    return <Redirect to="/home" />;
  }

  // ReactGA.pageview('/sign-in');
  // ReactGA.send({ hitType: 'pageview', page: '/sign-in', title: 'sign-in' });

  return (
    <>
      <SignUpNav />
      <div className={classNames(c.signInPageContainer)}>
        <div className={classNames(c.signInPage)}>
          <SignIn />
          <SignInFooter />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
