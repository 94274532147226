import { validateToken } from '../../utils/utility';
import { getSessions } from '../ExtensionHandler';
import { fetchUserDetails } from '../Profile/GeneralInfo';
import { getJoinedNeighborhood } from '../Profile/JoinedNeighborhood';
import { GetLicenseClients } from '../signup/LicenseCode';
import { Redirect, useLocation } from 'react-router-dom';
import { getAccountsData,getProfileData } from '../Home/CreateAccount/DropdownMenu/ProfileMenu';
import { extUpdateUI } from '../ExtensionHandler';
export const getUserDetails = async (token) => {
  const urlParams = new URLSearchParams(window.location.search);
  const session_url = urlParams.get('session');
  if (session_url === "true"){
    await fetchUserDetails();
    await GetLicenseClients();
    await getSessions(true);
    await getJoinedNeighborhood();
    getAccountsData();
    await extUpdateUI()     
  }
else if (token && validateToken(token)) {
    await fetchUserDetails();
    await GetLicenseClients();
    await getSessions(true);
    await getJoinedNeighborhood();
    getAccountsData();
  }
  else{
    return <Redirect to='/login'  />
  }
};