import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ModuleStyling from '../../../../../../MyAccount/Modals/modal.Module.scss';
import classNames from 'classnames';

const CustomSelect = ({ items, formik, data = [], ...props }) => {
  return (
    <>
      <Select
        name={items?.id}
        IconComponent={KeyboardArrowDown}
        onChange={formik?.handleChange}
        value={formik?.values[items?.id]}
        id={items?.id}
        // displayEmpty
        inputProps={{
          className: ` ${ModuleStyling.modalFields} `,
        }}
        className={`${ModuleStyling.modalFieldsContainer}`}
      >
        <MenuItem value="select">{props?.text}</MenuItem>
        {data && data?.map((Menudata, index) => {
          return (
            <MenuItem key={Menudata?.id} value={Menudata?.id}>
              {Menudata?.name}
            </MenuItem>
          );
        })}
      </Select>

      {
        formik?.errors[items?.id] &&
        <p className={classNames(ModuleStyling.errorText)}>
          {formik?.errors[items?.id]}
        </p>
      }
    </>
  );
};
export default CustomSelect;
