import classNames from 'classnames';
import React, { useState } from 'react';
import { Resources } from '../../../config/Resources';
import ManagerDashboardStyling from '../managerDashboard.module.scss';
import { CustomIframe } from '../../Shared/CustomIframe';

const WelcomePanal = () => {
  const [selectedLink, setSelectedLink] = useState('');

  return (
    <div className={classNames(ManagerDashboardStyling.managerHeading)}>
      <div className="d-flex  justify-content-around">
        <div className={classNames(ManagerDashboardStyling.firstText, 'col-5')}>
          <p className="fw-bold">
            Welcome back! Manage and track your teams performance and
            development efforts.
          </p>
        </div>
        <div
          className={classNames(ManagerDashboardStyling.secondText, 'col-4')}
        >
          {/* <p> All analytics and metrics have been updated!</p> */}
          <a
            className={classNames(
              ManagerDashboardStyling.headingCls,
              ManagerDashboardStyling.cursorPointer,
              ManagerDashboardStyling.font_16,
              ManagerDashboardStyling.fontWeight500
            )}
            onClick={() =>
              setSelectedLink(
                'https://ahuraai.com/solutions/top-uses-for-managers/'
              )
            }
          >
            Top ways to mentor using Ahura AI
          </a>
        </div>

        <CustomIframe
          iconClass={ManagerDashboardStyling.cursorPointer}
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
        />

        <div>
          <img src={Resources.images.headerManager} alt="header" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePanal;
