import React, { useState } from 'react';
import Forgot_Password from './ForgotPassword';
import ForgotPasswordOTP from './ForgotPasswordOTP';
import UpdatePassword from './UpdatePassword';

const ForgotPassword = ({ ...props }) => {
  const [page, setPage] = useState(0);
  const [mail, setMail] = useState('');
  
  const updatePage=()=>{
      setPage(page+1);
  }

  if (page === 0) return <Forgot_Password updatePage={updatePage} setMail={setMail}/>;
  if (page === 1) return <ForgotPasswordOTP mail={mail} updatePage={updatePage}/>;
  if (page === 2) return <UpdatePassword mail={mail} />;
};
export default ForgotPassword;
