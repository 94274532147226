import React, { useEffect, useState } from 'react';
import Headings from '../../components/Shared/Headings';
import classNames from 'classnames';
import c from '../../components/Home/home.Module.scss';
import CustomSelect from '../../components/Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable from '../../components/Shared/Table';
import { columns } from '../../helpers/Home/Community';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { manager_columns } from '../../helpers/Home/AssignedCapabilities';
import { Bar, Line } from 'react-chartjs-2';
import SessionsTable from '../../components/Home/Sessions/Table';
import { getTimeInHMS, googleAnalyticsTriggerFn } from '../../utils/utility';
import moment from 'moment/moment';
import {
  BarOptions,
  CapBarData,
  HourBarData,
} from '../../helpers/Home/Highlights2.0';
import {
  InputBase,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  createFilterOptions,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CourseCard from '../../components/Shared/CourseCard';
import { getAllCourses } from '../../helpers/Content';
import _ from 'lodash';
import { HTML_TAG_PATTERN } from '../../config/Regex/regex';
import { useDebouncedCallback } from 'use-debounce';
import { handleStartSession } from '../../helpers/ExtensionHandler';
import { fetchCategories } from '../../helpers/LearningPlans/LearningPlansModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSessionDetailsOpen } from '../../redux/Actions/ExtensionHandler';
import SessionDetails from '../../components/Shared/SessionDetails';
import MyAccountStyling from '../../components/MyAccount/MyAccount.Module.scss';
import { ArrowBackIosNew, LabelSharp } from '@mui/icons-material';
import { Resources } from '../../config/Resources';
import Text from '../../components/Shared/Text';

import { sessionRecord } from '../../redux/Actions/SessionRecord';
import {
  getTopAndLowScore,
  getWeekSessionData,
} from '../../helpers/Home/UserCapabilities';
import { getCapabilityStats } from '../../helpers/Home/UserCapabilities';
import { getAllEmpCapbilities } from '../../helpers/Home/UserCapabilities';
import { Avatar } from '@mui/material';
import EmployeeCapabilityStats from '../../components/ManagerDashBoard/Employees/EmployeeCapabilityStats';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import './bootStrapOveride.css';
import { labels } from '../../helpers/Home/ExpertiseJourney2.0';
const CapabilitiesPage = (props) => {
  const [value, setValue] = useState('');
  const history = useHistory();
  const selectedCategory = useSelector((state) => state?.selectedCategory);
  const [sessionsTableData, setSessionsTableData] = useState([]);
  const [search, setSearch] = useState(selectedCategory?.name || '');
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const isSessionDetailsOpen =
    useSelector((state) => state?.isSessionDetailsOpen) || false;
  const barData = useSelector((state) => state?.barData[0]) || [];
  const sessionInfo = useSelector((state) => state?.sessionData[0]) || [];
  const corp_id = useSelector((state) => state?.generalInformation?.client_id);
  const pageParams = useParams();
  const { search: querySearchParams } = useLocation();
  const searchParams = new URLSearchParams(querySearchParams);
  const menuItems = [
    { label: 'This Week', value: 'this week' },
    { label: 'Previous Week', value: 'previous Week' },
  ];

  const onSearchChangeHandle = async (value, courseUpdate = false) => {
    let searchVal = '';
    if (typeof value === 'string') {
      searchVal = value || '';
    } else {
      searchVal = value?.name || '';
    }
    setSearch(searchVal || '');
    if (courseUpdate) {
      const res = await getAllCourses(searchVal);
      // setCourses(res);
    }
  };

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const [sort, setSort] = useState(false);
  const [sortColumn, setSortColumn] = useState('date');
  const isManager = false;
  const [tablePageNo, setTablePageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [barDataGraph, setBarGraphData] = useState();
  const [employeeTableData, setEmployeeTableData] = useState();
  const [nameSort, setNameSort] = useState(false);
  const [durationSort, setDurationSort] = useState(false);
  const [scoreSort, setScoreSort] = useState(false);

  const onViewSessionClick = (data) => {
    dispatch(sessionRecord(data));

    history.push(`/sessions/${data?.session_id}`);
  };

  const EmployeeTableColumn = [
    { id: 'name', label: 'Name', minWidth: 190 },
    { id: 'total_duration', label: 'Hours Studied', minWidth: 190 },
    { id: 'average_score', label: 'Assesment', minWidth: 190 },
    { id: 'assignedBy', label: 'Assigned By', minWidth: 190 },
  ];

  function compareValues(key, order = false) {
    return function innerSort(a, b) {
      const varA = !a.hasOwnProperty(key)
        ? '0'
        : typeof a[key] === 'string'
        ? a[key].toUpperCase()
        : a[key];
      const varB = !b.hasOwnProperty(key)
        ? '0'
        : typeof b[key] === 'string'
        ? b[key].toUpperCase()
        : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === true ? comparison * -1 : comparison;
    };
  }

  const handleSort = (column_id) => {
    if (column_id === 'name') {
      setSort(!sort);
      setSortColumn('name');
      setEmployeeTableData(
        employeeTableData.sort(compareValues('name', nameSort))
      );
      setNameSort(!nameSort);
    }
    if (column_id === 'total_duration') {
      setSort(!sort);
      setSortColumn('total_duration');
      setEmployeeTableData(
        employeeTableData.sort(compareValues('total_duration', durationSort))
      );
      setDurationSort(!durationSort);
    }
    if (column_id === 'average_score') {
      setSort(!sort);
      setSortColumn('average_score');
      setEmployeeTableData(
        employeeTableData.sort(compareValues('average_score', scoreSort))
      );
      setScoreSort(!scoreSort);
    }
  };

  useEffect(() => {}, [employeeTableData]);

  const courses = useSelector((state) => state?.learningPlanData);

  Date.prototype.getWeek = function () {
    var target = new Date(this.valueOf());
    var dayNr = (this.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    var firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000);
  };

  function getDateRangeOfWeek(weekNo) {
    var d1 = new Date();
    let numOfdaysPastSinceLastMonday = eval(d1.getDay() - 1);
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    var weekNoToday = d1.getWeek();
    var weeksInTheFuture = eval(weekNo - weekNoToday);
    d1.setDate(d1.getDate() + eval(7 * weeksInTheFuture));
    var rangeIsFrom = `${eval(
      d1.getMonth() + 1
    )}-${d1.getDate()}-${d1.getFullYear()}`;
    d1.setDate(d1.getDate() + 6);
    var rangeIsTo = `${eval(
      d1.getMonth() + 1
    )}-${d1.getDate()}-${d1.getFullYear()}`;
    return (
      rangeIsFrom.slice(0, rangeIsFrom.length - 4) +
      rangeIsFrom.slice(rangeIsFrom.length - 2, rangeIsFrom.length) +
      ' to ' +
      rangeIsTo.slice(0, rangeIsTo.length - 4) +
      rangeIsTo.slice(rangeIsTo.length - 2, rangeIsTo.length)
    );
  }

  const option = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleAlign: 'left',
        displayColors: true,
        boxWidth: 0,
        bodyAlign: 'left',
        //   titleMarginBottom: 10,
        // titleFont: { size: 20, weight: 'bold', lineHeight: 1.9 },
        // bodyFont: { size: 16, weight: 'bold' },
        usePointStyle: true,
        boxHeight: 0,
        footerFont: { size: 60 },
        padding: '15',
        caretSize: 0,
        caretPadding: 10,
        //   borderColor: 'rgba(163, 174, 208, 1)',
        //   borderWidth: 1,
        cornerRadius: 0,
        yAlign: 'bottom',
        callbacks: {
          labelTextColor: function (context) {
            return '#FFFFFF';
          },
          // beforeLabel: function (context) {
          //   return 'Hi ' + context.parsed.y;
          // },
          beforeLabel: function (context) {
            return `From: ${context.dataset.tooltip[context.dataIndex]}`;
            return '';
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: 'star',
              rotation: 0,
            };
          },
        },
      },
      title: {
        text: 'Sessions',
        display: true,
        position: 'left',
      },
    },
  };

  const data = {
    labels: barDataGraph?.map((item, val) => {
      return `Week ${item.week}`;
    }),
    datasets: [
      {
        label: 'Sessions',
        data: barDataGraph?.map((item, val) => {
          return item.sessions;
        }),
        backgroundColor: '#00a89a',
        tooltip: barDataGraph?.map((item, val) => {
          return getDateRangeOfWeek(item.week);
        }),
      },
    ],
  };

  function minuteToHr(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : false;
    var mDisplay = m > 0 ? m + (m == 1 ? 'min ' : 'min ') : false;
    var sDisplay = s > 0 ? s + (s == 1 ? 's ' : 's') : false;
    if (!hDisplay && !mDisplay && !sDisplay) {
      return '0';
    }
    if (!hDisplay && !mDisplay && sDisplay) {
      return sDisplay;
    } else if (!hDisplay && mDisplay && sDisplay) {
      return mDisplay + sDisplay;
    } else if (hDisplay && mDisplay && !sDisplay) {
      return hDisplay + mDisplay;
    } else if (hDisplay && !mDisplay && !sDisplay) {
      return hDisplay;
    } else if (!hDisplay && !mDisplay && !sDisplay) {
      return '0';
    } else if (!hDisplay && mDisplay && !sDisplay) {
      return mDisplay;
    } else if (hDisplay && mDisplay && sDisplay) {
      return hDisplay + mDisplay + sDisplay;
    }
  }

  const [capabilityStats, setCapabilityStats] = useState();
  const [empCapabilities, setEmpCapabilities] = useState();
  const [coursesData, setCourseData] = useState();
  const [sessionWeekData, setSessionWeekData] = useState();

  useEffect(() => {
    if (corp_id && !!pageParams) onMount();
  }, [corp_id]);

  const onMount = async () => {
    const employeeCapability = await getAllEmpCapbilities(
      corp_id,
      searchParams.get('category_name'),
      pageParams?.capability_id
    );
    if (typeof employeeCapability.data === 'object') {
      setEmpCapabilities(employeeCapability?.data);
      setEmployeeTableData(employeeCapability?.data.ourTableData3);
    }

    const availaibleCourses = await getAllCourses(
      searchParams.get('category_name')
    );
    if (typeof availaibleCourses === 'object') {
      setCourseData(availaibleCourses);
    }

    const dataForBar = await getWeekSessionData(
      corp_id,
      searchParams.get('category_name')
    );
    if (typeof dataForBar === 'object') {
      setSessionWeekData(dataForBar.data);
    }
  };

  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    if (sessionWeekData) {
      const sessionDataMap = sessionWeekData[`${year}`]
        ? sessionWeekData[`${year}`]
        : sessionWeekData[`${year - 1}`];
      // const abc = sessionWeekData[`${year}`].sort((a, b) => parseFloat(b.week) - parseFloat(a.week)).slice(0, 11);

      const largestElement = Math.max.apply(
        Math,
        sessionDataMap.map(function (o) {
          return o.week;
        })
      );

      const dataArray = [];

      for (let i = largestElement - 10; i <= largestElement; i++) {
        dataArray.push({
          capability: null,
          month: null,
          sessions: 0,
          users: null,
          week: i,
          year: null,
        });
      }
      sessionDataMap.map((val1, ind1) => {
        dataArray.map((val2, ind2) => {
          if (val1.week == val2.week) {
            dataArray[ind2]['capability'] = val1.capability;
            dataArray[ind2]['month'] = val1.month;
            dataArray[ind2]['sessions'] = val1.sessions;
            dataArray[ind2]['users'] = val1.users;
            dataArray[ind2]['week'] = Number(val1.week);
            dataArray[ind2]['year'] = val1.year;
          }
        });
      });
      setBarGraphData(dataArray);
    }
  }, [sessionWeekData]);

  return (
    <>
      <div className="p-1">
        <Headings
          classToOverride={classNames(MyAccountStyling.primaryColor, 'fw-bold')}
          headingType="h5"
        >
          Capabilities
        </Headings>

        <div
          className="d-flex align-items-center mt-4"
          onClick={() => {
            history.push('/manager-dashboard?setIndex=2');
            googleAnalyticsTriggerFn(
              'Button',
              'Back',
              'Back button on capabilities page clicked'
            );
          }}
          style={{ cursor: 'pointer' }}
        >
          <ArrowBackIosNew style={{ color: '#00a89a' }} />
          <Headings
            headingType="h6"
            classToOverride={classNames(
              MyAccountStyling.primaryColor,
              'fw-bold'
            )}
          >
            Back
          </Headings>
        </div>

        <div className={classNames(`${c.highlights} mt-4`)}>
          <div className="d-flex">
            {/* <img
              src={Resources.images.home.pythonBlue}
              alt=""
              style={{ marginRight: '14px', fill: '#00A89A' }}
            /> */}
            <Headings
              classToOverride={classNames('fw-bold align-items-center')}
              headingType="h4"
            >
              {searchParams.get('category_name')} - Overview
            </Headings>
          </div>

          {/* <Text
            text="Python is an interpreted, object-oriented, high-level programming 
                language with dynamic semantics. Its high-level built in data structures, 
                combined with dynamic typing and dynamic binding, make it very attractive
                for Rapid Application Development, as well as for use as a scripting or glue
                language to connect existing components together. Python's simple, easy to
                learn syntax emphasizes readability and therefore reduces the cost of program
                    maintenance. Python supports modules and packages, which encourages program
                    modularity and code reuse. The Python interpreter and the extensive standard
                    library are available in source or binary form without charge for all major
                    platforms, and can be freely distributed"
            textType="t1"
            classToOverride={'mt-4'}
          /> */}
          <div className="mt-4 d-flex justify-content-between">
            <Headings
              classToOverride={classNames('fw-bold align-items-center mb-4')}
              headingType="h4"
            >
              Community
            </Headings>

            <div className="col-3"></div>
          </div>

          <div
            className="rounded d-flex justify-content-between"
            style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px' }}
          >
            <div className="" style={{ width: '45%' }}>
              <div style={{ marginTop: '5px', marginLeft: '25px' }}>
                {/* <Headings
                  classToOverride={classNames(MyAccountStyling.primaryColor)}
                  headingType="h6"
                >
                  Strong Expertise
                </Headings> */}
                <h5
                  style={{
                    color: '#00A89A',
                    marginTop: '26px',
                    fontSize: '1.2rem',
                    marginBottom: '0px',
                  }}
                >
                  Strong Expertise
                </h5>
              </div>
              <table
                style={{
                  width: '100%',
                  margin: '10px',
                  borderSpacing: '0 15px',
                  borderCollapse: 'separate',
                }}
              >
                <tr
                  className="p-2"
                  style={{ backgroundColor: '#66e5db', textAlign: 'center' }}
                >
                  <th style={{ width: '40%', padding: '13px' }}>Learner</th>
                  <th style={{ width: '30%', padding: '13px' }}>Progress</th>
                  <th style={{ width: '30%', padding: '13px' }}>Details</th>
                </tr>
                <tbody>
                  {empCapabilities?.userScoreObj?.highScoreUsers?.map(
                    (item, ind) => {
                      return (
                        <tr
                          className="rounded bg-light"
                          style={{
                            textAlign: 'center',
                            boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
                          }}
                        >
                          <td
                            className=""
                            style={{ width: '35%', padding: '10px' }}
                          >
                            <div className="d-flex justify-content-around">
                              <span>
                                <Avatar
                                  alt="profile picture"
                                  src={item.profile_image_url}
                                />
                              </span>
                              <span className="d-flex align-items-center">
                                {`${item.name}`}
                              </span>
                            </div>
                          </td>

                          <td style={{ width: '33%', padding: '10px' }}>
                            <div className="d-flex flex-column">
                              <span className="d-flex justify-content-right align-items-center">
                                <img
                                  src={Resources.images.home.AverageScore}
                                  alt=""
                                  style={{
                                    marginRight: '4px',
                                    height: '17px',
                                    marginRight: '10px',
                                  }}
                                />
                                {`${Number(item?.average_score).toFixed(1)} %`}
                              </span>
                              <span
                                className="d-flex justify-content-right align-items-center"
                                style={{ textAlign: 'center' }}
                              >
                                <img
                                  src={Resources.images.home.StudyTimeClock}
                                  alt=""
                                  style={{
                                    marginRight: '4px',
                                    height: '17px',
                                    marginRight: '10px',
                                  }}
                                />
                                {minuteToHr(item.total_duration)}
                              </span>
                            </div>
                          </td>

                          <td
                            style={{
                              width: '35%',
                              padding: '10px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              history.push(
                                `/employee-details/${item.user_client_id}`
                              );
                              googleAnalyticsTriggerFn(
                                'Hyperlink',
                                'View Details',
                                'View Details clicked to view employee detials'
                              );
                            }}
                          >
                            <a>View Details</a>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>

            <div className="" style={{ width: '45%', marginRight: '20px' }}>
              <div style={{ marginTop: '5px', marginLeft: '25px' }}>
                {/* <Headings
                  classToOverride={classNames(MyAccountStyling.primaryColor)}
                  headingType="h6"
                  style={{ marginTop: '22px' }}
                >
                  Emerging Expertise
                </Headings> */}
                <h5
                  style={{
                    color: '#00A89A',
                    marginTop: '26px',
                    fontSize: '1.2rem',
                    marginBottom: '0px',
                  }}
                >
                  Emerging Expertise
                </h5>
              </div>
              <table
                style={{
                  width: '100%',
                  margin: '13px',
                  borderSpacing: '0 15px',
                  borderCollapse: 'separate',
                }}
              >
                <tr
                  className="p-2"
                  style={{ backgroundColor: '#66e5db', textAlign: 'center' }}
                >
                  <th style={{ width: '40%', padding: '13px' }}>Learner</th>
                  <th style={{ width: '30%', padding: '13px' }}>Progress</th>
                  <th style={{ width: '30%', padding: '13px' }}>Details</th>
                </tr>
                <tbody>
                  {empCapabilities?.userScoreObj?.lowScoreUsers?.map(
                    (item, ind) => {
                      return (
                        <tr
                          className="rounded bg-light"
                          style={{
                            textAlign: 'center',
                            boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
                          }}
                        >
                          <td
                            className=""
                            style={{ width: '35%', padding: '10px' }}
                          >
                            <div className="d-flex justify-content-around">
                              <span>
                                <Avatar
                                  alt="profile picture"
                                  src={item.profile_image_url}
                                />
                              </span>
                              <span className="d-flex align-items-center">
                                {`${item.name}`}
                              </span>
                            </div>
                          </td>

                          <td style={{ width: '33%', padding: '10px' }}>
                            <div className="d-flex flex-column">
                              <span
                                className="d-flex justify-content-right"
                                style={{ textAlign: 'center' }}
                              >
                                <img
                                  src={Resources.images.home.StudyTimeClock}
                                  alt=""
                                  style={{
                                    marginRight: '4px',
                                    height: '17px',
                                    marginRight: '10px',
                                  }}
                                />
                                {minuteToHr(item.total_duration)}
                              </span>
                              <span className="d-flex justify-content-right">
                                <img
                                  src={Resources.images.home.AverageScore}
                                  alt=""
                                  style={{
                                    marginRight: '4px',
                                    height: '17px',
                                    marginRight: '10px',
                                  }}
                                />
                                {`${Number(item?.average_score).toFixed(1)} %`}
                              </span>
                            </div>
                          </td>

                          <td
                            style={{
                              width: '35%',
                              padding: '10px',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() =>
                              history.push(
                                `/employee-details/${item.user_client_id}`
                              )
                            }
                          >
                            <a>View Details</a>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Quickstats starts */}
          <div
            className="rounded mt-4"
            style={{
              boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
              padding: '20px',
            }}
          >
            <div className="mt-4 row">
              <div className="col">
                <Headings
                  classToOverride={classNames(
                    'fw-bold align-items-center mb-4'
                  )}
                  headingType="h4"
                >
                  Quick Stats
                </Headings>
                <Divider></Divider>
              </div>
            </div>
            <div>
              <div className="col-2 mt-5">
                {/* <CustomSelect
                  label="This Week"
                  dropdownIcon={KeyboardArrowDown}
                /> */}
              </div>
              <Bar data={data} options={option} height={118} />
              <div
                className={classNames(
                  'col-12 text-center mb-4 mt-2',
                  c.fontWeight600
                )}
              >
                <Text textType="t1">Weeks</Text>
              </div>
            </div>
            <Divider></Divider>
            <Headings
              classToOverride={classNames('fw-bold align-items-center mt-4')}
              headingType="h4"
            >
              Stats
            </Headings>

            <div className="mt-4">
              <table className="col-12 ">
                <tr className="d-flex justify-content-between">
                  <th className="d-flex flex-column">
                    <span>No of Students</span>
                    <span className="mt-2">
                      {empCapabilities?.statsData[0].total_user}
                    </span>
                  </th>
                  <th className="d-flex flex-column">
                    <span>No of Sessions</span>
                    <span className="mt-2">
                      {empCapabilities?.statsData[0].total_sessions}
                    </span>
                  </th>
                  <th className="d-flex flex-column">
                    <span>Total Duration</span>
                    <span className="mt-2">
                      {minuteToHr(
                        empCapabilities?.statsData[0].average_poficiency
                      )}
                    </span>
                  </th>
                  <th className="d-flex flex-column">
                    <span>Avg. Focus</span>
                    <span className="mt-2">No Data</span>
                  </th>
                  <th className="d-flex flex-column">
                    <span>Learner Rating</span>
                    <span className="mt-2">No Data</span>
                  </th>
                </tr>
              </table>
            </div>
          </div>

          <div
            className="rounded my-4"
            style={{
              boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
              padding: '20px',
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-4 d-flex justify-content-around">
                <h4 className="m-0" style={{ fontWeight: 'bold' }}>
                  Availaible Course Plans
                </h4>
                <p
                  className="m-0 font-weight-bold"
                  onClick={() => {
                    history.push(
                      `/content?searchContent=${searchParams.get(
                        'category_name'
                      )}`
                    );
                    googleAnalyticsTriggerFn(
                      'Hyperlink',
                      'View All',
                      'View All link clicked on Available course plan on capabilites page'
                    );
                  }}
                  style={{
                    textDecoration: 'underline',
                    color: '#00a89a',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  View All
                </p>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <Button
                  disabled={true}
                  variant="contained"
                  onClick={() => {
                    history.push(`/campaigns`);
                  }}
                >
                  Add Course Plans
                </Button>
              </div>
            </div>
            <Divider className="mt-4"></Divider>

            <div className="row">
              <Carousel variant="" style={{ height: 300 }} interval={null}>
                {coursesData &&
                  sliceIntoChunks(coursesData, 3).map((courseData, index) => {
                    return (
                      // return courseData.map((course, ind) => {
                      //   return (
                      <Carousel.Item key={index}>
                        <Stack
                          direction="horizontal"
                          gap={3}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <div className="g-0 px-1 mt-3">
                            {courseData[0] && (
                              <CourseCard
                                className=""
                                id={courseData[0]?.id}
                                courseCategory={
                                  courseData[0]?.campaign_category
                                }
                                heading={courseData[0]?.heading}
                                desc={_?.replace(
                                  courseData[0]?.description,
                                  HTML_TAG_PATTERN,
                                  ''
                                )}
                                peers={0}
                                quizzes={courseData[0]?.quiz_count}
                                sections={courseData[0]?.section_count}
                              />
                            )}
                          </div>

                          <div className="g-0 px-1 mt-3">
                            {courseData[1] && (
                              <CourseCard
                                className=""
                                id={courseData[1]?.id}
                                courseCategory={
                                  courseData[1]?.campaign_category
                                }
                                heading={courseData[1]?.heading}
                                desc={_?.replace(
                                  courseData[1]?.description,
                                  HTML_TAG_PATTERN,
                                  ''
                                )}
                                peers={0}
                                quizzes={courseData[1]?.quiz_count}
                                sections={courseData[1]?.section_count}
                              />
                            )}
                          </div>

                          <div className="g-0 px-1 mt-3">
                            {courseData[2] && (
                              <CourseCard
                                className=""
                                id={courseData[2]?.id}
                                courseCategory={
                                  courseData[2]?.campaign_category
                                }
                                heading={courseData[2]?.heading}
                                desc={_?.replace(
                                  courseData[2]?.description,
                                  HTML_TAG_PATTERN,
                                  ''
                                )}
                                peers={0}
                                quizzes={courseData[2]?.quiz_count}
                                sections={courseData[2]?.section_count}
                              />
                            )}
                          </div>
                        </Stack>
                      </Carousel.Item>
                      //   );
                      // });
                    );
                  })}
              </Carousel>
            </div>
          </div>

          <div
            className="rounded mt-4"
            style={{
              boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
              padding: '20px',
            }}
          >
            <Headings
              classToOverride={classNames(
                'fw-bold align-items-center mt-4 mb-4'
              )}
              headingType="h4"
            >
              Learners
            </Headings>

            <div className={classNames('row')}>
              <CustomTable
                columns={EmployeeTableColumn}
                onClick={handleSort}
                pageNo={tablePageNo}
                setTablePageNo={setTablePageNo}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              >
                {employeeTableData?.map((item) => {
                  return (
                    <EmployeeCapabilityStats
                      tName={item.name}
                      tHoursStudied={item.total_duration}
                      tAssignedBy={item.assigned_by}
                      tAssessment={item.average_score}
                    />
                  );
                })}
              </CustomTable>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {isSessionDetailsOpen && (
        <SessionDetails
          sessionInfo={sessionInfo}
          barInfo={barData}
          setModal={() => dispatch(setIsSessionDetailsOpen(false))}
        />
      )}
    </>
  );
};

export default CapabilitiesPage;
