import TableCell from '@mui/material/TableCell';
import DropdownButton from '../DropdownButton';
import classNames from 'classnames';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Avatar from '@mui/material/Avatar';
import { MoveSortIcon, CircleRightChevron, SessionIcon } from '../../icons';
import Text from '../../Text';
// import { Select3 } from "../../Select";
// import Heading from "../../Heading";
import {
  Button,
  CircularProgress,
  Switch,
  TableBody,
  TableRow,
} from '@mui/material';
import MoreButton from '../MoreButton';
// import { Button1 } from "../../Buttons";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DropdownButton2 from '../DropdownButton2';
import Headings from '../../../../../components/Shared/Headings';
import { Button3 } from '../../Buttons';

export const TableCellImage = ({ text, text2, type, style, ...props }) => {
  return (
    <TableCell
      component="th"
      scope="row"
      className={classNames(style, 'w-auto border-0')}
    >
      <div className="!flex !items-center">
        {/* <div className={props?.css && props?.css(props?.cls?.icons)}>
          <DragIndicatorIcon className="!text-lg !mr-[10px] " />
        </div> */}

        <div className="!flex !items-center">
          {type !== 'square' ? (
            props.img ? (
              <img
                src={props.img}
                className="h-[60px] w-[60px] !rounded-[5px]"
                alt="User"
              />
            ) : (
              <div className="!w-[60px] !h-[60px] !bg-admin-gainsboro-1 !rounded-[5px]"></div>
            )
          ) : (
            <Avatar alt="Remy Sharp" src="" />
          )}

          <div className="!ml-[10px]">
            <Text className="text-lg !m-0 !mb-0">{text}</Text>
            <Text className="text-base !mb-0">{text2}</Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export const TableCellText = ({
  text,
  css,
  style,
  classToOverride,
  ...props
}) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <Text
        className={classNames(
          'fs-6 mb-0',
          css?.TextColor || '',
          classToOverride
        )}
        {...props}
      >
        {text}
      </Text>
    </TableCell>
  );
};

export const TableCellDropdown = ({
  btnName,
  options,
  style,
  item,
  setValue,
  ...props
}) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <DropdownButton
        btnName={btnName}
        item={item}
        options={options}
        setValue={setValue}
        {...props}
      />
    </TableCell>
  );
};

export const TableCellSortable = ({ text1, text2, css, ...props }) => {
  return (
    <TableCell className="!w-auto border-0">
      <div className={classNames(css?.MoveIconMargin, '!flex !items-center')}>
        <div className={classNames(css?.HoverEnable, '!mr-2')}>
          <MoveSortIcon />
        </div>
        <div className="!flex !items-center">
          <div className="!w-[60px] !h-[60px] !bg-gainsboro_1 !rounded-[5px]"></div>
          <div className="!ml-[10px]">
            <Text className="text-lg !font-bold !text-charcoal">{text1}</Text>
            <Text className="text-sm !text-charcoal">{text2}</Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export const TableCellWIthSubtext = ({
  text1,
  text2,
  style,
  textStyle,
  ...props
}) => {
  return (
    <TableCell className="!w-auto border-0">
      <div className={classNames(style, '!flex !items-center')}>
        <div>
          <Text className={classNames(textStyle, 'text-lg !text-charcoal')}>
            {text1}
          </Text>
          <Text className="text-sm !text-charcoal">{text2}</Text>
        </div>
      </div>
    </TableCell>
  );
};

export const TableCell6 = ({
  value,
  options,
  handleChange,
  text2,
  ...props
}) => {
  return (
    <TableCell className="!w-auto border-0">
      <div className="!flex !items-center">
        {/* <Select3 value={value} options={options} handleChange={handleChange} /> */}
        <div className="!ml-2">
          <CircleRightChevron />
        </div>
      </div>
    </TableCell>
  );
};

export const TableCell7 = ({ text1, text2, css, ...props }) => {
  return (
    <TableCell className="!w-auto border-0">
      <div className={classNames(css?.MoveIconMargin, '!flex !items-center')}>
        <div className={classNames(css?.HoverEnable, 'mr-2')}>
          <MoveSortIcon />
        </div>
        <div className="flex items-center">
          <div className="!w-[60px] !h-[60px] !bg-gainsboro_1 !rounded-md"></div>
          <div className="!ml-[10px]">
            <Text className={classNames('text-lg !font-bold', css?.TextColor)}>
              {text1}
            </Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export const TableCell8 = ({ text, text2, type, drag, ...props }) => {
  return (
    <TableCell component="th" scope="row" className="!w-auto border-0">
      <div className="!flex !items-center">
        <div className="!flex !items-center">
          <div className="!w-[50px] !h-[50px] !bg-gainsboro_1 !rounded-[50%] !flex !justify-center !items-center">
            <SessionIcon />
          </div>

          <div className="!ml-[10px]">
            <Text className={classNames('text-lg !m-0 !mb-0')}>{text}</Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};
export const TableCell9 = ({ text, text2, url, style, iconType, ...props }) => {
  return (
    <TableCell
      component="th"
      scope="row"
      className={classNames(style, 'w-auto border-0')}
    >
      <div className="!flex !items-center">
        {iconType === '1' ? <DragIndicatorIcon /> : <MoveSortIcon />}

        <div className="!flex !items-center">
          {url === 'none' ? (
            <></>
          ) : !url ? (
            <div className="!w-[60px] !h-[60px] !bg-admin-gainsboro-1 !rounded-md" />
          ) : url === 'video' ? (
            <div className="!w-[60px] !h-[60px] !rounded-md !bg-admin-gainsboro-1 flex items-center justify-center">
              <PlayCircleIcon className="!text-5xl" />
            </div>
          ) : (
            <img
              alt="Image"
              src={url}
              className="!w-[60px] !h-[60px] !bg-gainsboro_1 !rounded-md object-cover object-center"
            />
          )}

          <div className="!ml-[10px]">
            <Headings headingType="h18">{text}</Headings>
            <Text textType="t16">{text2}</Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export const TableCell10 = ({ text, style, textOverride, ...props }) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <Text
        className={classNames('text-sm font-bold cursor-pointer', textOverride)}
      >
        {text}
      </Text>
    </TableCell>
  );
};

export const TableCell11 = ({ text, style, textOverride, file, ...props }) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <a
        href={file}
        target="_blank"
        rel="noreferrer"
        download={file}
        className={classNames('text-sm font-bold cursor-pointer', textOverride)}
      >
        {text}
      </a>
    </TableCell>
  );
};

export const TableCell12 = ({
  btnName,
  options,
  style,
  item,
  setValue,
  ...props
}) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <MoreButton
        item={item}
        options={options}
        setValue={setValue}
        {...props}
      />
    </TableCell>
  );
};

export const TableCellButton = ({ btnName, onClick, style, btnClass }) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <Button3 value={btnName} onClick={onClick} />
    </TableCell>
  );
};

export const TableCellSwitch = ({ checked, onClick }) => {
  return (
    <TableCell className={classNames('w-auto border-0')}>
      <Switch
        checked={checked}
        onChange={onClick}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </TableCell>
  );
};

//ANCHOR TAG
export const TableCell15 = ({ text, style, textOverride, handleClick }) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <p
        role="button"
        onClick={handleClick}
        className={classNames(
          'text-sm font-bold cursor-pointer text-admin-primary mb-0',
          textOverride
        )}
        style={{ cursor: 'pointer' }}
      >
        {text}
      </p>
    </TableCell>
  );
};

export const TableCell16 = ({
  btnName,
  options,
  style,
  item,
  setValue,
  view,
  ...props
}) => {
  return (
    <TableCell className={classNames(style, 'w-auto border-0')}>
      <div className="flex place-content-between items-center content-end">
        <Button
          className="!rounded-xl !min-w-[220px]"
          variant="contained"
          color="primary"
          onClick={() => setValue({ btnName }, item)}
        >
          <Text className="!font-bold !normal-case text-lg">{btnName}</Text>
        </Button>
        <DropdownButton2
          item={item}
          options={options}
          setValue={setValue}
          {...props}
        />
      </div>
    </TableCell>
  );
};

export const TableCell17 = ({
  text,
  text2,
  url,
  style,
  iconType,
  ...props
}) => {
  return (
    <TableCell
      component="th"
      scope="row"
      className={classNames(style, 'w-auto ')}
    >
      <div className="!flex !items-center">
        <div className="!flex !items-center !m-1">
          {url === 'none' ? (
            <></>
          ) : !url ? (
            <div className="!w-[60px] !h-[60px] !bg-admin-gainsboro-1 !rounded-md" />
          ) : url === 'video' ? (
            <div className="!w-[60px] !h-[60px] !rounded-md !bg-admin-gainsboro-1 flex items-center justify-center">
              <PlayCircleIcon className="!text-5xl" />
            </div>
          ) : (
            <img
              alt="Image"
              src={url}
              className="!w-[60px] !h-[60px] !bg-gainsboro_1 !rounded-md object-cover object-center"
            />
          )}

          <div className="!ml-[10px]">
            <Text textType="t16">{text}</Text>
            <Text textType="t16">{text2}</Text>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

// export const TableCell18 = ({
//   text,
//   css,
//   style,
//   classToOverride,
//   tablestyle,
//   ...props
// }) => {
//   return (
//     <TableCell className={classNames(tablestyle, "w-auto border-0")}>
//       <div style={style}>
//         <Text
//           className={classNames(
//             "text-lg",
//             css?.TextColor || "",
//             classToOverride
//           )}
//         >
//           {text}
//         </Text>
//       </div>
//       {/* <div className="w-[50px] h-[50px] bg-admin-primary skew-x-[-12deg] flex justify-center items-center text-admin-white">

//       </div> */}
//     </TableCell>
//   );
// };

export const TableCellLoader = ({ colSpan }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <div className="h-14 !w-full text-center">
            <CircularProgress color="inherit" />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
