import { createContext } from 'react';
import { getApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import * as CONSTANTS from '../../../config/Constants';
import store from '../../../redux/store';
import { setLearningPlanData } from '../../../redux/Actions/LearningPlans';

export const PlanContext = createContext(null);

export const getPlanData = async (planId, user_client_id, setPlanData) => {
  try {
    const queryParams = {
      id: planId,
      corporate_id: CONSTANTS.CLIENT_ID,
      created_by: user_client_id,
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.learning_plans, paramsData);
    setPlanData(response?.data[0]);
    await fetchCourses(response?.data[0]?.category_id);
  } catch (err) {
    console.log('Error in getting Learning Plan details', err);
  }
};

export const fetchCourses = async (category_id) => {
  try {
    const queryParams = {
      client_id: CONSTANTS.CLIENT_ID,
      category_id: category_id,
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const fetchedCourses = await getApiCall(
      API_URLS.courses_by_category_id,
      paramsData
    );
    store.dispatch(setLearningPlanData(fetchedCourses));
  } catch (error) {
    console.log('Error in getting courses', error);
  }
};
