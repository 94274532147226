import React from 'react';
import { CapabilityData } from '../../../helpers/Landing';
import classNames from 'classnames';
import c from '../landing.module.scss';
import Headings from '../../Shared/Headings';
import { Resources } from '../../../config/Resources';
import { useHistory } from 'react-router-dom';

const Capability = () => {
    const history = useHistory();
  return (
    <div className={classNames('row mx-0')}>
      <div className={classNames('col-md-12 col-lg-12', c.CapabilityContainer)}>
        <Headings
          headingType="h1"
          classToOverride={classNames('text-white fw-bold my-4 text-center')}
        >
          Type or choose a capability to learn about
        </Headings>
        <div className={classNames('row mx-3')}>
          {CapabilityData &&
            CapabilityData.map((item) => {
              return (
                <div
                  key={item?.text}
                  className={classNames('col-lg-4 col-md-6 mb-4')}
                  onClick={()=>{window.open('/content', '_blank')}}
                >
                  <div className={classNames(c.CapabilityCourseContainer)}>
                    <div className={classNames('text-white')}>{item?.icon}</div>
                    <Headings
                      headingType="h3"
                      classToOverride={classNames('text-white mt-2')}
                    >
                      {item?.text}
                    </Headings>

                    <div className={classNames(c.CapabilityCourseActivate)}>
                      <img
                        src={Resources.images.LandingPageCourseIcon}
                        className={classNames(c.LandingPageCourseIcon)}
                        alt="cover"
                      />
                      <Headings
                        headingType="h5"
                        classToOverride={classNames('text-white ms-2')}
                      >
                        Open Library
                      </Headings>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Capability;
