/**
 * @function CustomSlider
 * Component of profile/LearningPreferences
 * Used to design customize slider
 * Takes data from Airbnb Slider and AirbnbThumbComponent
 * @param sliderTitle Title of the slider displays at the top of the slider
 * @param sliderText1 Text displays at the left of the slider
 * @param sliderText2 Text displays at the right of the slider
 * @param value default value of the slider
 * @returns Customize slider
 */

import React from 'react';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import Text from '../../../Shared/Text/index';
import { AirbnbSlider, AirbnbThumbComponent } from '../AirbnbSlider/index';

const CustomSlider = ({
  sliderTitle,
  sliderText1,
  sliderText2,
  value,
  isDisabled,
  onChange,
  name,
}) => {
  return (
    <>
      <Text textType="t2">{sliderTitle}</Text>
      <hr />
      <div className="row mt-4 ">
        <div className={classNames(c.sliderText, 'col-2 text-center')}>
          <Text textType="t1">{sliderText1}</Text>
        </div>
        <div className="col-6">
          <AirbnbSlider
            components={{ Thumb: AirbnbThumbComponent }}
            name={name}
            value={parseInt(value)}
            track={false}
            disabled={isDisabled}
            step={1}
            min={0}
            max={5}
            onChange={onChange}
          />
        </div>
        <div className={classNames(c.sliderText, 'col-2 text-center')}>
          <Text textType="t1">{sliderText2}</Text>
        </div>
      </div>
    </>
  );
};

export default CustomSlider;
