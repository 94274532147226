import { API_URLS } from '../../../config/API_URLS/api_urls';
import { CLIENT_ID } from '../../../config/Constants';
import { setUserCorporateData } from '../../../redux/Actions/UserCorporateDetails';
import store from '../../../redux/store';
import { getApiCall } from '../../../utils/axios/axios';

export const getJoinedNeighborhood = async () => {
  try {
    const paramsData = {
      queryParams: {
        user_id: store.getState().generalInformation.user_id,
        platform_id: CLIENT_ID,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(
      API_URLS?.getJoinedNeighborhood,
      paramsData
    );
    response && store.dispatch(setUserCorporateData(response));
  } catch (error) {
    console.log(`Error in fetching User details ${error}`);
  }
};
