import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import c from '../signUp.Module.scss';
import classNames from 'classnames';
import SchoolIcon from '@mui/icons-material/School';
import { Resources } from '../../../config/Resources';
import { useHistory } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
const SignUpNav = ({ pageNo }) => {
  const history = useHistory();
  return (
    <nav
      className={classNames(
        'd-flex justify-content-between py-2 px-4',
        c.signUPNav
      )}
    >
      {pageNo === 1 || pageNo === 2 || pageNo === 3 ? (
        // <img src={Resources.images.InfoBubbleIcon} alt="logo" />
        <div></div>
      ) : (
        <IconButton aria-label="home" onClick={() => history.push('/')}>
          <img src={Resources.images.SignUpHomeIcon} alt="logo" />
        </IconButton>
      )}
      <img
        className={classNames('text-center')}
        src={Resources.images.AhuraHeaderLogo}
        alt="logo"
      />
      {pageNo === 1 || pageNo === 2 || pageNo === 3 ? (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<SchoolIcon />}
          className={`${c.signInBtn} rounded-pill`}
          onClick={() => {
            localStorage.setItem('token', '');
            googleAnalyticsTriggerFn(
              'Button',
              'Sign In',
              'Sign In button on Sign-Up page clicked'
            );
            return history.push('/sign-in');
          }}
        >
          Sign In
        </Button>
      ) : (
        <div></div>
      )}
    </nav>
  );
};

export default SignUpNav;
