/*global chrome */
import { handleStartStop } from '../../ExtensionHandler';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';
import store from '../../../redux/store';
import { CHROME_EXTENSION_ID } from '../../../config/Constants';

export const columns = [
  { id: 'time_finished', label: 'Date' },
  { id: 'description', label: 'Topic' },
  { id: 'source', label: 'Source' },
  { id: 'duration', label: 'Duration' },
  { id: 'assessment', label: 'Assessment' },
  { id: 'actions', label: 'Actions' },
];
export const emotionColors = {
  angry: 'rgba(213, 73, 65, 1)',
  sad: 'rgba(232, 81, 179, 1)',
  happy: 'rgba(0, 82, 217, 1)',
  neutral: 'rgba(2, 156, 212, 1)',
  surprised: 'rgba(43, 164, 113, 1)',
  confused: 'rgba(245, 186, 24, 1)',
  disgusted: 'rgba(227, 115, 24, 1)',
};

export const focusedEmotions = ['happy', 'surprised', 'neutral'];
export const extractDomain = (url) => {
  var parsedUrl = new URL(url.replace(/'/g, ''));
  return parsedUrl.hostname;
};
export const calculateEmotionPercentage = (data) => {
  const filteredData = data.filter((item) =>
    ['happy', 'surprised', 'neutral'].includes(item.expression_name)
  );
  const sum = filteredData.reduce((acc, item) => acc + item.value, 0);
  return sum + '%';
};

export const capitalizeFirstLetter = (emotion) => {
  return emotion.charAt(0).toUpperCase() + emotion.slice(1);
};

export const handleStartSession = (event, topic) => {
  event?.preventDefault();

  if (chrome && chrome?.runtime) {
    handleStartStop(true, topic);
  } else {
    store.dispatch(
      SET_TOAST_ERROR({
        error: true,
        message: 'Extension is not installed or Something went Wrong',
      })
    );
    window.open(
      `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID}`
    );
  }
};

export const filterOptions = [
  {
    label: 'This Month',
    value: 'this',
  },
  {
    label: 'Previous Month',
    value: 'prev',
  },
  {
    label: 'All time',
    value: 'all',
  },
];
