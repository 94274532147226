import React from "react";
// import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import c from '../table.module.scss';
// import IconButton from "@mui/material/IconButton";
import Text from "../../Text";
import MoreVertIcon from '@mui/icons-material/MoreVert';
function DropdownButton2({ btnName, options, setValue, item }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setValue(event.target.innerText, item);
    setAnchorEl(null);
  };

  return (
    <>
      <MoreVertIcon
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        color="black"
      >
      </MoreVertIcon>

      <Menu
        elevation={0}
        classes={{
          paper: "!rounded-t-2xl !rounded-b-2xl !bg-admin-primary !w-44 !mt-[10px]",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((items) => {
          return (
            <MenuItem key={items.id} onClick={handleClose}>
              <Text className="!text-lg !text-admin-white !font-normal !normal-case">
                {items.value}
              </Text>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default DropdownButton2;
