import React from 'react';
import classNames from 'classnames';

import Text from '../../../Shared/Text';
import QuizRadioButtonForm from '../QuizRadioButton';

const QuizQuestion = ({
  question = '',
  questionNo = 1,
  options = [],
  questionData,
  addQuestionsAns,
  submitStatus,
  setSubmitStatus,
}) => {
  return (
    <div className={classNames('mt-5')} key={questionNo}>
      <Text textType="t0" classToOverride={classNames('fw-bold')}>
        Q {questionNo}: {question}
      </Text>
      <QuizRadioButtonForm
        options={options}
        questionData={questionData}
        addQuestionsAns={addQuestionsAns}
        submitStatus={submitStatus}
      />
    </div>
  );
};
export default QuizQuestion;
