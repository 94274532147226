import { getApiCall } from '../../utils/axios/axios';
import { API_URLS } from '../../config/API_URLS/api_urls';

export const getUserInfo = async (id) => {
  const params = {
    id: id,
  };
  const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
  const response = await getApiCall(API_URLS.auth, paramsData);
  return response;
};
