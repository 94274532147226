import React from 'react';
import Card from '@mui/material/Card';
import classNames from 'classnames';
import c from '../../../learningPlans.Module.scss';
import CardContent from '@mui/material/CardContent';
import { Grid, Tooltip, IconButton } from '@mui/material';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import Text from '../../../../Shared/Text';
import _ from 'lodash';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Resources } from '../../../../../config/Resources';

const CustomizedContentCard = ({
  type = 'courses',
  topic,
  desc,
  removeCard,
  id,
}) => {
  return (
    <Card className={classNames('mt-1', c.customizedContentCard)}>
      <CardContent
        className={classNames(
          'h-100 p-0 d-flex flex-column border-start border-5',
          c[`${type}Card`]
        )}
      >
        <Grid container alignItems="center" className="mt-2">
          <Grid item xs={1} className="text-center">
            <ViewAgendaOutlinedIcon
              className={classNames(c.font_16, c.viewAgendaIcon)}
            />
          </Grid>

          <Grid item xs>
            <Text textType="t1" classToOverride="fw-bold text-uppercase">
              {type}
            </Text>
          </Grid>
        </Grid>

        <div className="ms-2 ps-4">
          <Text headingType="t0" classToOverride="fw-bold pt-4">
            {topic}
          </Text>

          <Tooltip title={desc}>
            <span
              className={classNames(
                'pt-1',
                c.font_14,
                c.fontWeight500,
                c.cardDesc
              )}
            >
              {_.truncate(desc, { length: 130 })}
            </span>
          </Tooltip>
        </div>

        <div className="pe-4 mt-auto mb-2 d-flex justify-content-between align-items-center">
          <IconButton onClick={removeCard} value={id}>
            <RemoveCircleOutlineRoundedIcon
              className={classNames('text-dark', c.font_20)}
            />
          </IconButton>
          {type === 'video' ? (
            <YouTubeIcon className="fs-1" />
          ) : (
            <img
              src={
                type === 'audio'
                  ? Resources?.icons?.headphones
                  : Resources?.icons[type]
              }
              alt={`${type ? type : 'course'} icon`}
              height="24px"
              width="24px"
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomizedContentCard;
