import React from "react";
import Headings from "../../../Shared/Headings";

const LearningBehaviorHeader = () => {
  return (
    <div className={`p-4`}>
      <Headings headingType="h3" classToOverride="fw-bold col-md-4">
        Learning Behavior
      </Headings>
    </div>
  );
};

export default LearningBehaviorHeader;
