import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Text from '../../../Shared/Text';
import classNames from 'classnames';
import EmployeeStyling from '../EmployeeStyling.module.scss';
import { getMinutesAndHrsString } from '../../../../utils/utility';
import { CoPresentOutlined } from '@mui/icons-material';

// tName={item.name}
// tHoursStudied={item.total_duration}
// tAssignedBy = {item.assigned_by}
// tAssessment={item.average_score}
const EmployeeCapabilityStats = ({
  tName,
  tHoursStudied,
  tAssignedBy,
  tAssessment,
}) => {
  // const minuteToHr = (minutes)=> {
  //     var h = Math.floor(minutes / 60);
  //     var m = minutes % 60;
  //     h = h < 10 ? '0' + h : h;
  //     m = m < 10 ? '0' + m : m;
  //     return h + 'hr' +' '+ m+'min';
  //   }

  function minuteToHr(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? 'min ' : 'min ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : '';
    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <>
      <TableRow>
        <TableCell
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
          key={1}
        >
          {/* <Text classToOverride={EmployeeStyling.TableData} textType="t1"> */}
          {tName || ''}
          {/* </Text> */}
        </TableCell>
        <TableCell
          key={2}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          {/* <Text classToOverride={EmployeeStyling.TableData} textType="t1"> */}
          {/* //fixed for now */}
          {/* {'Finance'} */}
          {minuteToHr(Number(tHoursStudied)) || '-'}
          {/* </Text> */}
        </TableCell>
        <TableCell
          key={3}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          {/* <Text classToOverride={EmployeeStyling.TableData} textType="t1"> */}
          {`${Number(tAssessment).toFixed(1)} %` || 0}
          {/* </Text> */}
        </TableCell>
        <TableCell
          key={4}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          {/* <Text classToOverride={EmployeeStyling.TableData} textType="t1"> */}
          {tAssignedBy || '-'}
          {/* </Text> */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeeCapabilityStats;
