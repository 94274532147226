import React from 'react';
import { Modal, Box, IconButton, Chip, Rating } from '@mui/material';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import CloseIcon from '@mui/icons-material/Close';
import Headings from '../../Shared/Headings';
import Text from '../../Shared/Text';
import { preliminaryInformation } from '../../../MockData/LearningPlans/PlanPreview';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const PlanPreview = ({ openModal, setModal }) => {
  return (
    <Modal open={openModal}>
      <Box
        pl={8}
        className={classNames(
          'container-fluid g-0 mt-5 pt-2 pb-5 rounded bg-white',
          c.planPreviewModalBox
        )}
      >
        <IconButton className="float-end me-2" onClick={() => setModal(false)}>
          <CloseIcon />
        </IconButton>
        <div className="pt-5 d-flex align-items-center">
          <Headings
            headingType="h2"
            classToOverride={classNames('pb-1', c.heading)}
          >
            Preview
          </Headings>
          <Chip
            size="small"
            label="New"
            className={classNames('mx-3 rounded', c.modalChip)}
          />
          <Text
            textType="t2"
            classToOverride={classNames('me-2 fw-bold', c.primaryColor)}
          >
            3.0
          </Text>
          <Rating size="small" value={3} readOnly className={c.primaryColor} />
        </div>
        <Box pr={9}>
          <Headings
            headingType="h5"
            classToOverride={classNames('pt-3 fw-bold', c.primaryColor)}
          >
            Learning Plan #1
          </Headings>
          <Text textType="t0" classToOverride="pt-3">
            Learn the core Greensock Animation Platform. [ GSAP ]. Building
            advanced web animations using JavaScript SVG & CSS
          </Text>
          <Headings
            headingType="h5"
            classToOverride={classNames('pt-4 pb-3 fw-bold', c.primaryColor)}
          >
            Description
          </Headings>
          <Text textType="t1">
            In this course, you will learn in-depth knowledge of the methods and
            properties of the GreenSock GSAP library core. The course also
            includes information about the plugins built into the core.
            <br /> <br /> At the beginning of each lesson, theoretical
            information about the studied material is presented, then practical
            examples follow to consolidate new knowledge.
            <br /> <br /> All animations presented in the course are attached to
            the corresponding lessons in the archives.
          </Text>
          <Headings
            headingType="h5"
            classToOverride={classNames('pt-5 fw-bold', c.primaryColor)}
          >
            What you’ll learn
          </Headings>
          <List>
            {preliminaryInformation['learningContent'].map((item, index) => (
              <ListItem
                key={`learningContent${index}`}
                disableGutters
                alignItems="flex-start"
              >
                <ListItemIcon
                  className={classNames('pe-2', c.listItemIcon, c.primaryColor)}
                >
                  <CheckRoundedIcon className={c.font_20} />
                </ListItemIcon>
                <ListItemText>
                  <Text textType="t1">{item}</Text>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Headings
            headingType="h5"
            classToOverride={classNames('py-3 fw-bold', c.primaryColor)}
          >
            Requirements
          </Headings>
          <List>
            {preliminaryInformation['requirements'].map((item, index) => (
              <ListItem key={`requirement${index}`} disablePadding>
                <ListItemIcon className={classNames('pe-2', c.listItemIcon)}>
                  <FiberManualRecordIcon
                    className={classNames('text-dark', c.font_8)}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Text textType="t1">{item}</Text>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default PlanPreview;
