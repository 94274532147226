/**
 * @function LearningPlansOverview
 */

import React from 'react';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import Warning from '../Warning';
import CustomTable from '../../Shared/Table';
import { columns } from '../../../helpers/LearningPlans/Overview';

const LearningPlansOverview = () => {
  return (
    <>
      <div
        className={classNames(
          'mt-4 rounded',
          c.overviewSection,
          c.overviewFirstSection
        )}
      >
        <div className="h-100 w-100 d-flex align-items-center">
          <Warning />
        </div>
      </div>
      <div className={classNames('mt-5 p-3', c.overviewSection)}>
        <CustomTable columns={columns} showPagination={false} />
        <Warning />
      </div>
    </>
  );
};

export default LearningPlansOverview;
