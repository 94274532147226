/**
 * @function ProfileAccountMenu
 *
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import c from '../../../home.Module.scss';
import {
  corporateAccountMenuItems,
  mainMenuItems,
  profileAccountMenuItems,
} from '../../../../../helpers/Home/CreateAccount';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemText from '@mui/material/ListItemText';
import Headings from '../../../../Shared/Headings';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch } from 'react-redux';
import { onLogOut } from '../../../../../redux/Actions/UserLoggedOut';
import { chromeWebStoreLink } from '../../../../../config/Constants';
import ExtensionIcon from '@mui/icons-material/Extension';
import JoinCorporateNew from '../../../../Shared/JoinCorporateNew';
import ChangeExtension from '../../../../Shared/ChangeExtension';
import { getApiCall } from '../../../../../utils/axios/axios';
import { useSelector } from 'react-redux';
import { API_URLS } from '../../../../../config/API_URLS/api_urls';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import axios from 'axios';
import { SET_TOAST_ERROR } from '../../../../../redux/Actions/Common';
import {
  discontinueAccess,
  leaveAccount,
  makeMainAccountMenu,
} from '../../../../../helpers/My Account';
import { Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getAccountsData } from '../../../../../helpers/Home/CreateAccount/DropdownMenu/ProfileMenu';
import { id } from 'date-fns/locale';
import { getJoinedNeighborhood } from '../../../../../helpers/Profile/JoinedNeighborhood';

const ProfileAccountMenu = ({
  anchorEl,
  isCorporate,
  setAnchorEl,
  mainAcc,
  corporateId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showJoinCom, setJoinComp] = React.useState(false);
  const [showChangeExtension, setShowChangeExtension] = React.useState(false);
  const [neighbourhoodData, setNeighbourhoodData] = React.useState('');
  const { generalInformation } = useSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [openLeaveAccountModal, setOpenLeaveAccountModal] = useState(false);
  const accounts = useSelector((state) => state?.accounts) || {};
  const [disableMakeMainAccount, setDisableMakeMainAccount] = useState(mainAcc);

  const [mapCorporateItems, setMapCorporateItems] = useState(
    isCorporate ? corporateAccountMenuItems : profileAccountMenuItems
  );

  const onLeaveAccountClick = () => {
    setOpenLeaveAccountModal(true);
  };

  const onMenuItemClick = async (id, link) => {
    if (id === 'discontinueAccess') {
      // await discontinueAccess();
      setOpenModal(true);
    }
    // if (id === 'viewProfileItem') {
    //   goToMenu(true);
    // }
    if (id === 'extensionItem') {
      window.open(chromeWebStoreLink);
    }
    if (id === 'changeExtension') {
      setShowChangeExtension(true);
    }
    if (id === 'logOutItem') {
      localStorage.clear();
      dispatch(onLogOut());
    } else if (id === 'joinOrganization') {
      if (showJoinCom == false) {
        setJoinComp(true);
      } else {
        return;
      }
    }
    if (id === 'makeMainAccount') {
      if (!neighbourhoodData || !neighbourhoodData.main_acc) {
        console.log('not neighbourhoodData');
        // Only allow making main account if main_acc is not already true
        await makeMainAccountMenu(
          generalInformation.user_id,
          corporateId,
          generalInformation.platform_id
        );

        // Set the state to disable the menu after successfully making the main account
        setDisableMakeMainAccount(true);

        // Set local storage to persist the state
        // localStorage.setItem('disableMakeMainAccount', 'true');

        // Refetch neighbourhood data and accounts data
        await getJoinedNeighborhood();
        await getAccountsData();
      }
    }

    history.push(link);
  };

  useEffect(async () => {
    await getAccountsData();
  }, [disableMakeMainAccount]);

  const leaveAccountFunc = async () => {
    const response = await leaveAccount(
      generalInformation.user_id,
      corporateId,
      generalInformation.platform_id,
      generalInformation.external_id
    );
    setOpenLeaveAccountModal(false);
    if (response.result === 'removed Successfully') {
      await getAccountsData();
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: response.result,
        })
      );
    }
  };

  return (
    <>
      {/* {showErrorMsg && (
        <Alert
          severity="error"
          className="w-100 mb-2"
          onClose={() => setShowErrorMsg(false)}
        >
          Please make some other account as main account
        </Alert>
      )} */}
      <Menu
        aria-labelledby="account menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ position: 'fixed', top: '50px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classNames(c.accountMenu, c.primaryColor),
        }}
      >
        <MenuList>
          {mapCorporateItems.map((item) => {
            return (
              <MenuItem
                key={item.id}
                onClick={() => {
                  if (item.id === 'leaveAccount') {
                    onLeaveAccountClick();
                  } else {
                    onMenuItemClick(item.id, item.redirectTo);
                  }
                }}
                disabled={mainAcc && item.id === 'makeMainAccount'}
                // Disable the menu item if `disabled` is true
              >
                <>
                  <ListItemText
                    className={
                      (mainAcc && item.id === 'makeMainAccount') ||
                      item.id === 'viewPaymentItem' ||
                      item.id === 'viewCorporatePaymentItem'
                        ? c.disabledMenuIcon
                        : c.menuIcon
                    }
                  >
                    {item.name}
                  </ListItemText>
                </>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>

      {/* Deactivate My Account Modal */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Discontinue Access?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After this, you will no longer have login access to Ahura. To change
            that in the future, email hi@ahuraai.com.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              const discontinueResult = discontinueAccess();
              if (discontinueResult) {
                setOpenModal(false);
                localStorage.clear();
                dispatch(onLogOut());
                history.push('/sign-in');
              }
              // If discontinue access is returning true then only modal will be closed
              // else it won't get closed and will not allow user to logout
            }}
            color="primary"
            autoFocus
          >
            Discontinue Access
          </Button>
        </DialogActions>
      </Dialog>

      {/* Leave Account Modal */}
      <Dialog
        open={openLeaveAccountModal}
        onClose={() => setOpenLeaveAccountModal(false)}
        aria-labelledby="leave-account-dialog-title"
        aria-describedby="leave-account-dialog-description"
      >
        <DialogTitle id="leave-account-dialog-title">
          {'Leave Account?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="leave-account-dialog-description">
            {mainAcc
              ? 'Please create another main account.'
              : 'Are you sure want to leave account?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${mainAcc ? 'd-none' : ''}`}
            onClick={() => setOpenLeaveAccountModal(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={
              mainAcc ? () => setOpenLeaveAccountModal(false) : leaveAccountFunc
            }
            color="primary"
            autoFocus
          >
            {mainAcc ? 'OK' : 'Leave Account'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileAccountMenu;
