import React from 'react';
import classNames from 'classnames';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import ContentUsageRow from '../ContentUsage/row';
import { urlShorten } from '../../../../utils/utility';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
// import { changeToSeconds } from '../../../../helpers/ExtensionHandler';

const ContentUsage = ({ pages }) => {
  return (
    <React.Fragment>
      <Headings
        headingType="h6"
        classToOverride={classNames(LearningBehaviorCss.visitedSessionCaption)}
      >
        Top Distractions:
      </Headings>

      <div className={classNames('col-md-12 mt-2 mb-1')}>
        <div className={classNames('row')}>
          <div className={classNames('col-md-4 text-start')}>
            <Text
              textType="t2"
              classToOverride={LearningBehaviorCss.visitedSessionHead}
              text="Content/URLs"
            />
          </div>
          <div className={classNames('col-md-4 text-center')}>
            <Text
              textType="t2"
              classToOverride={LearningBehaviorCss.visitedSessionHead}
              text="Average Focus"
            />
          </div>
          <div className={classNames('col-md-4 text-end')}>
            <Text
              textType="t2"
              classToOverride={LearningBehaviorCss.visitedSessionHead}
              text="Average Time"
            />
          </div>
        </div>
      </div>
      {pages?.slice(0, 3).map((page, index) => {
        return (
          <ContentUsageRow
            key={index}
            link={urlShorten(page?.url)}
            percentage={''}
            time={moment
              .duration(page?.page_duration, 'seconds')
              ?.format('HH:mm:ss', { trim: false })}
          />
        );
      })}
      {/* </table> */}
      {/* <ContentUsageRow link={"Facebook.com"} percentage={"35%"} time={"18:32:00"} /> */}
    </React.Fragment>
  );
};

export default ContentUsage;
