import React from 'react';
import c from './../leftpanel.module.scss';
import classNames from 'classnames';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Badge } from 'react-bootstrap';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Permission } from '../../../../config/Permission';
import subListsCls from './subListCls.module.scss';

const SubListItem = ({
  category_item,
  selectedIndex,
  handleSubCategoryView,
  permission,
}) => {
  const setSubCategoryData = ({ ...params }) => {
    handleSubCategoryView({ ...params });
  };

  return (
    <React.Fragment>
      {category_item &&
        category_item.sub_menu_items?.map((sub_category_item) => (
          <ListItemButton
            key={sub_category_item.id}
            selected={selectedIndex === sub_category_item.id}
            className={classNames('p-0 mt-1')}
          >
            <ListItemText
              onClick={() => setSubCategoryData(sub_category_item)}
              className={classNames(subListsCls.listItemsWrapper)}
            >
              <div className={classNames(subListsCls.subItemName)}>
                {sub_category_item.menu_name}
              </div>
            </ListItemText>
            {sub_category_item?.count !== null &&
              sub_category_item?.count !== undefined && (
                <Badge className={classNames(subListsCls.badgeClass)}>
                  {sub_category_item?.count}
                </Badge>
              )}
          </ListItemButton>
        ))}
    </React.Fragment>
  );
};

export default SubListItem;
