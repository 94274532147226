/**
 * @function LicenseDistribution
 * Component of Manager Dashboard Overview section
 */

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Headings from '../../Shared/Headings';
import style from '../managerDashboard.module.scss';
import Button from '@mui/material/Button';
import { Doughnut } from 'react-chartjs-2';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import store from '../../../redux/store';
import {
  LicenseDistributionDoughnutChartOptions,
  //doughnutChartPlugin,
  getLicenseData,
} from '../../../helpers/ManagerDashboard/LicenseDistribution';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LicenseDistributionMenuItems } from '../../../MockData/ManagersDashboard';
import Text from '../../Shared/Text';
import { useSelector } from 'react-redux';

const LicenseDistribution = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [licenseMenu, setLicenseMenu] = React.useState(null);
  const openMenu = Boolean(licenseMenu);
  const handleClickMenu = (event) => {
    setLicenseMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setLicenseMenu(null);
  };

  // const result = LicenseDistributionDoughnutChartData.datasets[0].data.reduce(
  //   (total, currentValue) => (total = total + currentValue),
  //   0);

  const client_id = useSelector(
    (state) => state?.generalInformation?.client_id
  );

  const [licenseData, setLicenseData] = useState({});

  useEffect(() => {
    getLicenseDetails();
  }, [client_id]);

  const LicenseDistributionDoughnutChartData = {
    labels: ['Used Licenses', 'Inactive Licenses', 'Remaining Licenses'],
    datasets: [
      {
        data: [
          licenseData?.used_licenses,
          licenseData?.inactive_licenses,
          licenseData?.remaining_licenses,
        ],
        backgroundColor: ['#00A89A', '#A45BFF', '#87d4e4'],
        borderColor: ['#00A89A', '#A45BFF', '#87d4e4'],
        borderWidth: 1,
      },
    ],
  };

  const getLicenseDetails = async () => {
    try {
      if (client_id) {
        const res = await getLicenseData(client_id);
        setLicenseData(res);
      }
    } catch (err) {
      console.log('Error in getProfileDetails', err);
    }
  };

  return (
    <div className={classNames(style.LicenseDistributionContainer, 'p-2 me-1')}>
      <div className="d-flex bd-highlight justify-content-between mt-3 mb-3">
        <div className="bd-highlight">
          <Headings headingType="h6" classToOverride="fw-bold p-2">
            License Distribution
          </Headings>
        </div>
        <Menu
          id="license-distribution-menu"
          anchorEl={licenseMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'license-distribution-button',
          }}
        >
          {LicenseDistributionMenuItems &&
            LicenseDistributionMenuItems?.map((item) => {
              return (
                <MenuItem key={item?.id} onClick={handleCloseMenu}>
                  {item?.text}
                </MenuItem>
              );
            })}
        </Menu>
      </div>

      {/* <div className="mb-3 row justify-content-end">
        {LicenseDistributionDoughnutChartData.datasets[0].data.map(
          (d, index) => (
            <div className="col-2" key={index}>
              {d}/{licenseData?.total_licenses}
            </div>
          )
        )}
      </div> */}
      <div className={classNames(style.LicenseDistributionChart, 'mt-5')}>
        <Doughnut
          data={LicenseDistributionDoughnutChartData}
          options={LicenseDistributionDoughnutChartOptions}
          //plugins={[doughnutChartPlugin]}
        />
      </div>
      <Text textType={'t0'} classToOverride="fw-bold text-end me-5 mt-2">
        Total Licences:{' '}
        <span className={classNames(style.primaryColor)}>
          {licenseData?.total_licenses}
        </span>
      </Text>
    </div>
  );
};

export default LicenseDistribution;
