import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import c from '../shared.Module.scss';
import Headings from '../Headings';
import ProfilePicture from '../ProfilePicture';
import SessionMetrics from './SessionMetrics';
import { SessionDetailsURL } from '../../../config/API_URLS/api_urls';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';
import axios from 'axios';
import moment from 'moment';
import Text from '../Text';
import SessionSummary from './SessionSummary';
import SessionEmotionsChart from './SessionEmotionsChart';
import Chart from 'chart.js/auto';
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';
import { calculateEmotionPercentage } from '../../../helpers/Home/Sessions';
ChartJs.register(Tooltip, Title, ArcElement, Legend);

const SessionDetails = ({ sessionInfo, barInfo, setModal }) => {
  const userGeneralInfo =
    useSelector((state) => state?.generalInformation) || {};
  const { profile_image_url, user_name } = userGeneralInfo;
  const [sessionData, setSessionData] = useState({});
  const [isShowMore, setIsShowMore] = useState(false);
  const dispatch = useDispatch();

  const getSessionDetails = async () => {
    try {
      const res = await axios.get(SessionDetailsURL(sessionInfo?.session_id));
      setSessionData({ ...res.data });
      console.log('session data', setSessionData({ ...res.data }));
    } catch (error) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something went wrong while fetching session details',
        })
      );
    }
  };
  useEffect(() => {
    if (sessionInfo?.session_id) getSessionDetails();
  }, [sessionInfo?.session_id]);

  return (
    <Modal
      open={true}
      className="d-flex align-items-center overflow-auto mb-5"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        className={classNames('row g-0 flex-wrap mx-auto', c.summaryDetails)}
      >
        <div
          className={classNames(
            'col-xl-3 text-white rounded-start',
            c.firstBox1
          )}
        >
          <Headings
            headingType="h2"
            classToOverride="pt-5 fw-bold text-center pb-5"
          >
            Summary
          </Headings>
          {/* <div className="pt-5 d-flex align-items-center">
            <ProfilePicture
              imgSrc={profile_image_url}
              alt={user_name}
              classToOverride={c.profileImage}
            /> */}
          <div className={classNames('rounded-3 mx-auto', c.firstBox2)}>
            <div className="p-3">
              <div className="d-flex justify-content-between ">
                <Headings>Focused Emotions</Headings>
                {sessionData?.expression_data && (
                  <Headings classToOverride="font-bold">
                    {calculateEmotionPercentage(sessionData.expression_data)}
                  </Headings>
                )}
              </div>
              {/* <div className="d-flex justify-content-between pt-2">
                <Headings >Camera Analytics</Headings>
                <Headings >
                </Headings>
              </div>
              <div>
                <Headings headingType="h6">"Enabled"</Headings>
              </div> */}

              <div className="pt-4 mx-lg-auto ">
                {sessionData?.expression_data && (
                  <SessionEmotionsChart
                    data={sessionData.expression_data}
                    isShowMore={isShowMore}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <SessionSummary
          stats={{
            duration: moment
              .duration(sessionData?.browsing?.total_duration, 'seconds')
              ?.format('HH:mm:ss', { trim: false }),
            visited_pages: sessionData?.visited_pages?.map((item) => {
              return {
                ...item,
                duration: moment
                  .duration(item.page_duration, 'seconds')
                  ?.format('HH:mm:ss', { trim: false }),
                unformatedTime: item.page_duration,
              };
            }),
          }}
          barData={{
            good: sessionData?.browsing?.focus_prcnt,
            neutral: sessionData?.browsing?.neutral_prcnt,
            bad: sessionData?.browsing?.distraction_prcnt,
          }}
          setModal={setModal}
          sessionInfo={sessionInfo}
          sessionData={sessionData}
          sessionNudges={sessionData?.session_nudges}
          isShowMore={isShowMore}
          setIsShowMore={setIsShowMore}
        />
      </Box>
    </Modal>
  );
};

export default SessionDetails;
