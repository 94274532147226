import React from 'react';
import Modal from '@mui/material/Modal';
import { TextField, Button } from '@mui/material';
import { CHROME_EXTENSION_ID_PROD } from '../../../config/Constants';
import classNames from 'classnames';
import c from '../shared.Module.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ChangeExtension = (props) => {
  const [extensionID, setExtensionID] = React.useState('');
  const [validationErr, setValidationErr] = React.useState(false);

  const handleSubmit = () => {
    if (isValidChromeExtensionId(extensionID)) {
      localStorage.setItem('extension_id', extensionID);
      window.location.reload();
    }
  }

  function isValidChromeExtensionId(extensionId) {
    if (extensionId === CHROME_EXTENSION_ID_PROD) {
      return false;
    }
    const idPattern = /^[a-z0-9]{32}$/;
    return idPattern.test(extensionId);
  }

  return (
    <Modal
      open={props.showChangeExtension}
      className="d-flex align-items-center"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableBackdropClick
      onClose={() => props.setShowChangeExtension(false)}
    >
      <div
        className={classNames(
          'row g-0 justify-content-center flex-wrap mx-auto',
          c.sessionDetailWhite
        )}
      >
        <div
          className={classNames(
            'col-xl-6 text-white rounded-start',
            c.firstBox
          )}
        >
          <div className="mt-2 text-end">
            <IconButton
              onClick={() => {
                props.setShowChangeExtension(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h3
            classToOverride="pt-5 fw-bold"
            className={classNames('text-center', c.headingColor)}
          >
            Change Extension
          </h3>

          <div className="row justify-content-center mt-5">
            <div className="row justify-content-center">
              <TextField
                size="small"
                id="outlined-basic"
                label="Enter Extension ID"
                variant="outlined"
                sx={{ width: '75%', mt: 5 }}
                error={validationErr}
                helperText={validationErr ? 'Invalid Extension Id' : ''}
                onChange={(e) => {
                  // e.stopPropagation();
                  setExtensionID(e.target.value);
                  setValidationErr(!isValidChromeExtensionId(e.target.value));
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>

            <div className="row justify-content-center">
              <Button
                className={classNames(c.ButtonCreate)}
                variant="contained"
                onClick={(e) => {
                  handleSubmit();
                }}
                sx={{ width: '25%', mt: 5 }}
                disabled={
                  extensionID
                    ? false
                    : true
                }
              >
                Change
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeExtension;
