/**
 * @function LearningPlansPage
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import c from './learningPlans.module.scss';
import Headings from '../../components/Shared/Headings';
import { InputBase, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from '../../components/Layout/Select';
import AddIcon from '@mui/icons-material/Add';
import LearningPlansTabs from '../../components/LearningPlans/LearningPlansTab';
import LearningPlansOverview from '../../components/LearningPlans/Overview';
import LearningPlan from '../../components/LearningPlans/LearningPlan';
import LearningPlansModalBox from '../../components/LearningPlans/LearningPlansModalBox';
import AddingPlanModules from '../../components/LearningPlans/AddingPlanModules';
import { googleAnalyticsTriggerFn } from '../../utils/utility';

const LearningPlansPage = ({ search }) => {
  const [openModal, setModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleAddPlan = (addPlan) => setModal(addPlan);
  const searchParam = new URLSearchParams(search);

  return (
    <>
      {!searchParam.toString() && (
        <div className={classNames(c.learningPlans)}>
          <div className="row">
            <div className="col-6">
              <Headings
                headingType="h3"
                classToOverride={classNames(c.primaryColor, 'fw-bold')}
              >
                Learning Plans
              </Headings>
            </div>
            <div className="col-4">
              <div
                className={classNames(
                  'd-flex align-items-center w-100',
                  c.search
                )}
              >
                <IconButton
                  type="submit"
                  aria-label="search"
                  className={classNames(c.primaryColor)}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'Search' }}
                  className={classNames(c.primaryColor)}
                />
              </div>
            </div>
            <div className="col-2">
              <CustomSelect label="This Week" />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            {tabIndex === 0 && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setModal(true);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Add New Plan',
                    'Add New Plan button clicked on learning-plans page'
                  );
                }}
              >
                Add New Plan
              </Button>
            )}
          </div>
          <div className={`row mt-3 ${tabIndex !== 0 && 'mt-5'}`}>
            <LearningPlansTabs index={tabIndex} setTabIndex={setTabIndex} />
          </div>
          <div className="row">
            {tabIndex === 0 && <LearningPlansOverview />}
            {tabIndex === 1 && <LearningPlan handleAddPlan={handleAddPlan} />}
          </div>
          {openModal && (
            <LearningPlansModalBox openModal={openModal} setModal={setModal} />
          )}
        </div>
      )}

      {searchParam.has('plan_id') && (
        <AddingPlanModules planId={searchParam.get('plan_id')} />
      )}

      {searchParam.has('capability_id') && (
        <AddingPlanModules capabilityId={searchParam.get('capability_id')} />
      )}
    </>
  );
};

export default LearningPlansPage;
