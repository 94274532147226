import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Text from '../../../Shared/Text';
import LearningPlanStyling from './LearningPlan.module.scss'
import classNames from 'classnames';
const LearningPlanTable = ({ TableData = [], menuItems = [] }) => {
  return (
    <>
      {TableData.length > 0 &&
        TableData.map((data, index) => {
          return (
            <TableRow key={index}  >
              <TableCell key={1} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.learninfPlan}</Text>
              </TableCell>
              <TableCell key={2} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.learningPlanName}</Text>
              </TableCell  >
              <TableCell key={3} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.capabilities}</Text>
              </TableCell>
              <TableCell key={4} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.numberofContents}</Text>
              </TableCell>
              <TableCell key={5} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.numberofUses}</Text>
              </TableCell>
              <TableCell key={6} className={classNames(LearningPlanStyling.columnsStyling)}>
                <Text textType="t1">{data.avgRateCompleted}</Text>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};
export default LearningPlanTable;