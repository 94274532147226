import classNames from 'classnames';
import React, { useState } from 'react';
// import LearningProgressTitle from './LearningProgressTitle';
import LearningProgressStyling from './LearningProgress.module.scss';
import ChapterProgress from './ChapterProgress';
import Quiz from '../../../../components/Course/Quiz';

const LearningProgress = ({ getCourseDetails, ...props }) => {
  return (
    <div
      className={classNames(
        'ms-auto mt-5',
        LearningProgressStyling.LearningProgressContainer
      )}
    >
      {/* <LearningProgressTitle
        LessonTitle="Learning Plan 1:  For Developers"
        LessonSubTitle="Learn how to design and build websites that utilize Javascript."
      /> */}
      <ChapterProgress getCourseDetails={getCourseDetails} />
    </div>
  );
};

export default LearningProgress;
