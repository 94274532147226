import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import c from '../home.Module.scss';
import { Resources } from '../../../config/Resources';
import Box from '@mui/system/Box';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import {
  inCompleteProfileTabsDetail,
  learnerData,
} from '../../../helpers/Home/Stats';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
import Text from '../../Shared/Text';
import { CustomIframe } from '../../Shared/CustomIframe';

// Custom SVG icon for a golden 4-point star
const FourPointStarIcon = () => (
  <svg
    fill="gold"
    height="35"
    width="35"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12,17c0.8-4.2,1.9-5.3,6.1-6.1c0.6-0.1,0.8-0.5,1-0.9c-0.2-0.4-0.4-0.7-1-0.9C13.9,8.1,12.8,7,12,2.8C11.9,2.3,11.5,2,11,2 c-0.5,0-0.9,0.3-1,0.8C9.2,7,8.1,8.1,3.9,8.9C3.3,9,3.1,9.4,3.1,9.9s0.3,0.9,0.8,1c4.2,0.8,5.3,1.9,6.1,6.1c0.1,0.5,0.4,0.8,1,0.9 S11.9,17.4,12,17z" />
  </svg>
);

const handleLinkClick = (url) => {
  window.open(url, '_blank', 'noopener noreferrer');
};

const cardTexts = [
  'Refresh your goals',
  'How Ahura helps you',
  'How Ahura keeps data private',
];
const Stats = () => {
  const history = useHistory();
  const [selectedLink, setSelectedLink] = useState('');
  const profilePercentageStatus = useSelector(
    (state) => state?.profileCompletionStatus?.percentage
  );
  const isProfileComplete = profilePercentageStatus == 100 ? true : false;
  const profileIncompleteTabs = useSelector(
    (state) => state?.profileCompletionStatus?.incompleteTabs
  );
  return (
    <div
      className={classNames(
        'h-100 w-100 bg-white rounded-3 row g-0 justify-content-center align-items-center',
        c.stats
      )}
    >
      <div className={classNames('col-2', c.statsImgContainer)}>
        <img
          src={Resources?.images?.home?.stats}
          alt="stats"
          className="h-auto w-100"
        />
      </div>
      <div className="col-10 pt-4 ps-3">
        {/* <div className={classNames('fw-bold', c.progressHeading)}>
          You’re almost there! Continue with your progress and become a standout
          performer!
        </div>
        <div className={classNames('pt-2', c.fontWeight500, c.performanceStat)}>
          Use information on this page to review performance for you and your
          colleagues, start study sessions in backlog and access your profile to
          set new goals
        </div> */}
        {/* <Text classToOverride={classNames('pb-1', c.headingCls)}>
          Lifelong learning companion for your Career growth
        </Text> */}
        <Text classToOverride={classNames('pb-1', c.headingCls)}>
          Ahura is the home of life long learners.
        </Text>
        <br />
        {/* Overlay Section */}
        <div style={{ paddingLeft: '25px',  width: '900px'}}>
          <Grid container spacing={{ xs: 2, md: 20 }}>
            {learnerData.map((item, index) => (
              <Grid item xs={2} sm={4} md={2.5} key={index}>
                <a
                  href="#"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={() => setSelectedLink(item.redirectionUrl)}
                >
                  <Card
                    sx={{
                      borderRadius: 5,
                      bgcolor:
                        index === 0
                          ? '#55AA9A'
                          : index === 1
                          ? '#7240A0'
                          : '#C55A11',
                      color: 'white',
                      marginLeft: -2,
                      height: index === 0 ? 150 : index === 1 ? 150 : 150,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      maxWidth: 220,
                      '@media (min-width: 50vw)': {
                        width: 150, // Adjusted width for half screen
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: 'center',
                          whiteSpace: 'pre-line',
                          fontSize: '20px', // Default font size for larger screens
                          '@media (max-width: 768px)': {
                            fontSize: '18px', // Font size for medium screens
                          },
                          '@media (max-width: 480px)': {
                            fontSize: '16px', // Font size for smaller screens
                          },
                        }}
                      >
                        {cardTexts[index]}
                        {index === 0 && (
                          <FourPointStarIcon style={{ marginLeft: '4px' }} />
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
        <CustomIframe
          iconClass={c.cursorPointer}
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
        />

        <div
          className={classNames(
            'd-flex flex-wrap justify-content-between align-items-center',
            c.profileStatContainer
          )}
        >
          <Box display="flex" pr={5}>
            <InfoRoundedIcon
              className={classNames(
                'text-white rounded-circle',
                c.statsInfoIcon
              )}
            />
            <div className="mb-1 d-flex flex-wrap flex-column">
              <div
                className={classNames(
                  'ps-2',
                  c.fontWeight500,
                  c.profileCompletionStat
                )}
              >
                <h5>
                  {' '}
                  Your profile is {profilePercentageStatus || 0}% complete.
                  {isProfileComplete ? (
                    <></>
                  ) : (
                    <>
                      {' '}
                      Following item(s) need to be updated to complete your
                      profile:
                    </>
                  )}
                </h5>
              </div>
              <Breadcrumbs
                separator={
                  <FiberManualRecordIcon className={c.statsSeparatorIcon} />
                }
                aria-label="profileItems"
              >
                {profileIncompleteTabs &&
                  profileIncompleteTabs.length > 0 &&
                  profileIncompleteTabs.map((item) => (
                    <Button
                      key={inCompleteProfileTabsDetail[item]?.id}
                      className={classNames(
                        'text-capitalize',
                        c.profileCompletionItems
                      )}
                      onClick={() => {
                        history.push(
                          inCompleteProfileTabsDetail[item]?.redirectTo
                        );
                        googleAnalyticsTriggerFn(
                          'Button',
                          inCompleteProfileTabsDetail[item]?.name,
                          `${
                            inCompleteProfileTabsDetail[item]?.name
                          }${' '} clicked`
                        );
                      }}
                      disabled={
                        inCompleteProfileTabsDetail[item]?.disabled
                          ? true
                          : false
                      }
                      size="large"
                    >
                      {/* <span className={c.profileCompletionItems}> */}
                      <u>{inCompleteProfileTabsDetail[item]?.name}</u>
                      {/* </span> */}
                    </Button>
                  ))}
              </Breadcrumbs>
            </div>
          </Box>
          <Button
            variant="contained"
            className="text-capitalize"
            onClick={() => {
              history.push('/profile');
              googleAnalyticsTriggerFn(
                'Button',
                'Update Profile',
                'Update Profile button clicked'
              );
            }}
          >
            Update Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Stats;
