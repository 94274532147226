/**
 * @function Menu
 *
 */

import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import c from '../home.Module.scss';
import ProfileMenu from './DropdownMenu/ProfileMenu';
import MainMenu from './DropdownMenu/MainMenu';

export const DropdownMenu = ({ anchorEl, setAnchorEl }) => {
  const [goToAnotherMenu, setGoToAnotherMenu] = useState(false);

  return (
    <Menu
      aria-labelledby="account menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{ position: 'fixed', top: '50px' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classNames(c.accountMenu, c.primaryColor),
      }}
    >
      {goToAnotherMenu ? (
        <ProfileMenu goToMenu={setGoToAnotherMenu} />
      ) : (
        <MainMenu goToMenu={setGoToAnotherMenu} />
      )}
    </Menu>
  );
};
