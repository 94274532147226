/**
 * @function Warning
 * Display no data warning
 */

import React from 'react';
import c from '../learningPlans.Module.scss';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Headings from '../../Shared/Headings';

const Warning = () => {
  return (
    <div className="w-100 d-flex flex-column justify-content-center align-items-center">
      <ErrorRoundedIcon className={c.errorIcon} />
      <Headings headingType="h3" classToOverride={c.primaryColor}>
        No Data
      </Headings>
      <Headings headingType="h6" classToOverride="mt-1">
        Start a Learning Plan to start tracking your progress
      </Headings>
    </div>
  );
};

export default Warning;
