/**\
 * This modules renders a image icon with it's info as inline block
 * @function AvatarWithInfo
 * @param {string} src source of image
 * @param {string} alt alternate for image
 * @param {string} variant variant of mui avatar
 * @param {string | number} width width of avatar
 * @param {string | number} height height of avatar
 * @param {string} leftMarginClass
 * @param {string} classForHead heading class
 * @param {string} head heading
 * @param {string} classForSubHead sub heading class
 * @param {string} subHead subheading
 * @returns Avatar with Info
 */

import React from 'react';
import Avatar from '@mui/material/Avatar';

const AvatarWithInfo = ({
  src,
  alt,
  variant,
  width,
  height,
  leftMarginClass = 'ms-3',
  classForHead,
  head,
  classForSubHead,
  subHead,
}) => {
  return (
    <div className="d-flex align-items-center">
      <Avatar
        src={src}
        alt={alt}
        variant={variant}
        sx={{ width: width, height: height }}
      />
      <div className={leftMarginClass}>
        <div className={classForHead}>{head}</div>
        <div className={classForSubHead}>{subHead}</div>
      </div>
    </div>
  );
};

export default AvatarWithInfo;
