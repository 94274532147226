import { CssTwoTone } from '@mui/icons-material';
import { padding } from '@mui/system';


export const HorizontalStackedBaroptions = {
  indexAxis: 'y',
  maintainAspectRatio: true,
  borderRadius: 4,
  borderSkipped: false,
  barThickness: 'flex',
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  layout: {
    padding: {
      left: 20,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: true,
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};

export const HorizontalStackedBardata = {
  labels: ['A: ', 'B: ', 'C: ', 'D: '],
  datasets: [
    {
      data: [90, 59, 27, 65],
      backgroundColor: ['#757F88', '#757F88', '#757F88', '#757F88'],
    },
  ],
};

const highlighAnswer = (Answer) => {
  const options = ['A', 'B', 'C', 'D'];
  const index = options.indexOf(Answer);
  HorizontalStackedBardata.datasets[0].backgroundColor[index] = '#00A89A';
};

highlighAnswer('D');
