export const NetworkPermissions = {
  Invite: false,
  menu: {
    visible: {
      isVisible: true,
      exclude: [],
    },
    create: {
      isCreate: false,
      exclude: [],
    },
    edit: {
      isEdit: false,
      exclude: [],
    },
    remove: {
      isRemove: false,
      exclude: [],
    },
  },
  menu_items: {
    visible: {
      isVisible: true,
      exclude: [],
    },
    create: {
      isCreate: false,
      exclude: [],
    },
    edit: {
      isEdit: false,
      exclude: [],
    },
    remove: {
      isRemove: false,
      exclude: [],
    },
  },
  sub_menu_items: {
    visible: {
      isVisible: true,
      exclude: [],
    },
    create: {
      isCreate: false,
      exclude: [],
    },
    edit: {
      isEdit: false,
      exclude: [],
    },
    remove: {
      isRemove: false,
      exclude: [],
    },
  },
};
