import { Divider } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  HorizontalStackedBardata,
  HorizontalStackedBaroptions,
} from '../../../../../helpers/Course/QuestionsSummary';
import Headings from '../../../../Shared/Headings';
import Text from '../../../../Shared/Text';
import c from '../../quiz.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Options from './Options';

const QuestionsCard = (props) => {
  const [selectedOptionId, setSelectedOptionId] = useState(
    props.selectedOption
  );

  // console.log("options",props.options);
  // console.log("selected answer",selectedOptionId);
  return (
    <div className={classNames('ms-5 mb-5 d-flex ')}>
      <div className={classNames('col-8 ')}>
        <Headings headingType="h6" classToOverride={c.questionsCard}>
          Question {props.number}
        </Headings>
        <Text textType="t2" classToOverride={'fw-bold col-10 '}>
          {props.questionTitle}
        </Text>
        <Headings headingType="h6" classToOverride={'mt-5 fw-bold'}>
          Your Answer:
        </Headings>
        {/* {selectedOption && (
          <>
            <Text textType="t2" classToOverride="mt-2">
              A: &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              <br />
              {selectedOption === 'A' && correctOption === 'A' ? <div className={(classNames(c.correct, 'ms-4'))}> Correct Answer<DoneIcon fontSize='small' /></div> : ''}
              {selectedOption === 'A' && correctOption !== 'A' ? <div className={(classNames(c.warning, 'ms-4'))}> Inorrect Answer<CloseIcon fontSize='small' /></div> : ''}
            </Text>
            <Text textType="t2" classToOverride="mt-2">
              B: &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              <br />
              {selectedOption === 'B' && correctOption === 'B' ? <div className={(classNames(c.correct, 'ms-4'))}> Correct Answer<DoneIcon fontSize='small' /></div> : ''}
              {selectedOption === 'B' && correctOption !== 'B' ? <div className={(classNames(c.warning, 'ms-4'))}> Inorrect Answer<CloseIcon fontSize='small' /></div> : ''}
            </Text>
            <Text textType="t2" classToOverride="mt-2">
              C: &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              <br />
              {selectedOption === 'C' && correctOption === 'C' ? <div className={(classNames(c.correct, 'ms-4'))}> Correct Answer<DoneIcon fontSize='small' /></div> : ''}
              {selectedOption === 'C' && correctOption !== 'C' ? <div className={(classNames(c.warning, 'ms-4'))}> Inorrect Answer<CloseIcon fontSize='small' /></div> : ''}

            </Text>
            <Text textType="t2" classToOverride="mt-2">
              D: &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              <br />
              {selectedOption === 'D' && correctOption === 'D' ? <div className={(classNames(c.correct, 'ms-4'))}> Correct Answer<DoneIcon fontSize='small' /></div> : ''}
              {selectedOption === 'D' && correctOption !== 'D' ? <div className={(classNames(c.warning, 'ms-4'))}> Inorrect Answer<CloseIcon fontSize='small' /></div> : ''}

            </Text>
          </>
        )} */}
        {(props?.options).map((item, i) => (
          <Options
            key={item.id}
            value={item.option_value}
            selectedAnswerId={selectedOptionId}
            optionId={item.id}
            isCorrect={item.is_correct}
            optionNumber={i}
          />
        ))}
        <Divider
          flexItem={true}
          variant="fullWidth"
          style={{ marginRight: 25, marginTop: 25, color: '#DBE3EB' }}
        />
      </div>
      <Divider
        orientation="vertical"
        flexItem={true}
        variant="fullWIdth"
        className={c.divider}
      />
      {/* <div className={classNames("col-4 ms-4")}>
        <div className={classNames('d-flex justify-content-between')}>
          <div>
            <Headings headingType="h6" classToOverride={c.questionsCard}>
              Class Average Answers
            </Headings>
            <Text textType="t2" classToOverride={'fw-bold col-10'}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </Text>
          </div>
          <MoreVertIcon sx={{ color: '#913DFB' }} />
        </div>
        <div className={classNames('d-flex w-100 mt-4 justify-content-center')}>
          <Bar
            data={HorizontalStackedBardata}
            options={HorizontalStackedBaroptions}
          />
        </div>
        <div className={classNames('d-flex justify-content-end')}>
          <Text textType="t2" classToOverride={c.correct}>Correct Answer: {correctOption}</Text>
        </div>
      </div> */}
    </div>
  );
};

export default QuestionsCard;
