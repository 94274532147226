import { API_URLS } from '../../config/API_URLS/api_urls';
import { CLIENT_ID } from '../../config/Constants';
import { getApiCall } from '../../utils/axios/axios';

export const getAllCourses = async (search = null) => {
  try {
    const params = {
      client_id: CLIENT_ID,
      search: search,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(
      API_URLS.courses_by_category_id,
      paramsData
    );
    return response.sort((a, b) => a.is_public - b.is_public);
  } catch (err) {
    console.log('Error in getAllCourses | helpers Content', err);
  }
};
export const getAllCategories = async () => {
  try {
    const params = {
      client_id: CLIENT_ID,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.categories, paramsData);
    return response;
  } catch (err) {
    console.log('Error in getAllCourses1 | helpers Content', err);
  }
};
