import classNames from 'classnames';
import Panel from '../../components/layout/Panel';
import LeftPanel from '../../components/layout/Panel/LeftPanel';
import { RightPanel } from '../../components/layout/Panel/RightPanel';
import Network from '../../components/networks';
import { Button1 } from '../../components/shared/Buttons';
import { NetworkPermissions } from '../../config/Permission/Networks';
import networksCls from './networks.module.scss';
import { useEffect, useState } from 'react';
import Category from '../../shared/Panel/LeftPanel/Category';
import { useQuery } from 'react-query';
import { getNetworkSummary } from '../../services/networks';
import PageHeader from '../../components/shared/Header/PageHeader';
import { Resources } from '../../config/Resources';
import { useSelector } from 'react-redux';
import { ROLE_IDS } from '../../../config/Constants';

const Networks = () => {
  const [networkType, setNetworkType] = useState('ALL_REGISTRANTS');
  const [networkSummaryData, setNetworkSummaryData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const { generalInformation } = useSelector((state) => state);
  const handleCategoryView = ({ ...params }) => {
    if (params.menu_name === 'All Registrants') {
      setSelectedData(params);
      setNetworkType('ALL_REGISTRANTS');
    } else if (params.menu_name === 'Users') {
      setSelectedData(params);
      setNetworkType('CITIZENS');
    } else if (params.menu_name === 'Admins') {
      setSelectedData(params);
      setNetworkType('ADMINS');
    } else if (params.menu_name === 'Neighborhoods') {
      setSelectedData(params);
      setNetworkType('NEIGHBOURHOODS');
    }
  };

  useQuery(
    ['getNetworkSummary'],
    () =>
      getNetworkSummary(
        [ROLE_IDS.CLIENT_OWNER, ROLE_IDS.CLIENT_ADMIN].includes(
          generalInformation.role_id
        )
          ? { corp_id: generalInformation.client_id }
          : {}
      ),
    {
      retry: false,
      enabled: generalInformation.id ? true : false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setNetworkSummaryData(response);
        if (
          response?.length > 0 &&
          response[0]?.menu_items[0] &&
          Object.keys(selectedData)?.length <= 0
        )
          setSelectedData(response[0]?.menu_items[0]);
      },
    }
  );

  return (
    <>
      <Panel>
        <LeftPanel heading="Networks">
          {NetworkPermissions.Invite && (
            <Button1
              classToOverride={classNames(networksCls.inviteBtnCustom)}
              value="Invite"
            />
          )}
          <Category
            from="networks"
            handleCategoryView={handleCategoryView}
            data={networkSummaryData ? [...networkSummaryData] : []}
            selectedIndex={selectedData.id}
          />
        </LeftPanel>
        <RightPanel>
          <PageHeader
            icon={Resources.admin.Network}
            name={'Networks'}
            subText={
              'Manage networks, teams, groups and audiences in various formats.'
            }
          />
          <Network networkType={networkType} />
        </RightPanel>
      </Panel>
    </>
  );
};
export default Networks;
