import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Resources } from '../../../../config/Resources';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  calculateEmotionPercentage,
  capitalizeFirstLetter,
  emotionColors,
  focusedEmotions,
} from '../../../../helpers/Home/Sessions';
import Headings from '../../Headings';
import c from '../../shared.Module.scss';

const SessionEmotionsChart = ({ data, isShowMore }) => {
  const chartData = {
    datasets: [
      {
        data: data.map((data) => data.value),
        backgroundColor: data.map(
          (dataItem) => emotionColors[dataItem.expression_name]
        ),
      },
    ],
    labels: data.map((data) => data.expression_name),
  };

  const chartOptions = {
    animation: {
      animateRotate: false,
      animateScale: false,
    },
    cutout: '60%',
    plugins: {
      title: {
        text: 'Emotions',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: 'white',
        font: { size: '12px', weight: '600' },
        rotation: (context) => {
          const index = context.dataIndex;
          let angle = (index * 60) % 180;
          if (angle > 90 && angle < 270) {
            angle += 180;
          }
          return angle;
        },
        formatter: (value, context) => {
          if (value < 10) {
            return ''; // Hide label by default if value is less than 10
          }
          const labels = context.chart.data.labels[context.dataIndex];

          return capitalizeFirstLetter(labels);
        },
      },
    },
  };


  const filteredFocusedEmotions = data.filter((item) =>
    focusedEmotions.includes(item.expression_name)
  );

  const filteredStruggleEmotions = data.filter(
    (item) => !focusedEmotions.includes(item.expression_name)
  );
  return (
    <div>
      <div className="position-relative">
        <Doughnut
          data={chartData}
          options={chartOptions}
          plugins={[ChartDataLabels]}
        />

        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#fff' }}>
            {calculateEmotionPercentage(data)}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center flex-wrap flex-shrink-0 pt-2">
        <img src={Resources.images.GaugeColorIcon} alt="Gauge color" />
        <Headings classToOverride={c.doughnutData}>
          Keep higher than 75%
        </Headings>
      </div>
      {isShowMore && filteredFocusedEmotions.length > 0 && (
        <div>
          <Headings classToOverride={c.emotionHeading}>
            Focused Emotion:
          </Headings>
          <div className="d-flex align-items-center justify-content-center">
            {filteredFocusedEmotions?.map((item, index) => (
              <Headings key={item.id}>
                {capitalizeFirstLetter(item.expression_name)}
                {index !== filteredFocusedEmotions.length - 1 && ',\u00A0'}
              </Headings>
            ))}
          </div>
        </div>
      )}
      {isShowMore && filteredStruggleEmotions.length > 0 && (
        <div>
          <Headings classToOverride={c.emotionHeading}>
            Struggle Emotions:
          </Headings>
          <div className="d-flex align-items-center justify-content-center">
            {filteredStruggleEmotions?.map((item, index) => (
              <Headings key={item.id} classToOverride={c.emotions}>
                {capitalizeFirstLetter(item.expression_name)}
                {index !== filteredStruggleEmotions.length - 1 && ',\u00A0'}
              </Headings>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionEmotionsChart;
