/**
 * @function CoorporateDetails
 * @param {function} isOpen ,handleClose
 * @returns It will contains the Upload Picture modal.
 */

import React, { useState } from 'react';
import ModuleStyling from '../modal.Module.scss';
import classNames from 'classnames';
import RoundedCircle from '../Box/RoundedCircle';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import BackAndContinue from '../Box/BackAndContinue';
import Button from '@mui/material/Button';
import ProfilePicture from '../../../Shared/ProfilePicture';
import { Alert } from '@mui/material';
import ResizeImage from '../../../Profile/GeneralInformation/ProfileImageUpload/ResizeImage';
import { encodeFileToDataURL } from '../../../../utils/utility';
import { validImageFormats } from '../../../../config/Constants';
import { uploadCorporateProfilePicture } from '../../../../helpers/My Account';

const UploadPicture = ({ handleContinue, setOpen, corporateUserData }) => {
  // const [isuploaded, setIsuploaded] = React.useState(false);
  const [croppedImage, setCroppedImage] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [originalImage, setOriginalImage] = useState('');
  const [cropModal, setCropModal] = useState(false);

  const handleFileChange = (event) => {
    const originalFile = event?.target?.files[0];
    if (validImageFormats.includes(originalFile?.type)) {
      setShowErrorMsg(false);
      encodeFileToDataURL(originalFile, setOriginalImage);
      setCropModal(true);
    } else {
      setShowErrorMsg(true);
    }
  };

  const submitForm = async () => {
    if(originalImage === ''){
      handleContinue();
    }
    else{
      await uploadCorporateProfilePicture(croppedImage, corporateUserData);
      handleContinue();
    }
  };


  return (
    <>
      <div className="d-flex justify-content-between">
        <Headings headingType="h4" classToOverride="row g-0 ">
          Upload Picture or Logo
        </Headings>
        <div>
          <RoundedCircle numberOfItems={4} indexOfColoredItem={2} />
        </div>
      </div>

      <Text
        classToOverride={ModuleStyling.para}
        text="what type of account would you like to purchase ?"
      />
      {/* {isuploaded === false ? ( */}
      <div>
        <div
          className={classNames(
            // ModuleStyling.avatarDiv,
            'd-flex justify-content-center mt-5'
          )}
        >
          {/* <Avatar className={classNames(ModuleStyling.uploadAvatar)}>
              <span className={classNames(ModuleStyling.avatarText)}>BT</span>
            </Avatar> */}
          <div>
            {showErrorMsg && (
              <Alert
                severity="error"
                className="w-100 mb-2"
                onClose={() => setShowErrorMsg(false)}
              >
                Select a Valid Image
              </Alert>
            )}
            <ProfilePicture
              imgAlt="Profile Image"
              imgSrc={croppedImage}
              classToOverride={ModuleStyling.imageDimensions}
              onLoad={() => {}}
            />
          </div>
        </div>

        {/* <Text
            classToOverride="text-center mt-2"
            text="Upload Profile Photo"
          /> */}

        <center>
          <Button
            variant="text"
            component="label"
            size="large"
            className={classNames(
              'text-capitalize',
              ModuleStyling.fontWeight600
            )}
            // disabled={isEditable}
          >
            Upload Profile Photo
            <input
              accept="image/png,image/gif,image/jpeg"
              type="file"
              className="d-none"
              onChange={handleFileChange}
            />
          </Button>
        </center>

        <div
          className={classNames(
            ModuleStyling.skipNow,
            ModuleStyling.cursorPointer,
            'text-center'
          )}
          onClick={handleContinue}
        >
          <Text
            classToOverride="text-decoration-underline"
            text="Skip for now"
          />
        </div>
      </div>
      {/* ) : (
        <div>
          <div
            className={classNames(
              ModuleStyling.avatarDiv,
              'd-flex justify-content-center'
            )}
          >
            <img src={croppedImage} alt="logo" />
          </div>

          <p className="text-center mt-2">
            <b>Upload Profile Photo </b>
          </p>
        </div>
      )} */}

      {cropModal && (
        <ResizeImage
          originalImage={originalImage}
          setCropStep={setCropModal}
          setCroppedImage={setCroppedImage}
        />
      )}

      <BackAndContinue handleContinue={submitForm} setOpen={setOpen} />
    </>
  );
};

export default UploadPicture;
