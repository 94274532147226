import React from 'react';
import classNames from 'classnames';
import c from '../../community/community.Module.scss';
// import { FormControl } from "@mui/material";
// import { InputLabel } from "@mui/material";
// import { Select } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomSelect from '../../../Layout/Select';
import Text from '../../../Shared/Text';
import { CommunitiesDropdown } from '../../../../helpers/Home/Community';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const TopBar = ({ uniFilter, setUniFilter }) => {
  return (
    <div className={classNames('d-flex bd-highlight')}>
      <Text
        textType="t1"
        classToOverride={classNames(
          'me-auto bd-highlight ms-3',
          c.CommonHeadingCommunity
        )}
      >
        Community{' '}
      </Text>
      <div className=" bd-highlight w-25 me-3">
        <CustomSelect
          value={uniFilter}
          label="Filter"
          menuItems={CommunitiesDropdown}
          dropdownIcon={FilterAltIcon}
          onChange={(e) => {
            setUniFilter(e?.target?.value);
            googleAnalyticsTriggerFn(
              'Filter',
              'Community Filter',
              'Community Filter changed'
            );
          }}
        />
      </div>
    </div>
  );
};

export default TopBar;
