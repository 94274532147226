import React from 'react';
import { Box } from '@mui/system';
import CustomTabs from '../../../../Layout/Tabs';
import { InviteTabsOptions } from '../../../../../helpers/Allocation';

const InviteTabs = ({ index, setTabIndex }) => {
  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={InviteTabsOptions}
      />
    </Box>
  );
};

export default InviteTabs;
