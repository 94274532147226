/**
 * @function ProfileTabs
 * the tabs of profile details section are rendered here
 */

import React from 'react';
import { Box } from '@mui/system';
import CustomTabs from '../../Layout/Tabs';
const AccountTabs = ({ index, setTabIndex }) => {
  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={[{ label: 'Accounts', value: 0 }]}
      />
    </Box>
  );
};

export default AccountTabs;
