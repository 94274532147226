import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import classNames from 'classnames';
import c from '../layout.Module.scss';
import { CircularProgress } from '@mui/material';
import Headings from '../../Shared/Headings';

const Loader = () => {
  const loaderState = useSelector((state) => state?.loaderState);
  return (
    <Backdrop
      className={classNames(c.backdrop, 'd-flex flex-column text-white')}
      open={loaderState}
    >
      <CircularProgress size="5rem" thickness={2.5} color="inherit" />
      <Headings headingType="h5" classToOverride="pt-4">
        Loading
      </Headings>
    </Backdrop>
  );
};

export default Loader;
