export const EMAIL_REGEX_PATTERN = /\S+@\S+\.\S+/;

export const TEXT_FIELD_REGEX_PATTERN = /^[A-Za-z ]*$/;

export const MIME_TYPE_REGEX = /:(.*?);/;

export const UPPERCASE_PATTERN = /[A-Z]/;

export const LOWERCASE_PATTERN = /[a-z]/;

export const NUMBER_PATTERN = /[0-9]/;

export const SPECIAL_CHAR_PATTERN = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const PHONE_NUMBER_REGEX_PATTERN = /^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const AMAZON_S3_URL_REGEX =
  /[\w\-.]+\.s3\.?(?:[\w\-.]+)?\.amazonaws\.com|(?<!\.)s3\.?(?:[\w\-.]+)?\.amazonaws\.com\\?\/[\w\-.]+/;

export const GUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;

export const HTML_TAG_PATTERN =
  /<[^>]*(>|$)|&nbsp;|&ensp;|&emsp;|&thinsp;|&zwnj;|&zwj;/g;
