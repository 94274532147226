// CourseCardV2.js
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Headings from '../Headings';
import c from './courseCardv2.scss';
import { Chip } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { GroupOutlined } from '@mui/icons-material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import Text from '../Text';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
import {Resources} from '../../../config/Resources';
import styles from '../../Course/CourseData/courseData.module.scss'
import {shareButtonHostURL} from '../../../config/Constants';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  TwitterShareButton,
} from "react-share";

function CourseCardV2({
  type = "courses",
  id,
  courseCategory,
  heading,
  courseCategoryId,
  desc,
  peers,
  quizzes,
  sections,
  image_url,
}) {
  const shareUrl = shareButtonHostURL + '/course/' + id
  const customShareMessage = `Check out this awesome course! ${heading}`;
  return (
    <div className="card-container">
      <Link 
        onClick={() => {
              googleAnalyticsTriggerFn(
                'Button',
                'View',
                'View icon button clicked on course card V2'
              );
            }}
        to={`/course/${id}`} 
        className="card-image-link">
          <div className="card-image">
            <img src={image_url ? image_url : Resources.images.Ahura} alt={heading} />
            <div className="share-overlay">
              <div className="share-buttons">
                <TwitterShareButton url={shareUrl} className={`share-icon ${styles['social-button']}`}>
                  <XIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} className={`share-icon ${styles['social-button']}`}>
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <FacebookShareButton url={shareUrl} className={`share-icon ${styles['social-button']}`}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <WhatsappShareButton url={shareUrl} separator=" - " className={`share-icon ${styles['social-button']}`} title={customShareMessage}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
      </Link>
      <CardContent className="card-content">
        <Chip
          label={courseCategory}
          className="category"
          size="small"
        />
        <Link 
          onClick={() => {
            googleAnalyticsTriggerFn(
              'Button',
              'View',
              'View icon button clicked on course card V2'
            );
          }}
          to={`/course/${id}`} 
          className="title">
            <Headings headingType="h5" classToOverride="pt-2 fw-bold">
              {heading}
            </Headings>
        </Link>
        <div className="statistics">
        {type === 'courses' && (
          <div className="d-flex mt-2">
            {/* <div className="col-4"> */}
            <div
              className={classNames(
                c.peerSection,
                'text-center rounded-3 me-2'
              )}
            >
              <div className="d-flex align-items-center justify-content-center">
                <GroupOutlined />
                <Text textType={'t2'} classToOverride="ms-1">
                  {' '}
                  {peers} Peers
                </Text>
              </div>
            </div>
            {/* </div> */}

            {/* <div className="col-4"> */}
            <div
              className={classNames(
                c.peerSection,
                'text-center rounded-3 me-3'
              )}
            >
              <div className="d-flex align-items-center justify-content-center">
                <MenuBookOutlinedIcon />
                <Text textType={'t2'} classToOverride="ms-1">
                  {' '}
                  {sections} Sections
                </Text>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="col-4"> */}
            <div
              className={classNames(
                c.peerSection,
                'text-center rounded-3 me-3'
              )}
            >
              <div className="d-flex align-items-center justify-content-center">
                <WebOutlinedIcon />
                <Text textType={'t2'} classToOverride="ms-1">
                  {' '}
                  {quizzes} Quizzes
                </Text>
              </div>
            </div>
            {/* </div> */}
          </div>
        )}
        </div>
      </CardContent>
    </div>
  );
}

export default CourseCardV2;
