import React from 'react';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import c from '../quiz.module.scss';
import classNames from 'classnames';

const QuizTitle = ({ heading, title, ...props }) => {
  return (
    <div>
      <Headings
        headingType="h3"
        classToOverride={classNames(c.primaryColor, 'fw-bold')}
      >
        {heading}
      </Headings>
      <Text textType="t3">{title}</Text>
    </div>
  );
};
export default QuizTitle;
