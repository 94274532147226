import { API_URLS } from "../../../config/API_URLS/api_urls";
import { Resources } from "../../../config/Resources";
import { postApiCall } from "../../../utils/axios/axios";

export const Submitintrest =async (intrestList ,user_id ) => {
    try {
        const reqBody = {
          interest : intrestList,
          user_id
        };
        // const paramsData = {queryParams: {}, headers: {},isAuthorized : true}
        const res = await postApiCall(API_URLS.save_user_interet, reqBody,{ queryParams: {}, headers: {}, isAuthorized: true });
        return res
      } catch (err) {
        console.log('error in login', err);
      } 
}


export const IntrestData = [{
      icon: Resources.images.Python,
      text: "Back-End Coding",
      id: 1,
      IsSelected: false,
    },
    {
      icon: Resources.images.Economics,
      text: "Economics",
      id: 2,
      IsSelected: false,
    },
    {
      icon: Resources.images.Management,
      text: "Management",
      id: 3,
      IsSelected: false,
    },
    {
      icon: Resources.images.Science,
      text: "Science",
      id: 4,
      IsSelected: false,
    },
    {
      icon: Resources.images.Marketing,
      text: "Marketing",
      id: 5,
      IsSelected: false,
    },
    {
      icon: Resources.images.GreenTech,
      text: "Green tech",
      id: 6,
      IsSelected: false,
    },

    {
      icon: Resources.images.CleanEnergy,
      text: "Clean Energy",
      id: 7,
      IsSelected: false,
    },
    {
      icon: Resources.images.FrontEnd,
      text: "Front-end coding",
      id: 8,
      IsSelected: false,
    },
    {
      icon: Resources.images.Therapy,
      text: "Therapy",
      id: 9,
      IsSelected: false,
    },]