import { useState } from 'react';
import NeighborhoodList from './neighborhoodList';
import NeighborhoodMembers from './neignborhoodMembers';

const Neighborhoods = () => {
  const [showMemebers, setShowMemebers] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState('');
  if (!showMemebers)
    return (
      <NeighborhoodList
        setSelectedId={setSelectedId}
        setShowMemebers={setShowMemebers}
      />
    );
  else
    return (
      <NeighborhoodMembers
        setSearch={setSearch}
        searchString={search}
        corporateId={selectedId}
        setShowMemebers={setShowMemebers}
      />
    );
};
export default Neighborhoods;
