/* global chrome */

import { CHROME_EXTENSION_ID } from '../../../config/Constants';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

export let isExtensionInstalled = false;

export const extensionDetection = (event, goToNextPage, timerId) => {
  event.preventDefault();
  if (chrome && chrome.runtime) {
    console.log('log1', chrome.runtime, timerId, isExtensionInstalled);
    clearInterval(timerId.current);
    isExtensionInstalled = true;
    console.log('log2', chrome.runtime, timerId, isExtensionInstalled);
    goToNextPage();
  } else {
    console.log('log3', chrome.runtime, timerId, isExtensionInstalled);
    console.log('no installed');
    console.log('log4', chrome.runtime, timerId, isExtensionInstalled);
  }
  if (timerId.current === null) {
    console.log('log5', chrome.runtime, timerId, isExtensionInstalled);
    timerId.current = setInterval(() => {
      console.log('log6', chrome.runtime, timerId, isExtensionInstalled);
      extensionDetection(event, goToNextPage, timerId);
      console.log('log7', chrome.runtime, timerId, isExtensionInstalled);
    }, 3000);
    console.log('log8', chrome.runtime, timerId, isExtensionInstalled);
  }
};

export const installExtensionHandler = (e, goToNextPage, timerId) => {
  extensionDetection(e, goToNextPage, timerId);
  goToNextPage();
  window.open(
    `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID}`,
    '_blank'
  );
};

export const openExtension = (e) => {
  e.preventDefault();
  // this.onClickNextPage(e);
  window.open(
    `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID}`,
    '_blank'
  );
  googleAnalyticsTriggerFn(
    'Button',
    'Click here to relaunch chrome store',
    'Click here to relaunch chrome store Button clicked on signUp'
  );
};
