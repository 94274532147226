import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import createCategoryCls from './CreateCategory.module.scss';
const CreateCategory = ({ text, createHandle, classToOverride }) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center cursor-pointer',
        classToOverride
      )}
      onClick={() => createHandle()}
    >
      <IconButton aria-label="menu">
        <AddIcon className={classNames(createCategoryCls.addIcon)} />
      </IconButton>
      <div className={classNames(createCategoryCls.createCategoryLabel)}>
        {text || 'Create'}
      </div>
    </div>
  );
};
export default CreateCategory;
