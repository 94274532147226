import {
  SET_PLAN_DATA,
  SET_CUSTOMIZED_PLAN_DATA,
  SET_DISPLAY_ADDING_PLAN_FORM,
} from '../../Types/LearningPlans';

const LearningPlanData = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PLAN_DATA:
      return [...payload];
    default:
      return state;
  }
};

const CustomizedLearningPlanData = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOMIZED_PLAN_DATA:
      return [...payload];
    default:
      return state;
  }
};

const DisplayAddingPlanForm = (state = {}, action) => {
  const { type, payload } = action;
  const initial_state = { ...state };
  switch (type) {
    case SET_DISPLAY_ADDING_PLAN_FORM:
      return { ...initial_state, ...payload };
    default:
      return state;
  }
};

export { LearningPlanData, CustomizedLearningPlanData, DisplayAddingPlanForm };
