import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Resources } from '../../../../config/Resources/index';
import PanalHeaderStyling from '../networks.module.scss';
import SearchIcon from '@mui/icons-material/Search';
const PanalHeader = ({heading, isSearch, isSelect, setSearch, ...props}) => {
  return (
    <div className={classNames('p-4 d-flex ')}>
      <div className={classNames('d-flex align-items-center')}>
        <img src={Resources.admin.TitleIcon} alt="" />
        <div className={classNames('ms-2 fw-bolder')}>{heading}</div>
      </div>

      <div className={classNames('d-flex ms-auto ')}>
        {
          isSearch === true &&
          <TextField
          id="filled-hidden-label-normal"
          variant="outlined"
          placeholder="Search"
          onChange={(e)=>{setSearch((e.target.value).toLowerCase().trim())}}
          InputProps={{
            classes: {
              root: PanalHeaderStyling.SearchRoot,
            },
            endAdornment:(
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classNames(PanalHeaderStyling.SearchInput,'p-3')}
        />
        }
        
        {
        isSelect === true &&
        <Select
          defaultValue="none"
          className={classNames(PanalHeaderStyling.SelectInput, 'ms-3')}
        >
        
        {
          props.options.map((item)=>{
            return(
              <MenuItem key={item.id} value="none">{item.option}</MenuItem>
            )
          })
        }
          
        </Select>
        }
      </div>
    </div>
  );
};

export default PanalHeader;
