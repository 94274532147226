import React from 'react';
import { UploadCSVImage } from '../../../Shared/Icons';
import classNames from 'classnames';
import { LinearProgress } from '@mui/material';
import Text from '../../../Shared/Text';
import c from '../../allocation.module.scss';
import ModalHeaderText from '../ModalHeaderText';
import { Button } from '@mui/material';

const CSVUploadProgress = ({setModalStatus, ...props}) => {
  return (
    <React.Fragment>
      <ModalHeaderText
        heading="Uploading CSV File"
        text="How would you like to integrate your employee records?"
      />

      <div className={classNames('text-center mt-5')}>
        <UploadCSVImage />
        <Text
          textType="t0"
          classToOverride={classNames(c.primaryColor, 'mt-2')}
        >
          Uploading CSV File
        </Text>
        <center>
          <div className="col-md-6 mt-4">
            <LinearProgress variant="determinate" value={80} />
            <div
              className={classNames(
                c.primaryColor,
                'd-flex justify-content-between mt-1'
              )}
            >
              <Text textType="t0">0%</Text>
              <Text textType="t0">100%</Text>
            </div>
          </div>
        </center>
      </div>
      <div className={classNames(c.ButtonContainer)}>
        <Button variant="outlined" onClick={()=>setModalStatus(false)}>Cancel</Button>
        <Button variant="contained" className="ms-2 text-capitalize fw-bold">
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};
export default CSVUploadProgress;
