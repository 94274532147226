import ModuleStyling from '../../../../MyAccount/Modals/modal.Module.scss';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import Headings from '../../../../Shared/Headings';
import CountryAndState from './CountryAndState';

const Form = ({ FormFields = [], formik = {} }) => {
  return (
    <div>
      {FormFields.map((items, index) => {
        return (
          <div key={index}>
            <Headings
              headingType="h6"
              classToOverride={ModuleStyling.accountText}
            >
              {items.Label}
            </Headings>

            <div className="d-flex justify-content-center">
              <FormControl className={classNames('w-50 mt-1')}>
                {items.type === 'select' ? (
                  items?.id === 'state' || items?.id === 'country' ? (
                    <CountryAndState formik={formik} items={items} />
                  ) : (
                    <Select
                      name={items.id}
                      IconComponent={KeyboardArrowDown}
                      onChange={formik?.handleChange}
                      value={formik?.values[items?.id]}
                      id={items?.id}
                      // displayEmpty
                      inputProps={{
                        className: ` ${ModuleStyling.modalFields} `,
                      }}
                      className={`${ModuleStyling.modalFieldsContainer}`}
                    >
                      {items.MenuItems.map((Menudata, index) => {
                        return (
                          <MenuItem key={index} value={Menudata.value}>
                            {Menudata.Label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )
                ) : (
                  <TextField
                    name={items.id}
                    type={items.type}
                    placeholder="Enter Here"
                    onChange={formik?.handleChange}
                    value={formik?.values[items?.id]}
                    helperText={formik?.errors[items?.id] || ''}
                    error={formik?.errors[items?.id] ? true : false}
                    inputProps={{
                      className: ` ${ModuleStyling.modalFields} `,
                    }}
                    id={items.id}
                    className={`${ModuleStyling.modalFieldsContainer}`}
                  />
                )}
              </FormControl>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Form;
