import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import Allocation from '../../components/Allocation';
import Headings from '../../components/Shared/Headings';
import c from './allocation.module.scss';
import AddIcon from '@mui/icons-material/Add';
import AllocationModal from '../../components/Allocation/AllocationModal';
import { googleAnalyticsTriggerFn } from '../../utils/utility';

const AllocationPage = ({ ...props }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const groupId = (urlParams?.size > 0 && urlParams.get('group_id')) || '';
  const corpId = (urlParams?.size > 0 && urlParams.get('corp_id')) || '';

  return (
    <div className="bg-white p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Headings
          headingType={'h3'}
          classToOverride={classNames(c.primaryColor, 'fw-bold')}
        >
          {groupId && corpId ? 'Member Details' : 'Allocation'}
        </Headings>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          className="text-capitalize"
          onClick={() => {
            setModalStatus(true);
            googleAnalyticsTriggerFn(
              'Button',
              'Invite Members',
              'Invite members button clicked on allocation page'
            );
          }}
        >
          Invite Members
        </Button>
      </div>
      <Allocation />
      {modalStatus && (
        <AllocationModal
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      )}
    </div>
  );
};
export default AllocationPage;
