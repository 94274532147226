/**
 * @function Employees
 * @param TableData {Array}
 */

import React, { useEffect, useState } from 'react';
import CustomTable from '../../Shared/Table';
import {
  Employeecolumns,
  filters,
  getAllCorpEmployees,
} from '../../../helpers/ManagerDashboard/TableData';
import EmployeeTable from './EmployeeTable';
import EmployeeStyling from './EmployeeStyling.module.scss';
import Text from '../../Shared/Text';
import classNames from 'classnames';
import CustomSelect from '../../Layout/Select';
import { FilterAlt } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const Employees = ({ corpId }) => {
  const [filter, setFilter] = useState('all');
  const [pages, setPages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [pageNo, setPageNo] = useState(0);
  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);
  const platform_id = useSelector(
    (state) => state?.generalInformation?.platform_id
  );

  const [employeeTableData, setEmployeeTableData] = useState();
  const [nameSort, setNameSort] = useState(false);
  const [departmentSort, setDepartmentSort] = useState(false);
  const [topicLengthSort, setTopicLengthSort] = useState(false);
  const [durationSort, setDurationSort] = useState(false);
  const [topicSort, setTopicSort] = useState(false);
  const [sort, setSort] = useState(false);
  const [nudgesminSort, setNudgesminSort] = useState(false);
  const [strugglingemotionsSort, setStrugglingemotionsSort] = useState(false);
  const [sortColumn, setSortColumn] = useState('duration');

  useEffect(() => {}, [pages]);

  function compareValues(key, order) {
    return function innerSort(a, b) {
      // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      //   console.log("Tahir6", b.hasOwnProperty(key), a.hasOwnProperty(key))
      //   // property doesn't exist on either object
      //   return 0;
      // }

      const varA = !a.hasOwnProperty(key)
        ? '0'
        : typeof a[key] === 'string'
        ? a[key].toUpperCase()
        : a[key];
      const varB = !b.hasOwnProperty(key)
        ? '0'
        : typeof b[key] === 'string'
        ? b[key].toUpperCase()
        : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === true ? comparison * -1 : comparison;
    };
  }

  const handleSort = (column_id) => {
    if (column_id === 'employee') {
      setSort(!sort);
      setSortColumn('full_name');
      setPages(pages.sort(compareValues('full_name', !nameSort)));
      setNameSort(!nameSort);
    }
    if (column_id === 'department') {
      setSort(!sort);
      setSortColumn('department');
      setPages(
        pages.sort(compareValues('user_info.department', !departmentSort))
      );
      setDepartmentSort(!departmentSort);
    }
    if (column_id === 'topicsStudied') {
      setSort(!sort);
      setSortColumn('topic');
      setPages(pages.sort(compareValues('topics', !topicLengthSort)));
      setTopicLengthSort(!topicLengthSort);
    }

    if (column_id === 'timeStudied') {
      setSort(!sort);
      setSortColumn('duration');
      setPages(pages.sort(compareValues('duration', !durationSort)));
      setDurationSort(!durationSort);
    }

    if (column_id === 'last3topics') {
      setSort(!sort);
      setSortColumn('topics');
      setPages(pages.sort(compareValues('topics', !topicSort)));
      setTopicSort(!topicSort);
    }
    if (column_id === 'nudges_per_x_min') {
      setSort(!sort);
      setSortColumn('nudges_per_x_min');
      setPages(pages.sort(compareValues('nudges_per_x_min', !nudgesminSort)));
      setNudgesminSort(!nudgesminSort);
    }
    if (column_id === 'struggling_emotions') {
      setSort(!sort);
      setSortColumn('struggling_emotions');
      setPages(
        pages.sort(
          compareValues('struggling_emotions', !strugglingemotionsSort)
        )
      );
      setStrugglingemotionsSort(!strugglingemotionsSort);
    }
  };

  useEffect(() => {
    const pageArr = data?.slice(
      rowsPerPage * pageNo,
      rowsPerPage * (pageNo + 1)
    );
    setPages([...pageArr].sort(compareValues(sortColumn, true)));
  }, [rowsPerPage, pageNo, data]);

  useEffect(() => {
    if (corpId) {
      getEmployees(corpId);
    }
  }, [corpId, filter, platform_id]);

  // const getEmployees = async (corp_id) => {
  //   setloader(true);
  //   const res = await getAllCorpEmployees(corp_id, platform_id, filter);
  //   res?.map((item,ind) => {
  //     res[ind]["nudges_per_x_min"] = item?.nudge_yes_count ? Math.round((item.nudge_yes_count/Math.ceil(item.duration/30))) : 0;
  //     res[ind]["struggling_emotions"] = item?.struggling_emotion_count ? Math.round(((item.struggling_emotion_count/item.total_emotion_count)*100)): 0;
  //   })
  //   setData([...res]);
  //   setloader(false);
  // };

  const getEmployees = async (corp_id) => {
    setloader(true);
    const res = await getAllCorpEmployees(corp_id, platform_id, filter);

    // Filter out duplicate entries based on the 'user_id' property
    const uniqueRes = res.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.user_id === item.user_id)
    );


    uniqueRes.map((item, ind) => {
      uniqueRes[ind]['nudges_per_x_min'] = item?.nudge_yes_count
        ? Math.round(item.nudge_yes_count / Math.ceil(item.duration / 30))
        : 0;
      uniqueRes[ind]['struggling_emotions'] = item?.struggling_emotion_count
        ? Math.round(
            (item.struggling_emotion_count / item.total_emotion_count) * 100
          )
        : 0;
    });

    setData([...uniqueRes]);
    setloader(false);
  };

  return (
    <div className={EmployeeStyling.EmployeeDataContainer}>
      <div
        className={classNames(
          'pb-4 d-flex justify-content-between align-items-center',
          EmployeeStyling.HeadingContainer
        )}
      >
        <div>
          <Text
            textType="t1"
            classToOverride={classNames('mb-3', EmployeeStyling.PanalHeading)}
          >
            Learners
          </Text>

          {/* <Text
            textType="t3"
            classToOverride={classNames(EmployeeStyling.PanalSubHeading)}
          >
            Another line here for description
          </Text> */}
        </div>
        <div className="col-3">
          {!loader ? (
            <CustomSelect
              label="Filter"
              dropdownIcon={FilterAlt}
              value={filter}
              onChange={(e) => {
                setFilter(e?.target?.value);
                googleAnalyticsTriggerFn(
                  'Filter',
                  'Filter',
                  'Filter changed in Learners'
                );
              }}
              menuItems={filters}
            />
          ) : (
            <Skeleton className="w-100" height={67} />
          )}
        </div>
      </div>
      <CustomTable
        columns={Employeecolumns}
        showSortIcon={true}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNo={pageNo}
        setTablePageNo={setPageNo}
        onClick={handleSort}
      >
        {pages?.map((data, ind) => {
          return (
            <EmployeeTable
              key={data.user_id || ind} 
              full_name={data?.full_name}
              active={data?.active}
              department={data?.user_info?.department}
              topics={data?.topics_studied}
              topic={data?.topics}
              duration={data?.duration}
              nudges_per_x_min={data.nudges_per_x_min}
              struggling_emotions={
                data?.struggling_emotions
                  ? data?.struggling_emotions + '%'
                  : 'NA'
              }
              user_client_id={data?.user_info?.user_client_id}
            />
          );
        })}
      </CustomTable>
    </div>
  );
};

export default Employees;
