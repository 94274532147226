import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import Routes from './config/Routes/index';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header';
import ReactGA from 'react-ga4';


const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
ReactGA.initialize(MEASUREMENT_ID);

const queryClient = new QueryClient();
// const helmetContext = {};
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <Routes />
        </React.StrictMode>
      </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
