/**
 * @function LearningPlansModalBox
 * Renders modal box to add learning plan
 */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchCategories,
  createLearningPlan,
  validate,
  textInputsData,
  radioInputsData,
} from '../../../helpers/LearningPlans/LearningPlansModalBox';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import ModalBox from '../../Shared/ModalBox';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import Headings from '../../Shared/Headings';
import Timeline from '../Timeline';
import Text from '../../Shared/Text';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const LearningPlansModalBox = ({ openModal, setModal }) => {
  const [capabilities, setCapabilities] = useState([]);
  const [radioBtnActive, setRadioBtnActive] = useState(false);
  const [isPlanPublic, setIsPlanPublic] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => getPlanCapabilities(), []);

  const getPlanCapabilities = async () => {
    const response = await fetchCategories();
    setCapabilities(response?.data);
  };

  const onContinue = async (values) => {
    setModal(false);
    dispatch(showLoader());
    const addedPlanData = await createLearningPlan(values, isPlanPublic);
    if (addedPlanData?.data?.id) {
      history.push({
        pathname: '/learning-plans',
        search: `?plan_id=${addedPlanData?.data?.id}`,
      });
      googleAnalyticsTriggerFn(
        'Button',
        'Continue',
        'New plan added successfully'
      );
    }
    googleAnalyticsTriggerFn(
      'Button',
      'Continue',
      'Add new plan unsuccessfull'
    );
    dispatch(hideLoader());
  };

  const {
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    values,
  } = useFormik({
    initialValues: {
      planName: null,
      planDescription: null,
      planCapability: null,
    },
    validate,
    onSubmit: (values) => onContinue(values),
  });

  return (
    <ModalBox openModal={openModal} classToOverride={c.planDetailsModalBox}>
      <form
        onSubmit={handleSubmit}
        className={classNames('mt-2', c.planDetailsForm)}
      >
        <div className={classNames('row g-0', c.header)}>
          <div className="col-6 d-flex justify-content-start">
            <Headings headingType="h3" classToOverride={c.fontWeight600}>
              Plan Details
            </Headings>
          </div>

          <div className="col-6 d-flex justify-content-end align-items-center">
            <Timeline
              items={7}
              finishItems={1}
              activeItemClass={c.modalTimelineActiveItem}
              inertItemClass={c.modalTimelineInertItem}
            />
          </div>

          <Text
            textType="t0"
            classToOverride={classNames('mb-2', c.fontWeight500)}
          >
            Please start by determining the name, description, capability, and
            publication status of your plan.
          </Text>
        </div>
        <div className={classNames('mx-auto', c.inputGroup)}>
          {textInputsData.map((item) => (
            <div key={item?.id} className="mt-3">
              <Headings
                headingType="h6"
                classToOverride={classNames('mb-1', c.fontWeight500)}
              >
                {item?.inputLabel}
              </Headings>

              <TextField
                fullWidth
                id={item?.id}
                label={item?.textFieldLabel}
                variant="outlined"
                size="small"
                margin="dense"
                onChange={handleChange}
                error={errors[item?.id] ? true : false}
                helperText={errors[item?.id]}
              />
            </div>
          ))}

          <Headings
            headingType="h6"
            classToOverride={classNames('mt-3 mb-1', c.fontWeight500)}
          >
            3. What Capability does this plan teach?
          </Headings>

          <Autocomplete
            className="mb-1"
            disablePortal
            popupIcon={<KeyboardArrowDownIcon />}
            options={capabilities}
            getOptionLabel={(option) => option?.name}
            loading={!capabilities.length}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                margin="dense"
                label="Select capability"
                placeholder="Select capability"
                error={errors.planCapability ? true : false}
                helperText={errors.planCapability}
              />
            )}
            onChange={(_, value) =>
              setFieldValue('planCapability', value?.id ?? '')
            }
          />

          <Headings
            headingType="h6"
            classToOverride={classNames('mt-3 mb-1', c.fontWeight500)}
          >
            4. Would you like this plan to be public or private?
          </Headings>

          <FormControl component="fieldset">
            <RadioGroup
              className="mt-2"
              aria-label="radio-buttons-group"
              name="radio-buttons-group"
              onChange={() => setRadioBtnActive(true)}
            >
              {radioInputsData.map((item) => (
                <FormControlLabel
                  key={item?.id}
                  value={item?.id}
                  control={<Radio />}
                  label={item?.label}
                  onChange={() =>
                    setIsPlanPublic(item?.id === 'publicOption' ? true : false)
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="py-4 d-flex justify-content-end">
          <Button
            className="text-capitalize"
            variant="outlined"
            onClick={() => {
              resetForm();
              setModal(false);
              googleAnalyticsTriggerFn(
                'Button',
                'Cancel',
                'Cancel button clicked on Add New Plan modal'
              );
            }}
          >
            Cancel
          </Button>

          <Button
            className={classNames('text-capitalize', c.continueBtn)}
            variant="contained"
            type="submit"
            disabled={
              !(values.planName && values.planCapability && radioBtnActive)
            }
          >
            Continue
          </Button>
        </div>
      </form>
    </ModalBox>
  );
};

export default LearningPlansModalBox;
