import {
  BARDATA,
  IS_EXTENSION_ACTIVE,
  IS_SESSION_DETAILS_OPEN,
  IS_TOPIC_MODEL,
  SESSIONDATA,
  SESSIONREPORT,
  IS_EXTENSION_BTN_ENABLED,
} from '../../Types/ExtensionHandler';

export const IsExtensionActive = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_EXTENSION_ACTIVE:
      return payload;

    default:
      return state;
  }
};

export const IsExtensionBtnEnabled = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_EXTENSION_BTN_ENABLED:
      return payload;

    default:
      return state;
  }
};

export const SessionReport = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SESSIONREPORT:
      return payload;

    default:
      return state;
  }
};

export const BarData = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case BARDATA:
      return payload;
    default:
      return state;
  }
};

export const SessionData = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SESSIONDATA:
      return payload;
    default:
      return state;
  }
};

export const IsTopicModal = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_TOPIC_MODEL:
      return payload;
    default:
      return state;
  }
};

export const isSessionDetailsOpen = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_SESSION_DETAILS_OPEN:
      return payload;
    default:
      return state;
  }
};
