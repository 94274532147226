import { useEffect, useState } from 'react';
import { fetchStates } from '../../../../../../../helpers/My Account';
import CustomSelect from '../CustomSelect';

const State = ({ formik, items }) => {
  const [state, setState] = useState([]);
  useEffect(() => {
    if (formik?.values?.country === 'select') {
      setState([]);
    } else {
      getState();
    }
    if (!formik?.values?.state) {
      formik.setFieldValue('state', 'select');
    }

  }, [formik?.values?.country]);

  const getState = async () => {
    const res = await fetchStates(formik?.values?.country);
    setState(res);
  };

  return (
    <CustomSelect
      formik={formik}
      items={items}
      data={state}
      text="Select State"
    />
  );
};
export default State;
