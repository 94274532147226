import React from 'react';
import Text from "../../../Shared/Text";
import classNames from 'classnames';
import LearningBehaviorCss from '../LearningBehavior.module.scss';

const Bar = ({height, color, percentage, text, ...props}) => {
    return(
        <React.Fragment>
            <div style={{height:height, background: color}} className={classNames(LearningBehaviorCss.BrowsingBar, 'text-center', "d-flex justify-content-center align-items-center")}>
                <Text textType="t1" text={percentage}/>
            </div>
        </React.Fragment>
    )
}
export default Bar;