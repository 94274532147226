import React from 'react';
import classNames from 'classnames';
import c from '../landing.module.scss';
import { BackToTop } from '../../Shared/Icons';
import Text from '../../Shared/Text';
import { Resources } from '../../../config/Resources';

const VideoSection = ({ SetPlayerVideo, ...props }) => {
  return (
    <div className={classNames('col-lg-12 col-md-12 position-relative')}>
      <img
        src={Resources.images.LandingPageVideoImage}
        className={classNames(c.videoPlayImage)}
        alt="cover"
      />
      <div
        className={classNames(c.videoPlayAction)}
        onClick={() =>
          SetPlayerVideo(
            true,
            'https://ahura.sharemeister.com/static/media/Ahura%20features.0b37acb9.mp4'
          )
        }
      >
        <span></span>
      </div>

      <div
        className={classNames(c.BackToTop, 'text-center', c.cursorPointer)}
        onClick={() =>
          document.getElementById('landingMainContainer').scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        <BackToTop />
        <Text textType="t1" classToOverride={classNames('text-white')}>
          Back to Top
        </Text>
      </div>
    </div>
  );
};
export default VideoSection;
