/**
 * @function Calibrations
 * Component of Profile section
 *
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import c from '../Profile.Module.scss';
import Text from '../../Shared/Text/index';
import Divider from '@mui/material/Divider';
import { FacialExpressions } from './FacialExpressions/index';
import { ActivityComponent } from './ActivityComponent/index';
import { UploadComponent } from './UploadComponent/index';
import EditAndSaveBtn from '../FormEditAndSubmit';
import { Button } from '@mui/material';
import { CalibrationData } from '../../../MockData/Profile/FaceCalibrationData';
import DottedUploadInput from '../../Shared/DottedUploadInput';
const Calibrations = () => {
  const [editable, setEditable] = useState(false);
  const handleSubmit = () => {
    console.log('Saved');
  };
  return (
    <>
      <EditAndSaveBtn
        editable={editable}
        setEditable={setEditable}
        handleSubmit={handleSubmit}
        page="Calibratoins"
      />
      <div
        className={classNames(
          c.LearningPreferencesContainer,
          'p-3 pb-5 mb-2 border-6'
        )}
      >
        <div className="ps-3 pt-3">
          <Text textType="t1" classToOverride="fw-bold">
            Calibrations
          </Text>
          {/* <Text textType="t2">Another line here for description</Text> */}
        </div>
        <div className="m-2 p-2 mb-5">
          <div className="row">
            <div className="col-6">
              <div className="pb-2">
                <Text textType="t1">{'Your Photo'}</Text>
              </div>
              <Divider />
              <div className="row mt-3">
                <UploadComponent
                  title={'Edit Photo'}
                  text={'Add Image'}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="pb-2">
                {' '}
                <Text textType="t1">{'Your Video'}</Text>
              </div>
              <Divider />
              <div className="row mt-3">
                <UploadComponent
                  title={'Edit Video'}
                  text={'Add Video'}
                  accept="video/*"
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="d-flex justify-content-between align-items-center pb-2">
              <Text textType="t1">{'Facial Expressions Analysis'}</Text>
              <Button variant="contained">Recalibrate Camera</Button>
            </div>
            <Divider />
            <div className={classNames('d-flex mt-3')}>
              {CalibrationData &&
                CalibrationData.map((data, index) => {
                  return (
                    <React.Fragment key={data.id}>
                      <FacialExpressions index={index} FaceData={data} />
                    </React.Fragment>
                  );
                })}
              <span>
                <div className={classNames(c.CalibrationInput)}>
                  <DottedUploadInput
                    accept="video/*"
                    type="file"
                    text={'Add video'}
                  />
                </div>
                <div className={classNames(c.RecalibrateButtonWrapper)}>
                  <Button
                    disabled
                    className={classNames(c.RecalibrateButton, 'mt-4')}
                    variant="contained"
                  >
                    Recalibrate
                  </Button>
                </div>
              </span>
            </div>

            <div className="mt-5">
              {' '}
              <Text textType="t1">{'Speed of Learning'}</Text>
            </div>
            <ActivityComponent
              activity="Activity #1"
              description={'Description'}
            />
            <ActivityComponent
              activity="Activity #2"
              description={'Description'}
            />
            <ActivityComponent
              activity="Activity #3"
              description={'Description'}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Calibrations;
