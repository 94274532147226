import React, { useEffect, useState } from 'react';
import UpdatePassword from './UpdatePassword';
import Text from '../../../Shared/Text';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import { PersonalAccountDetailsForm } from '../../../../helpers/Profile/GeneralInfo';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import { fetchCountry } from '../../../../helpers/My Account';

const AccountDetails = ({ isEditable, formik }) => {
  const [requirePwdUpdate, setRequirePwdUpdate] = useState(false);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const data = await fetchCountry();
    setCountryData(data);
  };

  return (
    <>
      {requirePwdUpdate && (
        <UpdatePassword
          setRequirePwdUpdate={setRequirePwdUpdate}
          setFieldValue={formik?.setFieldValue}
        />
      )}
      <Text textType="t1" classToOverride={classNames(c.fontWeight600, 'mt-5')}>
        Account Details
      </Text>
      {/* <Text textType="t2" classToOverride={classNames(c.fade)}>
        Another line here for description
      </Text> */}
      <div className="row pe-5">
        {PersonalAccountDetailsForm.map((field) =>
        field?.id === 'location' ? (
            <div className="col-6 mt-4" key={field.id}>
              <FormControl variant="standard" className="w-100">
                <InputLabel id="select-label">{field?.label}</InputLabel>
                <Select
                  fullWidth
                  id={field?.id}
                  name={field?.id}
                  value={formik?.values[field?.id]}
                  displayEmpty
                  onChange={formik?.handleChange}
                  inputProps={{
                    readOnly: isEditable,
                    className: isEditable ? classNames('bg-transparent',c.profileInputFieldReadOnly) : classNames('bg-transparent',c.profileInputField),
                  }}
                >
                  {[
                    ...(field?.id === 'location' ? countryData : []),
                  ]?.map((country) => (
                    <MenuItem key={country?.id} value={country?.name}>
                      {country?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
          <div className="col-6 mt-4" key={field?.id}>
            <TextField
              id={field?.id}
              name={field?.id}
              variant="standard"
              fullWidth
              label={field?.label}
              type={field?.type}
              value={
                field?.id === 'password'
                  ? 'Password'
                  : formik?.values[field?.id]
              }
              onChange={
                field?.id === 'employer' ? () => {} : formik?.handleChange
              }
              error={formik?.errors[field?.id] ? true : false}
              helperText={formik?.errors[field?.id]}
              InputProps={
                field?.id === 'password'
                  ? {
                      readOnly: isEditable,
                      endAdornment: field?.id === 'password' && (
                        <Button
                          variant="text"
                          onClick={() => setRequirePwdUpdate(true)}
                          disabled={isEditable}
                        >
                          <Text
                            textType="t2"
                            classToOverride={classNames(
                              'text-nowrap text-decoration-underline text-capitalize',
                              c.fontWeight600
                            )}
                          >
                            Update Password
                          </Text>
                        </Button>
                      ),
                    }
                  : {
                      readOnly: isEditable,
                    }
              }
              inputProps={{
                className: classNames(c.profileInputField),
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default AccountDetails;
