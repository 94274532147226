import classNames from 'classnames';
import { leftNavBar } from '../../helpers/navBar';
import { NavLink } from 'react-router-dom';
import navBarCls from './navbar.module.scss';
import { useLocation } from 'react-router-dom';
const NavBar = () => {
  const location = useLocation();
  return (
    <div className={classNames(navBarCls.navbarContainer, 'bg-light')}>
      <div className={classNames(navBarCls.navbarWrapper)}>
        {leftNavBar.map(({ id, title, Icon, route }) => {
          return (
            <NavLink
              key={id}
              to={route}
              title={title}
              className={(navData) =>
                classNames(
                  navData && location.pathname === route
                    ? 'bg-secondary text-light'
                    : 'bg-white text-dark',
                  'd-flex  w-100 flex-col align-items-center justify-content-center my-2 p-2 cursor-pointer'
                )
              }
            >
              <Icon />
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default NavBar;
