import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import classNames from 'classnames';
import { Resources } from '../../../../config/Resources';
import Headings from '../../../Shared/Headings';
import EmotionProgress from '../EmotionProgress';

const LearningBehaviorLeftPanel = ({ emotionData }) => {
  const { profile_image_url } = useSelector(
    (state) => state?.generalInformation
  );
  
  const [emotion, setEmotion] = useState({});
    useEffect(() => {
      arrayToObject();
    }, [emotionData]);
  const arrayToObject = () => {
    const obj = {};
    emotionData?.forEach((item) => {
      obj[item['expression_name']] = item;
    });
    setEmotion(obj);
  };

  const getCircleColor = (color) => {
    switch (color) {
      case 'angry':
        return '#F053B1';
      case 'disgusted':
        return '#FB3549';
      case 'happy':
        return '#F6E655';
      case 'neutral':
        return '#225DF4';
      case 'sad':
        return '#225DF4';
      case 'surprised':
        return '#A45BFF';
      default:
        return '#225DF4';
    }
  };

  return (
    <React.Fragment>
      <div
        className={classNames(
          LearningBehaviorCss.lBehaviorLeftContainer,
          'col-md-6'
        )}
      >
        <div className="px-3 py-2">
          <Headings
            headingType="h6"
            classToOverride={classNames(
              LearningBehaviorCss.textColor,
              LearningBehaviorCss.font_bold_600
            )}
          >
            Emotion
          </Headings>

          <div className="m-0 mt-3">
            <div className={classNames('row justify-content-around')}>
              {/* {emotionData &&
                emotionData?.length > 0 &&
                emotionData.map((item) => (
                  <EmotionProgress
                    img={profile_image_url}
                    text={item?.expression_name}
                    circleColor={getCircleColor(item?.expression_name)}
                    value={item?.value}
                    emotionDataLength={emotionData?.length}
                  />
                ))} */}
              {
                <>
                  <EmotionProgress
                    img={profile_image_url}
                    text={'Neutral'}
                    circleColor="#225DF4"
                    value={emotion?.neutral?.value || 0}
                    // emotionDataLength={emotionData?.length}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text={'Joy'}
                    circleColor="#F6E655"
                    value={emotion?.happy?.value || 0}
                    // emotionDataLength={emotionData?.length}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text="Surprised"
                    circleColor="#A45BFF"
                    value={emotion?.surprised?.value || 0}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text="Anger"
                    circleColor="#F053B1"
                    value={emotion?.angry?.value || 0}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text="Disgust"
                    circleColor="#FB3549"
                    value={emotion?.disgusted?.value || 0}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text="Fear"
                    circleColor="#FB8C26"
                    value={emotion?.fearful?.value || 0}
                  />
                  <EmotionProgress
                    img={profile_image_url}
                    text="Sadness"
                    circleColor="#225DF4"
                    value={emotion?.sad?.value || 0}
                  />{' '}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LearningBehaviorLeftPanel;
