import { ClickAwayListener, Popper } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import c from '../../Header/Notification/notification.Module.scss';
import NotificationContainer from './NotificationContainer';

const Notification = ({
  requestModalStatus,
  setRequestModalStatus,
  isNotification,
  notificationData,
  handleNotification,
  ComponentRef,
  handleClick,
  additionalDetailsData,
  setAdditionalDetailsData,
  setIsNotification,
}) => {
  return (
    <>
      <Popper
        className={classNames(c.popperClass)}
        open={isNotification}
        anchorEl={ComponentRef}
      >
        <ClickAwayListener onClickAway={() => handleNotification(false)}>
          <div className={classNames(c.clickAway)}>
            <div className={classNames(c.notification)}>
              <div className={classNames(c.notificationText)}>
                Notifications
              </div>
            </div>
            <div className="mt-1">
              <NotificationContainer
                requestModalStatus={requestModalStatus}
                setRequestModalStatus={setRequestModalStatus}
                notificationData={notificationData}
                handleClick={handleClick}
                additionalDetailsData={additionalDetailsData}
                setAdditionalDetailsData={setAdditionalDetailsData}
                setIsNotification={setIsNotification}
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Notification;
