import React, { useState } from 'react';
import ModuleStyling from '../../Modals/modal.Module.scss';

import Box from '@mui/material/Box';

import classNames from 'classnames';
import Button from '@mui/material/Button';
import CreateAccount from '../CreateAccount';
import CoorporateDetails from '../CoorporateDetails';
import UploadPicture from '../UploadPicture';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OtpVerification from '../OtpVerification';

const CustomBox = ({ setOpen }) => {
  const [indexState, setIndexState] = React.useState(0);
  const [corporateUserData, setCorporateUserData] = useState({});
  const handleContinue = () => {
    setIndexState(indexState + 1);
    if (indexState > 2) {
      setOpen(false);
    }
  };

  const handleBack = () => {
    setIndexState(indexState - 1);
  };

  return (
    <Box className={classNames('px-4 py-3', ModuleStyling.ModalContainer)}>
      <div>
        {indexState === 0 && (
          <CreateAccount handleContinue={handleContinue} setOpen={setOpen} />
        )}
        {indexState === 1 && (
          <CoorporateDetails
            handleContinue={handleContinue}
            setOpen={setOpen}
            setCorporateUserData={setCorporateUserData}
          />
        )}
        {indexState === 2 && (
          <UploadPicture
            handleContinue={handleContinue}
            setOpen={setOpen}
            corporateUserData={corporateUserData}
          />
        )}

        {indexState === 3 && (
          <OtpVerification
            handleContinue={handleContinue}
            setOpen={setOpen}
            corporateUserData={corporateUserData}
          />
        )}
      </div>

      {indexState > 0 && (
        <div
          className={classNames(ModuleStyling.backBtn)}
          onClick={() => handleBack()}
        >
          <ArrowBackIosIcon
            className={classNames(ModuleStyling.backBtnInside)}
          />
        </div>
      )}
    </Box>
  );
};

export default CustomBox;
