import React from 'react';
import { Button } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import classNames from 'classnames';
import c from '.././Profile.Module.scss';
import Text from '../../Shared/Text';
import BadgeDescription from './BadgeDescription';

const Badges = () => {
  return (
    <>
      <div className="d-flex justify-content-end my-4">
        <Button
          variant="contained"
          startIcon={<BorderColorIcon />}
          size="large"
        >
          Edit
        </Button>
      </div>
      <div className={classNames('h-100 w-100', c.badges)}>
        <div className="pt-4 pb-5 ps-4">
          <Text textType="t1" classToOverride={c.fontWeight600}>
            Badges
          </Text>
          {/* <Text textType="t2" classToOverride="fw-normal">
            Another line here for description
          </Text> */}
        </div>
        <BadgeDescription badgeClassName={c.hexagon1} />
        <BadgeDescription badgeClassName={c.hexagon2} />
        <BadgeDescription badgeClassName={c.hexagon3} />
      </div>
    </>
  );
};

export default Badges;
