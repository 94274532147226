import { getApiCall, postApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { CLIENT_ID, CAMPAIGN_TYPES_IDS } from '../../../config/Constants';
import store from '../../../redux/store';

export const validate = (values) => {
  let { planName, planDescription, planCapability } = values;
  let errors = {};
  if (planName === '') {
    errors.planName = 'Learning Plan Name is required';
  }
  if (planDescription === '') {
    errors.planDescription = 'Learning Plan Description is required';
  }
  if (planCapability === '') {
    errors.planCapability = 'Learning Plan Capability is required';
  }
  return errors;
};

export const textInputsData = [
  {
    id: 'planName',
    inputLabel: '1. Please give your learning plan a name.',
    textFieldLabel: 'Enter lesson Plan here',
  },
  {
    id: 'planDescription',
    inputLabel: '2. Please describe your learning plan.',
    textFieldLabel: 'Enter lesson Description here',
  },
];

export const radioInputsData = [
  {
    id: 'privateOption',
    label: 'Private (only people within your company can see it)',
  },
  {
    id: 'publicOption',
    label: 'Public (everyone in the Ahura community can see it)',
  },
];

//functions

//function to fetch capabilities
export const fetchCategories = async () => {
  try {
    const paramsData = {
      queryParams: {
        client_id: CLIENT_ID,
        campaign_type_id: CAMPAIGN_TYPES_IDS?.courses,
        is_active: true,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URLS?.categories, paramsData);
    return response;
  } catch (error) {
    console.log(`Error in fetching Categories ${error}`);
  }
};

//function to create a Learning Plan
export const createLearningPlan = async (values, isPlanPublic) => {
  try {
    const { id } = store.getState().generalInformation;
    const reqBody = {
      created_by: id,
      category_id: values?.planCapability,
      corporate_id: CLIENT_ID,
      title: values?.planName,
      description: values?.planDescription,
      is_public: isPlanPublic,
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await postApiCall(
      API_URLS?.learning_plans,
      reqBody,
      paramsData
    );
    return response;
  } catch (error) {
    console.log(`Error in creating Learning Plan ${error}`);
  }
};
