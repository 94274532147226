/**
 * @function Resources
 * @params {none}
 * @exports {string} Object
 * @returns url of images png and svg present in image/common/folder
 */
const commonBase = '/images/common';

export const Resources = {
  admin : {
    Network : `${commonBase}/icons/admin/Network.svg`,
    Tutorial : `${commonBase}/icons/admin/Tutorial.svg`,
    Documentation:`${commonBase}/icons/admin/Documentation.svg`,
    Settings : `${commonBase}/icons/admin/Settings.svg`,
    TitleIcon : `${commonBase}/icons/admin/TitleIcon.svg`,
    Campaign : `${commonBase}/icons/admin/Campaign.svg`,
  },
};
