/**
 * @function AverageLearningVolume
 * Component of Manager Dashboard Overview section
 */

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Headings from '../../Shared/Headings';
import style from '../managerDashboard.module.scss';
import CustomSelect from '../../Layout/Select/index';
import QuickStatsDataAnalytics from '../../Home/QuickStats/QuickStatsDataAnalytics/index';
import c from '../../Home/home.Module.scss';
import {
  ContentTypeData,
  getCorporateHighlightsData,
  HighlightsChartOptions,
  weeklyLineChartOptions,
  capabilitiesBarChartOptions,
  hoursBarChartOptions,
} from '../../../helpers/ManagerDashboard/AverageVolData/index';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import {
  getPercentage,
  getMinutesAndHrsString,
  googleAnalyticsTriggerFn,
} from '../../../utils/utility';
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);
const AverageLearningVolume = ({ corpId }) => {
  const [contentType, setContentType] = useState('');
  const [avgWeeklyHrs, setAvgWeeklyHrs] = useState({});
  const [capabilities, setCapabilities] = useState({});
  const [hrsStudies, setHrsStudied] = useState({});
  const [chartData, setChartData] = useState([]);

  const ChartData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    datasets: [
      // {
      //   data: [...chartData.map((item) => item?.avg_weekly_hrs)],
      //   ...weeklyLineChartOptions,
      // },
      {
        data: [...chartData.map((item) => item?.capabilities)],
        ...capabilitiesBarChartOptions,
      },
      {
        data: [
          ...chartData.map((item) => Math.round(item?.hours_studied / 60)),
        ],
        ...hoursBarChartOptions,
      },
    ],
  };

  useEffect(() => {
    if (corpId) {
      getCorpHighlights(corpId, contentType);
    }
  }, [corpId, contentType]);

  //  const user_id = useSelector((state)=> state?.generalInformation?.user_id);

  const getCorpHighlights = async (corpId, contentType) => {
    const res = await getCorporateHighlightsData(corpId, contentType);
    // console.log('response in getCorp::::::', res);
    if (res?.avg_weekly_hrs) {
      setAvgWeeklyHrs(res.avg_weekly_hrs);
    }
    if (res?.capabilities) {
      setCapabilities(res.capabilities);
    }
    if (res?.hours_studied) {
      setHrsStudied(res.hours_studied);
    }
    if (res?.weekly_data) {
      setChartData(res.weekly_data);
    }
  };
  return (
    <div
      className={classNames(
        style.AverageLearningVolumeContainer,
        'p-3 ms-2 pt-1'
      )}
    >
      <div className="row mt-3 mb-5">
        <div className="col-md-7">
          <Headings headingType="h6" classToOverride="fw-bold p-2">
            {/* Average Learning Volume*/}
            Highlights this month
          </Headings>
        </div>
        <div className="col-md-5 pr-3">
          <CustomSelect
            label="Content Type"
            value={contentType}
            menuItems={ContentTypeData}
            onChange={(e) => {
              setContentType(e.target.value);
              googleAnalyticsTriggerFn(
                'Filter',
                'Content type Filter',
                'Content type Filter changed'
              );
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div
          className={classNames(
            style.averageLearningVolumeChart,
            'mt-5 px-5 w-100'
          )}
        >
          <Chart data={ChartData} options={HighlightsChartOptions} />
        </div>
      </div>
      <div
        className={classNames(
          'row justify-content-between p-2 mt-5 mb-5',
          c.quickStatsLearningDetails,
          style.averageLearningVolumeDataAnalytics
        )}
      >
        {/* -------------------Hours Studied-------------------- */}
        <QuickStatsDataAnalytics
          heading={'Hours Studied'}
          time={getMinutesAndHrsString(hrsStudies?.cur || 0)}
          status={getPercentage(hrsStudies?.prev, hrsStudies?.cur)?.toString()}
          // text={'text'}
        />

        {/* ---------------------Weekly Avg. Hours------------------- */}
        <QuickStatsDataAnalytics
          heading={'Weekly Avg. Hours'}
          time={getMinutesAndHrsString(avgWeeklyHrs?.cur || 0)}
          status={getPercentage(
            avgWeeklyHrs?.prev,
            avgWeeklyHrs?.cur
          )?.toString()}
          // text={'text'}
        />

        {/* ------------------------Capabilities Studied----------------- */}
        <QuickStatsDataAnalytics
          heading={'Capabilities Studied'}
          time={capabilities?.cur || 0}
          status={getPercentage(
            capabilities?.prev,
            capabilities?.cur
          )?.toString()}
          // text={'text'}
        />
      </div>
    </div>
  );
};

export default AverageLearningVolume;
