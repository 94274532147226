import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Headings from '../../components/Shared/Headings';
import WelcomeSection from '../../components/Shared/WelcomeSection';
import c from './sessionDetails.module.scss';
import Focus from '../../components/SessionDetails/Focus';
import LearningBehavior from '../../components/Home/LearningBehavior';
import VisitedURLs from '../../components/SessionDetails/VisitedURLs';
import LearningJourney from '../../components/SessionDetails/LearningJourney';
import { SessionDetailsURL } from '../../config/API_URLS/api_urls';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SET_TOAST_ERROR } from '../../redux/Actions/Common';
import Nudges from '../../components/Shared/Nudges';
import { useSelector } from 'react-redux';

const SessionDetailsPage = () => {
  const params = useParams();
  const [sessionData, setSessionData] = useState({});
  const [sessionDataAgumentation, setSessionDataAgumentation] = useState({});
  const [emotionData, setEmotionData] = useState([]);
  const [browsingData, setBrowsingData] = useState({});
  const [visitedPages, setVisitedPages] = useState([]);
  const [nudgesData, setNudgesData] = useState({});
  const dispatch = useDispatch();

  // console.log("Fetch Session Data")
  const sessionsData = useSelector((state) => state.allSessionData.sessionsData);

  // console.log("All Session Data", sessionsData)
  useEffect(() => {
    getSessionDetails();
    agumentSessionData();
  }, []);

  useEffect(() => {
    if (sessionData?.visited_pages) {
      const filtered_pages = sessionData?.visited_pages?.filter(
        (page) =>
          page?.is_relevant_algo === 0 && page?.is_transitional_algo === 0
      );
      // console.log('filtered Pages::::::', filtered_pages);
      setVisitedPages(filtered_pages);
    }
  }, [sessionData]);

  const getSessionDetails = async () => {
    try {
      const res = await axios.get(SessionDetailsURL(params.id));
      setSessionData({ ...res.data });
      console.log("Inform", { ...res.data })
      setNudgesData(res?.data?.session_nudges);
      // if (res && res?.data?.data?.sessions) {
      //   setSessionsTableData(res?.data?.data?.sessions);
      // }
    } catch (error) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something went wrong while fetching session details',
        })
      );
    }
  };

const agumentSessionData = async () => {
    try {
      // console.log("Agument",  params.id)
      // console.log("Agument Session",  sessionsData)
      const session = sessionsData.find(obj => obj.session_id === params.id);
      // console.log("Agumented", session)    
      setSessionDataAgumentation(session)
    } catch (error) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something went wrong while fetching session details',
        })
      );
    }
  };


  
  useEffect(() => {
    if (sessionData.browsing) {
      setBrowsingData({ ...sessionData.browsing, visited_pages: visitedPages });
    }
    if (sessionData.expression_data) {
      setEmotionData([...sessionData.expression_data]);
    }

  }, [sessionData, visitedPages, ]);


  return (
    <>
      {/* <WelcomeSection />   */}
      <Headings
        headingType="h3"
        classToOverride={classNames('fw-bold', c.primaryColor)}
      >
        Session Details
      </Headings>

      <LearningJourney session_details={sessionData?.session_details} topic={visitedPages[0]?.capability ? visitedPages[0].capability : ''}  />

      <Focus focus_data={sessionData?.focus_data} session_data_agumentation={sessionDataAgumentation} />
      <div className="mt-5">
        <LearningBehavior
          emotion_data={emotionData}
          browsing_data={browsingData}
        />
      </div>
      <div className="mt-5">
        <Nudges nudgesData={nudgesData} />
      </div>
      <div className="mt-5">
        <VisitedURLs visited_pages={sessionData?.visited_pages} />
      </div>
    </>
  );
};

export default SessionDetailsPage;
