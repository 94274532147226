/**
 * @function RightPanelPage11
 * @param {number} goToNextPage
 * @returns It will show the eight page of register page.
 * This page was earlier in Access Granted screen but changed to Jumpstart
 */

import React from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import classNames from 'classnames';
import { Button } from '@mui/material';
import SignupStyling from '../../signUp.Module.scss';
import { Resources } from '../../../../config/Resources';
import { useHistory } from 'react-router-dom';
import { videoTutorialsLink } from '../../../../config/Constants';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const RightPanelPage11 = (props) => {
  const history = useHistory();

  const handleClick = (event, url) => {
    event.preventDefault();
    window.open(url, '_blank');
  };
  return (
    <RightPanelLayout
      heading="Jumpstart your Ahura experience"
      desc=""
      goToNextPage={props.goToNextPage}
      showContinueBtn={false}
      showBackBtn={false}
    >
      <div>
        <div className={classNames(SignupStyling.rightpanelMainbox)}>
          <div className={classNames(SignupStyling.rightpanelSubbox)}>
            <img src={Resources.images.setGoals} alt="logo" />
            <Button
              variant="contained"
              className={classNames('fw-bold', SignupStyling.accessBtn)}
              onClick={() => {
                history.push(`/profile`);
                googleAnalyticsTriggerFn(
                  'Button',
                  'Set learning goals',
                  'Set learning goals button on sign up page clicked'
                );
              }}
            >
              Set learning goals
            </Button>
          </div>
          <div className={classNames(SignupStyling.rightpanelSubbox)}>
            <img src={Resources.images.watchTutorials} alt="logo" />
            <Button
              variant="contained"
              className={classNames('fw-bold', SignupStyling.accessBtn)}
              onClick={(event) => {
                handleClick(event, videoTutorialsLink);
                googleAnalyticsTriggerFn(
                  'Button',
                  'Watch tutorials',
                  'Watch tutorials button on sign up page clicked'
                );
              }}
            >
              Watch tutorials
            </Button>
          </div>
          <div className={classNames(SignupStyling.rightpanelSubbox)}>
            <img src={Resources.images.growthMindset} alt="logo" />
            <Button
              variant="contained"
              className={classNames('fw-bold', SignupStyling.accessBtn)}
              onClick={(event) => {
                handleClick(event, 'https://ahuraai.com/quiz-mindset');
                googleAnalyticsTriggerFn(
                  'Button',
                  'Take growth mindset challenge',
                  'Take growth mindset challenge button on sign up page clicked'
                );
              }}
            >
              Take growth mindset challenge
            </Button>
          </div>
          <div className={classNames(SignupStyling.rightpanelSubbox)}>
            <img src={Resources.images.procrastinationChallenge} alt="logo" />
            <Button
              variant="contained"
              className={classNames('fw-bold', SignupStyling.accessBtn)}
              onClick={(event) => {
                handleClick(event, 'https://ahuraai.com/quiz-procrastination');
                googleAnalyticsTriggerFn(
                  'Button',
                  'Take procrastination',
                  'Take procrastination button on sign up page clicked'
                );
              }}
            >
              Take procrastination challenge
            </Button>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classNames(
            'w-100 fw-bold py-2 mb-2',
            SignupStyling.signUpBtns,
            SignupStyling.buttonBackground,
            SignupStyling.backgroundColorBtn
          )}
          onClick={() => {
            props.goToNextPage();
            googleAnalyticsTriggerFn(
              'Button',
              'Open dashboard',
              'Open dashboard button on sign up page clicked'
            );
          }}
        >
          Open dashboard
        </Button>
      </div>
    </RightPanelLayout>
  );
};
export default RightPanelPage11;
