import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  UpdatePasswordValidationSchema,
  UpdatePasswordForm,
  updateUserPassword,
} from '../../../../../helpers/Profile/GeneralInfo';
import ModalBox from '../../../../Shared/ModalBox';
import Headings from '../../../../Shared/Headings';
import { TextField, IconButton, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import classNames from 'classnames';
import c from '../../../Profile.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../../../redux/Actions/Loader';

const UpdatePassword = ({ setRequirePwdUpdate, setFieldValue }) => {
  const userData = useSelector((state) => state?.generalInformation);
  const dispatch = useDispatch();
  const [showPwdInfo, setShowPwdInfo] = useState({
    existingPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const formik = useFormik({
    initialValues: {
      existingPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values, actions) => {
      onFormSubmit(values, actions);
    },
    
    validationSchema: UpdatePasswordValidationSchema,
  });

  const onFormSubmit = async (values, actions) => {
    dispatch(showLoader());
    const response = await updateUserPassword(
      userData?.id,
      existingPassword,
      newPassword
    );

    if(response?.message)
      setRequirePwdUpdate(false);
    else{
      actions.setFieldValue('existingPassword', "");
      actions.setFieldValue('newPassword', "");
      actions.setFieldValue('confirmPassword', "");
    }
    dispatch(hideLoader());
  };

  const { existingPassword, newPassword, confirmPassword } = formik?.values;

  const handleFormCancel = () => {
    formik?.resetForm();
    setRequirePwdUpdate(false);
  };

  return (
    <ModalBox openModal={true} classToOverride="w-50">
      <form className="text-center" onSubmit={formik.handleSubmit}>
        <Headings headingType="h3" classToOverride="pt-4 fw-bold">
          Update Password
        </Headings>
        {UpdatePasswordForm.map((field) => (
          <div className="pt-4" key={field?.id}>
            <TextField
              id={field?.id}
              name={field?.id}
              variant="standard"
              className="w-75"
              label={field?.label}
              type={showPwdInfo[field?.id] ? 'text' : field?.type}
              value={formik?.values[field?.id]}
              disabled={
                field?.id === 'confirmPassword' && !formik?.values?.newPassword
              }
              onChange={formik?.handleChange}
              error={formik?.errors[field?.id] ? true : false}
              helperText={formik?.errors[field?.id]}
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={
                      field?.id === 'confirmPassword' &&
                      !formik?.values?.newPassword
                    }
                    onClick={() =>
                      setShowPwdInfo({
                        ...showPwdInfo,
                        [field?.id]: !showPwdInfo[field?.id],
                      })
                    }
                  >
                    {showPwdInfo[field?.id] ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
              inputProps={{
                className: classNames(c.profileInputField),
              }}
            />
          </div>
        ))}
        <div className="pt-5 pb-4">
          <Button
            className="text-capitalize me-4"
            variant="contained"
            type="submit"
            disabled={!(existingPassword && newPassword && confirmPassword)}
          >
            Update
          </Button>
          <Button
            className="text-capitalize"
            variant="outlined"
            onClick={handleFormCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ModalBox>
  );
};

export default UpdatePassword;
