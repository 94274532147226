import React from 'react';
import CSVSuccess from '../CSVSuccess';
import CSVUploadFailed from '../CSVUploadFailed';
import CSVUploadProgress from '../CSVUploadProgress';

const CSVFileUpload = ({status, setModalStatus, ...props}) => {
    switch(status){
        case "progress":
            return(<CSVUploadProgress setModalStatus={setModalStatus}/>)
        case "success":
            return(<CSVSuccess/>)
        case "failed":
            return(<CSVUploadFailed/>)
        default:
            return(<></>)
    }
}
export default CSVFileUpload;