import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Headings from '../../Headings';
import classNames from 'classnames';
import c from '../../shared.Module.scss';
import Text from '../../Text';
import Button from '@mui/material/Button';
import { useState } from 'react';
import SessionDetailsChart from '../SessionDetailsChart';
import { Resources } from '../../../../config/Resources';
import SessionGaugeChart from '../SessionGuageChart';
import { useEffect } from 'react';
import TopWebsites from '../TopWebsites';
import TopDistractions from '../TopDistractions';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import LanguageIcon from '@mui/icons-material/Language';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import StudyTips from '../StudyTips';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { remapLabels } from '../../../../utils/utility';

const SessionSummary = ({
  stats,
  setModal,
  sessionInfo,
  sessionNudges,
  sessionData,
  isShowMore,
  setIsShowMore,
}) => {
  const [nudgeCount, setNudgeCount] = useState(0);
  const [nudgeLabel, setNudgeLabel] = useState([{}]);
  const [modifiedNudgeData, setModifiedNudgeData] = useState({});
  const [newNudgeLabels, setNewNudgeLabels] = useState([]);
  useEffect(() => {
    if (sessionNudges && Object.keys(sessionNudges).length > 0)
      Object.keys(sessionNudges).map((value) => {
        setNudgeCount((prevValue) => prevValue + sessionNudges[value]);
      });
  }, [sessionNudges]);

  const time = sessionInfo?.duration?.split(':');

  useEffect(() => {
    NudgeFunction(sessionData?.nudge_timeseries || {});
  }, [sessionData]);

  const NudgeFunction = (data) => {
    let arr = [];
    const emotion = {
      auto_stop: <StopCircleIcon className={c.emotionIconSize} />,
      looking_away: <SentimentDissatisfiedIcon className={c.emotionIconSize} />,
      off_chrome: <WebAssetOffIcon className={c.emotionIconSize} />,
      off_screen: <StopScreenShareIcon className={c.emotionIconSize} />,
      social_media: <LanguageIcon className={c.emotionIconSize} />,
      web_surfing: <RssFeedIcon className={c.emotionIconSize} />,
      idle: <AccessTimeIcon className={c.emotionIconSize} />,
    };
    const allNudgesData = {
      auto_stop: [],
      looking_away: [],
      off_chrome: [],
      off_screen: [],
      social_media: [],
      web_surfing: [],
      idle: [],
    };

    let nudgeCategory = Object.keys(data)?.length > 0 ? Object.keys(data) : [];
    Object.keys(allNudgesData).forEach(function (key) {
      if (nudgeCategory.includes(key)) {
        delete allNudgesData[key];
      }
    });

    const newData = { ...data, ...allNudgesData };
    for (const key in newData) {
      if (newData.hasOwnProperty(key) && Array.isArray(newData[key])) {
        arr.push({
          label: remapLabels(key), // Apply remapping here
          count: newData[key]?.length || 0,
          icon: emotion[key],
        });
      }
    }
    setModifiedNudgeData({ ...data, ...allNudgesData });
    setNewNudgeLabels(arr);
  };
  return (
    <>
      <div className="col-xl-6 position-relative ps-4 pe-3 bg-white ">
        <div>
          <div className={classNames('d-inline-flex', c.boxMargin)}>
            <div
              className={classNames(
                'd-inline-flex align-items-center rounded-3',
                c.secondBox
              )}
            >
              <img src={Resources.images.TrophyIcon} alt="trophy" />
              <Text
                textType="t22"
                classToOverride="fw-semibold text-white ps-2"
              >
                You added{' '}
                <span>
                  {time[0] > 0 && `${time[0]}h`} {time[1] > 0 && `${time[1]}m`}{' '}
                  {time[2] > 0 && `${time[2]}s`}{' '}
                </span>{' '}
                to your knowledge of <span>{sessionInfo?.description}</span>
              </Text>
            </div>
          </div>

          {/* <div>
      <div>
        Your preferences: Morning, Long session, Single vs Multi-tasker,
        Many breaks,...
      </div>
    </div> */}

          <div className="p-3 shadow-sm my-4 rounded-3 ">
            <div className="fw-bold">
              Number of Nudges:{' '}
              <span className="fw-semibold">{nudgeCount}</span>
            </div>
          </div>
          <div
            className={classNames(
              'd-flex align-items-center justify-content-center flex-shrink-0 ',
              isShowMore && 'd-none',
              c.showButton
            )}
          >
            <Button
              variant="outlined"
              size="large"
              className={classNames(
                'w-100 border-0 text-capitalize',
                c.buttonText
              )}
              onClick={() => setIsShowMore(!isShowMore)}
            >
              Show More
            </Button>
          </div>
          {isShowMore && (
            <SessionDetailsChart
              nudgeTimeseries={modifiedNudgeData}
              sessionInfo={sessionInfo}
              labelsToShow={newNudgeLabels}
              time={time}
            />
            // <div>

            //   THESE LABELS {JSON.stringify(newNudgeLabels)}
            // </div>
          )}

          {isShowMore && <StudyTips labelsToShow={newNudgeLabels} />}
          <div
            className={classNames(
              'd-flex align-items-center justify-content-center flex-shrink-0 my-4',
              !isShowMore && 'd-none',
              c.showButton
            )}
          >
            <Button
              variant="outlined"
              size="large"
              className={classNames(
                'w-100 border-0 text-capitalize',
                c.buttonText
              )}
              onClick={() => setIsShowMore(!isShowMore)}
            >
              Show Less
            </Button>
          </div>
        </div>
      </div>
      <div className="col-xl-3 position-relative bg-white rounded-end">
        <Box className="mt-2 text-end">
          <IconButton onClick={() => setModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={classNames('rounded-3 m-2 p-3', c.firstBox3)}>
          <div>
            <Headings headingType="h6" classToOverride="fw-semibold">
              Focused Browsing
            </Headings>
          </div>
          <div className="pt-2">
            <SessionGaugeChart visitedPages={sessionData?.visited_pages} />
          </div>
          <div
            className={classNames(
              'd-flex align-items-center justify-content-center flex-wrap flex-shrink-0 gap-3',
              c.gaugeData
            )}
          >
            <div className="d-flex justify-content-center align-items-center ">
              <img src={Resources.images.GaugeColorIcon} alt="Gauge color" />
              <Headings className={classNames(c.gaugeData)}>
                Keep higher than 60%
              </Headings>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              <img src={Resources.images.GaugeColorIcon} alt="Gauge color" />
              <Headings classToOverride={c.gaugeData}>Lorem Lipsum</Headings>
            </div> */}
          </div>
        </div>

        {/* Top Websites */}
        {isShowMore && (
          <>
            <TopWebsites
              visitedPages={sessionData?.visited_pages}
              stats={stats}
              sessionInfo={sessionInfo}
            />
            <TopDistractions
              visitedPages={sessionData?.visited_pages}
              stats={stats}
              sessionInfo={sessionInfo}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SessionSummary;
