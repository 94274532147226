/**All the left panel pages are listed here
 * @function Interest
 * @param {} intrestIcon
 * @param {boolean} IsSelected
 * @param { string} interestText
 * @param {number} interestId
 * @param {function} handleSelected
 * @returns the interest page.
 */

import React from 'react';
import classNames from 'classnames';
import signupStyling from '../signUp.Module.scss';
import Text from '../../Shared/Text';
const Interest = ({
  intrestIcon,
  IsSelected,
  intrestText,
  intrestId,
  handleSelected,
}) => {
  return (
    <div className="d-flex">
      <div
        onClick={() => handleSelected(intrestText)}
        className={classNames(
          signupStyling.Icons,
          IsSelected && signupStyling.InterestbtnActive,
          signupStyling.Interestbtn,
          'text-center'
        )}
      >
        <img src={intrestIcon} alt="logo" className="text-center pt-2" />
        <Text textType="t2">{intrestText}</Text>
      </div>
    </div>
  );
};

export default Interest;
