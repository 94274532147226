/**
 * @function CoorporateDetails
 * @param {function} isOpen ,handleClose , handleCompletion
 * @returns It will contains the Coorporate Details modal.
 */
import React, { useState } from 'react';
import ModuleStyling from '../modal.Module.scss';
import classNames from 'classnames';
import Radio from '@mui/material/Radio';
import Text from '../../../Shared/Text';
import { CorporateDetailsFormFields } from '../../../../helpers/My Account/FormFieldsData';
import RoundedCircle from '../Box/RoundedCircle';
import Form from '../Box/Form';
import Headings from '../../../Shared/Headings';
import { Link } from 'react-router-dom';
import {
  createCorporateAccount,
  initialValuesForCorporateDetails,
  validateSchemaForCorpDetails,
} from '../../../../helpers/My Account';
import { useFormik } from 'formik';
import BackAndContinue from '../Box/BackAndContinue';
import { useDispatch, useSelector } from 'react-redux';

const CoorporateDetails = ({ handleContinue, setOpen, setCorporateUserData }) => {
  const user_id = useSelector((state)=> state?.generalInformation?.user_id)
  const formik = useFormik({
    initialValues: initialValuesForCorporateDetails,
    validationSchema: validateSchemaForCorpDetails,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });


  const onFormSubmit = async (values) => {
    const response = await createCorporateAccount(user_id, values)
    if(response?.id){
      setCorporateUserData(response);
      handleContinue();
    }
    else
      console.log(response?.error)
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <Headings headingType="h4" classToOverride="row g-0 fw-bold ">
          Coorporate Account
        </Headings>
        <div>
          <RoundedCircle numberOfItems={4} indexOfColoredItem={1} />
        </div>
      </div>

      <form>
        <Text
          classToOverride={ModuleStyling.para}
          text="What type of account would you like to purchase ?"
        />
        <div className={classNames('mainClass', ModuleStyling.FormContainer)}>
          <Form FormFields={CorporateDetailsFormFields} formik={formik} />
          <div className={classNames(ModuleStyling.radioDiv, 'd-flex mt-3')}>
            <Radio checked={true} />

            <Text classToOverride="mt-2 align-items-center">
              Agree to{' '}
              <Link
                className={classNames(ModuleStyling.link)}
                to={'/terms-and-conditions'}
                target={'_blank'}
              >
                Terms and Service
              </Link>
              <span> and </span>
              <Link
                className={classNames(ModuleStyling.link)}
                to={'/privacy-policy'}
                target={'_blank'}
              >
                Privacy Policy
              </Link>
            </Text>
          </div>
        </div>
        <BackAndContinue
          handleContinue={formik.handleSubmit}
          setOpen={setOpen}
        />
      </form>
    </>
  );
};

export default CoorporateDetails;
