export const IS_EXTENSION_ACTIVE = 'IS_EXTENSION_ACTIVE';
export const IS_TOPIC_MODEL = 'IS_TOPIC_MODEL';
export const EMOTIONAL_DATA = 'EMOTIONAL_DATA';
export const TOP_DISTRACTION = 'TOP_DISTRACTION';
export const REPORT_DATA = 'REPORT_DATA';
export const DISTRACTION = 'DISTRACTION';
export const BARDATA = 'BARDATA';
export const SESSIONDATA = 'SESSIONDATA';
export const SESSIONREPORT = 'SESSIONREPORT';
export const IS_SESSION_DETAILS_OPEN = 'is_session_details_open';
export const IS_EXTENSION_BTN_ENABLED = 'IS_EXTENSION_BTN_ENABLED';
