import * as yup from 'yup';
import { API_URLS } from '../../config/API_URLS/api_urls';
import { getApiCall, postApiCall } from '../../utils/axios/axios';
import { dataURLtoFile } from '../../utils/utility';
import { AMAZON_S3_URL_REGEX } from '../../config/Regex/regex';
import { generateSignedUrl, uploadFile } from '../Profile/GeneralInfo';

export const myGroupColumnHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'mentors', label: 'Mentors' },
  { id: 'size', label: 'Size' },
  { id: 'date_created', label: 'Date Created' },
  { id: 'view_details', label: 'View Dashboard' },
  { id: 'action', label: 'Action' },
];

export const findGroupColumnHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'request_to_join', label: 'Request to Join' },
  { id: 'mentors', label: 'Mentors' },
  { id: 'size', label: 'Size' },
];

export const mentorData = (mentors) => {
  if (mentors && mentors?.length > 0) {
    const mentorNames = mentors.map((mentor) => mentor.name);
    return mentorNames.join(', ');
  }
  return '-';
};

export const generateInitialValues = () => {
  let values = {};
  values.groupName = '';
  values.description = '';
  values.members_data = [];
  values.groupImageURL = '';

  return values;
};

export const groupStatus = ['PENDING', 'APPROVED', 'REJECTED', 'UNKNOWN'];

export const createGroupValidationSchema = yup.object().shape({
  groupName: yup.string().required('Group Name is required'),
});

export const getGroupFormInfo = async (formData) => {
  const { groupImageURL } = formData;
  let userInfo = formData;
  if (groupImageURL) {
    if (!AMAZON_S3_URL_REGEX.test(groupImageURL)) {
      const newFile = dataURLtoFile(groupImageURL);
      const response = await generateSignedUrl();
      const signedImgUrl = response?.imageSignedUrl;
      const result = await uploadFile(signedImgUrl, newFile);
      if (result) {
        const url = signedImgUrl.substr(
          0,
          signedImgUrl.indexOf('?X-Amz-Algorithm=')
        );
        userInfo = { ...userInfo, groupImageURL: url };
      }
    }
  } else {
    userInfo = { ...userInfo, groupImageURL: '' };
  }
  return userInfo;
};

export const fetchMyGroupsTable = async (params) => {
  try {
    const queryParams = {
      ...params,
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };

    const res = await getApiCall(API_URLS.joinedGroups, paramsData);
    return res;
  } catch (err) {
    console.log('Error in fetchManageGroupsTable | Helpers :', err);
  }
};

export const fetchAllGroupsTable = async (params) => {
  try {
    const queryParams = {
      corp_id: params?.corp_id,
      user_id: params?.user_id,
      search: params?.search || '',
      limit: params?.limit,
      page_no: params?.page_no,
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };

    const res = await getApiCall(API_URLS.allGroups, paramsData);
    return res;
  } catch (err) {
    console.log('Error in fetchManageGroupsTable | Helpers :', err);
  }
};

export const createGroup = async (values) => {
  try {
    let reqBody = {
      ...values,
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await postApiCall(API_URLS?.neighborhood_sign_up, reqBody, paramsData);
  } catch (error) {
    console.log(`Error in creating group ${error}`);
  }
};

export const requestToJoinGroup = async (res) => {
  try {
    const reqBody = {
      ...res,
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const response = await postApiCall(
      API_URLS?.addUserToNeighborhood,
      reqBody,
      paramsData
    );

    return response;
  } catch (err) {
    console.log('Error in join group request Data ', err);
  }
};
