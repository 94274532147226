import classNames from 'classnames';
import React from 'react';
import Text from '../../../../../Shared/Text';
import c from '../../../quiz.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const Options = (props) => {
//   console.log("i",String.fromCharCode(props?.optionNumber + 65));
  return (
    <div>
      <Text textType="t2" classToOverride="mt-2">
        {String.fromCharCode(props?.optionNumber + 65)}
        {': '}
        {props.value}
        {/* <br /> */}
        {props.selectedAnswerId === props.optionId && props.isCorrect === 1 ? (
          <div className={classNames(c.correct, 'ms-3')}>
            Correct Answer
            <DoneIcon fontSize="small" />
          </div>
        ) : (
          ''
        )}
        {props.selectedAnswerId === props.optionId && props.isCorrect !== 1 ? (
          <div className={classNames(c.warning, 'ms-3')}>
            Inorrect Answer
            <CloseIcon fontSize="small" />
          </div>
        ) : (
          ''
        )}
      </Text>
    </div>
  );
};
export default Options;
