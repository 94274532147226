import { ACCOUNTS_DATA } from '../../Types/Accounts';

export const AccountsData = (state = {}, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ACCOUNTS_DATA:
      return { ...payload };
    default:
      return state;
  }
};
