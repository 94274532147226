export const CLIENT_ID = "cb19c961-fc50-4613-87d9-40488220a1d9";

export const ROLE_IDS = {
  USER: "604698b9-997f-4d4b-a7e7-524cff62a4b9",
  CLIENT_OWNER: "9bed9123-06a7-4b1f-bf1f-4ed9e779fd79",
  CLIENT_ADMIN: "8aa1f764-8d6f-47c0-a049-d1239267dc74",
  PARENT_OWNER: "de16a4f7-dcc9-45ab-bbe1-1c9ba2cc38da",
  PARENT_ADMIN: "082df2b2-b048-4b99-a019-9870b339753b",    
  SUPER_OWNER: "fd6dd9c1-96dd-4489-bfe3-ed823d5e5446",
}
