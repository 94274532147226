import React, { useEffect, useState } from 'react';
import c from '../../shared.Module.scss';
import { API_URLS, getNudgesData } from '../../../../config/API_URLS/api_urls';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SET_TOAST_ERROR } from '../../../../redux/Reducers/Common';

const StudyTips = ({ labelsToShow }) => {
  const dispatch = useDispatch();
  const [studyTips, setStudyTips] = useState({});
  const [sortedLabels, setSortedLabels] = useState([]);

  const getStudyTips = async () => {
    try {
      await axios
        .get(process.env.REACT_APP_BASE_EXT_API_URL + API_URLS.nudge_tips)
        .then((res) => {
          setStudyTips(res.data);
        });
    } catch (error) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something went wrong while fetching session details',
        })
      );
    }
  };

  useEffect(() => {
    getStudyTips();
  }, []);

  useEffect(() => {
    setSortedLabels(
      labelsToShow
        .sort((a, b) => b.count - a.count)
        .filter((label) => label.count !== 0)
        .slice(0, 2)
    );
  }, [labelsToShow]);

  return (
    <>
      {sortedLabels && sortedLabels?.length > 0 && (
        <>
          <div className="p-3 shadow-sm my-4 rounded-3 ">
            <div className="fw-bold mb-3">Study Tips</div>
            {sortedLabels?.map((value) => {
              return studyTips[value.label]?.map((tip, index) => {
                return (
                  <div className={c.tipsContent} key={index}>
                    {'“'}
                    {tip}
                    {'”'}
                  </div>
                );
              });
            })}
            <div className="mt-3 d-flex justify-content-between">
              <div>
                <div className="fw-bold">Our AI continues to learn</div>
                <div className={c.tipsContent}>
                  Email to inform us on any incorrect information you spot above
                </div>
              </div>
              <div className="fw-bold">
                Contact:<span className={c.urlText}>hi@ahuraai.com</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StudyTips;
