import React from 'react';
import classNames from 'classnames';
import c from '../../allocation.module.scss';
import Text from '../../../Shared/Text';
import { UploadIcon } from '../../../Shared/Icons';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeaderText from '../ModalHeaderText';
import { Button } from '@mui/material';

const CSVUploadFailed = ({ ...props }) => {
  return (
    <React.Fragment>
      <ModalHeaderText
        heading="Uploading CSV File"
        text="How would you like to integrate your employee records?"
      />
      <div className="text-center mt-5">
        <div className={classNames(c.UploadIconContainer, 'mx-auto')}>
          <UploadIcon />
          <div className={classNames(c.UploadCloseContainer)}>
            <div
              className={classNames(
                c.UploadCloseIcon,
                'd-flex align-items-center justify-content-center'
              )}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <Text
          textType="t0"
          classToOverride={classNames(c.UploadFailedText, 'mt-4')}
        >
          Failed to upload your file!
        </Text>
        <Text
          textType="t0"
          classToOverride={classNames(c.UploadFailedDescription, 'mt-4')}
        >
          Error occured when attempting to upload your file due to incorrect
          formatting. <br />
          Please try again.
        </Text>
      </div>
      <div className={classNames(c.ButtonContainer)}>
        <Button variant="outlined">Cancel</Button>
        <Button variant="contained" disabled={true} className="ms-2 text-capitalize fw-bold">
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};
export default CSVUploadFailed;
