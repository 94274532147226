import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import c from '../../sessionDetails.Module.scss';
import classNames from 'classnames';
import Text from '../../../Shared/Text';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const VisitedURLsTableRow = ({ ...props }) => {
  return (
    <TableRow>
      <TableCell className="text-center">
        <YouTubeIcon sx={{ color: '#A80051' }} />
      </TableCell>
      <TableCell
        className={classNames(
          c.primaryColor,
          c.cursorPointer,
          'text-decoration-underline'
        )}
      >
        <a
          href={props.url?.slice(1, -1)}
          onClick={() => {
            googleAnalyticsTriggerFn(
              'Hyperlink',
              'Title',
              'Link clicked under visited links on sessions page'
            );
          }}
          rel="noreferrer"
          target="_blank"
        >
          {props?.title}
        </a>
      </TableCell>
      {/* <TableCell>
        <Text textType="t1">{props?.topic}</Text>
      </TableCell> */}
      <TableCell>
        <Text textType="t1">
          {/* <span className={classNames(c.fontRed)}>03:20:45 PM</span> |{' '} */}
          <span className={classNames(c.fontRed)}>{props?.timeStamp}</span> |{' '}
          <span className={classNames(c.primaryColor)}>{props?.duration}</span>
        </Text>
      </TableCell>
      <TableCell>
        <Text textType="t1" classToOverride={classNames(c.fontRed)}>
          {props?.item && props?.item?.is_relevant_algo
            ? 'Relevant'
            : props?.item?.is_transitional_algo
            ? 'Not Calculated'
            : 'Distracted'}
        </Text>
      </TableCell>
      {/* <TableCell>
        <div className={classNames('position-relative')}>
          <select className={classNames(c.TableSelect, c.cursorPointer)}>
            <option value={'Action'} key={'Action'}>
              Action
            </option>
          </select>
          <div className={classNames(c.selectDownIcon)}>
            <KeyboardArrowDownIcon />
          </div>
        </div>
      </TableCell> */}
    </TableRow>
  );
};

export default VisitedURLsTableRow;
