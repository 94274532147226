/**
 * @function LeftPanelLayout
 * @param {string} smallHead
 * @param {string} head
 * @param {string} decs
 * @returns It contains the heading and description of all the left panel.
 */

import classNames from "classnames";
import React from "react";
import SignupStyling from "../signUp.Module.scss";
import Heading from "../../Shared/Headings";
import Text from "../../Shared/Text";


const LeftPanelLayout = (props) => {
  return (
    <>
      <Heading
        headingType="h4"
        classToOverride={classNames(SignupStyling.primaryColor)}
      >
        {props.smallHead}
      </Heading>
      <Heading
        headingType="h0"
        classToOverride={`${SignupStyling.LeftPanalHeading} fw-bold`}
      >
        {props.head}
      </Heading>
      <Heading
        headingType="h2"
        classToOverride={`${SignupStyling.LeftPanalHeading} fw-normal`}
      >
        {" "}
        {props.desc}{" "}
      </Heading>
      <Text
        Size="ps36"
        classToOverride={`${SignupStyling.LeftPanalDesc} fw-normal`}
      ></Text>
    
    </>
  );
};

export default LeftPanelLayout;
