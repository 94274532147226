/**
 * @function AddingPlanLeftModule
 * Renders the left panel of the plan
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Headings from '../../Shared/Headings';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Text from '../../Shared/Text';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CourseCard from '../../Shared/CourseCard';
import _ from 'lodash';
import { HTML_TAG_PATTERN } from '../../../config/Regex/regex';

const AddingPlanLeftModule = ({ planData }) => {
  const courses = useSelector((state) => state?.learningPlanData);
  const history = useHistory();
  return (
    <>
      <Headings
        headingType="h3"
        classToOverride={classNames(c.primaryColor, 'fw-bold')}
      >
        Learning Plans
      </Headings>
      <Button
        variant="text"
        size="large"
        startIcon={<ChevronLeftIcon />}
        className="my-2"
        onClick={() => history.push('/learning-plans')}
      >
        Back
      </Button>
      <Headings headingType="h30" classToOverride="fw-bold">
        Search Content Across all Ahura Topics
      </Headings>
      <div
        className={classNames(
          'w-100 my-4 bg-white rounded d-flex align-items-center',
          c.searchContent
        )}
      >
        <IconButton
          type="submit"
          aria-label="search"
          className={c.primaryColor}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          fullWidth
          placeholder="Search Content"
          inputProps={{ 'aria-label': 'Search' }}
          className="ps-1 pe-4"
        />
        <Divider
          orientation="vertical"
          className={classNames('ms-auto', c.primaryColor, c.divider)}
        />
        <KeyboardArrowDown className={classNames('mx-3', c.primaryColor)} />
      </div>
      {planData && !_.isEmpty(planData) && (
        <div className="fw-bold">
          <Headings headingType="h5" classToOverride={c.primaryColor}>
            {planData?.title}
          </Headings>
          <Text textType="t1" classToOverride={c.subHeading}>
            {planData?.description}
          </Text>
        </div>
      )}
      {courses && courses.length > 0 && (
        <Droppable droppableId={'dropOne'}>
          {(provided) => {
            return (
              <div
                className={classNames('d-grid col-12', c.leftPanelCourses)}
                {...provided?.droppableProps}
                ref={provided?.innerRef}
              >
                {courses.map((course, index) => (
                  <Draggable
                    key={course?.id}
                    draggableId={course?.id}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided) => {
                      return (
                        <div
                          className="mt-4 w-100"
                          key={course?.id}
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          {...provided?.dragHandleProps}
                        >
                          <CourseCard
                            id={course?.id}
                            courseCategory={course?.campaign_category}
                            heading={course?.heading}
                            desc={_?.replace(
                              course?.description,
                              HTML_TAG_PATTERN,
                              ''
                            )}
                            peers={0}
                            quizzes={course?.quiz_count}
                            sections={course?.section_count}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided?.placeholder}
              </div>
            );
          }}
        </Droppable>
      )}
    </>
  );
};

export default AddingPlanLeftModule;
