import FilterAltIcon from '@mui/icons-material/FilterAlt';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  columns,
  fetchAllocationTable,
} from '../../helpers/Allocation/AllocationTable';
import c from '../Allocation/allocation.module.scss';
import CustomTable from '../Shared/Table';
import Text from '../Shared/Text';
import AllocationTable from './AllocationTable';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { CLIENT_ID } from '../../config/Constants';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import { fetchMyGroupsTable, groupStatus } from '../../helpers/ManageGroups';
import { useHistory } from 'react-router-dom';

const Allocation = () => {
  const [data, setData] = useState({});
  const [search, setSearch] = useState('');
  const [tablePageNo, setTablePageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isManageGroup, setIsManageGroup] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const groupId = (urlParams?.size > 0 && urlParams.get('group_id')) || '';
  const corpId = (urlParams?.size > 0 && urlParams.get('corp_id')) || '';
  const groupName = (urlParams?.size > 0 && urlParams.get('group_name')) || '';
  const [membersRequestCount, setMembersRequestCount] = useState({});
  const accounts = useSelector((state) => state?.accounts) || {};
  const accountsNeighbourhoods =
    useSelector((state) => state?.accounts?.Neighbourhoods) || {};

  const client_id = useSelector(
    (state) => state?.generalInformation?.client_id
  );

  const [organisation, setOrganisation] = useState('');

  const history = useHistory();

  const user_id = useSelector((state) => state?.generalInformation?.user_id);

  const debounced = useDebouncedCallback(
    // function
    () => {
      getAllocationTableData();
    },
    // delay in ms
    400
  );

  useEffect(() => {
    if (client_id && groupId && corpId) {
      getMyGroupMentorsData();
    }
  }, [client_id]);

  useEffect(() => {
    if (data?.length > 0 && groupId && corpId) {
      const filteredMembers = data?.filter(
        (item) => item?.group_status === 'PENDING'
      );

      console.log('filteredMembers', filteredMembers?.length);
      console.log('selectedIds', selectedIds?.length);
      if (
        selectedIds?.length === filteredMembers?.length &&
        filteredMembers?.length !== 0
      ) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    }
  }, [selectedIds, data]);

  useEffect(() => {
    if (client_id && groupId && corpId) {
      if (isManageGroup) {
        getAllocationTableData();
      }
    } else {
      if (client_id) {
        getAllocationTableData();
      }
    }

    // eslint-disable-next-line
  }, [client_id, tablePageNo, rowsPerPage, isManageGroup]);

  const getMyGroupMentorsData = async () => {
    const params = {
      corp_id: corpId,
      user_id,
    };
    const response = await fetchMyGroupsTable(params);
    if (response) {
      const modifiedRes = response?.joinedGroups?.filter(
        (item) => item?.id === groupId
      );
      const mentors =
        modifiedRes?.length > 0 ? modifiedRes[0]?.mentors || [] : [];
      const mentorWithUserId =
        mentors?.length > 0
          ? mentors?.find((mentor) => mentor.user_id === user_id)
          : '';

      if (mentorWithUserId) {
        setIsManageGroup(true);
      } else {
        // history.push('/manage-groups');
      }
    }
  };

  const getAllocationTableData = async () => {
    const params = {
      corporate_id: groupId && corpId ? groupId : client_id,
      platform_id: CLIENT_ID, //Ahura Client ID
      limit: rowsPerPage,
      search: search,
      page_no: tablePageNo + 1,
      is_group: groupId && corpId ? true : false,
    };
    const response = await fetchAllocationTable(params);
    if (response) {
      let data = response.data;

    // Filter out duplicate entries based on the 'user_id' property
    const uniqueData = data.filter((item, index, self) =>
      index === self.findIndex((t) => t.user_id === item.user_id)
    );

      if (groupId && corpId) {
        const sortedData =uniqueData.sort((a, b) => {
          const statusOrder = {
            PENDING: 1,
            APPROVED: 2,
            UNKNOWN: 3,
          };
          return statusOrder[a.group_status] - statusOrder[b.group_status];
        });
        setData(sortedData);
        setMembersRequestCount(response?.data?.members_count[0]);
      } else {
         setData(uniqueData);
      }
    }
  };

  useEffect(() => {
    if (client_id && accountsNeighbourhoods?.length > 0) {
      const organizationName = accountsNeighbourhoods?.find(
        (obj) => obj.client_id === corpId
      )?.community_name;
      setOrganisation(organizationName);
    }
  }, [client_id, accountsNeighbourhoods]);

  const handleSearch = (value) => {
    setSearch(value);
    debounced();
    googleAnalyticsTriggerFn('Search', 'Search', 'Searched on allocation page');
  };
  const handleCheckboxChange = (e, memberId, full_name) => {
    const filteredUsers =
      selectedIds?.length > 0
        ? selectedIds.filter((user) => user === memberId)
        : [];
    if (filteredUsers?.length > 0) {
      setSelectedIds((prevSelected) =>
        prevSelected.filter((item) => item !== memberId)
      );
    } else {
      setSelectedIds((prevSelected) => [...prevSelected, memberId]);
    }
  };

  const handleSelectAll = () => {
    if (!checkedAll) {
      const filteredMembers = data?.filter(
        (item) => item?.group_status === 'PENDING'
      );
      const allMemberIds = filteredMembers?.map((member) => member.id);
      setSelectedIds(allMemberIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckbox = () => {
    if (data?.length > 0) {
      const filteredMembers = data?.filter(
        (item) => item?.group_status === 'PENDING'
      );
  
      if (filteredMembers?.length > 0 && groupId && corpId) {
        return true;
      } 
      return false;
    }
  }
  return (
    <>
      <div className={classNames('bg-white rounded', c.allocation)}>
        <div className="row g-0 p-4 align-items-center">
          {groupId && corpId ? (
            <>
              <div className="col-9">
                <Text
                  textType="t0"
                  classToOverride={classNames(c.memberDetails, 'fw-bold')}
                >
                  Organization:{' '}
                  <span className={classNames(c.detailsData)}>
                    {organisation}
                  </span>
                </Text>
                <Text
                  textType="t0"
                  classToOverride={classNames(c.memberDetails, 'fw-bold')}
                >
                  Group:{' '}
                  <span className={classNames(c.detailsData)}>{groupName}</span>
                </Text>
                <Text
                  textType="t0"
                  classToOverride={classNames(c.memberDetails, 'fw-bold')}
                >
                  Members:{' '}
                  <span className={classNames(c.detailsData)}>
                    {membersRequestCount?.approved_count || '0'}
                  </span>
                </Text>
                <Text
                  textType="t0"
                  classToOverride={classNames(c.memberDetails, 'fw-bold')}
                >
                  Requests:{' '}
                  <span className={classNames(c.detailsData)}>
                    {membersRequestCount?.pending_count || '0'}
                  </span>
                </Text>
              </div>
            </>
          ) : (
            <>
              <div className="col-9">
                <Text
                  textType="t0"
                  classToOverride={classNames(c.memberDetails, 'fw-bold')}
                >
                  Members
                </Text>
              </div>
            </>
          )}
          <div className="col-3 p-3">
            <TextField
              className={classNames('w-100')}
              name="search"
              label="Search"
              type="text"
              id="search"
              size="small"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          {/* <div className="col-3">
            <CustomSelect label="Filter" dropdownIcon={FilterAltIcon} />
          </div> */}
        </div>
        <div className={classNames('row g-0', c.tableContainer)}>
          <CustomTable
            pageNo={tablePageNo}
            setTablePageNo={setTablePageNo}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            columns={columns}
            minWidth={true}
            showSortIcon={false}
            checkedAll={checkedAll}
            setCheckedAll={setCheckedAll}
            handleSelectAll={handleSelectAll}
            isCheckbox={handleCheckbox()}

            // count={50}
          >
            {/* {[...Array(10)].map(() => {
              return <AllocationTable />;
            })} */}
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <AllocationTable
                      name={item?.full_name}
                      // department={item?.user_info?.department}
                      studyHours={item?.duration}
                      sessions={item?.sessions}
                      topics={item?.topics}
                      // viewDashboard={}
                      email={item?.user_info.email}
                      assign={item?.license_active_status}
                      groupId={groupId || ''}
                      corpId={corpId || ''}
                      isAdmin={item?.is_admin}
                      isTeacher={item?.user_info?.is_teacher}
                      saveVideo={item?.recording_flag}
                      isMentor={groupId && corpId ? item?.is_admin : false}
                      ext_id={item?.user_info?.external_id}
                      user_id={item?.user_id}
                      id={item?.id}
                      user_client_id={item?.user_info?.user_client_id}
                      getAllocationTableData={getAllocationTableData}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      handleCheckboxChange={handleCheckboxChange}
                      isCheckbox={handleCheckbox()}
                      groupStatus={item?.group_status}
                      isMember={
                        item?.group_status === 'APPROVED' ? true : false
                      }
                    />
                  </React.Fragment> //Why is it needed
                );
              })}
          </CustomTable>
        </div>
      </div>
    </>
  );
};
export default Allocation;
