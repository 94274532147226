import React from 'react';
import c from '../../../Header/Notification/notification.Module.scss';
import classNames from 'classnames';
import NotificationData from './NotificationData';
import { Divider } from '@mui/material';

const NotificationContainer = ({
  requestModalStatus,
  setRequestModalStatus,
  notificationData,
  handleClick,
  setAdditionalDetailsData,
  setIsNotification,
}) => {
  return (
    <div className={classNames(c.notificationContainer)}>
      {notificationData?.length !== 0 ? (
        <div>
          {notificationData?.map(
            ({
              id,
              created_at,
              notification_message_plain,
              notification_image,
              user_name,
              is_read,
              additional_details,
              ref_record_id,
              notification_id
            }) => {
              return (
                <React.Fragment key={id}>
                  <NotificationData
                    id={id}
                    notification_message_plain={notification_message_plain}
                    requestModalStatus={requestModalStatus}
                    setRequestModalStatus={setRequestModalStatus}
                    notification_image={notification_image}
                    name={user_name}
                    created_at={created_at}
                    is_read={is_read}
                    handleClick={handleClick}
                    setAdditionalDetailsData={setAdditionalDetailsData}
                    additionalDetails={additional_details}
                    setIsNotification={setIsNotification}
                    refRecordId={ref_record_id}
                    notificationId={notification_id}
                  />
                  <Divider />
                </React.Fragment>
              );
            }
          )}
        </div>
      ) : (
        <div className={classNames(c.noNewnotification)}>
          No new notifications
        </div>
      )}
    </div>
  );
};

export default NotificationContainer;
