import { Divider } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Text from '../../../Shared/Text';
import EditAndSaveBtn from '../../FormEditAndSubmit';
import ClientAccountDetails from '../ClientAccountDetails';
import ProfileImageUpload from '../ProfileImageUpload';
import { showLoader, hideLoader } from '../../../../redux/Actions/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClientDataValidationSchema,
  generateInitialClientValues,
  getClientInfo,
  getUserInfo,
  updateClientDetails,
} from '../../../../helpers/Profile/GeneralInfo';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';

const ClientAccountDetailForm = () => {
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();
  const [clientData, setClientData] = useState({});
  const clientId = useSelector((state) => state?.generalInformation?.id);

  useEffect(() => {
    if (clientId) {
      getClientData();
    }
  }, [clientId]);

  const getClientData = async () => {
    const data = await getClientInfo(clientId);
    setClientData(data);
  };

  const handleClientSave = async () => {
    dispatch(showLoader());
    const clientDetails = await getUserInfo(formik.values);
    await updateClientDetails(clientDetails);
    await getClientData();
    dispatch(hideLoader());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialClientValues(clientData),
    validationSchema: ClientDataValidationSchema,
    validateOnMount: true,
    onSubmit: handleClientSave,
  });

  return (
    <>
      <EditAndSaveBtn
        editable={editable}
        setEditable={setEditable}
        handleSubmit={handleClientSave}
        disabled={!formik.isValid}
        page="General Information"
      />
      <div className={classNames('rounded pt-3 ps-3', c.generalInfo)}>
        <Text textType="t1" classToOverride={c.fontWeight600}>
          General Information
        </Text>
        <Text textType="t2" classToOverride="mt-4 fw-light">
          Primary Image
        </Text>
        <Divider className="mt-2" />
        <form>
          <ProfileImageUpload isEditable={!editable} formik={formik} />
          <ClientAccountDetails isEditable={!editable} formik={formik} />
        </form>
      </div>
    </>
  );
};

export default ClientAccountDetailForm;
