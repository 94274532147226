import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { preferedLessonFormatData } from '../../../../helpers/Profile/LearningPreferences';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
const PreferedLessonFormat = (props) => {
  return (
    <>
      <div className="row">
        {props.editable ? (
          <FormControl variant="standard" className="w-100">
            <Select
              id="demo-simple-select"
              value={props.value1}
              onChange={props.handleChange}
              name={props.name}
              inputProps={{
                readOnly: !props.editable,
                className: classNames('bg-transparent'),
              }}
            >
              {preferedLessonFormatData
                .filter(
                  (data) => data.id !== props.value2 && data.id !== props.value3
                )
                .map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    sx={{ height: '45px' }}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className={classNames(c[data.name], 'rounded-circle')}
                      >
                        {data.logo}
                      </div>
                      <div className="text-capitalize ms-3">{data.name}</div>
                    </div>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl variant="standard" className="w-100">
            <Select
              id="demo-simple-select"
              value={props.value1}
              onChange={props.handleChange}
              name={props.name}
              disableUnderline
              IconComponent={''}
              inputProps={{
                readOnly: !props.editable,
              }}
            >
              {preferedLessonFormatData
                .filter(
                  (data) => data.id !== props.value2 && data.id !== props.value3
                )
                .map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    sx={{ height: '45px' }}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className={classNames(c[data.name], 'rounded-circle')}
                      >
                        {data.logo}
                      </div>
                      <div className="text-capitalize ms-3">{data.name}</div>
                    </div>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
    </>
  );
};
//className={c.dropdownIconBackground} sx={{ bgcolor: '#00A89A' }} style={{ backgroundColor: '#00A89A' }}
export default PreferedLessonFormat;
