/**
 * @function LearningPersona
 * @param {none}
 * @returns It  is main component of 
 */




import React from "react";
import LearningPersonaRightPanal from "./LearningPersonaRightPanal";
// import LearningHeader from "./LearningHeader";
import LearningHeader from './LearningHeader/index'
// import LearningPersonaLeftpanal from "./LearningPersonaLeftpanal";
import LearningPersonaLeftpanal from './LearningPersonaLeftpanal/index'
import LearningPersonaStyling from "./LearningPersonaStyling.module.scss";
import classNames from "classnames";
const LearningPersona = () => {
  return (
    <div
      className={classNames(LearningPersonaStyling.learning_persona_container)}
    >
      <div className={classNames(LearningPersonaStyling.headerContainer)}>
        <LearningHeader />
      </div>
      <div className={classNames("d-flex mx-5 h-auto")}>
        <LearningPersonaLeftpanal />
        <LearningPersonaRightPanal />
      </div>
    </div>
  );
};

export default LearningPersona;
