import classNames from 'classnames';
import React from 'react';
import CreatorActions from './CreatorAction';
import CreatorInfo from './CreatorInfo';
import CreatorStyling from '../../courseData.module.scss';
import { Resources } from '../../../../../config/Resources';

const ContantCreator = ({ CreatorName, Role, CreatorProfileLogo }) => {
  return (
    <div className={classNames('d-flex my-4 py-3', CreatorStyling.CreatorInfoContainer)}>
      <div className={classNames('d-flex align-items-center ')}>
        <img
          src={CreatorProfileLogo || Resources.images.AhuraCreatorLogo}
          className={classNames(CreatorStyling.CreatorIconLogo)}
          alt="#"
        />
        <CreatorInfo CreatorName={CreatorName} Role={Role} />
      </div>
      <CreatorActions />
    </div>
  );
};

export default ContantCreator;
