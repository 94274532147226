import {
  IS_EXTENSION_ACTIVE,
  BARDATA,
  SESSIONDATA,
  IS_TOPIC_MODEL,
  SESSIONREPORT,
  IS_SESSION_DETAILS_OPEN,
  IS_EXTENSION_BTN_ENABLED,
} from '../../Types/ExtensionHandler';

export const setIsExtensionActive = (payload) => ({
  type: IS_EXTENSION_ACTIVE,
  payload,
});

export const setIsExtensionBtnEnabled = (payload) => ({
  type: IS_EXTENSION_BTN_ENABLED,
  payload,
});

export const setSessionReport = (payload) => ({
  type: SESSIONREPORT,
  payload,
});

export const setBarData = (payload) => ({
  type: BARDATA,
  payload,
});

export const setSessionData = (payload) => ({
  type: SESSIONDATA,
  payload,
});

export const setIsTopicModel = (payload) => ({
  type: IS_TOPIC_MODEL,
  payload,
});

export const setIsSessionDetailsOpen = (payload) => ({
  type: IS_SESSION_DETAILS_OPEN,
  payload,
});
