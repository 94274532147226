import React from 'react';
import Text from '../../../Shared/Text';
import Divider from '@mui/material/Divider';
import {
  targetLearningHoursForm,
  targetCapabilitiesForm,
} from '../../../../helpers/Profile/Targets';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';

const AddTargets = ({ formik, capabilities }) => {
  const { values, handleChange, errors, setFieldValue } = formik;

  return (
    <>
      <div className="row">
        <div className="col-6">
          <Text textType="t2" classToOverride="pb-3 fw-light">
            Set your Target Hours
          </Text>
          <Divider />
          <div className="pt-3">
            {targetLearningHoursForm.map((field) => (
              <div key={field?.id}>
                <TextField
                  fullWidth
                  className="mt-3 pb-2"
                  variant="outlined"
                  id={field?.id}
                  name={field?.id}
                  label={field?.label}
                  value={values[field?.id] ?? ''}
                  type="number"
                  placeholder={field?.placeholder}
                  onChange={handleChange}
                  error={errors[field?.id] ? true : false}
                  helperText={errors[field?.id]}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-6">
          <Text textType="t2" classToOverride="pb-3 fw-light">
            Set your Top 3 Target Capabilities
          </Text>
          <Divider />
          <div className="pt-3">
            {targetCapabilitiesForm.map((field) => (
              <div key={field?.id}>
                <Autocomplete
                  className="mt-3 pb-2"
                  id={field?.id}
                  value={capabilities.find(
                    (obj) => obj?.id === values[field?.id]
                  )}
                  options={capabilities}
                  getOptionLabel={(option) => option?.name}
                  getOptionDisabled={(option) =>
                    _.values(_.pickBy(values, _.isString)).includes(option?.id)
                  }
                  loading={!capabilities?.length}
                  ListboxProps={{ className: classNames(c.dropdownBox) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field?.label}
                      placeholder={field?.placeholder}
                      error={errors[field?.id] ? true : false}
                      helperText={errors[field?.id]}
                    />
                  )}
                  onChange={(_, value) =>
                    setFieldValue([field?.id], value?.id ?? '')
                  }
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-2">
          <Button
            className={classNames(
              'h-100 w-100 mt-3 text-capitalize fw-bold',
              c.assignTargetBtn
            )}
            variant="outlined"
          >
            Assign Target
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default AddTargets;
