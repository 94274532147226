import React from 'react';
import { Box } from '@mui/system';
import { PlanPerformanceTabsOptions } from '../PlanPerformanceTabsOptions';
import CustomTabs from '../../../../components/Layout/Tabs';
import { useHistory } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';
const PlanPerformanceTabs = ({ index, setTabIndex }) => {
  const history = useHistory();
  const handleChange = (e, newValue) => {
    if (newValue === 0) {
      history.push('/manager-dashboard');
    }
    setTabIndex(newValue);
    if (newValue === 0) {
      googleAnalyticsTriggerFn('Tab', 'Overview', 'Overview tab clicked');
    } else if (newValue === 1) {
      googleAnalyticsTriggerFn(
        'Tab',
        'Learning Plans',
        'Learning Plans tab clicked'
      );
    } else if (newValue === 2) {
      googleAnalyticsTriggerFn(
        'Tab',
        'Capabilities',
        'Capabilities tab clicked'
      );
    }
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
        value={index}
        onChange={handleChange}
        options={PlanPerformanceTabsOptions}
      />
    </Box>
  );
};

export default PlanPerformanceTabs;
