// import c from '../table.module.scss';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Text from '../../Text';
import { TableSortLabel } from '@mui/material';

const CellText = ({ item }) => {
  return (
    <Text
      // className="text-base !font-bold text-admin-charcoal dark:text-admin-white"
      className="fw-bold fs-6"
    >
      {item.cellName}
    </Text>
  );
};

const CustomTableHead = ({ headerData, sortHandler, sort, ...props }) => {
  return (
    <TableHead>
      <TableRow hover role="checkbox">
        {headerData?.map((item) => (
          <TableCell
            // className="!w-auto !border-none"
            className="w-auto border-0"
            key={item?.id}
          >
            {item?.isSort || item?.enableSort ? (
              <TableSortLabel
                active={item?.isSort}
                direction={
                  sort[item?.id] === item?.defaultSortValue ? 'asc' : 'desc'
                }
                onClick={() => sortHandler(item?.id)}
                className="d-flex align-items-start"
              >
                <CellText item={item} />
              </TableSortLabel>
            ) : (
              <CellText item={item} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
