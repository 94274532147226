/**
 * @function ProfileMenu
 *
 */

import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import c from '../../../home.Module.scss';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ListItemText from '@mui/material/ListItemText';
import Headings from '../../../../Shared/Headings';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Avatar } from '@mui/material';
import Text from '../../../../Shared/Text';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { switchAccount } from '../../../../../helpers/Home/CreateAccount/DropdownMenu/ProfileMenu';
import { googleAnalyticsTriggerFn } from '../../../../../utils/utility';

const ProfileMenu = ({ goToMenu }) => {
  const history = useHistory();

  const accounts = useSelector((state) => state?.accounts) || {};

  return (
    <MenuList>
      <MenuItem onClick={() => goToMenu(false)}>
        <ListItemIcon className={c.menuIcon}>
          <KeyboardArrowLeft />
        </ListItemIcon>

        <ListItemText>
          <Headings headingType="h6" text="Back to Menu" />
        </ListItemText>
      </MenuItem>

      <Divider variant="middle" className="mt-0 text-dark" />

      {/* -----------User's accounts mapping-------------- */}

      {accounts &&
        accounts?.Users &&
        accounts?.Users?.length > 0 &&
        accounts?.Users?.map((item) => (
          <MenuItem
            onClick={() => {
              switchAccount(item?.id);
              history.push('/home');
              googleAnalyticsTriggerFn(
                'Menu Item',
                'Personal Account',
                'clicked on Personal Account'
              );
            }}
            key={item?.id}
          >
            <ListItemAvatar>
              <Avatar
                alt="profile picture"
                src={item?.profile_image_url}
                className="border-0 ms-1"
              />
            </ListItemAvatar>
            <ListItemText className="d-flex flex-column align-items-start">
              <Text textType="t0" classToOverride={c.fontWeight600}>
                {item?.user_name}
              </Text>
              <Text
                textType="t4"
                classToOverride={classNames('text-muted', c.fontWeight600)}
              >
                Personal Account
              </Text>
            </ListItemText>
          </MenuItem>
        ))}

      {/* --------------Corporate Owner Mapping--------------------- */}

      {accounts &&
        accounts?.Clients &&
        accounts?.Clients?.length > 0 &&
        accounts?.Clients?.map((item) => (
          <MenuItem
            onClick={() => {
              switchAccount(item?.id);
              history.push('/manager-dashboard');
              googleAnalyticsTriggerFn(
                'Menu Item',
                'Corporate Account',
                'clicked on Corporate Account'
              );
            }}
            key={item?.id}
          >
            <ListItemAvatar>
              <Avatar
                alt="profile picture"
                src={item?.profile_image_url}
                className="border-0 ms-1"
              />
            </ListItemAvatar>
            <ListItemText className="d-flex flex-column align-items-start">
              <Text textType="t0" classToOverride={c.fontWeight600}>
                {item?.user_name}
              </Text>
              <Text
                textType="t4"
                classToOverride={classNames('text-muted', c.fontWeight600)}
              >
                Corporate Account
              </Text>
            </ListItemText>
          </MenuItem>
        ))}

      {/* ---------------Admin mapping ---------- */}

      {accounts &&
        accounts?.Admins &&
        accounts?.Admins?.length > 0 &&
        accounts?.Admins?.map((item) => (
          <MenuItem
            onClick={() => {
              switchAccount(item?.id);
              history.push('/manager-dashboard');
              googleAnalyticsTriggerFn(
                'Menu Item',
                'Admin Account',
                'clicked on Admin Account'
              );
            }}
            key={item?.id}
          >
            <ListItemAvatar>
              <Avatar
                alt="profile picture"
                src={item?.profile_image_url}
                className="border-0 ms-1"
              />
            </ListItemAvatar>
            <ListItemText className="d-flex flex-column align-items-start">
              <Text textType="t0" classToOverride={c.fontWeight600}>
                {item?.user_name}
              </Text>
              <Text
                textType="t4"
                classToOverride={classNames('text-muted', c.fontWeight600)}
              >
                Corporate Account
              </Text>
            </ListItemText>
          </MenuItem>
        ))}

      {/* ------------City Account-------------- */}

      {accounts &&
        accounts?.Parents &&
        accounts?.Parents?.length > 0 &&
        accounts?.Parents?.map((item) => (
          <MenuItem
            onClick={() => {
              switchAccount(item?.id);
              history.push('/manager-dashboard');
              googleAnalyticsTriggerFn(
                'Menu Item',
                'City Account',
                'clicked on City Account'
              );
            }}
            key={item?.id}
          >
            <ListItemAvatar>
              <Avatar
                alt="profile picture"
                src={item?.profile_image_url}
                className="border-0 ms-1"
              />
            </ListItemAvatar>
            <ListItemText className="d-flex flex-column align-items-start">
              <Text textType="t0" classToOverride={c.fontWeight600}>
                {item?.user_name}
              </Text>
              <Text
                textType="t4"
                classToOverride={classNames('text-muted', c.fontWeight600)}
              >
                City Account
              </Text>
            </ListItemText>
          </MenuItem>
        ))}

      <MenuItem
        onClick={() => {
          history.push('/myaccount');
          googleAnalyticsTriggerFn(
            'Menu Item',
            'Create Account',
            'clicked on Create Account'
          );
        }}
      >
        <ListItemIcon>
          <IconButton>
            <AddIcon
              className={classNames(
                'rounded-circle p-1',
                c.primaryColor,
                c.createAccountBtn
              )}
            />
          </IconButton>
        </ListItemIcon>
        <ListItemText>
          <Headings headingType="h6" text="Create Account" />
        </ListItemText>
      </MenuItem>
    </MenuList>
  );
};

export default ProfileMenu;
