export const columns = [
  { id: 'empty', label: '' },
  { id: 'page_title', label: 'Page Title' },
  // { id: 'topic', label: 'Topic' },
  { id: 'timestamp', label: 'TimeStamp and Duration' },
  { id: 'relevance', label: 'Relevance' },
  // { id: 'actions', label: 'Actions' },
];

 export const getMinutesAndSeconds = (minutes) => {
   const sec = Math.round((Number(minutes) % 1) * 60) ;
   const min = Math.floor(Number(minutes)) 
   return `${min} min ${sec} sec`;
 };