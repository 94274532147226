import React from 'react';
import EmailSuccess from './EmailSuccess';
import InviteByEmail from './InviteByEmail';

const EmailInvite = ({status, setStatus, setModalStatus, ...props}) => {
    switch(status){
        case "InviteByEmail":
            return(<InviteByEmail setStatus={setStatus} setModalStatus={setModalStatus}/>)
        case "EmailSuccess":
            return(<EmailSuccess setModalStatus={setModalStatus}/>)
        default:
            return(<></>)
    }
}
export default EmailInvite;