import classNames from "classnames";
import NavBar from "../../navBar";
import panelClasses from "./panel.module.scss"

const Panel = ({ ...props }) => {
  return (
    <div className="d-flex position-relative w-100 vh-100">
      <NavBar/>
      <div className={classNames(panelClasses.panelNavbarChildens)}>
        <div className={classNames("d-flex w-100")}>{props.children}</div>
      </div>
    </div>
  );
};

export default Panel;
