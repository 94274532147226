import Country from './Country';
import State from './State';

const CountryAndState = ({ items, formik }) => {
  switch (items?.id) {
    case 'country':
      return <Country formik={formik} items={items} />;
    case 'state':
      return <State formik={formik} items={items} />;
    default:
      return <></>;
  }
};
export default CountryAndState;
