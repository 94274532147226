export const tableHeader = [
  {
    id: 'name',
    cellName: 'Name',
    isSort: false,
    defaultSortValue: 'asc',
    enableSort: true,
  },
  { id: 'networkType', cellName: 'Type' },
  { id: 'corporates', cellName: 'Corporates' },
  { id: 'email', cellName: 'Email' },
  {
    id: 'date',
    cellName: 'Date Of Joining',
    isSort: false,
    defaultSortValue: 'asc',
    enableSort: true,
  },
  { id: 'admin', cellName: 'S-Admin' },
  { id: 'action', cellName: 'Action' },
];
