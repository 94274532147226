export const neighborhoodTableHeader = [
  { id: 'name', cellName: 'Name', isSort: true, defaultSortValue: 'asc' },
  { id: 'licenses', cellName: 'Licenses' },
  { id: 'admin', cellName: 'Admin' },
  { id: 'admin email', cellName: 'Admin Email' },
  { id: 'license_code', cellName: 'License Code' },
  { id: 'action', cellName: 'Action' },
];

export const neighborhoodMembersTableHeader = [
  { id: 'name', cellName: 'Name', isSort: true, defaultSortValue: 'asc' },
  { id: 'email', cellName: 'Email' },
  { id: 'session', cellName: 'Session' },
  { id: 'topics', cellName: 'Topics' },
  { id: 'admin', cellName: 'Admin' },
  { id: 'view', cellName: 'View Dashboard' },
  { id: 'action', cellName: 'Action' },
];
