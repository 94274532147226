/**
 * @function LearningPlan
 * Renders all Learning plans content
 */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import { fetchLearningPlans } from '../../../helpers/LearningPlans/LearningPlan';
import classNames from 'classnames';
import c from '../learningPlans.Module.scss';
import PlanPreview from '../PlanPreview';
import DottedUploadInput from '../../Shared/DottedUploadInput';
import LearningPlanCard from '../LearningPlanCard';

const LearningPlan = ({ handleAddPlan }) => {
  const [previewPlan, setPreviewPlan] = useState(false);
  const [plansData, setPlansData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      const response = await fetchLearningPlans();
      setPlansData(response?.data);
      dispatch(hideLoader());
    })();
  }, [dispatch]);

  return (
    <div
      className={classNames(
        'mx-0 px-0 d-grid justify-content-evenly',
        c.leaningPlanSection
      )}
    >
      {previewPlan && (
        <PlanPreview openModal={previewPlan} setModal={setPreviewPlan} />
      )}
      <div className={c.addLearningPlanBox}>
        <DottedUploadInput
          onClick={() => handleAddPlan(true)}
          text="Add a Learning Plan"
        />
      </div>
      {plansData &&
        plansData.map((item, index) => (
          <div key={item.id}>
            <LearningPlanCard
              index={index + 1}
              cardHead={item?.title}
              handlePlanPreview={setPreviewPlan}
            >
              {item?.description}
            </LearningPlanCard>
          </div>
        ))}
    </div>
  );
};

export default LearningPlan;
