import React from 'react';
import LearningBehaviorCss from '../LearningBehavior.module.scss';
import classNames from 'classnames';
import Bar from './bar';
import Text from '../../../Shared/Text';

const BrowsingBar = ({ distractionPrct, focusPrct, neutralPrct }) => {
  return (
    <div className={classNames('mx-5 mb-3')}>
      <div
        className={classNames(
          LearningBehaviorCss.BrowsingContainer,
          'd-flex justify-content-between align-items-end flex-wrap'
        )}
      >
        <Bar
          height={`${focusPrct}%`}
          color="#028277"
          percentage={`${focusPrct || 0}%`}
          text="Focused"
        />
        <Bar
          height={`${neutralPrct}%`}
          color="#57575C"
          percentage={`${neutralPrct || 0}%`}
          text="Not Calculated"
        />
        <Bar
          height={`${distractionPrct}%`}
          color="#FB8C26"
          percentage={`${distractionPrct || 0}%`}
          text="Distraction"
        />
      </div>

      <div
        className={classNames(
          'd-flex justify-content-between align-items-end flex-wrap'
        )}
      >
        <Text
          textType="t3"
          classToOverride={classNames(
            LearningBehaviorCss.Width80,
            'text-center'
          )}
          text={'Focused'}
        />
        <Text
          textType="t3"
          classToOverride={classNames(
            LearningBehaviorCss.Width80,
            'text-center'
          )}
          text={'Not Calculated'}
        />
        <Text
          textType="t3"
          classToOverride={classNames(
            LearningBehaviorCss.Width80,
            'text-center'
          )}
          text={'Distraction'}
        />
      </div>
    </div>
  );
};
export default BrowsingBar;
