import React from 'react';
import classNames from 'classnames';
import c from '../../../allocation.module.scss';
import Text from '../../../../Shared/Text';
import { LinearProgress } from '@mui/material';
import { SuccessIcon } from '../../../../Shared/Icons';
import ModalHeaderText from '../../ModalHeaderText';
import { Button } from '@mui/material';

const EmailSuccess = ({ setModalStatus, ...props }) => {
  return (
    <React.Fragment>
      <ModalHeaderText heading="Success!" text="Email Sent" />
      <div className={classNames('text-center mt-5')}>
        <SuccessIcon />
        <Text
          textType="t0"
          classToOverride={classNames(c.primaryColor, 'mt-2')}
        >
          Success! Invites Sent
        </Text>
      </div>
      <div className={classNames(c.ButtonContainer)}>
        <Button variant="outlined" onClick={() => setModalStatus(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => setModalStatus(false)}
          className="ms-2 text-capitalize fw-bold"
        >
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};

export default EmailSuccess;
