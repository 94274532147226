import React from 'react';
import classNames from 'classnames';
import QuizQuestionsTitle from '../QuizQuestionsTitle';
// import QuizSelect from '../QuizSelect';
import CustomSelect from '../../../Layout/Select';
const QuizQuestionsTitleContainer = ({ heading, title, ...props }) => {
  return (
    <div
      className={classNames('row align-items-center justify-content-between')}
    >
      <div className="col-4">
        <QuizQuestionsTitle heading={heading} title={title} />
      </div>
      {/* <div className="col-3">
        <CustomSelect
          label="Filter"
          menuItems={[{ label: 'This Week', value: 'this' }]}
          value='this'
        />
      </div> */}
    </div>
  );
};
export default QuizQuestionsTitleContainer;
