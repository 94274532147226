import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';

export const TabOptions = [
  { label: 'By Me', value: 0 },
  { label: 'By Company', value: 1 },
];

export const EmployeeDetailsTabs = [
  {label : 'By Company', value: 1 }
]

export const cardIconsInfo = [
  { id: 'viewIcon', iconName: VisibilityIcon, downLabel: 'View' },
  { id: 'completeIcon', iconName: DoneIcon, downLabel: 'Complete' },
];

export const learning_plan_columns = [
  { id: 'learning_plan', label: 'Learning Plan' },
  { id: 'Category', label: 'Category' },
];