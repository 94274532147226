import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import classNames from 'classnames';

const Timeline = ({
  items,
  finishItems,
  activeItemClass,
  inertItemClass,
}) => {
  return (
    <>
      {[...Array(items)].map((_, index) => (
        <FiberManualRecordIcon
          key={`timeline${index}`}
          className={classNames(
            index < finishItems ? activeItemClass : inertItemClass,
            'rounded-circle'
          )}
        />
      ))}
    </>
  );
};

export default Timeline;
