/**
 * @function CreateAccount
 * @param {function} isOpen ,handleClose , handleChange
 * @returns It will contains the Create Account modal.
 */

import React from 'react';
import ModuleStyling from '../modal.Module.scss';

import classNames from 'classnames';
import Text from '../../../Shared/Text';
import { CreateAccountFields } from '../../../../helpers/My Account/FormFieldsData';
import Form from '../Box/Form';
import RoundedCircle from '../Box/RoundedCircle';
import Headings from '../../../Shared/Headings';
import { useFormik } from 'formik';
import { initialValuesforCreateAccountBox } from '../../../../helpers/My Account';
import BackAndContinue from '../Box/BackAndContinue';

const CreateAccount = ({ handleContinue, setOpen }) => {
  const formik = useFormik({
    initialValues: initialValuesforCreateAccountBox,
    onSubmit: (values) => {
      handleContinue();
    },
  });

  return (
    <>
      <div className="d-flex justify-content-between">
        {/* <h3>Create Account</h3> */}
        <Headings headingType="h4" classToOverride="row g-0 fw-bold">
          Create Account
        </Headings>
        <div>
          <RoundedCircle numberOfItems={4} indexOfColoredItem={0} />
        </div>
      </div>
      <Text
        classToOverride={ModuleStyling.para}
        text="What type of account would you like to purchase?"
      />
      <Form
        FormFields={CreateAccountFields}
        formik={formik}
        // selectRole={selectData}
        // handleChange={handleChange}
      />
      <BackAndContinue handleContinue={formik.handleSubmit} setOpen={setOpen} />
    </>
  );
};

export default CreateAccount;
