import React from 'react';
import classNames from 'classnames';
import c from '../../Profile.Module.scss';
import Box from '@mui/system/Box';
import Text from '../../../Shared/Text';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

const BadgeDescription = ({ badgeClassName }) => {
  return (
    <div className={classNames('row g-0', c.containerDiv)}>
      <Box className={badgeClassName} />
      <div className={classNames('col-2 pt-4', c.secondDiv)}>
        <Text
          textType="t1"
          classToOverride={classNames('pt-3', c.fontWeight600)}
        >
          Badge Name
        </Text>
        <Text textType="t2" classToOverride="fw-light">
          Description
        </Text>
      </div>
      <div className={classNames('col-2 pt-4', c.thirdDiv)}>
        <Text
          textType="t1"
          classToOverride={classNames('pt-3', c.fontWeight600)}
        >
          Date Acquired
        </Text>
        <Text textType="t2" classToOverride="fw-light">
          Jan 20, 2021
        </Text>
      </div>
      <Box className={classNames('col-1', c.vertical)} />
      <div className="col pt-4">
        <Text textType="t1" classToOverride="pt-3 fw-normal">
          Lorem Ipsum
        </Text>
        <Text textType="t2" classToOverride="fw-light">
          Lorem Ipsum
        </Text>
      </div>
      <MoreHorizSharpIcon
        className={classNames(c.moreHorizSharpIcon, c.primaryColor)}
      />
    </div>
  );
};

export default BadgeDescription;
