import React from 'react';
// import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import classNames from 'classnames';
import c from '../table.module.scss';
// import IconButton from "@mui/material/IconButton";
import Text from '../../Text';

function DropdownButton({ btnName, options, setValue, item }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setValue(event.target.innerText, event.target.id, item);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classNames(
          c.button,
          open ? c.bottomBorderNone : c.bottomBorder,
          'mb-1 text-capitalize'
        )}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        color="primary"
      >
        <Text className={classNames(c.font16, 'font-weight-bold')}>
          {btnName}
        </Text>
      </Button>

      <Menu
        elevation={0}
        classes={{
          paper: c.menuDropdown,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((items) => {
          return (
            <MenuItem key={items.id} onClick={handleClose}>
              <Text className={classNames(c.font16, 'm-0')} id={items.id}>
                {items.value}
              </Text>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default DropdownButton;
