import React from 'react';
import ModuleStyling from '../../modal.Module.scss';
import Button from '@mui/material/Button';
import classNames from 'classnames';
const BackAndContinue = ({ setOpen, handleContinue }) => {
  return (
    <div
      className={classNames(ModuleStyling.footer, 'modal-footer border-top-0')}
    >
      <Button
        size="large"
        onClick={() => setOpen(false)}
        variant="outlined"
        className={`${ModuleStyling.cancelButton}`}
      >
        Cancel
      </Button>
      <Button
        onClick={()=> handleContinue()}
        variant="contained"
        size="large"
        className={classNames(ModuleStyling.continueBtn)}
      >
        Continue
      </Button>
    </div>
  );
};

export default BackAndContinue;
