import { EMAIL_REGEX_PATTERN } from '../../config/Regex/regex';
import { postApiCall } from '../../utils/axios/axios';
import { API_URLS } from '../../config/API_URLS/api_urls';
import jwtDecode from 'jwt-decode';
import { ROLE_IDS } from '../../config/Constants';
import ReactGA from 'react-ga4';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
export const initialValues = {
  email: '',
  pass: '',
};

export const validate = (values) => {
  const errors = {};
  if (!values?.email) {
    errors.email = 'Email is required';
  }
  if (
    values?.email &&
    !EMAIL_REGEX_PATTERN.test(values?.email?.toLowerCase())
  ) {
    errors.email = 'Not a valid Email';
  }
  if (!values?.pass) {
    errors.pass = 'Password is required';
  }
  return errors;
};

export const onSubmit = async (values, history, setIsLoading, dispatch) => {
  try {
    const reqBody = {
      client_id: 'cb19c961-fc50-4613-87d9-40488220a1d9',
      email: values?.email.toLowerCase(),
      password: values?.pass,
      get_other_accounts: true,
    };
    setIsLoading(true);
    const res = await postApiCall(API_URLS.login, reqBody);
    if (res?.token) {
      localStorage.setItem('token', res?.token);
      const decode_token = jwtDecode(res?.token);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ user_id: decode_token.id });

      if (
        decode_token &&
        (decode_token.role_id === ROLE_IDS.CLIENT_ADMIN ||
          decode_token.role_id === ROLE_IDS.CLIENT_OWNER ||
          decode_token.role_id === ROLE_IDS.PARENT_ADMIN ||
          decode_token.role_id === ROLE_IDS.PARENT_OWNER)
      ) {
        history.push('/manager-dashboard');
      } else {
        const isPreviousPagefromAhuraDomain = history.location.state?.fromAhuraDomain;
        const targetPath = isPreviousPagefromAhuraDomain ? history.goBack() : '/home';
        history.push(targetPath);
      }
      window.location.reload();
      googleAnalyticsTriggerFn('Button', 'Login', 'Login Successfull');
    }
    if (res?.error) {
      if (res?.error?.email_verified === false) {
        history.push('/signUp', {
          emailId: values?.email,
          page_number: 4,
        });
        googleAnalyticsTriggerFn('Button', 'Login', 'Login Unsuccessfull');
      }
      console.log('Err', res?.error?.message);
    }
    setIsLoading(false);
  } catch (err) {
    console.log('error in login', err);
    setIsLoading(false);
  }
};
