import React, { useState } from 'react';
import ModalBox from '../../../Shared/ModalBox';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import { Button } from '@mui/material';
import classNames from 'classnames';
import c from '../../home.Module.scss';
import { InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Table } from './Table';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';

const AssignLearningPlan = ({ setShowModal }) => {
  const [learningPlan, setLearningPlan] = useState([]);
  const handleAddingLearningPlan = () => {
    // console.log('data', learningPlan);
    googleAnalyticsTriggerFn(
      'Button',
      'Add',
      'Add button clicked on Assign Learning Plan modal'
    );
  };

  return (
    <ModalBox openModal={true} classToOverride="w-75 mt-3 mb-3">
      <div className="m-4">
        <Headings
          headingType="h3"
          classToOverride={classNames('fw-bold', c.learningPlanHeading)}
        >
          Learning Plans
        </Headings>
        <Text
          textType="t0"
          classToOverride={classNames(c.fontWeight600, c.learningPlanHeading)}
        >
          Select the Learning plans you would like to assign to capabilities
        </Text>
        <div className={classNames('d-flex align-items-center mt-4', c.search)}>
          <IconButton
            type="submit"
            aria-label="search"
            className={classNames(c.primaryColor)}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder="Search"
            inputProps={{ 'aria-label': 'Search' }}
            className={classNames(c.primaryColor)}
          />
        </div>
      </div>
      <Table learningPlan={learningPlan} setLearningPlan={setLearningPlan} />
      <div className="d-flex p-3 my-2 justify-content-end">
        <Button
          variant="outlined"
          sx={{ textTransform: 'capitalize' }}
          className="m-1"
          onClick={() => {
            setShowModal(false);
            googleAnalyticsTriggerFn(
              'Button',
              'Cancel',
              'Cancel button clicked on Assign Learning Plan modal'
            );
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: 'capitalize' }}
          className="m-1"
          onClick={handleAddingLearningPlan}
        >
          Add
        </Button>
      </div>
    </ModalBox>
  );
};

export default AssignLearningPlan;
