import classNames from 'classnames';
import React, { useState } from 'react';
import CustomSelect from '../../../Layout/Select';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import c from '../quiz.module.scss';
import QuestionsCard from './QuestionsCard';

const QuestionsSummmary = ({ questions, score, correctAnswers }) => {
  return (
    <>
      <div className={classNames(c.questionsSummary, 'bg-white')}>
        <div
          className={classNames('col-12 mt-5 d-flex justify-content-between ')}
        >
          <div className={classNames('m-5')}>
            <Headings headingType="h6" classToOverride={' fw-bold'}>
              Questions Summary
            </Headings>
            <Text textType="t3" classToOverride={'fw-bold'}>
              You scored {correctAnswers} out of {questions?.length} answers correct. Review
              incorrect answers below:
            </Text>
          </div>
          {/* <div className={classNames("d-flex col-4 justify-content-between m-5")}>
            <CustomSelect label="Quiz Summary" />
            <CustomSelect label="Top Performers" className="ms-2" />
          </div> */}
        </div>

        {questions &&
          questions.length > 0 &&
          questions.map((item) => (
            <React.Fragment key={item?.id}>
              <QuestionsCard
                key={item?.id}
                number={item?.question_type}
                questionTitle={item?.question_title}
                options={item?.question_options_attributes}
                selectedOption={
                  item?.answers && item?.answers?.length && item?.answers[0]
                    ? item?.answers[0]?.option_id
                    : ''
                }
              />
            </React.Fragment>
          ))}
        {/* <QuestionsCard number={2} selectedOption={'D'} correctOption={'A'} />
        <QuestionsCard number={3} selectedOption={'D'} correctOption={'A'} /> */}
      </div>
    </>
  );
};

export default QuestionsSummmary;
