import classNames from 'classnames';
import React from 'react';
import PageHeaderStyling from '../pageheader.module.scss';
const PageHeaderRightSection = (props) => {
  return (
    <div>
      <div
        className={classNames(
          PageHeaderStyling.SectionTitle,
          'd-flex'
        )}
      >
        <img src={props?.icon} alt="" />
        <h4 className={classNames('fw-bold mb-0 ms-2')}>{props.name}</h4>
      </div>
      <div className={classNames(PageHeaderStyling.SectionSubTitle, 'fw- bolder mt-2')}>
        <p>{props.subText} </p>
      </div>
    </div>
  );
};

export default PageHeaderRightSection;
