import React, { useEffect, useState } from 'react';
import YourQuizScore from '../YourQuizScore';
import Headings from '../../../Shared/Headings';
import c from '../quiz.module.scss';
import classNames from 'classnames';
import QuestionsSummmary from '../QuestionsSummary';
import { fetchAssignmentReport } from '../../../../helpers/Quiz';
// import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
const QuizReport = ({ assignment_id, user_client_id, courseId }) => {
  const history = useHistory();
  const [quizReportData, setQuizReportData] = useState({});
  useEffect(() => {
    document
      .getElementById('parent-con')
      ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(async () => {
    if (assignment_id && user_client_id) {
      const params = {
        assignment_id: assignment_id,
        user_client_id: user_client_id,
      };
      const response = await fetchAssignmentReport(params);
      if (response) setQuizReportData(response?.data);
    }
  }, [assignment_id, user_client_id]);

  return (
    <div className="bg-white p-5">
      <Headings headingType={'h3'} classToOverride={classNames(c.primaryColor)}>
        Quiz Report
      </Headings>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNewIcon />}
        className="mt-2"
        onClick={() => history.push(`/course/${courseId}`)}
      >
        Back
      </Button>
      <div className="row mt-5">
        <div className="col-12">
          <YourQuizScore score={+quizReportData?.score} />
        </div>
      </div>
      <div className="mt-5">
        <QuestionsSummmary
          score={+quizReportData?.score}
          questions={quizReportData?.questions}
          correctAnswers={quizReportData?.total_correct_answers}
        />
      </div>
    </div>
  );
};

export default QuizReport;
