/**
 * @function CustomIframe
 * @param {text} selectedLink link to display in iframe
 * @param {function} setSelectedLink function to set link to display in iframe
 * @param {text} iconClass class for cross icon
 * @returns It will return a modal which consist a iframe with custom links page
 *
 */

import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import ModalBox from '../ModalBox';
import CloseIcon from '@mui/icons-material/Close';

export const CustomIframe = ({ selectedLink, setSelectedLink, iconClass }) => {
  const [loader, setLoader] = useState(true);
  return (
    <ModalBox
      openModal={selectedLink.length !== 0}
      classToOverride="w-75 mt-3 mb-3"
    >
      <>
        <CloseIcon
          className={iconClass}
          onClick={() => {
            setSelectedLink('');
            setLoader(true);
          }}
        />
        {loader && (
          <div className="d-flex justify-content-center align-items-center position-absolute top-50 start-50">
            <CircularProgress />
          </div>
        )}
        <iframe
          src={selectedLink}
          width="100%"
          height="600"
          onLoad={() => {
            setLoader(false);
          }}
        />
      </>
    </ModalBox>
  );
};
