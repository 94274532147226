import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { PlanContext } from '../../../../../helpers/LearningPlans/AddingPlanModules';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CustomizedContentCard from '../CustomizedContentCard';
import { courseCategoryId } from '../../../../../config/Constants';

const DragDropFeature = () => {
  const onLessonCreationOrDeletion = useContext(PlanContext);
  const customizedLearningPlanData =
    useSelector((state) => state?.customizedLearningPlanData) || [];

  const removeCard = async (e) =>
    onLessonCreationOrDeletion({ lessonId: e?.currentTarget?.value }, false);

  return (
    <Droppable droppableId={'dropTwo'}>
      {(provided) => {
        return (
          <>
            <div
              {...provided?.droppableProps}
              ref={provided?.innerRef}
              className="h-100"
            >
              {customizedLearningPlanData.length > 0 &&
                customizedLearningPlanData.map((item, index) => (
                  <Draggable
                    key={item?.id}
                    draggableId={item?.id}
                    index={index}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          {...provided?.dragHandleProps}
                        >
                          <CustomizedContentCard
                            id={item?.id}
                            type={Object.keys(courseCategoryId).find(
                              (key) => courseCategoryId[key] === item?.type_id
                            )}
                            topic={item?.heading}
                            desc={item?.description}
                            removeCard={removeCard}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided?.placeholder}
            </div>
          </>
        );
      }}
    </Droppable>
  );
};

export default DragDropFeature;
