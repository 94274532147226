/**
 * @function RightPanelPage3
 * @param {number} goToNextPage
 * @returns It will show the third page of right panel.
 */

import React, { useState } from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import { TextField } from '@mui/material';
import SignupStyling from '../../signUp.Module.scss';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import { initialValues, ValidatePassword } from '../../../../helpers/signup';
import { onSubmit } from '../../../../helpers/signup';
import PasswordValidate from './passwordValidate';
import { VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../../redux/Actions/UserData';
const RightPanelPage3 = (props) => {

  const dispatch = useDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const SaveUserData = (resultData) => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length === 0) {
        resultData && resultData.data && dispatch(setUserData(resultData.data));
        // resultData &&
        //   resultData.data &&
        //   localStorage.setItem('token', resultData.data.UserClients[0]?.token);
        props.goToNextPage();
      }
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidatePassword,
    onSubmit: onSubmit({
      SaveUserData: SaveUserData,
      setIsLoading: setIsLoading,
      setUserId: props?.setUserId
    }),
  });


  
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setisConfirmPasswordVisible] =
    useState(false);
  return (
    <RightPanelLayout
      formik={formik}
      heading="Create a Password"
      desc="Create a unique password to setup your account."
      goToNextPage={props.goToNextPage}
      signUpApi={true}
      signUpApiFunc={formik.handleSubmit}
    >
      <form className="w-100">
        <TextField
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder="Password"
          name="pass"
          id="pass"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  onClick={() => setisPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? <VisibilityOff /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
            className: ` fw-bold ${SignupStyling.signUpFields}`,
          }}
          className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          value={formik?.values?.pass}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            (formik?.touched.pass || formik?.touched.sumbited) &&
            formik?.errors?.pass
              ? true
              : false
          }
          helperText={
            (formik?.touched.pass || formik?.touched.sumbited) &&
            formik?.errors?.pass
          }
        />
        <TextField
          type={isConfirmPasswordVisible ? 'text' : 'password'}
          id="confirm_pass"
          name="confirm_pass"
          placeholder="Repeat Password"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  onClick={() =>
                    setisConfirmPasswordVisible(!isConfirmPasswordVisible)
                  }
                >
                  {isConfirmPasswordVisible ? (
                    <VisibilityOff />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            className: ` fw-bold ${SignupStyling.signUpFields}`,
          }}
          className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          value={formik?.values?.confirm_pass}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            (formik?.touched.confirm_pass || formik?.touched.sumbited) &&
            formik?.errors?.confirm_pass
              ? true
              : false
          }
          helperText={
            (formik?.touched.confirm_pass || formik?.touched.sumbited) &&
            formik?.errors?.confirm_pass
          }
        />
        {/* {console.log("repeat",repeatPassword)} */}
      </form>
      <PasswordValidate />
    </RightPanelLayout>
  );
};
export default RightPanelPage3;
