import {
  NetworkIcon,
  CampaignIcon,
  ShopIcon,
  CommunicationIcon,
  FinanceIcon,
  HomeIcon,
} from '../../components/shared/icons';

const NavbarItems = {
  home: {
    id: 'home',
    title: 'Home',
    Icon: HomeIcon,
    route: '/',
  },
  networks: {
    id: 'networks',
    title: 'Networks',
    Icon: NetworkIcon,
    route: '/networks',
  },
  campaigns: {
    id: 'campaigns',
    title: 'Campaigns',
    Icon: CampaignIcon,
    route: '/campaigns',
  },
  shops: {
    id: 'shops',
    title: 'Shops',
    Icon: ShopIcon,
    route: '/shops',
  },
  communications: {
    id: 'communications',
    title: 'Communications',
    Icon: CommunicationIcon,
    route: '/communications',
  },
  finances: {
    id: 'finances',
    title: 'Finances',
    Icon: FinanceIcon,
    route: '/finances',
  },
};

export const leftNavBar = [
  NavbarItems.home,
  NavbarItems.networks,
  // NavbarItems.communications,
  // NavbarItems.campaigns,
  // NavbarItems.shops,
  // NavbarItems.finances,
];
