/**
 * @function AirbnbSlider
 * Component of profile/LearningPreferences
 * Used to design customize slider
 * export Airbnb Slider and AirbnbThumbComponent which is used in Custom Slider
 */
import React from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


export const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#9595A1',
  height: 2,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 21,
    width: 14,
    borderRadius: 4,
    backgroundColor: '#00A89A',
  },
}));

export const AirbnbThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
};
