/**
 * Side bar for the app
 * @function Sidebar
 */

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useHistory } from 'react-router-dom';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  IconButton,
} from '@mui/material';
import Headings from '../Shared/Headings';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import c from './sidebar.Module.scss';
import {
  sidebarListItemsNonLoggedIn,
  sidebarListItemsLearnerDashboard,
  sidebarListItemsManagerandTeacherDashboard,
} from '../../helpers/Sidebar';
import { useSelector } from 'react-redux';
import { ROLE_IDS } from '../../config/Constants';
import classNames from 'classnames';

const mixin = (theme, reqWidth) => ({
  width: reqWidth,
  marginTop: '60px',
  height: 'calc(100% - 60px)',
  border: 'none',
  background: '#f2f2f2',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  whiteSpace: 'nowrap',
  ...(open
    ? {
        overflowX: 'hidden',
        ...mixin(theme, 347),
        '& .MuiDrawer-paper': { overflowX: 'hidden', ...mixin(theme, 347) },
      }
    : {
        overflow: 'hidden',
        ...mixin(theme, 57),
        '& .MuiDrawer-paper': { overflow: 'hidden', ...mixin(theme, 57) },
      }),
}));

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [listItemOpen, setListItemOpen] = useState({
    Home: false,
    Education: false,
    Employees: false,
    Metrics: false,
    SessionLog: false,
    Chat: false,
    Payments: false,
  });
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedSubItem, setSelectedSubItem] = useState('');

  const role_id = useSelector((state) => state?.generalInformation?.role_id);
  const [sidebarItems, setSidebarItems] = useState([
    ...sidebarListItemsNonLoggedIn,
  ]);

  useEffect(() => {
    if (role_id) {
      if (
        role_id === ROLE_IDS.CLIENT_OWNER ||
        role_id === ROLE_IDS.CLIENT_ADMIN ||
        role_id === ROLE_IDS.PARENT_ADMIN ||
        role_id === ROLE_IDS.PARENT_OWNER
      ) {
        setSidebarItems([...sidebarListItemsManagerandTeacherDashboard]);
      } else {
        setSidebarItems([...sidebarListItemsLearnerDashboard]);
      }
    }
  }, [role_id]);

  const openListItems = (item) => {
    setListItemOpen({
      ...listItemOpen,
      [item]: !listItemOpen[item],
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/home') {
      setSelectedItem('Home');
      setSelectedSubItem('');
    } else if (history.location.pathname === '/myaccount') {
      setSelectedItem('License');
      setSelectedSubItem('Account');
    } else if (history.location.pathname === '/allocation') {
      setSelectedItem('License');
      setSelectedSubItem('Member details');
    // } else if (history.location.pathname === '/manage-groups') {
    //   setSelectedItem('License');
    //   setSelectedSubItem('Manage Groups');
    } else if (history.location.pathname === '/learning-plans') {
      setSelectedItem('Library');
    } else if (history.location.pathname === '/content') {
      setSelectedItem('Library');
      setSelectedSubItem('Content');
    } else if (history.location.pathname === '/campaigns') {
      setSelectedItem('Library');
      setSelectedSubItem('Create Content');
    } else if (history.location.pathname === '/capabilities') {
      setSelectedItem('capabilities');
      setSelectedSubItem();
    }
  }, [history.location.pathname]);

  return (
    <Box className="d-flex">
      <Drawer variant="permanent" open={open}>
        <List className={c.list}>
          {sidebarItems.map((item, index) => (
            <div key={index}>
              <ListItemButton
                className={classNames(
                  c.itemButton,
                  item.name === selectedItem && c.sidebarItemStyle
                )}
                onClick={(event) => {
                  open
                    ? openListItems(item.name)
                    : history.push(item?.redirectTo);
                  setSelectedItem(item.name);
                  item.name !== selectedItem && setSelectedSubItem('');
                }}
                disabled={item?.disabled ? true : false}
              >
                <ListItemIcon
                  className={c.itemIcon}
                  onClick={() => history.push(item?.redirectTo)}
                >
                  <img src={item.icon} alt={item.name} />
                </ListItemIcon>

                <ListItemText>
                  <Headings
                    headingType="h5"
                    text={item.name}
                    classToOverride="fw-light"
                  />
                </ListItemText>

                {listItemOpen[item.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              {open &&
                item.dropdownItems.map((dropdownItem, index) => (
                  <Collapse
                    key={index}
                    in={listItemOpen[item.name]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItemButton
                        className={classNames(
                          c.dropdownItemButton,
                          dropdownItem.itemName === selectedSubItem &&
                            c.sidebarSubItems
                        )}
                        onClick={() => {
                          history.push(dropdownItem?.redirectTo);
                          setSelectedSubItem(dropdownItem.itemName);
                          setSelectedItem(item.name);
                        }}
                        disabled={dropdownItem?.disabled ? true : false}
                      >
                        <ListItemText>
                          <Headings
                            headingType="h5"
                            text={dropdownItem.itemName}
                            classToOverride={c.itemText}
                          />
                        </ListItemText>
                      </ListItemButton>

                      <Divider />
                    </List>
                  </Collapse>
                ))}
            </div>
          ))}

          {open ? (
            <div className="d-flex justify-content-end align-items-center mt-4 pb-4 pr-5">
              <IconButton onClick={() => setOpen(!open)}>
                <KeyboardDoubleArrowLeftRoundedIcon
                  className={c.doubleArrowIconButton}
                />
              </IconButton>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center my-5">
              <IconButton onClick={() => setOpen(!open)}>
                <KeyboardDoubleArrowRightRoundedIcon
                  className={c.doubleArrowIconButton}
                />
              </IconButton>
            </div>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
