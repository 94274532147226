import { Resources } from '../../../config/Resources';

export const TopLearnersDataManager = [
  {
    LearnerImage: Resources.images.home.TopLearners1,
    LearnerName: 'Catherine O’Hara',
    Capabilities: '5',
    Time: ' 6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners2,
    LearnerName: 'Akita Manning',
    Capabilities: '5',
    Time: '6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners3,
    LearnerName: 'Joseph Brigado',
    Capabilities: '5',
    Time: '6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners4,
    LearnerName: 'Jake Paul',
    Capabilities: '5',
    Time: '6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners5,
    LearnerName: 'Kathy Hawthorne',
    Capabilities: '5',
    Time: '6hr 33min',
  },
];
