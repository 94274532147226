import classNames from "classnames";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Dognutoptions, Dougnutdata } from "../../../../helpers/Home/LearningPersona/GraphData";
import Headings from "../../../Shared/Headings";
import ContactFormat from "../ContentFormat";
import LearningPersonaStyling from "../LearningPersonaStyling.module.scss";

const LearningPersonaRightPanal = () => {

  return (
    <div className={classNames(`w-50 px-5 pt-5`)}>
      <Headings headingType="h5" classToOverride={classNames("fw-bold")}>
        Browsing Activity
      </Headings>
      <div className={classNames("d-flex py-5 justify-content-center")}>
        <div className={classNames(`${LearningPersonaStyling.ChartStyling}`)}>
          <Doughnut data={Dougnutdata} options={Dognutoptions} height={"200px"} />
        </div>
      </div>
      <ContactFormat />
    </div>
  );
};

export default LearningPersonaRightPanal;
