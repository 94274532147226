import { NetworkPermissions } from './Networks';

export const SerivcesPermission = (type) => {
  switch (type) {
    case 'networks':
      return NetworkPermissions;
  }
};

export const Permission = (key, check, exclude = []) =>
  check ? !exclude.includes(key) : exclude.includes(key);
