import { getApiCall } from '../../../../../utils/axios/axios';
import { API_URLS } from '../../../../../config/API_URLS/api_urls';
import store from '../../../../../redux/store';
import { SET_ACCOUNTS_DATA } from '../../../../../redux/Actions/Accounts';
import { fetchUserDetails } from '../../../../Profile/GeneralInfo';

export const getAccountsData = async () => {
  try {
    const queryParams = {};
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS?.accounts, paramsData);
    console.log(response, 'accounts response');
    store?.dispatch(SET_ACCOUNTS_DATA(response));
  } catch (err) {
    console.log('Error in getAccounts Data ', err);
  }
};

export const switchAccount = async (id) => {
  try {
    const queryParams = { id: id };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS?.switch_account, paramsData);
    if (response?.token) {
      localStorage.setItem('token', response?.token);
      fetchUserDetails();
    }
  } catch (err) {
    console.log('Error in switch Account', err);
  }
};
