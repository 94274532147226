import React from 'react';
import Headings from '../../../Shared/Headings';
import classNames from 'classnames';
import c from '../../learningPlans.Module.scss';
import { TextField } from '@mui/material';

const InputControls = ({
  inputLabel,
  id,
  values,
  textFieldLabel,
  handleChange,
  errors,
}) => {
  return (
    <>
      <Headings
        headingType="h6"
        classToOverride={classNames(c.inputGroupLabel, c.fontWeight500)}
      >
        {inputLabel}
      </Headings>

      <TextField
        fullWidth
        InputProps={{
          classes: {
            notchedOutline: values[id] ?? classNames(c.inputGroupBorder),
          },
        }}
        InputLabelProps={{
          classes: {
            root: values[id] ?? classNames(c.inputGroupLabel),
          },
        }}
        id={id}
        label={textFieldLabel}
        size="small"
        className="mt-3 mb-4"
        onChange={handleChange}
        error={errors[id] ? true : false}
        helperText={errors[id]}
      />
    </>
  );
};

export default InputControls;
